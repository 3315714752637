import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateTimeFormat'
})

export class DateTimeFormatPipe implements PipeTransform {
    transform(value: any): any {
        let timeLocation: Date = new Date(value);
        timeLocation = new Date(
            timeLocation.getFullYear(),
            timeLocation.getMonth(),
            timeLocation.getDate(),
            timeLocation.getHours() - 3,
            timeLocation.getMinutes(),
            timeLocation.getSeconds());
        return value = timeLocation;
    }
}