<div class="containerLogin">
    <div class="boxLogin">
        <div class="leftBox">
            <div class="whiteLogo"></div>
            <div class="textLogin">
                Economize tempo e recursos na gestão dos documentos empresariais
            </div>
        </div>
        <div class="rightBox">
            <div class="ocLogoItau"></div>
            <div class="boxForm">
                <form style="width: 90%; margin: 0 auto" #loginForm="ngForm" novalidate>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <h4>Você está a um passo de criar seu acesso ao ITCAD goLiza <br>Por favor complete o
                                cadastro abaixo.</h4>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p style="padding: 0.9rem; border: 1px solid #ccc"><span class="text-bold">Nome Completo:
                                </span>{{loginResponse?.ssoInformation?.userFullName}}</p>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p style="padding: 0.9rem; border: 1px solid #ccc"><span class="text-bold">E-mail:
                                </span>{{loginResponse?.ssoInformation?.userEmail}}</p>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                            <span class="p-float-label">
                                <p-inputMask class="inputLizaMask" inputId="documentNumber" type="tel"
                                    mask="999.999.999-99" [unmask]="true" [(ngModel)]="guestDocumentNumber"
                                    #documentNumber="ngModel" name="documentNumber" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber">Digite o CPF</label>
                            </span>
                            <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>
                    </div>
                    <br>
                    <div class="contentButtonActions">
                        <button pButton pRipple type="button" label="Criar" class="action-button p-ml-2"
                            (click)="onSave(loginForm)"></button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>