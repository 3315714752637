import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brCurrency'
})

export class BrCurrencyPipe implements PipeTransform {

  transform(value: any): any {
    
    let formatted: string = null;

    value = value.toFixed(2);

      formatted = 'R$ ' + value.toString().replace('.', ',');

      if (value >= 1000 && value < 10000) {
        formatted = [formatted.slice(0, 4), '.', formatted.slice(4)].join('');
      }else if (value >= 10000 && value < 100000) {
        formatted = [formatted.slice(0, 5), '.', formatted.slice(5)].join('');
      }else if (value >= 100000) {
        formatted = [formatted.slice(0, 6), '.', formatted.slice(6)].join('');
      }    

      return formatted;
  }
}