import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { ExtractDocumentVariablesRequest } from 'src/app/services/artifact-templates/models/requests/extract-document-variables-request';
import { FileUploadResponse } from 'src/app/services/file-uploads/models/responses/file-upload-response';
import { LocationService } from 'src/app/services/locations/location-service';
import { ProvinceViewModel } from 'src/app/services/locations/models/view-models/province-view-model';
import { UpdateRepresentationRequest } from 'src/app/services/representations/models/requests/update-representation-request';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './representation-edit.component.html',
    styleUrls: ['../../../../../assets/layout/customized/datatable.scss', '../../../../../assets/layout/customized/custom-card.scss']
})
export class RepresentationEditComponent implements OnInit {

    procuratorSeleceted: RepresentationViewModel = null;
    selectedBirthDate: Date = null;
    selectedExpireDate: Date = null;
    selectedGender: string = null;
    selectedAddressState: string = null;
    selectedBirthState: string = null;
    selectedAdditionalDocumentType: string = null;
    selectedAdditionalDocumentIssueDate: Date = null;
    selectedAdditionalDocumentIssuingBodyState: string = null;
    typeDocuments: SelectItem[] = [];
    genderOptions: SelectItem[] = [];
    allBirthState: SelectItem[] = [];
    allProvinces: ProvinceViewModel[] = [];
    allAddressState: SelectItem[] = [];
    allAdditionalDocumentIssuingBodyState: SelectItem[] = [];
    procurationFileUrl: string = null;
    documentFileUrl: string = null;
    displayEditUploadDocumentFile: boolean = false;
    displayEditProcurationFile: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private representationService: RepresentationService,
        private locationService: LocationService,
        private formValidationService: FormValidationService
    ) { }

    async ngOnInit() {
        this.loadingService.show();
        this.getGender();
        this.getTypeDocument();
        await this.getProvinces();
        await this.getProcurator();
        this.loadingService.hide();
    }

    getGender() {
        this.genderOptions = [
            { label: 'Masculino', value: 'Masculino' },
            { label: 'Feminino', value: 'Feminino' },
            { label: 'Outro', value: 'Outro' },
        ]
    }

    getTypeDocument() {
        this.typeDocuments = [
            { label: 'RG', value: 'RG' },
            { label: 'CNH', value: 'CNH' },
            { label: 'Passaporte', value: 'Passaporte' },
        ];
    }

    changeGender(event) {
        this.selectedGender = event.value;
    }

    changeExpireDate(event) {
        this.selectedExpireDate = event.value;
    }

    changeAddressState(event) {
        this.selectedAddressState = event.value;
    }

    changeBirthDate(event) {
        this.selectedBirthDate = event.value;
    }

    changeBirthState(event) {
        this.selectedBirthState = event.value;
    }

    changeTypeDocument(event) {
        this.selectedAdditionalDocumentType = event.value;
    }

    changeDocumentIssueDate(event) {
        this.selectedAdditionalDocumentIssueDate = event.value;
    }

    changeDocumentIssuingBodyState(event) {
        this.selectedAdditionalDocumentIssuingBodyState = event.value;
    }

    async getProvinces() {
        this.allProvinces = await this.locationService.getProvices();
        this.allProvinces.map(response => {
            this.allAddressState.push({ label: response.abbreviation, value: response.abbreviation })
        });
        this.allProvinces.map(response => {
            this.allAdditionalDocumentIssuingBodyState.push({ label: response.abbreviation, value: response.abbreviation })
        })
        this.allProvinces.map(response => {
            this.allBirthState.push({ label: response.abbreviation, value: response.abbreviation })
        })
    }

    async getProcurator() {
        const procuratorId = this.route.snapshot.params.id;
        await this.getProcuratorById(procuratorId).then(() => {
            this.procuratorSeleceted.expireDate = new Date(this.procuratorSeleceted.expireDate);
            this.selectedExpireDate = new Date(this.procuratorSeleceted.expireDate);
            this.selectedBirthDate = new Date(this.procuratorSeleceted.birthDate);
            this.selectedAdditionalDocumentIssueDate = new Date(this.procuratorSeleceted.additionalDocumentIssueDate);
            this.selectedGender = this.procuratorSeleceted.gender;
            this.selectedAddressState = this.procuratorSeleceted.addressState;
            this.selectedBirthState = this.procuratorSeleceted.birthState;
            this.selectedAdditionalDocumentType = this.procuratorSeleceted.additionalDocumentType;
            this.selectedAdditionalDocumentIssuingBodyState = this.procuratorSeleceted.additionalDocumentIssuingBodyState;
        });
    }

    async getProcuratorById(procuratorId: string) {
        this.procuratorSeleceted = await this.representationService.getRepresentationById(procuratorId);
    }

    confirm(procurator: RepresentationViewModel) {
        this.confirmationService.confirm({
            header: `Procurador ${procurator?.name}`,
            message: `Tem certeza que deseja excluir ${procurator?.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeProcurator(procurator)
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    onCancel() {
        this.router.navigateByUrl(`app/customer/representation/list`);
    }

    async removeProcurator(procurator: RepresentationViewModel) {
        const procuratorId = procurator.id;

        try {
            this.loadingService.show();
            await this.representationService.deleteRepresentationById(procuratorId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.router.navigateByUrl(`app/customer/representation/list`);
        }
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;

        const request: UpdateRepresentationRequest = {
            representationId: this.procuratorSeleceted.id,
            name: componentForm.form.value.name,
            email: componentForm.form.value.email,
            additionalDocumentType: this.selectedAdditionalDocumentType,
            additionalDocumentNumber: componentForm.form.value.additionalDocumentNumber,
            additionalDocumentIssueDate: componentForm.form.value.additionalDocumentIssueDate,
            additionalDocumentIssuingBody: componentForm.form.value.additionalDocumentIssuingBody,
            additionalDocumentIssuingBodyState: componentForm.form.value.additionalDocumentIssuingBodyState,
            birthDate: componentForm.form.value.birthDate,
            birthCity: componentForm.form.value.birthCity,
            birthState: componentForm.form.value.birthState,
            birthCountry: componentForm.form.value.birthCountry,
            nationality: componentForm.form.value.nationality,
            gender: componentForm.form.value.gender,
            addressStreet: componentForm.form.value.addressStreet,
            addressNumber: componentForm.form.value.addressNumber,
            addressComplement: componentForm.form.value.addressComplement,
            addressNeighborhood: componentForm.form.value.addressNeighborhood,
            addressZipCode: componentForm.form.value.addressZipCode,
            addressCity: componentForm.form.value.addressCity,
            addressState: componentForm.form.value.addressState,
            addressCountry: componentForm.form.value.addressCountry,
            phoneNumber: componentForm.form.value.phoneNumber,
            expireDate: componentForm.form.value.expireDate,
            procurationFileUrl: this.procurationFileUrl,
            documentFileUrl: this.documentFileUrl,

        }

        const procuratorId = this.route.snapshot.params.id;

        try {
            this.loadingService.show();
            await this.representationService.updateRepresentation(request);
            await this.getProcuratorById(procuratorId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.updateUrl();
        }

    }

    onUploadDocumentFile(responses: FileUploadResponse[]) {
        this.documentFileUrl = null;
        const request: ExtractDocumentVariablesRequest = {
            fileUrl: responses[0].fileUrl
        }
        this.documentFileUrl = request.fileUrl;
    }

    onUploadProcurationFile(responses: FileUploadResponse[]) {
        this.procurationFileUrl = null;
        const request: ExtractDocumentVariablesRequest = {
            fileUrl: responses[0].fileUrl
        }
        this.procurationFileUrl = request.fileUrl;
    }

    openDisplayEditUploadDocumentFile() {
        this.displayEditUploadDocumentFile = true;
    }

    openDisplayEditProcurationFile() {
        this.displayEditProcurationFile = true;
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }


}
