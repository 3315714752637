<ng-container>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items" (keydown.enter)="itemClick($event)"
       [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
       [pTooltip]="item.label" [tooltipDisabled]="!app.collapse">
        <span class="layout-menuitem-text headerMenuLiza"><p [ngClass]="{'textOverflow': app.collapse}">{{item.label}}</p></span>
        <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass" *ngIf="item.badge && !root">{{item.badge}}</span>
    </a>
    <a (click)="itemClick($event)" *ngIf="item.routerLink && !item.items"
       [routerLink]="item.routerLink" routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}"
       [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple
       [pTooltip]="item.label" [tooltipDisabled]="!app.collapse">
        <!-- <i [ngClass]="item.icon" class="layout-menuitem-icon"></i> -->
        <i class="material-icons">{{item.icon}}</i>
        <!-- <span class="material-icons-outlined">home</span> -->
        <span *ngIf="!app.labelCollapsed" class="layout-menuitem-text textLizaMenu">{{item.label}}</span>
        <span class="p-badge p-component p-badge-no-gutter" [ngClass]="item.badgeClass" *ngIf="item.badge && !root">{{item.badge}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
        <!-- <div class="upMenu"></div> -->
        <div class="downMenu"></div>
    </a>
    <ul class="ulSubMenuLiza" *ngIf="(item.items && root) || (item.items && active)" [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>

<!-- for menu accordion -->
        <!-- <div *ngIf="!app.collapse" id="menuAccordion">
            <p-accordion [multiple]="true" >
                <p-accordionTab header={{item?.items[0]?.label}}>
                    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
                        <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div *ngIf="app.collapse">
            <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
                <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
            </ng-template>
        </div> -->