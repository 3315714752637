import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { Console } from 'console';
import { MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { AssessmentPendingReasonEnum } from 'src/app/enums/assessment-pending-reason-Enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { AssessOperationByManagerRequest } from 'src/app/services/operations/models/request/assess-operation-by-manager-request';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel, RecipientViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { LoginSingleSignOnUserRequest } from 'src/app/services/users/models/requests/login-sso-request';
import { LoginResponseViewModel } from 'src/app/services/users/models/view-models/login-response-view-model';
import { UserRolesPropertiesViewModel } from 'src/app/services/users/models/view-models/user-roles-view-models';
import { UserService } from 'src/app/services/users/user-service';

@Component({
    templateUrl: './user-sso.component.html',
    styleUrls: ['./user-sso.component.scss'
        , '../../../assets/layout/customized/custom-card.scss']
})
export class UserSSOComponent implements OnInit {

    inviteUserRequest = null;
    guestDocumentNumber: string = null;

    loginResponse: LoginResponseViewModel = null;

    constructor(
        private router: Router,
        private contextService: ContextService,
        private route: ActivatedRoute,
        private operationService: OperationService,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private fileUploadService: FileUploadService,
        private userService: UserService,
    ) { }

    async ngOnInit() {
        this.setForms();
        await this.getUserInfo();
    }

    async getUserInfo() {
        this.loginResponse = await this.contextService.getUserInfo();
    }

    setForms() {
        this.inviteUserRequest = {
            fullName: null,
            email: null,
            documentNumber: null,
            userPlayerTypeId: PlayerTypeEnum.Customer
        }
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;

        try {
            this.loadingService.show();

            console.log('onSave()', 'guestDocumentNumber', this.guestDocumentNumber);
            console.log('onSave()', 'token', this.loginResponse.ssoInformation.ssoToken);

            const loginRequest: LoginSingleSignOnUserRequest = {
                token: this.loginResponse.ssoInformation.ssoToken,
                userEntry: {
                    documentNumber: this.guestDocumentNumber,
                    birthDate: null,
                    fullName: this.loginResponse.ssoInformation.userFullName,
                    email: this.loginResponse.ssoInformation.userEmail
                }
              };
          
              this.userService.loginSingleSignOn(loginRequest);
          
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Erro ao salvar dados complementares.',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }

    }

}
