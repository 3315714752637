import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { CustomvalidationService } from '../../providers/custom-validation-service';

@Directive({
    selector: '[appValidatePassword]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => ValidatePasswordDirective), multi: true }]

})
export class ValidatePasswordDirective implements Validator {

    constructor(private customValidator: CustomvalidationService) { }

    validate(control: AbstractControl): Promise<{ [key: string]: any }> | Observable<{ [key: string]: any }> {
        return this.customValidator.patternValidator(control);
    }
}