<div class="titleCustomCard">
        Procuradores
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="customCard">
            <p-tabView>
                <p-tabPanel header="Procuradores Ativos">
                    <p-table [responsive]="true" #dt [value]="procuratorsActive" dataKey="id" styleClass="p-datatable-customized"
                        selectionMode="single" [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                        [globalFilterFields]="['name','maskedPersonalDocumentNumber', 'email', 'expireDate']">
                        <ng-template pTemplate="caption">
                            <div class="contentButtonActions p-fluid">
                                <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search iconPosition"></i>
                                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Filtrar" />
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="!isUserOperator">
                                    <button  pButton type="button" label="Novo Procurador" class="p-mr-2 p-mb-2"
                                        (click)="addProcurator()"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="name">
                                    <div>
                                        Nome do Procurador
                                        <p-sortIcon field="name"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="maskedPersonalDocumentNumber">
                                    <div>
                                        CPF Procurador
                                        <p-sortIcon field="maskedPersonalDocumentNumber"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="email">
                                    <div>
                                        Email
                                        <p-sortIcon field="email"></p-sortIcon>
                                    </div>
                                </th>
                    
                                <th pSortableColumn="expireDate">
                                    <div>
                                        Validade
                                        <p-sortIcon field="expireDate"></p-sortIcon>
                                    </div>
                                </th>
                                <th>Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-procurator let-index>
                            <tr style="cursor: pointer" [pSelectableRow]="procurator">
                                <td>
                                    <!-- <span class="p-column-title">Nome</span> -->
                                    {{procurator.name}}
                                </td>
                                <td>
                                    {{procurator.maskedPersonalDocumentNumber}}
                                </td>
                                <td>
                                    {{procurator.email}}
                                </td>
                                <td>
                                    {{procurator.expireDate | date: 'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    <!-- <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button> -->
                                    <p-menu #menu [popup]="true" [model]="getMenuItems(procurator)"></p-menu>
                                    <button type="button" pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text"
                                        (click)="menu.toggle($event)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Nenhum usuário encontrado.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
                <p-tabPanel header="Procuradores Inativos">
                    <p-table [responsive]="true" #dt [value]="procuratorsInactive" dataKey="id" styleClass="p-datatable-customized"
                    selectionMode="single" [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                    [globalFilterFields]="['name','maskedPersonalDocumentNumber', 'email', 'expireDate']">
                    <ng-template pTemplate="caption">
                        <div class="contentButtonActions p-fluid">
                            <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search iconPosition"></i>
                                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Filtrar" />
                                </span>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="!isUserOperator">
                                <button pButton type="button" label="Novo Procurador" class="p-mr-2 p-mb-2"
                                    (click)="addProcurator()"></button>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">
                                <div>
                                    Nome do Procurador
                                    <p-sortIcon field="name"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="maskedPersonalDocumentNumber">
                                <div>
                                    CPF Procurador
                                    <p-sortIcon field="maskedPersonalDocumentNumber"></p-sortIcon>
                                </div>
                            </th>
                            <th pSortableColumn="email">
                                <div>
                                    Email
                                    <p-sortIcon field="email"></p-sortIcon>
                                </div>
                            </th>
                
                            <th pSortableColumn="expireDate">
                                <div>
                                    Validade
                                    <p-sortIcon field="expireDate"></p-sortIcon>
                                </div>
                            </th>
                            <th>Ações</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-procurator let-index>
                        <tr style="cursor: pointer" [pSelectableRow]="procurator">
                            <td>
                                <!-- <span class="p-column-title">Nome</span> -->
                                {{procurator.name}}
                            </td>
                            <td>
                                {{procurator.maskedPersonalDocumentNumber}}
                            </td>
                            <td>
                                {{procurator.email}}
                            </td>
                            <td>
                                {{procurator.expireDate | date: 'dd/MM/yyyy'}}
                            </td>
                            <td>
                                <!-- <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button> -->
                                <p-menu #menu [popup]="true" [model]="getMenuItems(procurator)"></p-menu>
                                <button type="button" pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text"
                                    (click)="menu.toggle($event)"></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">Nenhum usuário encontrado.</td>
                        </tr>
                    </ng-template>
                </p-table>
                </p-tabPanel>
            </p-tabView>
            
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>