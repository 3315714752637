<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Fundos
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p-tabView [(activeIndex)]="tabIndex" (onChange)="changeTab($event.index)">
                        <p-tabPanel header="Fundo Cotista">
                            <p-table [responsive]="true" #dt [value]="shareholderFunds" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['name', 'documentNumber']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                            <span class="p-input-icon-left">
                                                <!-- <i class="pi pi-search iconPosition"></i>
                                                <input pInputText type="text" [(ngModel)]="search"
                                                (input)="filter(search)"
                                                placeholder="Filtrar" /> -->
                                                <i class="pi pi-search"></i>
                                                <input type="text" pInputText placeholder="Filtrar" (input)="filter($event.target.value)" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th pSortableColumn="isShareholder">
                                        <div>
                                            Fundo Cotista ou Investido
                                            <p-sortIcon field="isShareholder"></p-sortIcon>
                                        </div>
                                    </th> -->
                                        <th pSortableColumn="name">
                                            <div>
                                                Razão Social
                                                <p-sortIcon field="name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="clousureDate">
                                            <div>
                                                Ativo/Encerrado
                                                <p-sortIcon field="clousureDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-fund let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="fund">
                                        <!-- <td>
                                        {{fund.isShareholder ? 'FUNDO COTISTA': 'FUNDO INVESTIDO'}}
                                    </td> -->
                                        <td>
                                            {{fund.name}}
                                        </td>
                                        <td>
                                            {{fund.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{fund.clousureDate ? (fund?.clousureDate | date: 'dd/MM/yyyy') : ('--')}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(fund)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhum usuário encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Fundo Investido"
                            *ngIf="selectedApplication?.application?.value == applicationTypeEnum.FoF async">
                            <p-table [responsive]="true" #dtInvested [value]="investedFunds" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['name', 'documentNumber']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                            <span class="p-input-icon-left">
                                                <!-- <i class="pi pi-search iconPosition"></i>
                                                <input pInputText type="text" [(ngModel)]="searchInvestedFund"
                                                    (input)="filter(searchInvestedFund)" placeholder="Filtrar" /> -->
                                                <i class="pi pi-search"></i>
                                                <input type="text" pInputText placeholder="Filtrar" (input)="filter($event.target.value)" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th pSortableColumn="isShareholder">
                                        <div>
                                            Fundo Cotista ou Investido
                                            <p-sortIcon field="isShareholder"></p-sortIcon>
                                        </div>
                                    </th> -->
                                        <th pSortableColumn="name">
                                            <div>
                                                Razão Social
                                                <p-sortIcon field="name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-fund let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="fund">
                                        <!-- <td>
                                        {{fund.isShareholder ? 'FUNDO COTISTA': 'FUNDO INVESTIDO'}}
                                    </td> -->
                                        <td>
                                            {{fund.name}}
                                        </td>
                                        <td>
                                            {{fund.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(fund)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhum usuário encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Carteira">
                            <p-table [responsive]="true" #dtWallet [value]="wallet" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['name', 'documentNumber']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                            <span class="p-input-icon-left">
                                                <!-- <i class="pi pi-search iconPosition"></i>
                                                <input pInputText type="text" [(ngModel)]="searchWallet"
                                                    (input)="filter(search)" placeholder="Filtrar" /> -->
                                                <i class="pi pi-search"></i>
                                                <input type="text" pInputText placeholder="Filtrar" (input)="filter($event.target.value)" />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header">
                                    <tr>
                                        <!-- <th pSortableColumn="isShareholder">
                                        <div>
                                            Fundo Cotista ou Investido
                                            <p-sortIcon field="isShareholder"></p-sortIcon>
                                        </div>
                                    </th> -->
                                        <th pSortableColumn="name">
                                            <div>
                                                Razão Social
                                                <p-sortIcon field="name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="clousureDate">
                                            <div>
                                                Ativo/Encerrado
                                                <p-sortIcon field="clousureDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Ações</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-fund let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="fund">
                                        <!-- <td>
                                        {{fund.isShareholder ? 'FUNDO COTISTA': 'FUNDO INVESTIDO'}}
                                    </td> -->
                                        <td>
                                            {{fund.name}}
                                        </td>
                                        <td>
                                            {{fund.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{fund.clousureDate ? (fund?.clousureDate | date: 'dd/MM/yyyy') : ('--')}}
                                        </td>
                                        <td>
                                            <p-menu #menu [popup]="true" [model]="getMenuWallet(fund)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhum usuário encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>

                </div>
            </div>
        </div>

    </div>
</div>