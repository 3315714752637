<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Administrador
            </div>
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações</div>
                    </div>
                    <div class="p-col-12 p-md-8 p-lg-8 ">
                        <div class="borderOutlined heightFix100">
                            <p><b class="text-bold">Nome:</b> <span> {{selectedFundAdministrator?.name}}</span></p>
                            <p><b class="text-bold">CNPJ:</b> <span> {{selectedFundAdministrator?.documentNumber
                                    }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="selectedFundAdministrator?.registrationFormTemplate">
                            <div class="text-bold">
                                Modelo de Ficha Cadastral
                            </div>
                            <a [href]="selectedFundAdministrator?.registrationFormTemplate?.fileUrl" target="_blank"
                                class="linkPrimary">
                                Ver PDF
                            </a>
                        </div>
                        <!-- <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="!selectedFundAdministrator?.registrationFormTemplate">
                            <div class="text-bold">
                               Modelo Ficha Cadastral
                            </div>
                            <a class="linkPrimary" (click)="updateRegistrationFormTemplate()">
                                Cadastrar
                            </a>
                        </div> -->
                    </div>
                </div>
                <br>
                <div class="cardHeader text-bold">Alterar Template da Ficha Cadastral</div>
                <app-artifact-template-create (onArtifactFilled)="registrationFormTemplateFilled($event)"></app-artifact-template-create>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                            (click)="onSave(entryForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>