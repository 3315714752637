<div class="content-analysis">
    <div class="boxLeft"></div>
    <div class="content-wrapp">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        {{selectedOperation?.type?.friendlyName}}
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <div class="p-col-9 p-md-9 p-lg-9"></div>
                        <div class="p-col-1 p-md-1 p-lg-1">
                            <p class="text-bold">ID</p>
                        </div>
                        <div class="p-col-2 p-md-2 p-lg-2">
                            {{selectedOperation?.sequentialId}}
                        </div>

                        <ng-container *ngIf="
                        selectedOperation?.type?.value == operationTypeEnum.CessaoDeCotasCompra || 
                        selectedOperation?.type?.value == operationTypeEnum.CessaoDeCotasDoacao ||
                        selectedOperation?.type?.value == operationTypeEnum.Incorporacao || 
                        selectedOperation?.type?.value == operationTypeEnum.Cisao || 
                        selectedOperation?.type?.value == operationTypeEnum.Integralizacao ||
                        selectedOperation?.type?.value == operationTypeEnum.Masterizacao">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <p class="text-bold">Fundo de Origem:</p>
                            </div>

                            <div class="p-col-12 col-md-6 p-lg-6">
                                {{selectedOperation?.destinationShareholderFund?.name}}
                            </div>

                            <div class="p-col-12 p-md-1 p-lg-1">
                                <p class="text-bold">CNPJ</p>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2">
                                {{selectedOperation?.destinationShareholderFund?.documentNumber| documentNumber:
                                'cnpj'}}
                            </div>
                        </ng-container>

                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Fundo Cotista:</p>
                        </div>

                        <div class="p-col-12 col-md-6 p-lg-6">
                            {{selectedOperation?.shareholderFund?.name}}
                        </div>

                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold"> Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedOperation?.investedFund?.name}}
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.investedFund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Distribuidor do fundo que receberá o rebate da aplicação:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.operationDistributorName}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.operationDistributorDocumentNumber | documentNumber: 'cnpj'}}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Administrador do Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.investedFund?.administrator?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.investedFund?.administrator?.documentNumber | documentNumber: 'cnpj'}}
                        </div>

                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Gestora do Fundo Investido:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedOperation?.investedFund?.manager?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedOperation?.investedFund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-4 p-lg-4" style="border: 1px solid white" [hidden]="selectedOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                        selectedOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="contentCenterColumn1">
                                <p class="textPrimary">Consulta CVM:</p>
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://sistemas.cvm.gov.br/?CadGeral" target="_blank">Site
                                    CVM</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        Resumo
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-2 p-lg-2"
                                        *ngFor="let artefact of selectedOperation?.artifacts; let index = index">
                                        <div class="contentCenterColumn">
                                            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
                                            <p class="text-bold textPrimary">{{artefact?.status?.friendlyName }}</p>
                                            <!-- <button
                                                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="action-button p-ml-2"></button> -->
                                            <button
                                                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && !artefact.isSignatureRequired"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="p-button"></button>
                                            <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="p-button"></button>
                                            <button
                                                *ngIf="artefact.status.value == artifactStatusEnum.Assinado && artefact.isSignatureRequired"
                                                (click)="downloadArtefact(artefact)" pButton pRipple type="button"
                                                label="Download" class="p-button"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [canRemoveFile]="false" [selectedOperation]="selectedOperation"
                                    (openArtefactUrlEmitter)="openAttachementUrl($event)"
                                    (openAddFileEmitter)="openAddFile()">
                                </app-attachment-list>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedOperation]="selectedOperation"
                                    [assessments]="assesments"></app-assessment-list>
                            </div>
                            <br>
                        </div>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Ações
                            </div>
                            <div class="borderOutlined">

                                <div class="contentAlignItemsCenterGap">
                                    <button pButton pRipple type="button" label="Aprovar" class="p-button-primary"
                                        (click)="openAlertDismissAssesment()"></button>
                                    <button pButton pRipple type="button" label="Pendenciar" class="p-button-outlined"
                                        (click)="openAvailableAssesments()"></button>
                                    <!-- *ngIf="assesments?.assessments.length == 0" -->
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Pendenciar" [(visible)]="displayAvailableAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <form #availableAssesmentForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div class="field-radiobutton">
                    <p-radioButton name="fillincorrecet" value="Preechimento Incorreto" [(ngModel)]="selectedAssesment">
                    </p-radioButton>
                    <label for="fillincorrecet"> Preechimento Incorreto</label>
                </div>
                <br>
                <div class="field-radiobutton">
                    <p-radioButton name="document" value="Documento Faltante" [(ngModel)]="selectedAssesment">
                    </p-radioButton>
                    <label for="document"> Documento Faltante</label>
                </div>
                <!-- <p-checkbox name="label" value="value" [value]="item" [(ngModel)]="selectedPendingItem">
                </p-checkbox>
                <label [for]="item.label"> {{item.label}}</label> -->
            </div>
            <br>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <!-- <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text" [(ngModel)]="requestAssesment.notes" name="notes" #notes="ngModel"
                            class="inputLiza" required pInputText>
                        <label for="name">Descrição</label>
                    </span>
                </div> -->
                <!-- <span class="p-float-label p-fluid"> -->
                <textarea id="float-input" pInputTextarea name="notes" required [style]="{'width': '100%'}"
                    placeholder="Descrição" [(ngModel)]="requestAssesment.notes" #notes="ngModel"></textarea>
                <!-- <label for="float-input">Descrição:</label> -->
                <!-- </span> -->
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <b>Obs: Caso o(s) documento(s) recebido(s) esteja(m) desatualizado(s), utilizar o campo Anexo dentro da
                    plataforma para subir o formulário correto.</b>
            </div>
        </div>
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                (click)="onAvailableAssesment(availableAssesmentForm)"></button>
        </div>
    </form>

</p-dialog>

<p-dialog header="Adicionar arquivo" [(visible)]="displayOperationFiles" [modal]="true" [responsive]="true"
    [style]="{width: '50vw'}">
    <form #filesForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <input id="name" type="text" [(ngModel)]="requestFiles.description" name="description"
                    #description="ngModel" placeholder="Descrição" class="inputLiza" required pInputText>

            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" style="display: flex; justify-content: center;">
                <p-fileUpload [files]="requestFiles.files" [multiple]="false" [customUpload]="true" [auto]="true"
                    (uploadHandler)="uploadFiles($event.files, filesForm)"></p-fileUpload>
            </div>
        </div>
    </form>

</p-dialog>

<p-dialog header=" " [(visible)]="displayAlertDismissAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}" [closable]="true">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <h3 style="text-align: center;"><span class="textPrimary">Aviso</span>!</h3>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold text-center" style="color: #000;">Você tem certeza de que deseja aprovar esta operação?
            </p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify; color: #000;">
                Uma vez aprovando esta operação significa que os documentos foram analisados e a solicitação foi
                concluída. O status será alterado para “<span class="textSecondary">Concluído</span>”, não havendo mais
                a oportunidade de pendenciar ou
                acessar novamente esta operação.</p>
        </div>
        <br>
        <br>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;" style="color: #000;">
                <li>
                    <span class="textPrimary">Compra:</span> O cadastro foi finalizado e o cotista está apto a efetuar
                    movimentações
                </li>
            </p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;" style="color: #000;">
                <li>
                    <span class="textPrimary">Atualização cadastral / Transferência de Administrador / Alteração de
                        Distribuidor / Movimentação Diária:</span> O cadastro do cotista em questão foi atualizado de
                    acordo com os documentos enviados.
                </li>
            </p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;" style="color: #000;">
                <li>
                    <span class="textPrimary">Incorporação / Cisão / Integralização / Masterização:</span> O cadastro do
                    cotista em questão foi finalizado e o ajuste no passivo dos fundos foi realizado.
                </li>
            </p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;" style="color: #000;">
                <li>
                    <span class="textPrimary">Resgate de Come Cotas:</span> A carta foi acatada.
                </li>
            </p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;" style="color: #000;">
                <li>
                    <span class="textPrimary">Boletim de Subscrição:</span> O cadastro foi finalizado e o boletim de
                    subscrição foi acatado.
                </li>
            </p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
            <button class="p-button-outlined" (click)="displayAlertDismissAssesment = false" pButton pRipple
                type="button" label="Cancelar"></button>
            <button class="p-button" (click)="approvedAssesments()" pButton pRipple type="button"
                label="Aprovar"></button>
        </div>
    </div>
</p-dialog>


<p-dialog header=" " [(visible)]="displayOperationTypeMessage" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}" [closable]="true">

    <div class="p-grid">

    </div>
</p-dialog>