import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { AssessmentPendingReasonEnum } from 'src/app/enums/assessment-pending-reason-Enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { AssessOperationByManagerRequest } from 'src/app/services/operations/models/request/assess-operation-by-manager-request';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { LoginResponseViewModel } from 'src/app/services/users/models/view-models/login-response-view-model';

@Component({
    templateUrl: './manager-analysis.component.html',
    styleUrls: ['./manager-analysis.component.scss'
        , '../../../assets/layout/customized/custom-card.scss']
})
export class ManagerAnalysisComponent implements OnInit {

    selectedOperation: OperationViewModel = null;
    // assesments: RecipientViewModel = null;
    assesments: AssessmentSummaryViewModel[] = [];
    token: string = null;
    tokenExpiration: Date = null;
    contactId: string = null;
    operationId: string = null;
    requestAssesment = null;
    requestApprovedAssesment = null;
    displayAvailableAssesment: boolean = false;
    displayApprovedAssesment: boolean = false;
    selectedAssesment = null;
    assesmentReasons: number = null;
    displayAlertDismissAssesment: boolean = false;

    notApproved: boolean = null;
    displayOperationFiles: boolean = false;
    displayOperationTypeMessage: boolean = false;
    requestFiles = null;

    public operationStatusEnum = OperationStatusEnum;
    public artifactStatusEnum = ArtifactStatusEnum;
    public operationTypeEnum = OperationTypeEnum;

    constructor(
        private router: Router,
        private contextService: ContextService,
        private route: ActivatedRoute,
        private operationService: OperationService,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private fileUploadService: FileUploadService,
    ) { }

    async ngOnInit() {
        this.setForms();
        this.setFormUpload();
        await this.verifyParams();
    }

    setFormUpload() {
        this.requestFiles = {
            description: null,
            files: []
        }
    }

    setForms() {
        this.requestAssesment = {
            notes: null,
        }
        this.requestApprovedAssesment = {
            notes: null,
        }
    }

    async verifyParams() {
        if (this.route.snapshot.queryParams.token &&
            this.route.snapshot.queryParams.expirationDate &&
            this.route.snapshot.queryParams.operation &&
            this.route.snapshot.queryParams.contact) {
            this.token = this.route.snapshot.queryParams.token;
            const expirationParts: string[] = this.route.snapshot.queryParams.expirationDate.split('-');
            this.tokenExpiration = new Date(+expirationParts[0], +expirationParts[1], +expirationParts[2], +expirationParts[3], +expirationParts[4], +expirationParts[5]);
            this.contactId = this.route.snapshot.queryParams.contact;
            this.operationId = this.route.snapshot.queryParams.operation;
            await this.setToken();
        } else {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'O link não é válido.', life: 5000 });
            this.contextService.logout();
        }
    }

    async setToken() {
        this.contextService.clearUserInfo();
        var user = {
            id: this.contactId
        };
        const request: LoginResponseViewModel = {
            token: this.token,
            expirationDate: this.tokenExpiration,
            user: user,
            applications: [{
                application: {
                    value: 1,
                    displayName: "FoF",
                    friendlyName: "FoF"
                },
                siteMap: []
            }],
            ssoInformation: null
            // siteMap: [],
        }
        this.contextService.setLoginResponse(request);
        await this.loadData();
        await this.loadAssesments();
        // this.operationNotApproved();
    }

    async loadData() {
        this.selectedOperation = await this.operationService.getOperationById(this.operationId);
        if (this.selectedOperation == null || this.selectedOperation.currentStatus.status.value != this.operationStatusEnum.EmAnalisePeloGestor) {
            this.messageService.add({ severity: 'warn', summary: 'Operação Expirada', detail: 'Esta operação já foi analisada por outro contato.', life: 5000 });
            this.contextService.logout();
        }
        this.selectedOperation?.attachments.map(it => {
            const format = new Date(it.createDate);
            format.setHours(format.getHours() - 3);
            it.createDate = format.toISOString();
        });
    }

    async loadAssesments() {
        const operationId = this.selectedOperation.id;
        this.assesments = await this.operationService.getOperationAssessments(operationId);
        this.assesments = this.assesments.sort((a, b) => {
            if (new Date(a.createDate) < new Date(b.createDate)) {
                return 1;
            } else {
                return -1
            }
        });
    }

    openApprovedAssesment() {
        this.displayApprovedAssesment = true;
    }

    async approvedAssesments() {
        this.displayAlertDismissAssesment = false;
        try {
            const request: AssessOperationByManagerRequest = {
                operationId: this.selectedOperation.id,
                notes: 'Aprovado',
                isApproved: true,
                assessmentPendingReasonId: null,
            }
            this.loadingService.show();
            this.displayApprovedAssesment = false;
            await this.operationService.createAssessment(request);
            await this.loadAssesments();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setForms();
            this.loadingService.hide();
        }
    }

    openAvailableAssesments() {
        this.displayAvailableAssesment = true
    }

    async onAvailableAssesment(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        if (this.selectedAssesment == 'Preechimento Incorreto') {
            this.assesmentReasons = AssessmentPendingReasonEnum.PreenchimentoIncorreto;
        } else if (this.selectedAssesment == 'Documento Faltante') {
            this.assesmentReasons = AssessmentPendingReasonEnum.DocumentoFaltante;
        }
        try {
            const request: AssessOperationByManagerRequest = {
                operationId: this.selectedOperation.id,
                notes: this.requestAssesment.notes,
                isApproved: false,
                assessmentPendingReasonId: this.assesmentReasons,
            }
            this.loadingService.show();
            this.displayAvailableAssesment = false;
            await this.operationService.createAssessment(request);
            await this.loadAssesments();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setForms();
            this.loadingService.hide();
        }
    }

    fillInfoTemplate(artifact: ArtifactViewModel) {
        let url = artifact.template?.fileUrl;

        if (artifact.signedFile != null) {
            url = artifact.signedFile;
        } else if (artifact?.originalFile != null) {
            url = artifact.originalFile;
        }
        window.open(url, '_blank');
    }

    openAttachementUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        window.open(file.fileUrl, '_blank');
    }

    downloadArtefact(artefact: ArtifactViewModel) {
        window.open(artefact.signedFile);
    }

    openAddFile() {
        this.displayOperationFiles = true;
    }

    async uploadFiles(event: File, formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) {
            this.requestFiles.files = [];
            return;
        };

        try {
            this.displayOperationFiles = false;
            this.loadingService.show();
            const response = await this.fileUploadService.upload(event[0]);
            const request: AddAttachmentRequest = {
                operationId: this.selectedOperation.id,
                description: this.requestFiles.description,
                fileUrl: response.fileUrl
            }
            this.selectedOperation = await this.operationService.operationAttachments(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationFiles = false;
        } finally {
            this.setFormUpload();
            this.loadingService.hide();
            this.loadData();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

    openAlertDismissAssesment() {
        this.displayAlertDismissAssesment = true;
    }

}
