import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRootComponent } from './navigation/app.root/app.root.component';
import { AppAccessdeniedComponent } from './pages/access-denied/app.access-denied.component';
import { BrokerAnalysisComponent } from './pages/broker-analysis/broker-analysis.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { LoggedUserProfileComponent } from './pages/logged-user-profile/logged-user-profile.component';
import { AppLoginComponent } from './pages/login/app.login.component';
import { ManagerAnalysisComponent } from './pages/manager-analysis/manager-analysis.component';
import { AppNotfoundComponent } from './pages/not-found/app.not-found.component';
import { UserActivationComponent } from './pages/user-activation/user-activation.component';
import { RouterValidationService } from './providers/router-validation-service';
import { UserSSOComponent } from './pages/user-sso/user-sso.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: AppLoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'user-activation/:userId',
        component: UserActivationComponent
    },
    {
        path: 'error',
        component: AppErrorComponent
    },
    {
        path: 'access-denied',
        component: AppAccessdeniedComponent
    },
    {
        path: 'manager-analysis',
        component: ManagerAnalysisComponent
        //manager-analysis?contact={xxxxxxxxxx}&operation={xxxxxxxxxxx}&token={xxxxxxxxxxxxx}&expirationDate
    },
    {
        path: 'broker-analysis',
        component: BrokerAnalysisComponent
        //broker-analysis?representative={xxxxxxxxxx}&operation={xxxxxxxxxxx}&token={xxxxxxxxxxxxx}&expirationDate
    },
    {
        path: 'user-sso',
        component: UserSSOComponent
    },
    {
        path: '404',
        component: AppNotfoundComponent
    },
    {
        path: 'app',
        component: AppRootComponent,
        children: [            
            {
                path: 'liza',
                loadChildren: () => import('./components/liza/liza.module').then(m => m.LizaModule)
            },            
            {
                path: 'customer',
                loadChildren: () => import('./components/customer/customer.module').then(m => m.CustomerModule)
            },
            {
                path: 'my-profile',
                component: LoggedUserProfileComponent,
                canActivate: [RouterValidationService]
            },
        ]
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }