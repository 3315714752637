import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { Calendar } from 'primeng/calendar';
import { AddressInformation } from 'src/app/modules/address-form/address-form.component';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { ExtractDocumentVariablesRequest } from 'src/app/services/artifact-templates/models/requests/extract-document-variables-request';
import { FileUploadResponse } from 'src/app/services/file-uploads/models/responses/file-upload-response';
import { LocationService } from 'src/app/services/locations/location-service';
import { ProvinceViewModel } from 'src/app/services/locations/models/view-models/province-view-model';
import { CreateRepresentationFromIntegrationRequest } from 'src/app/services/representations/models/requests/create-representation-from-integration-request';
import { RepresentationService } from 'src/app/services/representations/representation-service';
import { UserService } from 'src/app/services/users/user-service';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './representation-create.component.html',
    styleUrls: ['../../../../../assets/layout/customized/datatable.scss', '../../../../../assets/layout/customized/custom-card.scss']
})
export class RepresentationCreateComponent implements OnInit {

    request: CreateRepresentationFromIntegrationRequest = null;
    genderOptions: SelectItem[] = null;
    typeDocuments: SelectItem[] = null;
    allProvinces: ProvinceViewModel[] = [];
    allAddressState: SelectItem[] = [];
    allAdditionalDocumentIssuingBodyState: SelectItem[] = [];
    allBirthState: SelectItem[] = [];
    selectedAdditionalDocumentType: string = null;
    selectedAdditionalDocumentIssuingBodyState: string = null;
    selectedBirthState: string = null;
    selectedGender: string = null;


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private userService: UserService,
        private contextService: ContextService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private locationService: LocationService,
        private formValidationService: FormValidationService,
        private representationService: RepresentationService,
    ) { }

    ngOnInit() {
        this.setForms();
        this.getGender();
        this.getTypeDocument();
        this.getProvinces();
    }

    getGender() {
        this.genderOptions = [
            { label: 'Masculino', value: 'Masculino' },
            { label: 'Feminino', value: 'Feminino' },
            { label: 'Outro', value: 'Outro' },
        ]
    }

    getTypeDocument() {
        this.typeDocuments = [
            { label: 'RG', value: 'RG' },
            { label: 'CNH', value: 'CNH' },
            { label: 'Passaporte', value: 'Passaporte' },
        ];
    }

    async getProvinces() {
        this.allProvinces = await this.locationService.getProvices();
        this.allProvinces.map(response => {
            this.allAddressState.push({ label: response.abbreviation, value: response.abbreviation })
        });
        this.allProvinces.map(response => {
            this.allAdditionalDocumentIssuingBodyState.push({ label: response.abbreviation, value: response.abbreviation })
        })
        this.allProvinces.map(response => {
            this.allBirthState.push({ label: response.abbreviation, value: response.abbreviation })
        })
    }

    setForms() {
        this.request = {
            name: null,
            email: null,
            personalDocumentNumber: null,
            additionalDocumentType: null,
            additionalDocumentNumber: null,
            additionalDocumentIssueDate: null,
            additionalDocumentIssuingBody: null,
            additionalDocumentIssuingBodyState: null,
            birthDate: null,
            birthCity: null,
            birthState: null,
            birthCountry: null,
            nationality: null,
            gender: null,
            addressStreet: null,
            addressNumber: null,
            addressComplement: null,
            addressNeighborhood: null,
            addressZipCode: null,
            addressCity: null,
            addressState: null,
            addressCountry: null,
            phoneNumber: null,
            expireDate: null,
            procurationFileUrl: null,
            documentFileUrl: null,
        }
    }

    onCancel() {
        this.router.navigateByUrl(`app/customer/representation/list`);
    }

    async onSave(form: NgForm) {
        if (!this.formValidationService.validateForm(form.form)) return;

        try {
            const request: CreateRepresentationFromIntegrationRequest = {
                name: this.request.name,
                email: this.request.email,
                personalDocumentNumber: this.request.personalDocumentNumber,
                additionalDocumentType: this.selectedAdditionalDocumentType,
                additionalDocumentNumber: this.request.additionalDocumentNumber,
                additionalDocumentIssueDate: this.request.additionalDocumentIssueDate,
                additionalDocumentIssuingBody: this.request.additionalDocumentIssuingBody,
                additionalDocumentIssuingBodyState: this.selectedAdditionalDocumentIssuingBodyState,
                birthDate: this.request.birthDate,
                birthCity: this.request.birthCity,
                birthState: this.selectedBirthState,
                birthCountry: this.request.birthCountry,
                nationality: this.request.nationality,
                gender: this.selectedGender,
                addressStreet: this.request.addressStreet,
                addressNumber: this.request.addressNumber,
                addressComplement: this.request.addressComplement,
                addressNeighborhood: this.request.addressNeighborhood,
                addressZipCode: this.request.addressZipCode,
                addressCity: this.request.addressCity,
                addressState: this.request.addressState,
                addressCountry: this.request.addressCountry,
                phoneNumber: this.request.phoneNumber,
                expireDate: this.request.expireDate,
                procurationFileUrl: this.request.procurationFileUrl,
                documentFileUrl: this.request.documentFileUrl,
            }
            await this.representationService.createRepresentation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setForms();
            this.loadingService.hide();
            this.router.navigateByUrl(`app/customer/representation/list`);
        }
    }

    onAddressSelect(event: AddressInformation) {
        this.request.addressStreet = event.street;
        this.request.addressNumber = event.addressNumber;
        this.request.addressComplement = event.complement;
        this.request.addressNeighborhood = event.neighborhood;
        this.request.addressZipCode = event.postalCode;
        this.request.addressCity = event.city;
        this.request.addressState = event.province;
    }


    onUploadDocumentFile(responses: FileUploadResponse[]) {
        this.request.documentFileUrl = null;
        const request: ExtractDocumentVariablesRequest = {
            fileUrl: responses[0].fileUrl
        }
        this.request.documentFileUrl = request.fileUrl;
    }

    onUploadProcurationFile(responses: FileUploadResponse[]) {
        this.request.procurationFileUrl = null;
        const request: ExtractDocumentVariablesRequest = {
            fileUrl: responses[0].fileUrl
        }
        this.request.procurationFileUrl = request.fileUrl;
    }


}
