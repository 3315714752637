import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';
import { DirectivesModule } from '../directives/directives-module';
import { AddressFormModule } from '../modules/address-form/address-form.module';
import { AssessmentModule } from '../modules/assessment/assessment.module';
import { AttachmentModule } from "../modules/attachment/attachment.module";
import { FileUploaderModule } from '../modules/fileuploader/fileuploader.module';
import { PipesModule } from '../pipes/pipes-module';
import { AppAccessdeniedComponent } from './access-denied/app.access-denied.component';
import { BrokerAnalysisComponent } from './broker-analysis/broker-analysis.component';
import { AppErrorComponent } from './error/app.error.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AppLandingComponent } from './landing/app.landing.component';
import { LoggedUserProfileComponent } from './logged-user-profile/logged-user-profile.component';
import { AppLoginComponent } from './login/app.login.component';
import { ManagerAnalysisComponent } from './manager-analysis/manager-analysis.component';
import { AppNotfoundComponent } from './not-found/app.not-found.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { UserSSOComponent } from './user-sso/user-sso.component';

const COMPONENTS = [
  AppAccessdeniedComponent,
  AppErrorComponent,
  AppLandingComponent,
  AppLoginComponent,
  AppNotfoundComponent,
  LoggedUserProfileComponent,
  ForgotPasswordComponent,
  UserActivationComponent,
  ManagerAnalysisComponent,
  BrokerAnalysisComponent,
  UserSSOComponent
];

@NgModule({
    declarations: COMPONENTS,
    entryComponents: COMPONENTS,
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        ButtonModule,
        DropdownModule,
        CalendarModule,
        RadioButtonModule,
        CheckboxModule,
        InputTextModule,
        StepsModule,
        InputMaskModule,
        PasswordModule,
        DirectivesModule,
        PipesModule,
        AddressFormModule,
        SelectButtonModule,
        InputNumberModule,
        InputTextModule,
        InputMaskModule,
        ScrollPanelModule,
        DialogModule,
        InputTextModule,
        AssessmentModule,
        FileUploaderModule,
        FileUploadModule,
        AttachmentModule,
        ConfirmDialogModule
    ]
})
export class PagesModule { }
