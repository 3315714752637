export enum OperationStatusEnum {
    EmSetup = 1,
    PreenchimentoManualNecessario = 2,
    PreenchimentoConcluido = 3,
    AguardandoAssinatura = 4,
    EmAnalisePeloGestor = 5,
    PendenciadaPeloGestor = 6,
    Cancelada = 7,
    Concluída = 8,
    ErroCadastral = 9,
    ErroDePreenchimento = 10,
}