<div class="titleCustomCard">
    Usuários
    <div class="subTitle">
        Todos os usuários da plataforma
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="customCard">
            <p-table [responsive]="true" #dt [value]="users" dataKey="id" styleClass="p-datatable-customized" selectionMode="single"
                [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                [globalFilterFields]="['fullName','maskedDocumentNumber', 'cellPhone', 'email', 'isAccessActivated']">
                <ng-template pTemplate="caption">
                    <div class="contentButtonActions p-fluid">
                            <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search iconPosition"></i>
                                    <input pInputText type="text"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Filtrar" />
                                </span>
                            </div>
                            <div *ngIf="isUserAdministrator" class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <button pButton type="button" label="Adicionar Usuário" icon="pi pi-user" iconPos="left"
                                    class="p-mr-2 p-mb-2" (click)="addUser()"></button>
                            </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="fullName">
                            <div>
                                Nome
                                <p-sortIcon field="fullName"></p-sortIcon>
                                <p-columnFilter type="text" field="fullName" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="fullName">
                            <div>
                                Função
                                <p-sortIcon field="fullName"></p-sortIcon>
                                <p-columnFilter type="text" field="fullName" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="maskedDocumentNumber">
                            <div>
                                CPF
                                <p-sortIcon field="maskedDocumentNumber"></p-sortIcon>
                                <p-columnFilter type="text" field="maskedDocumentNumber" display="menu"
                                    class="p-ml-auto"></p-columnFilter>
                            </div>
                        </th>
                        <!-- <th pSortableColumn="birthdate">
                            <div>
                                Data de Nascimento
                                <p-sortIcon field="birthdate"></p-sortIcon>
                                <p-columnFilter type="date"  field="birthdate" dateFormat="dd/mm/yy" display="menu" class="p-ml-auto"></p-columnFilter>
                            </div>
                        </th> -->
                        <th pSortableColumn="email">
                            <div>
                                Email
                                <p-sortIcon field="email"></p-sortIcon>
                                <p-columnFilter type="text" field="email" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th>
                        <!-- <th pSortableColumn="cellPhone">
                            <div>
                                Celular
                                <p-sortIcon field="cellPhone"></p-sortIcon>
                                <p-columnFilter type="text" field="cellPhone" display="menu" class="p-ml-auto">
                                </p-columnFilter>
                            </div>
                        </th> -->
                        <th pSortableColumn="isAccessActivated">
                            <div>
                                Status
                                <p-sortIcon field="isAccessActivated"></p-sortIcon>
                            </div>
                        </th>
                        <th>Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user let-index>
                    <tr style="cursor: pointer" [pSelectableRow]="user">
                        <td>
                            <!-- <span class="p-column-title">Nome</span> -->
                            {{user.fullName}}
                        </td>
                        <td>
                            <div *ngFor="let item of user.userRoles; let i = index">
                                <!-- <span class="p-column-title">Nome</span> -->
                                {{(item.role.friendlyName.replace("", '')) }}
                            </div>
                        </td>
                        <td>
                            <!-- <span class="p-column-title">CPF</span> -->
                            {{user.maskedDocumentNumber ? user.maskedDocumentNumber : 'Não informado'}}
                        </td>
                        <!-- <td>
                            {{user.birthdate ? (user.birthdate | date: 'dd/MM/yyyy') : 'Não informado'}}
                        </td> -->
                        <td>
                            <!-- <span class="p-column-title">Email</span> -->
                            {{user.email ? user.email : 'Não informado'}}
                        </td>
                        <!-- <td>
                            {{user.cellPhone ? user.cellPhone : 'Não informado'}}
                        </td> -->
                        <td>
                            <!-- <span class="p-column-title">Status</span> -->
                            <!-- <span
                                [class]="user.isAccessActivated != null && user.isAccessActivated ? 'datatable-customized-badge status-qualified' : 'datatable-customized-badge status-unqualified'">
                                {{user.isAccessActivated != true && user.isAccessActivated != false ? 'Pendente Ativação' : (user.isAccessActivated ? 'Ativo' : 'Inativo')}}</span> -->

                                <span
                                [class]="
                                user.isAccessActivated != null && user.isAccessActivated && !user.isBlockedByExistingAccount ? 'datatable-customized-badge status-qualified' : 'datatable-customized-badge status-unqualified'">

                                {{user.isAccessActivated != true && user.isAccessActivated != false || user.isBlockedByExistingAccount ? 'Pendente Ativação' : (user.isAccessActivated ? 'Ativo' : 'Bloqueado')}}</span>
                        </td>
                        <td>
                            <!-- <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button> -->
                            <p-menu #menu [popup]="true" [model]="getMenuItems(user)"></p-menu>
                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                class="p-button-text" (click)="menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">Nenhum usuário encontrado.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog>