import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArtifactTemplateCreateComponent } from 'src/app/modules/artifact-template/artifact-template-create/artifact-template-create.component';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { UpdateFundBrokerOperationSetupRequest } from 'src/app/services/brokers/models/request/update-fund-broker-operation-setup-request';
import { BrokerOperationSetupViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-setup-view-model';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { CreateFundBrokerOperationSetupRequest } from 'src/app/services/fund-brokers/models/requests/create-fund-broker-operation-setup-request';
import { environment } from 'src/environments/environment';
import { BrokerService } from './../../../../services/brokers/broker-service';

@Component({
    templateUrl: './fund-broker-operation-setup.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FundBrokerOperationSetupComponent implements OnInit {

    fundBroker: FundBrokerViewModel = null;
    allOperationTypes: DefaultEnumViewModel[] = [];
    operationTypesOptions: DefaultEnumViewModel[] = [];
    requestModel: CreateFundBrokerOperationSetupRequest = null;
    operationExtra: boolean = false;
    hasContext = environment.context;

    @ViewChild('extraTemplate', { static: false }) extraTemplate: ArtifactTemplateCreateComponent;
    // @ViewChild('iCVMArtifactTemplate', {static: false}) iCVMArtifactTemplate: ArtifactTemplateCreateComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private fundBrokerService: FundBrokerService,
        private brokerServier: BrokerService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        const id = this.route.snapshot.paramMap.get('id');
        await this.getData(id);
        this.setForm();
        this.loadingService.hide();
    }

    async getData(id: string) {
        this.fundBroker = await this.fundBrokerService.getById(id);
        this.fundBroker.operationSetups = this.fundBroker.operationSetups.sort((a, b) => {
            if (a.operationType.friendlyName > b.operationType.friendlyName) {
                return 1
            } else {
                return -1
            }
        })
        // console.log('getData', this.fundAdministrator);        
        await this.getOperationTypes();
    }

    setForm() {
        this.requestModel = {
            fundBrokerId: this.fundBroker.id,
            operationTypeId: null,

            hasBrokerRegistrationFormTemplate: false,

            hasBrokerUpateFormTemplate: false,

            hasBrokerAddtionTemplate: false,

            hasBrokerOperationTemplate: false,

            extraTemplate: null,

            isBrokerRegistrationFormTemplateSignableByRepresentation: false,
            isBrokerRegistrationFormTemplateSignableByBrokerRepresentative: false,
            isBrokerUpdateFormTemplateSignableByRepresentation: false,
            isBrokerUpdateFormTemplateSignableByBrokerRepresentative: false,
            isBrokerAddtionTemplateSignableByRepresentation: false,
            isBrokerAddtionTemplateSignableByBrokerRepresentative: false,
            isBrokerOperationTemplateSignableByRepresentation: false,
            isBrokerOperationTemplateSignableByBrokerRepresentative: false,
            isExtraTemplateSignableByRepresentation: false,
            isExtraTemplateSignableByBrokerRepresentative: false,
        };
    }

    async getOperationTypes() {

        this.operationTypesOptions = [];

        if (this.allOperationTypes.length == 0) {
            this.allOperationTypes = await this.brokerServier.getBrokerOperationsType();
        }

        if (this.fundBroker.operationSetups.length > 0) {
            this.allOperationTypes.forEach(element => {
                // TODO: c => c.operationType.value == element.value == 2
                if (this.fundBroker.operationSetups.findIndex(c => c.operationType.displayName == element.displayName) == -1) {
                    this.operationTypesOptions.push(element);
                }
            });
        } else {
            this.operationTypesOptions = this.allOperationTypes;
        }
    }

    extraTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.extraTemplate = createArtifactTemplateRequest;
    }

    // icvmTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
    //     this.requestModel.icvmTemplate = createArtifactTemplateRequest;
    // }

    async saveOperationSetup(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        this.loadingService.show();

        if (!this.requestModel.hasBrokerRegistrationFormTemplate) {
            this.requestModel.isBrokerRegistrationFormTemplateSignableByRepresentation = false;
            this.requestModel.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative = false;
        }
        if (!this.requestModel.hasBrokerUpateFormTemplate) { 
            this.requestModel.isBrokerUpdateFormTemplateSignableByRepresentation = false; 
            this.requestModel.isBrokerUpdateFormTemplateSignableByBrokerRepresentative = false; 
        }
        if (!this.requestModel.hasBrokerAddtionTemplate) { 
            this.requestModel.isBrokerAddtionTemplateSignableByRepresentation = false; 
            this.requestModel.isBrokerAddtionTemplateSignableByBrokerRepresentative = false; 
        }
        if (!this.requestModel.hasBrokerOperationTemplate) { 
            this.requestModel.isBrokerOperationTemplateSignableByRepresentation = false; 
            this.requestModel.isBrokerOperationTemplateSignableByBrokerRepresentative = false; 
        }
        if (!this.requestModel.extraTemplate) { 
            this.requestModel.isExtraTemplateSignableByRepresentation = false; 
            this.requestModel.isExtraTemplateSignableByBrokerRepresentative = false; 
        }

        try {
            await this.fundBrokerService.createOperationSetup(this.requestModel);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            await this.getData(this.requestModel.fundBrokerId);
            this.extraTemplate.resetComponent();
            // this.letterArtifactTemplate.resetComponent();
            this.setForm();
            this.formValidationService.resetFormFields(formComponent.form);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            const currentRoute = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate([currentRoute]);
            });
        }
    }

    async removeOperationSetup(operationSetup: BrokerOperationSetupViewModel) {

        this.loadingService.show();

        try {
            await this.fundBrokerService.deleteOperationSetup(operationSetup.id);
            this.messageService.add({ severity: 'success', summary: 'Operação removida com sucesso', detail: `Os dados foram removidos com sucesso!`, life: 5000 });
            await this.getData(this.requestModel.fundBrokerId);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund-broker/list');
    }

    updateTemplate() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-broker/edit-form-template/${fundBrokerId}`)
    }

    changeFundAdministratorResistrationSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isBrokerRegistrationFormTemplateSignableByRepresentation = false;
            this.requestModel.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative = false;
        }
    }

    changeFundUpdateSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isBrokerUpdateFormTemplateSignableByRepresentation = false;
            this.requestModel.isBrokerUpdateFormTemplateSignableByBrokerRepresentative = false;
        }
    }

    changeFundAdditionSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isBrokerAddtionTemplateSignableByRepresentation = false;
            this.requestModel.isBrokerAddtionTemplateSignableByBrokerRepresentative = false;
        }
    }

    changeFundOperationSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isBrokerOperationTemplateSignableByRepresentation = false;
            this.requestModel.isBrokerOperationTemplateSignableByBrokerRepresentative = false;
        }
    }

    changeExtraSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isExtraTemplateSignableByRepresentation = false;
            this.requestModel.isExtraTemplateSignableByBrokerRepresentative = false;
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

    async handleCheckOperationSetups(checked: boolean, fundBroker: BrokerOperationSetupViewModel, currentKey: string) {
        this.loadingService.show();
        const request: UpdateFundBrokerOperationSetupRequest = {
            operationSetupId: fundBroker.id,
            hasBrokerRegistrationFormTemplate: fundBroker.hasBrokerRegistrationFormTemplate,
            isBrokerRegistrationFormTemplateSignableByRepresentation: fundBroker.isBrokerRegistrationFormTemplateSignableByRepresentation,
            isBrokerRegistrationFormTemplateSignableByBrokerRepresentative: fundBroker.isBrokerRegistrationFormTemplateSignableByBrokerRepresentative,
            hasBrokerUpateFormTemplate: fundBroker.hasBrokerUpateFormTemplate,
            isBrokerUpdateFormTemplateSignableByRepresentation: fundBroker.isBrokerUpdateFormTemplateSignableByRepresentation,
            isBrokerUpdateFormTemplateSignableByBrokerRepresentative: fundBroker.isBrokerUpdateFormTemplateSignableByBrokerRepresentative,
            hasBrokerAddtionTemplate: fundBroker.hasBrokerAddtionTemplate,
            isBrokerAddtionTemplateSignableByRepresentation: fundBroker.isBrokerAddtionTemplateSignableByRepresentation,
            isBrokerAddtionTemplateSignableByBrokerRepresentative: fundBroker.isBrokerAddtionTemplateSignableByBrokerRepresentative,
            hasBrokerOperationTemplate: fundBroker.hasBrokerOperationTemplate,
            isBrokerOperationTemplateSignableByRepresentation: fundBroker.isBrokerOperationTemplateSignableByRepresentation,
            isBrokerOperationTemplateSignableByBrokerRepresentative: fundBroker.isBrokerOperationTemplateSignableByBrokerRepresentative,
            isExtraTemplateSignableByRepresentation: fundBroker.isExtraTemplateSignableByRepresentation,
            isExtraTemplateSignableByBrokerRepresentative: fundBroker.isExtraTemplateSignableByBrokerRepresentative,
        }
        Object.keys(request).forEach(async key => {
            if (key == currentKey) {
                request[key] = checked;
                try {
                    await this.fundBrokerService.operationSetups(request);
                    this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
                } catch (error) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Não foi possível efetuar a alteração',
                        detail: `${error?.messageError}!`,
                        life: 5000
                    });
                } finally {
                    this.loadingService.hide();
                    this.updateUrl();
                }
            }
        })
    }

}