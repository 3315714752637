import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { FileUploaderModule } from '../fileuploader/fileuploader.module';
import { DropdownModule } from 'primeng/dropdown';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { LegalRepresentativeFundSelectComponent } from './legal-representative-fund/legal-representative-fund-select/legal-representative-fund-select.component';
import { AddressFormModule } from '../address-form/address-form.module';
import { LegalRepresentativeFundListComponent } from './legal-representative-fund/legal-representative-fund-list/legal-representative-fund-list.component';
import { DividerModule } from 'primeng/divider';
// import { LegalRepresentativeAdminCreateComponent } from './legal-representative-admin/legal-representative-admin-create/legal-representative-admin-create.component';
// import { LegalRepresentativeAdminListComponent } from './legal-representative-admin/legal-representative-admin-list/lega-representative-admin-list.component';

const COMPONENTS = [
  LegalRepresentativeFundSelectComponent,
  LegalRepresentativeFundListComponent
  // LegalRepresentativeAdminCreateComponent,
  // LegalRepresentativeAdminListComponent
];

@NgModule({
  imports: [
    DirectivesModule,
    PipesModule,
    ButtonModule,
    InputTextModule,
    FileUploaderModule,
    DropdownModule,
    CommonModule,
    ChipsModule,
    FormsModule,
    TableModule,
    CalendarModule,
    AddressFormModule,
    DividerModule
  ],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location],
  exports: [
    LegalRepresentativeFundSelectComponent,
    LegalRepresentativeFundListComponent
    // LegalRepresentativeAdminCreateComponent,
    // LegalRepresentativeAdminListComponent
  ]
})
export class LegalRepresentativeModule { }
