import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';

@Component({
    templateUrl: './legal-representative-fund-select.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-legal-representative-fund-select'
})
export class LegalRepresentativeFundSelectComponent implements OnInit {

    @Input() selectedRepresentativeA: RepresentationViewModel = null;
    @Input() selectedRepresentativeB: RepresentationViewModel = null;
    
    @Input() isRepresentativeA: boolean = false;
    @Input() isRepresentativeB: boolean = false;

    @Input() allRepresentativesA: RepresentationViewModel[] = [];
    @Input() allRepresentativesB: RepresentationViewModel[] = [];

    @Output() representativeAEmitter = new EventEmitter<RepresentationViewModel>();
    @Output() representativeBEmitter = new EventEmitter<RepresentationViewModel>();

    // @Output() defaultRepresentativeAEmitter = new EventEmitter<RepresentationViewModel>();
    // @Output() defaultRepresentativeBEmitter = new EventEmitter<RepresentationViewModel>();

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        // if(!this.selectedRepresentativeA || this.selectedRepresentativeA.id == '8fe8e4d0-e814-4a8f-952b-de3a0730a678') {
        //     this.defaultSelectedRepresentativeA();
        // }
        // if(!this.selectedRepresentativeB || this.selectedRepresentativeB.id == '02b10df0-184d-4a09-b9bb-30ce4bd01d33') {
        //     this.defaultSelectedRepresentativeB();
        // }
    }

    // defaultSelectedRepresentativeA() {
    //     this.allRepresentativesA.filter(fill => fill.id === '8fe8e4d0-e814-4a8f-952b-de3a0730a678').map(item => {
    //         this.selectedRepresentativeA = item;
    //     });
    //     this.defaultRepresentativeAEmitter.emit(this.selectedRepresentativeA);
    // }

    // defaultSelectedRepresentativeB() {
    //     this.allRepresentativesB.filter(fill => fill.id === '02b10df0-184d-4a09-b9bb-30ce4bd01d33').map(item => {
    //         this.selectedRepresentativeB = item;
    //         console.log(item)
    //     });
    //     this.defaultRepresentativeBEmitter.emit(this.selectedRepresentativeB);
    // }

    changeRepresentativeA(event: RepresentationViewModel){
        this.representativeAEmitter.emit(event);
    }

    changeRepresentativeB(event: RepresentationViewModel){
        this.representativeBEmitter.emit(event)
    }


}