export const environment = {
  production: true,
  envName: 'dev',
  baseApiUrl: "https://dev-itau-services.goliza.tec.br/api",
  logoUrl: "",
  logoSmallUrl: "",
  primaryColor: "",
  primaryColorContrast: "",
  context: "",
  ssoClientId: '84933e96-6939-459f-9d6d-9a63dca717b1', // This is the ONLY mandatory field that you need to supply.
  ssoAuthority: 'https://login.microsoftonline.com/591669a0-183f-49a5-98f4-9aa0d0b63d81', // Defaults to "https://login.microsoftonline.com/common"
  ssoRedirectUri: 'https://dev-itau.goliza.tec.br/login', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
  ssoPostLogoutRedirectUri: 'https://dev-itau.goliza.tec.br/login', // Indicates the page to navigate after logout.
};