<div class="p-grid">
    <div *ngIf="selectedFund?.type.value == fundTypeEnum.Cotista || selectedFund?.type.value == fundTypeEnum.Carteira"
        class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage" *ngIf="selectedFund?.type?.value == fundTypeEnum.Cotista">
                Fundo Cotista
            </div>
            <div class="titlePage" *ngIf="selectedFund?.type?.value == fundTypeEnum.Carteira">
                Carteira
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button *ngIf="!isUserOperator" pButton pRipple type="button" label="Editar"
                        class="p-button-primary" (click)="onEdit()"></button>
                    <button *ngIf="selectedFund?.type.value !== fundTypeEnum.Carteira" pButton pRipple type="button"
                        label="Atualizar CVM" class="p-button-outlined" (click)="updateCVM()"></button>
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
            </div>
            <div class="p-grid">

                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Razão Social</p>
                    <p>{{selectedFund?.name ? selectedFund?.name: "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.documentNumber ? selectedFund?.documentNumber : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Patrimonio Líquido </p>
                    <p>{{selectedFund?.currentInformation?.patrimony ? (selectedFund?.currentInformation?.patrimony |
                        currency: 'BRL') : "-" }} - {{selectedFund?.currentInformation?.patrimonyRelatedDate ? "Data: "
                        + (selectedFund?.currentInformation?.patrimonyRelatedDate | date: 'dd/MM/yyyy') : "-" }}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Data do Encerramento</p>
                    <p>
                        {{selectedFund?.clousureDate ? (selectedFund?.clousureDate | date : 'dd/MM/yyyy') : ('--')}}
                    </p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Data de constituição </p>
                    <p>{{selectedFund?.establishmentDate | date: 'dd/MM/yyyy'}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">CNAE</p>
                    <p>{{selectedFund?.cnaeCode ? selectedFund?.cnaeCode : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Descrição CNAE</p>
                    <p>{{selectedFund?.cnaeDescription ? selectedFund?.cnaeDescription : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Natureza Legal</p>
                    <p>{{selectedFund?.legalNatureCode ? selectedFund?.legalNatureCode : "-"}}
                        {{selectedFund?.legalNatureDescription ? selectedFund?.legalNatureDescription : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Código STI/ Galgo</p>
                    <p>{{selectedFund?.currentCustomProperty?.stiCode ? selectedFund?.currentCustomProperty?.stiCode :
                        "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Número GIIN</p>
                    <p>{{selectedFund?.currentCustomProperty?.giinNumber ?
                        selectedFund?.currentCustomProperty?.giinNumber : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Conta CETIP</p>
                    <p>{{selectedFund?.currentCustomProperty?.cetipAccount ?
                        selectedFund?.currentCustomProperty?.cetipAccount : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Público Alvo ANBIMA</p>
                    <p>{{selectedFund?.currentCustomProperty?.ambimaTargetGroup ?
                        selectedFund?.currentCustomProperty?.ambimaTargetGroup : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Nome do banco</p>
                    <p>{{selectedFund?.currentBankAccount?.bankName ? selectedFund?.currentBankAccount?.bankName : "-"}}
                    </p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Número do banco</p>
                    <p>{{selectedFund?.currentBankAccount?.bankCode ? selectedFund?.currentBankAccount?.bankCode : "-"}}
                    </p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Agência</p>
                    <p>{{selectedFund?.currentBankAccount?.agencyNumber ? selectedFund?.currentBankAccount?.agencyNumber
                        : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Conta corrente</p>
                    <p>{{selectedFund?.currentBankAccount?.accountNumber ?
                        selectedFund?.currentBankAccount?.accountNumber :
                        "-"}}-{{selectedFund?.currentBankAccount.accountDigit ?
                        selectedFund?.currentBankAccount.accountDigit : "-"}}
                    </p>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12"></div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">BOVESPA</p>
                    <p>{{selectedFund?.isBovespa ? "Sim" : "Não"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">MESA</p>
                    <p>{{selectedFund?.brokerOperationDeskName ? selectedFund?.brokerOperationDeskName : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">SIGLA SAC</p>
                    <p>{{selectedFund?.sac ? selectedFund?.sac : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">BM&F</p>
                    <p>{{selectedFund?.isBMF ? "Sim" : "Não"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">Reversão do fundo</p>
                    <p>
                        {{selectedFund?.currentCustomProperty?.fundOrManagerReversion ?
                        selectedFund?.currentCustomProperty?.fundOrManagerReversion : "-"}}
                    </p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Administrador</p>
                    <p>{{selectedFund?.administrator?.name ? selectedFund?.administrator?.name : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.administrator?.documentNumber ? selectedFund?.administrator?.documentNumber : "-"
                        }}</p>
                </div>
                <div class="pc-ol-12 p-md-12 p-lg-12"></div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Endereço: </p>
                    <p>{{ selectedFund?.administrator?.addressStreet }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Número: </p>
                    <p>{{ selectedFund?.administrator?.addressNumber }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Complemento: </p>
                    <p>{{ selectedFund?.administrator?.addressComplement }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Bairro: </p>
                    <p>{{ selectedFund?.administrator?.addressNeighborhood }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Cidade: </p>
                    <p>{{ selectedFund?.administrator?.addressCity }}</p>
                </div>
                <!-- <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">País: </p>
                    <p>{{ selectedFund?.administrator?.addressCountry }}</p>
                </div> -->
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">CEP: </p>
                    <p>{{ selectedFund?.administrator?.addressZipCode }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">UF: </p>
                    <p>{{ selectedFund?.administrator?.addressState }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Telefone: </p>
                    <p>{{ selectedFund?.administrator?.phoneNumber }}</p>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">E-mail: </p>
                    <p>{{ selectedFund?.administrator?.email }}</p>
                </div>

            </div>
            <br>
            <br>

            <!-- <div class="p-grid">

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">BOVESPA</p>
                    <p>{{selectedFund?.isBovespa ? "Sim" : "Não"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">SIGLA SAC</p>
                    <p>{{selectedFund?.sac ? selectedFund?.sac : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">BM&F</p>
                    <p>{{selectedFund?.isBMF ? "Sim" : "Não"}}</p>
                </div>

                <div class="p-col-12 p-md-2 p-lg-2">
                    <p class="text-bold">MESA</p>
                    <p>{{selectedFund?.deskConfig?.name ? selectedFund?.deskConfig?.name : "-"}}</p>
                </div>
            </div> -->
            <br>
            <br>

            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Prestadores de Serviço</div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Distribuidor</p>
                    <p>{{selectedFund?.fundDistributorName ? selectedFund?.fundDistributorName : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.fundDistributorDocumentNumber ? selectedFund?.fundDistributorDocumentNumber :
                        "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Gestora</p>
                    <p>{{selectedFund?.manager?.name ? selectedFund?.manager?.name : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.manager?.documentNumber ? selectedFund?.manager?.documentNumber : "-"}}</p>
                </div>

                <div class="p-col-12 p-md-4 p-lg-4">
                    <p class="text-bold">Custodiante</p>
                    <p>{{selectedFund?.custodianName ? selectedFund?.custodianName : "-"}}</p>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.custodianDocumentNumber ? selectedFund?.custodianDocumentNumber : "-"}}</p>
                </div>

            </div>
        </div>

    </div>

    <div *ngIf="selectedFund?.type.value == fundTypeEnum.Investido && selectedFund?.type?.value !== fundTypeEnum.Carteira"
        class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Fundo Investido
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton type="button" label="Editar" class="p-button-primary p-button"
                        (click)="openFundEdit()"></button>
                    <button *ngIf="selectedFund?.type.value !== fundTypeEnum.Carteira" pButton pRipple type="button"
                        label="Atualizar CVM" class="p-button-outlined" (click)="updateCVM()"></button>
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-7 p-lg-7">
                    <p class="text-bold">Razão Social</p>
                    <p>{{selectedFund?.name}}</p>
                </div>
                <div class="p-col-12 p-md-5 p-lg-5">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.documentNumber}}</p>
                </div>
                <div class="p-col-12 p-md-7 p-lg-7">
                    <p class="text-bold">Administradora</p>
                    <p>{{selectedFund?.administrator?.name}}</p>
                </div>
                <div class="p-col-12 p-md-5 p-lg-5">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.administrator?.documentNumber }}</p>
                </div>
                <div class="p-col-12 p-md-7 p-lg-7">
                    <p class="text-bold">Gestora</p>
                    <p>{{selectedFund?.manager?.name}}</p>
                </div>
                <div class="p-col-12 p-md-5 p-lg-5">
                    <p class="text-bold">CNPJ</p>
                    <p>{{selectedFund?.manager?.documentNumber }}</p>
                </div>
            </div>
            <br>
            <br>

            <div class="p-grid">
                <div class="p-col-12 p-md-6 p-lg-6" *ngIf="selectedFund?.adhesionTermTemplate">
                    <div class="cardHeader text-bold">Termo de Adesão</div>
                    <div class="customCardOutlined">
                        <a class="linkPrimary" [href]="selectedFund?.adhesionTermTemplate?.fileUrl" target="_blank">Ver
                            Arquivo</a>
                        <br>
                        <br>
                        <a *ngIf="!isUserOperator" class="linkPrimary"
                            (click)="openChangeAdhesionTermTemplate()">Alterar</a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p>{{selectedFund?.adhesionTermTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6" *ngIf="!selectedFund?.adhesionTermTemplate && !isUserOperator">
                    <div class="cardHeader text-bold">Termo de Adesão</div>
                    <div class="customCardOutlined">
                        <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledAdhesion($event)"
                            #adhesionArtifactTemplate>
                        </app-artifact-template-create>
                    </div>
                </div>

                <div class="p-col-12 p-md-6 p-lg-6" *ngIf="selectedFund?.subscriptionFormTemplate">
                    <div class="cardHeader text-bold">Boletim de Subscrição</div>
                    <div class="customCardOutlined">
                        <a class="linkPrimary" [href]="selectedFund?.subscriptionFormTemplate?.fileUrl"
                            target="_blank">Ver
                            Arquivo</a>
                        <br>
                        <br>
                        <a *ngIf="!isUserOperator" class="linkPrimary"
                            (click)="openChangeSubscriptionTemplate()">Alterar</a>
                        <div style="margin-top: 1rem;">
                            <p class="text-bold">Data de inclusão:</p>
                            <p>{{selectedFund?.subscriptionFormTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6" *ngIf="!selectedFund?.subscriptionFormTemplate && !isUserOperator">
                    <div class="cardHeader text-bold">Boletim de Subscrição</div>
                    <div class="customCardOutlined">
                        <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledSubscription($event)"
                            #subscriptionArtifactTemplate>
                        </app-artifact-template-create>
                    </div>
                </div>
            </div>

            <!-- <div class="p-grid" *ngIf="requestModelAdhesion || requestModelSubscription">
                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton class="p-button-primary" label="Salvar" (click)="onSaveTemplates()"></button>
                </div>
            </div> -->
        </div>

    </div>

    <div *ngIf="selectedFund?.type.value == fundTypeEnum.Cotista && selectedApplication?.application?.value == applicationTypeEnum.Broker || selectedFund?.type?.value == fundTypeEnum.Carteira"
        class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Cadastros em Corretoras
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button *ngIf="selectedFund?.type.value !== fundTypeEnum.Carteira" pButton pRipple type="button"
                        label="Atualizar CVM" class="p-button-outlined" (click)="updateCVM()"></button>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-sm-12">
                    <p-tabView>
                        <p-tabPanel header="A Expirar">
                            <p-table [responsive]="true" #dt [value]="aboutToExpireBrokerEntries" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['broker.name', 'broker.documentNumber', 'expirationDate', 'notes']">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="broker.name">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="broker.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.operationType.friendlyName">
                                            <div>
                                                Tipo de Operação
                                                <p-sortIcon field="broker.operationType.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.currentCBLC">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="broker.currentCBLC"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="notes">
                                            <div>
                                                Conta
                                                <p-sortIcon field="notes"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="expirationDate">
                                            <div>
                                                Data de expiração
                                                <p-sortIcon field="expirationDate"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-broker let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="broker">
                                        <td>
                                            {{broker.broker.name}}
                                        </td>
                                        <td>
                                            {{broker.broker.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{broker.operationType.friendlyName}}
                                        </td>
                                        <td>
                                            {{broker.cblc}}
                                        </td>
                                        <td>
                                            {{broker.notes}}
                                        </td>
                                        <td>
                                            {{broker.expirationDate | date : 'dd/MM/yyyy'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma corretora encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Ativos">
                            <p-table [responsive]="true" #dt [value]="brokerEntries" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['broker.name', 'broker.documentNumber', 'expirationDate', 'notes']">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="broker.name">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="broker.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.operationType.friendlyName">
                                            <div>
                                                Tipo de Operação
                                                <p-sortIcon field="broker.operationType.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.currentCBLC">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="broker.currentCBLC"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="notes">
                                            <div>
                                                Conta
                                                <p-sortIcon field="notes"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="expirationDate">
                                            <div>
                                                Data de expiração
                                                <p-sortIcon field="expirationDate"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-broker let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="broker">
                                        <td>
                                            {{broker.broker.name}}
                                        </td>
                                        <td>
                                            {{broker.broker.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{broker.operationType.friendlyName}}
                                        </td>
                                        <td>
                                            {{broker.cblc}}
                                        </td>
                                        <td>
                                            {{broker.notes}}
                                        </td>
                                        <td>
                                            {{broker.expirationDate | date : 'dd/MM/yyyy'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma corretora encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                        <p-tabPanel header="Expirados">
                            <p-table [responsive]="true" #dt [value]="expiredBrokerEntries" dataKey="id"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['broker.name', 'broker.documentNumber', 'expirationDate', 'notes']">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="broker.name">
                                            <div>
                                                Corretora
                                                <p-sortIcon field="broker.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.documentNumber">
                                            <div>
                                                CNPJ
                                                <p-sortIcon field="broker.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.operationType.friendlyName">
                                            <div>
                                                Tipo de Operação
                                                <p-sortIcon field="broker.operationType.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="broker.currentCBLC">
                                            <div>
                                                CBLC
                                                <p-sortIcon field="broker.currentCBLC"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="notes">
                                            <div>
                                                Conta
                                                <p-sortIcon field="notes"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="expirationDate">
                                            <div>
                                                Data de expiração
                                                <p-sortIcon field="expirationDate"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-broker let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="broker">
                                        <td>
                                            {{broker.broker.name}}
                                        </td>
                                        <td>
                                            {{broker.broker.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{broker.operationType.friendlyName}}
                                        </td>
                                        <td>
                                            {{broker.cblc}}
                                        </td>
                                        <td>
                                            {{broker.notes}}
                                        </td>
                                        <td>
                                            {{broker.expirationDate | date : 'dd/MM/yyyy'}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma corretora encontrado.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </div>
</div>


<p-dialog [(visible)]="displayAdhesionTermTemplate" [modal]="true" [responsive]="true" [style]="{width: '40vw'}">
    <div class="cardHeader text-bold">Termo de Adesão</div>
    <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledAdhesion($event)">
    </app-artifact-template-create>
</p-dialog>

<p-dialog [(visible)]="displaySubscriptionFormTemplate" [modal]="true" [responsive]="true" [style]="{width: '40vw'}">
    <div class="cardHeader text-bold">Boletim de Subscrição</div>
    <app-artifact-template-create (onArtifactFilled)="letterTemplateFilledSubscription($event)">
    </app-artifact-template-create>
</p-dialog>