import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { UserService } from 'src/app/services/users/user-service';

@Component({
    templateUrl: 'liza-user-list.component.html',
    styleUrls: [
        '../../../../../../assets/layout/customized/datatable.scss',
        '../../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class LizaUserListComponent implements OnInit {

    users: UserViewModel[];    

    constructor(
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private loadingService: LoadingService,
        private userService: UserService        
        ) {
        this.breadcrumbService.setItems([
            { label: 'User', routerLink: ['/app/liza/admin/user'] },
            { label: 'List'}
        ]);
    }

    ngOnInit() {
        this.loadTableData();
    }

    async loadTableData() {
        this.loadingService.show();
        this.users = await this.userService.getUsersByPlayerTypeAndEntity(PlayerTypeEnum.Liza, null);
        // console.log('users', this.users);
        this.users.forEach(user => user.birthdate = new Date(user.birthdate));
        this.loadingService.hide();
    }

    addUser() {
        this.router.navigateByUrl('app/liza/admin/user/create');
    }
}
