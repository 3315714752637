import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AddressInformation } from 'src/app/modules/address-form/address-form.component';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { CreateContactRequest } from 'src/app/services/fund-manager/models/requests/create-contact-request';
import { UpdateContactRequest } from 'src/app/services/fund-manager/models/requests/update-contact-request';
import { UpdateFundManagerRequest } from 'src/app/services/fund-manager/models/requests/update-fund-manager-request';
import { ContactViewModel } from 'src/app/services/fund-manager/models/view-models/contact-view-model';
import { FundManagerViewModel } from 'src/app/services/fund-manager/models/view-models/fund-manager-view-model';



@Component({
    templateUrl: './fund-manager-edit.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundManagerEditComponent implements OnInit {

    selectedFundManager: FundManagerViewModel = null;
    selectedContactsFundManager: ContactViewModel[] = [];
    decryptedDocumentContactFundManager: string = null;
    selectedContact: ContactViewModel = null;
    requestContacts: CreateContactRequest = null;
    fundManagerName: string = null;
    displayContact: boolean = false;
    requestFundManager: UpdateFundManagerRequest = null;
    establishmentDate: Date = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fundManagerService: FundManagerService,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
    ) {
    }

    async ngOnInit() {
        this.loadingService.show();
        this.setForms();
        await this.getManager();
        this.loadingService.hide();
    }

    async getManager() {
        const fundManagerId = this.route.snapshot.params.id;
        this.selectedFundManager = await this.fundManagerService.getFundManagerById(fundManagerId);
        this.selectedContactsFundManager = await this.fundManagerService.getContactsByFundManagerId(fundManagerId);
        this.fundManagerName = this.selectedFundManager.name;
        this.requestFundManager.email = this.selectedFundManager.email;
        this.requestFundManager.phoneNumber = this.selectedFundManager.phoneNumber;
        this.requestFundManager.giinNumber = this.selectedFundManager.giinNumber;
        this.selectedFundManager.documentNumber = this.parseCNPJ(this.selectedFundManager.documentNumber);
        if(this.selectedFundManager.establishmentDate !== null) {
            this.establishmentDate = new Date(this.selectedFundManager?.establishmentDate);
        } else {
            this.establishmentDate = null;
        }
        this.selectedContactsFundManager = this.selectedContactsFundManager?.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    setForms() {
        this.requestContacts = {
            fundManagerId: null,
            name: null,
            email: null,
            expireDate: null,
            personalDocumentNumber: null,
        }
        
        this.requestFundManager = {
            fundManagerId: null,
            name: null,
            email: null,
            addressStreet: null,
            addressNumber: null,
            addressComplement: null,
            addressNeighborhood: null,
            addressZipCode: null,
            addressCity: null,
            addressState: null,
            addressCountry: null,
            giinNumber: null,
            establishmentDate: null,
            phoneNumber: null,
        }
    }

    onAddressSelect(event: AddressInformation) {
        this.requestFundManager.addressStreet = event.street;
        this.requestFundManager.addressNumber = event.addressNumber;
        this.requestFundManager.addressComplement = event.complement;
        this.requestFundManager.addressNeighborhood = event.neighborhood;
        this.requestFundManager.addressZipCode = event.postalCode;
        this.requestFundManager.addressCity = event.city;
        this.requestFundManager.addressState = event.province;
    }

    async addContact(form: NgForm) {

        if (!this.formValidationService.validateForm(form.form)) return;

        const request: CreateContactRequest = {
            fundManagerId: this.selectedFundManager.id,
            name: this.requestContacts.name,
            email: this.requestContacts.email,
            expireDate: this.requestContacts.expireDate,
            personalDocumentNumber: this.requestContacts.personalDocumentNumber ? this.requestContacts.personalDocumentNumber : null
        }

        this.loadingService.show();
        try {
            await this.fundManagerService.createContactsFundManager(request);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.getManager();
            this.loadingService.hide();
        }
        form.reset();
    }

    async removeContact(contact: ContactViewModel) {
        const contactId = contact.id;
        try {
            this.loadingService.show();
            await this.fundManagerService.deleteContactInFunManager(contactId);
            await this.getManager();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async removeManager(manager: FundManagerViewModel) {
        const fundManagerId = manager.id;
        try {
            this.loadingService.show();
            await this.fundManagerService.deleteFundManager(fundManagerId);
            this.router.navigateByUrl(`app/customer/fund-manager/list`);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    confirm(manager: FundManagerViewModel) {
        this.confirmationService.confirm({
            header: `Gestora ${manager.name}`,
            message: `Tem certeza que deseja excluir ${manager.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeManager(manager);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

    onCancel() {
        const search = this.route.snapshot.queryParams.search;
        if (search) {
            this.router.navigate([`app/customer/fund-manager/list`], { queryParams: { search: search } });
        } else if (!search) {
            this.router.navigate([`app/customer/fund-manager/list`]);
        }
    }

    onEditCancel() {
        this.displayContact = false;
    }

    async onSave(managerForm: NgForm, contactForm: NgForm) {
        if (!this.formValidationService.validateForm(managerForm.form)) return;

        this.loadingService.show();
        try {
            const request: UpdateFundManagerRequest = {
                fundManagerId: this.selectedFundManager.id,
                name: this.fundManagerName,
                phoneNumber: this.requestFundManager.phoneNumber,
                addressStreet: this.requestFundManager.addressStreet,
                addressNumber: this.requestFundManager.addressNumber,
                addressComplement: this.requestFundManager.addressComplement,
                addressNeighborhood: this.requestFundManager.addressNeighborhood,
                addressZipCode: this.requestFundManager.addressZipCode,
                addressCity: this.requestFundManager.addressCity,
                addressState: this.requestFundManager.addressState,
                addressCountry: this.requestFundManager.addressCountry,
                giinNumber: this.requestFundManager.giinNumber,
                establishmentDate: this.establishmentDate,
                email: this.requestFundManager.email,
            }
            await this.fundManagerService.updateFundManager(request);
            await this.getManager();
            this.messageService.add({ severity: 'success', summary: 'Cadastro alterado com sucesso', detail: `Os dados foram alterados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.updateUrl();
        }

    }

    async editContact(contact: ContactViewModel) {
        this.selectedContact = contact;
        if(this.selectedContact.maskedPersonalDocumentNumber) {
            await this.fundManagerService.decryptedDocumentContactFundManager(contact.id).then((response) => {
                this.decryptedDocumentContactFundManager = response.decryptedDocumentNumber;
            })
        } else {
            this.decryptedDocumentContactFundManager = this.selectedContact.maskedPersonalDocumentNumber
        }
        this.displayContact = true;

    }

    async onEditContact(form: NgForm) {

        if (!this.formValidationService.validateForm(form.form)) return;

        this.loadingService.show();
        const request: UpdateContactRequest = {
            contactId: this.selectedContact.id,
            name: form.value.name,
            email: form.value.email,
            personalDocumentNumber: form.value.documentNumber,
        }
        try {
            await this.fundManagerService.updateContactsFundManager(request);
            await this.getManager();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.displayContact = false;
            this.loadingService.hide();
        }
    }

}