<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Nova Gestora
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
            </div>
            <form #managerForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-6 p-lg-6">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="request.name" name="name" #name="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="name">Gestora</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-2 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="createCustomerRequest" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" [(ngModel)]="request.documentNumber" name="documentNumber"
                                    #documentNumber="ngModel" [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                        </div>
                        <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="giinNumber" type="text" [(ngModel)]="request.giinNumber"
                                    name="giinNumber" #giinNumber="ngModel" class="inputLiza" pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.establishmentDate"
                                    name="establishmentDate" yearRange="2022:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    [required]="true" #establishmentDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="establishmentDate" class="labelOcta">Data de Constituição</label>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Endereço</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <form #addressForm="ngForm" novalidate>
                        <div class="p-grid">
                            <div class="p-col-6 p-md-6 p-lg-6">
                                <app-address-form [editAddressParams]="null" autocompleteId="mainAddress"
                                    [isCompleteAddressRequired]="true" (onSelect)="onAddressSelect($event)"></app-address-form>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Contatos do Gestora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <form #contactForm="ngForm" novalidate>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3 p-lg-3 customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="name" type="text" [(ngModel)]="requestContacts.name" name="name"
                                            #name="ngModel" class="inputLiza" required pInputText>
                                        <label for="name">Nome</label>
                                    </span>
                                </div>
                                <mark *ngIf="name.invalid && name.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3 customCardOutlined">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="email" type="email" [(ngModel)]="requestContacts.email" name="email"
                                            #email="ngModel" class="inputLiza" required appValidateEmail pInputText>
                                        <label for="email">E-mail</label>
                                    </span>
                                </div>
                                <mark *ngIf="email.invalid && email.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>

                            <div class="customCardOutlined">
                                <button type="button" pButton class="p-button-primary" label="Adicionar"
                                    (click)="addContact(contactForm)"></button>
                            </div>
                        </div>

                        <div *ngIf="allConacts.length > 0">
                            <!-- <p-scrollPanel [style]="{width: '100%', height: '200px'}"> -->
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">E-mail</p>
                                </div>
                            </div>
                            <div *ngFor="let contact of allConacts" class="p-grid contentAlignItemsCenter">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p>{{ contact?.name}}</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p>{{ contact?.email}}</p>
                                </div>
                                <div class="p-col-1 p-md-1 p-lg-1">
                                    <button pButton pRipple type="button" icon="pi pi-trash"
                                        class="p-button-rounded p-button-outlined-blue"
                                        (click)="removeManager(contact)"></button>
                                </div>
                                <div class="p-col-12 p-md-12 p-lg-10">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <!-- </p-scrollPanel> -->
                        </div>

                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                                    (click)="onCancel()"></button>
                                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                                    (click)="onSave(managerForm)"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>