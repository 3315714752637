import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ArtifactTemplateCreateComponent } from 'src/app/modules/artifact-template/artifact-template-create/artifact-template-create.component';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateOperationSetupRequest } from 'src/app/services/fund-administrators/models/requests/create-operation-setup-request';
import { UpdateOperationSetupRequest } from 'src/app/services/fund-administrators/models/requests/update-operation-setup-request';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { OperationSetupViewModel } from 'src/app/services/fund-administrators/models/view-models/operation-setup-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './fund-administrator-operation-setup.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FundAdministratorOperationSetupComponent implements OnInit {

    fundAdministrator: FundAdministratorViewModel = null;
    allOperationTypes: DefaultEnumViewModel[] = [];
    operationTypesOptions: DefaultEnumViewModel[] = [];
    requestModel: CreateOperationSetupRequest = null;
    operationLetter: boolean = false;
    icvmSpreadsheet: boolean = false;

    @ViewChild('letterArtifactTemplate', { static: false }) letterArtifactTemplate: ArtifactTemplateCreateComponent;
    @ViewChild('iCVMArtifactTemplate', { static: false }) iCVMArtifactTemplate: ArtifactTemplateCreateComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService,
        private operationService: OperationService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        const id = this.route.snapshot.paramMap.get('id');
        await this.getData(id);
        this.setForm();
        this.loadingService.hide();
    }

    async getData(id: string) {
        this.fundAdministrator = await this.fundAdministratorService.getById(id);
        this.fundAdministrator.operationSetups = this.fundAdministrator.operationSetups.sort((a, b) => {
            if (a.operationType.friendlyName > b.operationType.friendlyName) {
                return 1
            } else {
                return -1
            }
        })
        await this.getOperationTypes();
    }

    setForm() {
        this.requestModel = {
            fundAdministratorId: this.fundAdministrator.id,
            operationTypeId: null,
            hasFundAdministratorRegistrationFormTemplate: false,
            isFundAdministratorRegistrationFormTemplateSignable: false,
            hasFundSubscriptionFormTemplate: false,
            isFundSubscriptionFormTemplateSignable: false,
            hasFundAdhesionTermTemplate: false,
            isFundAdhesionTermTemplateSignable: false,
            letterTemplate: null,
            isLetterTemplateSignable: false,
            iCVMTemplate: null,
            isICVMTemplateSignable: false,
        };
    }

    async getOperationTypes() {

        this.operationTypesOptions = [];

        if (this.allOperationTypes.length == 0) {
            this.allOperationTypes = await this.operationService.getOperationTypes();
        }

        if (this.fundAdministrator.operationSetups.length > 0) {
            this.allOperationTypes.forEach(element => {
                if (this.fundAdministrator.operationSetups.findIndex(c => c.operationType.value == element.value) == -1) {
                    this.operationTypesOptions.push(element);
                }
            });
        } else {
            this.operationTypesOptions = this.allOperationTypes;
        }
    }

    letterTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.letterTemplate = createArtifactTemplateRequest;
    }

    icvmTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.iCVMTemplate = createArtifactTemplateRequest;
    }

    async saveOperationSetup(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        this.loadingService.show();

        if (!this.requestModel.hasFundAdministratorRegistrationFormTemplate) this.requestModel.isFundAdministratorRegistrationFormTemplateSignable = false;
        if (!this.requestModel.hasFundSubscriptionFormTemplate) this.requestModel.isFundSubscriptionFormTemplateSignable = false;
        if (!this.requestModel.hasFundAdhesionTermTemplate) this.requestModel.isFundAdhesionTermTemplateSignable = false;
        if (!this.requestModel.letterTemplate) this.requestModel.isLetterTemplateSignable = false;
        if (!this.requestModel.iCVMTemplate) this.requestModel.isICVMTemplateSignable = false;

        try {
            await this.fundAdministratorService.createOperationSetup(this.requestModel);
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            await this.getData(this.requestModel.fundAdministratorId);
            this.iCVMArtifactTemplate.resetComponent();
            this.letterArtifactTemplate.resetComponent();
            this.setForm();
            this.formValidationService.resetFormFields(formComponent.form);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.updateUrl();
        }
    }

    async removeOperationSetup(operationSetup: OperationSetupViewModel) {

        this.loadingService.show();

        try {
            await this.fundAdministratorService.deleteOperationSetup(operationSetup.id);
            this.messageService.add({ severity: 'success', summary: 'Operação removida com sucesso', detail: `Os dados foram removidos com sucesso!`, life: 5000 });
            await this.getData(this.requestModel.fundAdministratorId);
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/fund-administrator/list');
    }

    updateRegistrationFormTemplate() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-administrator/edit-form-template/${fundAdministratorId}`)
    }

    changeFundAdministratorResistrationSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isFundAdministratorRegistrationFormTemplateSignable = false;
        }
    }

    changeFundAdhesionSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isFundAdhesionTermTemplateSignable = false;
        }
    }

    changeFundSubscriptionSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isFundSubscriptionFormTemplateSignable = false;
        }
    }

    changeOperationLetter(event: boolean) {
        if (event == false) {
            this.requestModel.isLetterTemplateSignable = false;
        }
    }

    changeIcvmSignable(event: boolean) {
        if (event == false) {
            this.requestModel.isICVMTemplateSignable = false;
        }
    }

    async handleCheckOperationSetups(checked: boolean, operation: OperationSetupViewModel, currentKey: string) {
        this.loadingService.show();
        const request: UpdateOperationSetupRequest = {
            operationSetupId: operation.id,
            hasFundAdministratorRegistrationFormTemplate: operation.isFundAdministratorRegistrationFormTemplateSignable,
            isFundAdministratorRegistrationFormTemplateSignable: operation.isFundAdministratorRegistrationFormTemplateSignable,
            hasFundSubscriptionFormTemplate: operation.hasFundSubscriptionFormTemplate,
            isFundSubscriptionFormTemplateSignable: operation.isFundSubscriptionFormTemplateSignable,
            hasFundAdhesionTermTemplate: operation.hasFundAdhesionTermTemplate,
            isFundAdhesionTermTemplateSignable: operation.isFundAdhesionTermTemplateSignable,
            isLetterTemplateSignable: operation.isLetterTemplateSignable,
            isICVMTemplateSignable: operation.isICVMTemplateSignable,
        }
        Object.keys(request).forEach(async key => {
            if (key == currentKey) {
                request[key] = checked;
                try {
                    await this.fundAdministratorService.operationSetups(request);
                    this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
                } catch (error) {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Não foi possível efetuar a alteração',
                        detail: `${error?.messageError}!`,
                        life: 5000
                    });
                } finally {
                    this.loadingService.hide();
                    this.updateUrl();
                }
            }
        })
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

}