import { NgModule } from "@angular/core";
import { DateAgoPipe } from "./date-formating/date-ago";
import { DateTimeFormatPipe } from "./date-formating/date-time-format";
import { BrCurrencyPipe } from "./number-formatting/br-currency";
import { DocumentNumberPipe } from "./number-formatting/document-number-pipe";
import { PhoneNumberPipe } from "./number-formatting/phone-number-pipe";
import { TextSplit } from "./text-split/text-split-pipe";
import { TypesBrokerOperationPipe } from "./types-broker-operation/types-broker-operation-pipe";
import { TypesOperationPipe } from "./types-operation/types-operation-pipe";
import { UrlSanitizerPipe } from "./url-handler/safe-url-pipe";

const PIPES = [
    // Number
    DocumentNumberPipe,
    BrCurrencyPipe,
    PhoneNumberPipe,
    // Date
    DateAgoPipe,
    DateTimeFormatPipe,
    // URL
    UrlSanitizerPipe,
    // Text split
    TextSplit,
    TypesOperationPipe,
    TypesBrokerOperationPipe
];

@NgModule({
    declarations: PIPES,
    exports: PIPES
  })
  export class PipesModule { }
  