import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { isThisQuarter } from 'date-fns';
import { MenuItem, SelectItem } from 'primeng/api';
import { AppRootComponent } from '../app.root/app.root.component';


@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: MenuItem[];
    nameItensCollapsed: any[] = [];

    constructor(public app: AppRootComponent) {}

    ngOnInit() {

        this.loadSiteMap();
        // this.activeCollapse.emit(this.collapse)

        
    }

    navCollapse() {
        this.app.collapse = !this.app.collapse;
        if(this.app.collapse === true) {
            this.app.labelCollapsed = true;
        } else {
            this.app.labelCollapsed = false;
        }
        // if(this.app.collapse) {
        //     this.app.labelCollapsed = true
        // } else {
        //     this.app.labelCollapsed = false;
        // }
        // this.app.labelCollapsed = !this.app.labelCollapsed;
    }

    loadSiteMap() {
        const userSiteMap = this.app.contextService.getUserSitemap();

        if (userSiteMap != null && userSiteMap.length > 0) {

            this.model = [];

            userSiteMap.forEach(parent => {

                let menuItems : MenuItem[] = [];

                parent.items.forEach(item => {
                    if (item.isVisible) {
                        menuItems.push({label: item.name, icon: item.icon, routerLink: [`/app/${item.route}`]});
                    }
                });

                this.model.push({label: parent.title, icon: parent.icon, routerLink: [parent.route], items: menuItems});
            });

        }
    }
}
