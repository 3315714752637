<div class="containerLogin">
    <div class="boxLogin">
        <div class="leftBox">
            <div class="whiteLogo"></div>
            <div class="textLogin">
                Economize tempo e recursos na gestão dos documentos empresariais
            </div>
        </div>
        <div class="rightBox" *ngIf="currentStep == 0">
            <div class="ocLogo"></div>
            <div class="title">
                Recuperar Senha
            </div>
            <div class="boxForm">
                <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-envelope"></i>
                    </span>
                    <span class="p-float-label">
                        <input id="userCredentialInputInput" type="text" class="inputLogin"
                            [(ngModel)]="userCredential" name="userCredentialInput"
                            #userCredentialInput="ngModel" required pInputText />
                        <label class="labelLogin" for="userCredentialInput">Digite seu email</label>
                    </span>
                </div>
                <mark *ngIf="userCredentialInput.invalid && userCredentialInput.dirty"
                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                    obrigatório</mark>

                <div class="p-mt-4 containerButton">
                    <button class="buttonLogin" type="submit" (click)="sendCode(userCredentialInput)">
                        Enviar Código
                    </button>
                </div>

                <div class="infoText">
                    <b class="registerLink" (click)="loginPage()">Voltar para login</b>
                </div>
            </div>
        </div>

        <div class="rightBox" *ngIf="currentStep == 1 && codeOperationResponse != null">
            <div class="title">
                Resetar senha
            </div>

            <div class="infoDetails">
                O código foi enviado para <strong>{{codeOperationResponse.contactValue}}</strong>
                <br>
                e está válido até <strong>{{codeOperationResponse.codeExpirationDate |
                    date:'dd/MM/yyyy HH:mm'}}</strong>
            </div>

            <div class="boxForm">
                <div class="p-inputgroup p-mt-3">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-exclamation-triangle"></i>
                    </span>
                    <span class="p-float-label">
                        <input id="codeInput" type="text" class="inputLogin" [(ngModel)]="code" name="codeInput" required
                            #codeInput="ngModel" pInputText />
                        <label for="codeInput">Código Recebido</label>
                    </span>
                </div>
                <mark *ngIf="codeInput.invalid && codeInput.dirty"
                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                    obrigatório</mark>
    
                <div class="p-inputgroup p-mt-3">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <input id="newPasswordInput" class="inputLogin" type="password" [(ngModel)]="newPassword"
                            name="newPasswordInput" required #newPasswordInput="ngModel"
                            appValidatePassword pPassword />
                        <label for="newPasswordInput">Senha</label>
                    </span>
                </div>
                <mark *ngIf="newPasswordInput.invalid && newPasswordInput.dirty"
                    class="ui-message ui-messages-error ui-corner-all markError">A senha deve ser maior que 6 caracteres e conter número</mark>
    
                <div class="p-inputgroup p-mt-3">
                    <span class="p-inputgroup-addon">
                        <i class="pi pi-lock"></i>
                    </span>
                    <span class="p-float-label">
                        <input id="confirmPasswordInput" class="inputLogin" type="password" [(ngModel)]="confirmPassword"
                            name="confirmPasswordInput" required #confirmPasswordInput="ngModel"
                            pInputText />
                        <label for="confirmPasswordInput">Confirmar Senha</label>
                    </span>
                </div>
                <mark
                    *ngIf="confirmPasswordInput.invalid && confirmPasswordInput.dirty || (confirmPasswordInput.dirty && confirmPassword != newPassword)"
                    class="ui-message ui-messages-error ui-corner-all markError">Senha e confirmar senha devem ser iguais</mark>

                <div class="p-mt-4 containerButton">
                    <button class="buttonLogin" type="submit"
                    (click)="resetPassword(confirmPasswordInput)"
                    [disabled]="confirmPasswordInput.invalid || newPasswordInput.invalid"
                    >
                        Resetar Senha
                    </button>
                </div>

                <div class="pages-detail p-mb-2 p-mt-3">
                    Você possui {{codeOperationResponse.remaingResendAttempts}} reenvios restantes.
                </div>
    
                <div>
                    <p-button label="Reenviar Código" styleClass="p-button-link" (click)="resendCode()">
                    </p-button>
                </div>
            </div>
        </div>
    </div>
</div>