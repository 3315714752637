import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { BrokerOperationStatusEnum } from 'src/app/enums/broker-operation-status-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { AddBrokerOperationFundRegulationAsAttachmentRequest } from 'src/app/services/brokers/models/request/add-broker-operation-fund-regulation-as-attachment-request';
import { AddBrokerOperationRecipientsRequest } from 'src/app/services/brokers/models/request/add-broker-operation-recipients-request';
import { AddBrokerOperationSignersRequest } from 'src/app/services/brokers/models/request/add-broker-operation-signers-request';
import { AddBrokerSignersRequest } from 'src/app/services/brokers/models/request/add-broker-signers-request';
import { DismissBrokerOperationAssessmentRequest } from 'src/app/services/brokers/models/request/dismiss-broker-operation-assessment-request';
import { ReplyBrokerOperationAssessmentRequest } from 'src/app/services/brokers/models/request/reply-broker-operation-assessment-request';
import { ResendBrokerRepresentativeNotificationRequest } from 'src/app/services/brokers/models/request/resend-broker-representative-notification-request';
import { SendToBrokerOperationSignatureRequest } from 'src/app/services/brokers/models/request/send-to-broker-operation-signature-request';
import { SetBrokerOperationSetupAsDoneRequest } from 'src/app/services/brokers/models/request/set-broker-operation-setup-as-done-request';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { BrokerOperationRecipientViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-recipient-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerRepresentativeViewModel } from 'src/app/services/brokers/models/view-models/broker-representative-view-model';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { CreateBrokerRepresentativeRequest } from 'src/app/services/fund-brokers/models/requests/create-broker-representative-request';
import { ContactProperties } from 'src/app/services/fund-manager/models/view-models/contact-properties';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { FillOperationArtifactRequest } from 'src/app/services/operations/models/request/fill-operation-artifact-request';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';


@Component({
    templateUrl: './broker-operation-setup.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss', './broker-operation-setup.component.scss']
})
export class BrokerOperationSetupComponent implements OnInit {

    steps: MenuItem[] = [{ label: 'Setup' }, { label: 'Documentos' }, { label: 'Assinatura' }, { label: 'Análise da Corretora' }, { label: 'Concluído' }];
    currentStep: number = 0;
    managerContacts: ContactProperties[] = [];
    allRepresentativesA: RepresentationViewModel[] = [];
    allRepresentativesB: RepresentationViewModel[] = [];
    displayCreateBrokerContacts: boolean = false;
    displayPendingOperation: boolean = false;
    displayOperationFiles: boolean = false;
    displayApprovedAssesment: boolean = false;
    requestFiles = null;
    requestAssesmentReply = null;
    requestDissmisAssesment: DismissBrokerOperationAssessmentRequest = null;
    selectedBrokerOperation: BrokerOperationViewModel = null;
    displayFillInfoTemplate: boolean = false;
    artefactObservable = new Subject<ArtifactViewModel>();
    requestArtefacts: FillOperationArtifactRequest = null;
    displayButtonArtefacts: boolean = false;
    displyDismissAssesment: boolean = false;
    displayAlertDismissAssesment: boolean = false;
    stepDescription: string = null;
    selectedContacts: [] = null;
    recipients: BrokerOperationRecipientViewModel[] = [];
    brokerSigners: BrokerRepresentativeViewModel[] = [];
    brokerContacts: BrokerRepresentativeViewModel[] = [];

    switchContact: string = null;
    allContacts: SelectItem[] = [];
    selectedContatIncludeOperation: BrokerRepresentativeViewModel = null;
    displayCreateBrokerContact: boolean = false;

    allBrokerSignersAndContacts: BrokerRepresentativeViewModel[];
    assesments: BrokerOperationAssessmentSummaryViewModel[] = [];
    isBrokerSignableRepresentative: boolean = false;
    brokerById: FundBrokerViewModel = null;
    displayMessage: boolean = false;
    selecetedBrokerSigners: BrokerRepresentativeViewModel[] = [];
    hasBrokerFundRegulationFile: boolean = false;
    disabledButtonSignature: boolean = false;

    public artifactStatusEnum = ArtifactStatusEnum;
    public operationStatusEnum = OperationStatusEnum;
    public brokerOperationStatusEnum = BrokerOperationStatusEnum;


    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        if (this.selectedBrokerOperation.currentStatus.status.value !== OperationStatusEnum.Concluída
            && this.selectedBrokerOperation.currentStatus.status.value !== OperationStatusEnum.Cancelada) {
            this.router.navigate([`app/customer/broker-operation/list`], { queryParams: { tab: 0 } });
        } else if (this.selectedBrokerOperation.currentStatus.status.value == OperationStatusEnum.Concluída) {
            this.router.navigate([`app/customer/broker-operation/list`], { queryParams: { tab: 1 } });
        } else if (this.selectedBrokerOperation.currentStatus.status.value == OperationStatusEnum.Cancelada) {
            this.router.navigate([`app/customer/broker-operation/list`], { queryParams: { tab: 2 } });
        }
    }


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private representationService: RepresentationService,
        private brokerOperationService: BrokerService,
        private fileUploadService: FileUploadService,
        private confirmationService: ConfirmationService,
        private fundBrokerService: FundBrokerService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        this.setFormUpload();
        await this.loadData();
        await this.loadFundRepresentatives();
        await this.loadBrokerSigners();
        await this.loadBrokerById();
        this.loadBrokerSignableRepresentatives();
        await this.getAllContactsBroker();
        this.verifyHasBrokerFundRegulationFile();
        this.loadingService.hide();
    }

    setFormUpload() {
        this.requestFiles = {
            description: null,
            files: []
        }
        this.requestAssesmentReply = {
            reply: null,
        }
        this.requestDissmisAssesment = {
            notes: null,
            brokerAccountInfo: null,
            brokerOperationId: null,
            operationExpirationDate: null,
        }
    }

    defineStep() {
        switch (this.selectedBrokerOperation.currentStatus.status.value) {
            case OperationStatusEnum.EmSetup:
                this.currentStep = 0;
                this.stepDescription = 'Em Setup'
                break;
            case OperationStatusEnum.PreenchimentoManualNecessario:
            case OperationStatusEnum.PreenchimentoConcluido:
                this.currentStep = 1;
                this.stepDescription = 'Documentos';
                break;
            case OperationStatusEnum.AguardandoAssinatura:
                this.currentStep = 2;
                this.stepDescription = 'Assinatura'
                break;
            case OperationStatusEnum.EmAnalisePeloGestor:
            case OperationStatusEnum.PendenciadaPeloGestor:
                this.currentStep = 3;
                this.stepDescription = 'Análise da Corretora'
                this.getOpertionAssessments();
                break;
            case OperationStatusEnum.Concluída:
                this.currentStep = 4;
                this.stepDescription = 'Concluído'
                this.getOpertionAssessments();
                break;
            case OperationStatusEnum.Cancelada:
                this.currentStep = 5;
                this.stepDescription = 'Cancelado'
                this.getOpertionAssessments();
                break;
            default:
                this.messageService.add({ severity: 'warn', summary: 'Status não permitido', detail: 'O status atual da operação não permite operação', life: 5000 });
                this.router.navigateByUrl(`app/customer/broker-operation/list`)
                break;
        }
    }

    async loadBrokerById() {
        this.brokerById = await this.fundBrokerService.getById(this.selectedBrokerOperation.broker.id);
    }

    loadBrokerSignableRepresentatives(): boolean {
        let existRepresentative = false;
        this.selectedBrokerOperation.artifacts.forEach(setup => {
            if (setup.isBrokerRepresentativeSignatureRequired == true) {
                existRepresentative = true;
            }
        })
        return existRepresentative;
    }

    async getOpertionAssessments() {
        const brokerOperationId = this.selectedBrokerOperation.id;
        this.assesments = await this.brokerOperationService.getBrokerOperationAllAssessments(brokerOperationId);
        this.assesments = this.assesments.sort((a, b) => {
            if (new Date(a.createDate) < new Date(b.createDate)) {
                return 1;
            } else {
                return -1
            }
        });
    }

    async loadData() {
        const operationId = this.route.snapshot.params.id;
        this.selectedBrokerOperation = await this.brokerOperationService.getBrokerOperationById(operationId);
        this.defineStep();
    }


    async uploadFiles(event: File) {
        try {
            this.displayOperationFiles = false;
            this.loadingService.show();
            const response = await this.fileUploadService.upload(event[0]);
            const request: AddAttachmentRequest = {
                operationId: this.selectedBrokerOperation.id,
                description: this.requestFiles.description,
                fileUrl: response.fileUrl
            }
            this.selectedBrokerOperation = await this.brokerOperationService.brokerOperationAttachments(request);
            this.updateUrl();
            this.selectedBrokerOperation.attachments = this.selectedBrokerOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
                return {
                    description: it.description,
                    fileUrl: it.fileUrl,
                    user: it.user,
                    representative: it.representative,
                    id: it.id,
                    isActive: it.isActive,
                    createDate: it.createDate
                }
            })
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationFiles = false;
            this.setFormUpload();
        } finally {
            this.setFormUpload();
            this.loadingService.hide();
        }
    }

    async removeFile(file: BrokerOperationAttachmentViewModel | AttachmentViewModel) {
        try {
            this.loadingService.show();
            await this.brokerOperationService.deleteAttachment(file.id);
            this.updateUrl();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            await this.loadData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.formartDateAttachments();
            this.verifyHasBrokerFundRegulationFile();
        }
    }

    async onBrokerUploadFundRegulation() {
        this.loadingService.show();
        try {
            const request: AddBrokerOperationFundRegulationAsAttachmentRequest = {
                operationId: this.selectedBrokerOperation.id,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.brokerOperationAttachmentsOnFundRegulation(request);
            this.updateUrl();
            this.selectedBrokerOperation.attachments = this.selectedBrokerOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
                return {
                    description: it.description,
                    fileUrl: it.fileUrl,
                    user: it.user,
                    representative: it.representative,
                    id: it.id,
                    isActive: it.isActive,
                    createDate: it.createDate
                }
            })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.verifyHasBrokerFundRegulationFile();
            this.loadingService.hide();
        }
    }

    verifyHasBrokerFundRegulationFile() {
        this.hasBrokerFundRegulationFile = this.selectedBrokerOperation?.attachments.some(c => c.description.includes("Regulamento do Fundo"));
    }

    formartDateAttachments() {
        this.selectedBrokerOperation.attachments = this.selectedBrokerOperation.attachments.map(it => {
            const format = new Date(it.createDate);
            format.setHours(format.getHours() - 3);
            it.createDate = format.toISOString();
            return {
                description: it.description,
                fileUrl: it.fileUrl,
                user: it.user,
                representative: it.representative,
                id: it.id,
                isActive: it.isActive,
                createDate: it.createDate
            }
        });
    }

    confirm(file: BrokerOperationAttachmentViewModel | AttachmentViewModel) {
        this.confirmationService.confirm({
            header: `Anexo ${file.description}`,
            message: `Tem certeza que deseja excluir o anexo ${file.description}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeFile(file);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    openAddFile() {
        this.displayOperationFiles = true;
    }

    async loadFundRepresentatives() {
        const active = true;
        this.allRepresentativesA = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesB = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesA = this.allRepresentativesA.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
        this.allRepresentativesB = this.allRepresentativesB.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
    }

    async loadBrokerSigners() {
        const allBrokerSignersAndContacts = await this.fundBrokerService.getRepresentativesInFundBroker(this.selectedBrokerOperation.broker.id);
        this.brokerSigners = allBrokerSignersAndContacts.filter(fill => fill.canSign == true);
        if (this.selecetedBrokerSigners.length > 0) {
            this.selecetedBrokerSigners.forEach(c => {
                const alreadyExists = this.brokerSigners.some(b => b.id === c.id);
                if (!alreadyExists) {
                    this.brokerSigners.push(c);
                }
            });
        }
        this.brokerContacts = allBrokerSignersAndContacts;
    }

    async changedRepresentativeA(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddBrokerOperationSignersRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                representationSignerOneId: event.id,
                representationSignerTwoId: this.selectedBrokerOperation?.shareholderFundSignerOne?.id ? this.selectedBrokerOperation?.shareholderFundSignerTwo?.id : null,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.operationSigners(request);
            // await this.loadData();
            this.allRepresentativesB = this.allRepresentativesB.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async changedRepresentativeB(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddBrokerOperationSignersRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                representationSignerOneId: this.selectedBrokerOperation?.shareholderFundSignerOne?.id ? this.selectedBrokerOperation?.shareholderFundSignerOne?.id : null,
                representationSignerTwoId: event.id,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.operationSigners(request);
            // await this.loadData();
            this.allRepresentativesA = this.allRepresentativesA.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async selectedCheckboxSigners(event: BrokerRepresentativeViewModel) {
        try {
            this.loadingService.show()
            const request: AddBrokerSignersRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                representativesIds: [event.id]
            };
            await this.brokerOperationService.operationBrokerSigners(request);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async unSelectCheckboxSigners(event: BrokerRepresentativeViewModel) {
        try {
            this.loadingService.show();
            const brokerOperationId = this.selectedBrokerOperation.id;
            const signerIndex = this.selectedBrokerOperation.brokerSigners.findIndex(signer => signer.representativeSigner.id == event.id);
            const signer = this.selectedBrokerOperation.brokerSigners.find(c => c.representativeSigner.id == event.id);
            await this.brokerOperationService.deleteBrokerSigner(brokerOperationId, signer.representativeSigner.id);
            this.selectedBrokerOperation.brokerSigners.splice(signerIndex, 1);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async toggleOnHeaderSigners(representative: boolean) {
        if (representative) {
            for (let signer of this.brokerSigners) {
                await this.selectedCheckboxSigners(signer);
            }
        } else {
            for (let signer of this.brokerSigners) {
                await this.unSelectCheckboxSigners(signer);
            }
        }
    }


    async selectedCheckboxContact(event: BrokerRepresentativeViewModel) {
        try {
            this.loadingService.show()
            const request: AddBrokerOperationRecipientsRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                brokerRepresentativesIds: [event.id]
            };
            await this.brokerOperationService.operationBrokerRecipients(request);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async unSelectCheckboxContact(event: BrokerRepresentativeViewModel) {
        try {
            this.loadingService.show();
            const recipientIndex = this.selectedBrokerOperation.recipients.findIndex(recipient => recipient.representative.id == event.id);
            const recipient = this.selectedBrokerOperation.recipients.find(it => it.representative.id == event.id);
            await this.brokerOperationService.deleteBrokerRecipients(recipient.id);
            this.selectedBrokerOperation.recipients.splice(recipientIndex, 1);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async toggleOnHeaderContact(recipient: boolean) {
        if (recipient) {
            for (let recipient of this.recipients) {
                await this.selectedCheckboxContact(recipient.representative);
            }
        } else {
            for (let recipient of this.recipients) {
                await this.unSelectCheckboxContact(recipient.representative);
            }
        }
    }

    addBrokerContacts() {
        this.displayCreateBrokerContact = true;
    }

    async saveBrokerContact(event, requestFormManagerContact) {

        if (!this.formValidationService.validateForm(requestFormManagerContact.form.form)) return;

        try {
            this.loadingService.show();
            const request: CreateBrokerRepresentativeRequest = {
                fundBrokerId: this.selectedBrokerOperation.broker.id,
                personalDocumentNumber: null,
                name: event.request.name,
                email: event.request.email,
                expireDate: event.request.expireDate,
                canSign: false,
            }
            await this.fundBrokerService.createRepresentative(request);
            this.displayCreateBrokerContact = false;
            let response = await this.fundBrokerService.getRepresentativesInFundBroker(this.selectedBrokerOperation.broker.id);
            let filterContact = response.filter(fill => fill.email == event.request.email)[0];
            const requestTwo: AddBrokerOperationRecipientsRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                brokerRepresentativesIds: [filterContact.id]
            };
            await this.brokerOperationService.operationBrokerRecipients(requestTwo);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.loadData();
            await this.loadBrokerSigners();
            this.displayCreateBrokerContact = false;
            this.loadingService.hide();
            this.switchContact = null;
        }
    }

    onCancel() {
        this.displayCreateBrokerContact = false;
        this.displayCreateBrokerContacts = false;
        this.switchContact = null;
    }


    fillInfoTemplate(artefact: ArtifactViewModel) {
        this.displayFillInfoTemplate = true;
        this.artefactObservable.next(artefact);
        return this.artefactObservable.asObservable();
    }

    async updateArtifactById(artifact: ArtifactViewModel) {
        try {
            this.loadingService.show();
            const artifactId = artifact.id;
            await this.brokerOperationService.changeSingatures(artifactId);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Status atualizado com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async saveFillTemplate(event) {
        try {
            this.displayFillInfoTemplate = false;
            this.loadingService.show();
            this.selectedBrokerOperation = await this.brokerOperationService.updateArtifacts(event);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    verifySetAsDone(): boolean {
        if ((this.selectedBrokerOperation?.shareholderFundSignerOne == null && this.selectedBrokerOperation?.shareholderFundSignerTwo == null) ||
            (this.selectedBrokerOperation?.recipients.length == 0) ||
            (this.loadBrokerSignableRepresentatives() && this.selectedBrokerOperation.brokerSigners.length == 0)) {
            return true;
        } else {
            return false;
        }
    }

    async setAsDone() {
        // if(this.switchRepresentativeAdminInvestedFund && this.selectedBrokerOperation.administratorSigners.length == 0 ||
        //     this.switchRepresentativeManagerInvestedFund && this.selectedBrokerOperation.managerSigners.length == 0) {
        //     this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Outras Assinaturas está ativado. Selecione os Rep. Legais/Procuradores ou desative para prosseguir', life: 5000 });
        //     return;
        // }
        try {
            this.loadingService.show();
            const request: SetBrokerOperationSetupAsDoneRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.setupDone(request);
            this.defineStep();
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async sendToSign() {
        this.disabledButtonSignature = true;
        this.loadingService.show();
        try {
            const request: SendToBrokerOperationSignatureRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.signatureReady(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    openArtefactUrl(file: BrokerOperationAttachmentViewModel | AttachmentViewModel) {
        window.open(file.fileUrl, '_blank');
    }

    openAlertDismissAssesment() {
        this.displayAlertDismissAssesment = true;
    }

    openDismissAssesment() {
        this.displayAlertDismissAssesment = false;
        this.displyDismissAssesment = true;
    }

    async sendReplyAssesment() {
        const representativeId = this.selectedBrokerOperation.recipients.find(find => find.isOperationApproved == false).representative.id;
        try {
            this.loadingService.show();
            const request: ReplyBrokerOperationAssessmentRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                contactId: representativeId,
                notes: this.requestAssesmentReply.reply,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.replyAssesment(request);
            this.defineStep();
            this.displayMessage = true;
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormUpload();
            this.loadingService.hide();
        }
    }

    async dismissAssesments(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        try {
            const request: DismissBrokerOperationAssessmentRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                brokerAccountInfo: this.requestDissmisAssesment.brokerAccountInfo,
                operationExpirationDate: this.requestDissmisAssesment.operationExpirationDate,
                // notes: this.requestDissmisAssesment.notes,
                notes: `${this.requestDissmisAssesment?.notes}. Conta: ${this.requestDissmisAssesment?.brokerAccountInfo}. Data de expiração: ${this.requestDissmisAssesment?.operationExpirationDate?.toLocaleDateString('pt-BR')}.`,
            }
            this.loadingService.show();
            this.displyDismissAssesment = false;
            this.selectedBrokerOperation = await this.brokerOperationService.dismissAssessment(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async resendEmail(recipient) {
        try {
            this.loadingService.show();
            const request: ResendBrokerRepresentativeNotificationRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                // contactId: this.selectedBrokerOperation.recipients[0].contact.id,
                // brokerRepresentativeId: this.selectedBrokerOperation.recipients[0].representative.id,
                brokerRepresentativeId: recipient.representative.id,
            }
            this.selectedBrokerOperation = await this.brokerOperationService.resendMailAssesment(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'E-mail enviado.', detail: `E-mail enviado com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async getAllContactsBroker() {
        let response = await this.fundBrokerService.getRepresentativesInFundBroker(this.selectedBrokerOperation.broker.id);
        response.map(item => {
            this.allContacts.push({ label: `${item.name} - ${item.email}`, value: item })
        })
    }

    async onSaveExistContactInBrokerOperation(contact: BrokerRepresentativeViewModel) {
        try {
            this.loadingService.show()
            const request: AddBrokerOperationRecipientsRequest = {
                brokerOperationId: this.selectedBrokerOperation.id,
                brokerRepresentativesIds: [contact.id]
            };
            this.displayCreateBrokerContact = false;
            await this.brokerOperationService.operationBrokerRecipients(request);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.displayCreateBrokerContact = false;
            this.loadingService.hide();
            this.switchContact = null;
        }
    }

    async removeRepresentative(representative: BrokerRepresentativeViewModel) {
        if (this.selectedBrokerOperation.recipients.length == 1) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'A Operação precisa ter pelo menos um contato da gestora.', life: 5000 });
            return;
        }
        try {
            this.loadingService.show();
            await this.brokerOperationService.deleteRecipients(representative.id);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

}