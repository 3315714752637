import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { GetOperationsPendingToExportRequest } from 'src/app/services/operations/models/request/get-operations-pending-to-export-request';
import { GetDoneOperationsTimeframeByDateRangeResponse } from 'src/app/services/operations/models/responses/get-done-operations-timeframe-by-date-range-response';
import { GetFinishedOperationsByDateRangeResponse } from 'src/app/services/operations/models/responses/get-finished-operations-by-date-range-response';
import { GetFinishedOperationsYearlyReportResponse } from 'src/app/services/operations/models/responses/get-finished-operations-yearly-report-response';
import { GetOperantionsPendingSummaryResponse } from 'src/app/services/operations/models/responses/get-operantions-pending-summary-response';
import { GetOperationsPendingSummaryResponse } from 'src/app/services/operations/models/responses/get-operations-pending-summary-response';
import { OperationService } from 'src/app/services/operations/operation-service';
import { OperationViewModel } from './../../../../services/operations/models/view-models/operation-view-model';

@Component({
    templateUrl: './operation-report.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class OperationReportComponent implements OnInit {

    allTypesOperation: any[] = [];
    allYears: SelectItem[] = [];
    selectedYear: SelectItem = null;
    typeOperation: SelectItem[] = [];
    typeOperationGraphic: SelectItem[] = [];
    statusOperation: SelectItem[] = [];
    selectedStatusOperation: SelectItem = null;
    selectedTypeOperation: any = null;
    typeReportOptions: SelectItem[] = [];
    selectedReport: SelectItem = null;
    periods: SelectItem[] = [];

    stackedData: any = null;
    stackedOptions: any = null;

    stackedReportTimeFrames: any = null;
    stackedReportTimeFramesOptions: any = null;

    selectedPeriod: GetFinishedOperationsByDateRangeResponse = null;
    selectedPeriodYearly: GetFinishedOperationsYearlyReportResponse[] = [];
    selectedPeriodTimeFrames: GetDoneOperationsTimeframeByDateRangeResponse[] = []

    displayPeriod: boolean = false;

    startPeriod: Date = null;
    endPeriod: Date = null;
    startDate: string = null;
    endDate: string = null;

    filteredPeriod: SelectItem = null;
    amountTimeFrames: number = 0;
    daysAmountTimeFrames: number = 0;

    operations: OperationViewModel[] = [];
    operationsPending: GetOperantionsPendingSummaryResponse[] = [];
    firstPage: number = 0;
    operationsExport: any[] = [];
    consultMonth: number = null
    consultYear: number = null

    allTypesStatus: any[] = [];
    totalDone: number = 0;
    totalCancel: number = 0;
    allYearsCharges: SelectItem[] = [];
    allMonthsCharges: SelectItem[] = [];

    checkAllPeriod: boolean = false;
    fullOperations: GetOperationsPendingSummaryResponse[] = [];
    startAllPeriod: Date = null;
    endAllPeriod: Date = null;
    displayReport: boolean = false;
    operationSummary: GetOperationsPendingSummaryResponse = null;

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private operationService: OperationService,
        private messageService: MessageService,
        private datePipe: DatePipe,
        private loadingService: LoadingService,
    ) {
    }

    ngOnInit() {
        this.getTypeReports();
    }

    allMonthsAndYearsCharges() {
        this.allMonthsCharges = [
            { label: "Janeiro", value: 1 },
            { label: "Fevereiro", value: 2 },
            { label: "Março", value: 3 },
            { label: "Abril", value: 4 },
            { label: "Maio", value: 5 },
            { label: "Junho", value: 6 },
            { label: "Julho", value: 7 },
            { label: "Agosto", value: 8 },
            { label: "Setembro", value: 9 },
            { label: "Outubro", value: 10 },
            { label: "Novembro", value: 11 },
            { label: "Dezembro", value: 12 },
        ]
        this.allYearsCharges = [
            { label: "2022", value: 2022 },
            { label: "2023", value: 2023 },
            { label: "2024", value: 2024 },
        ]
    }

    allTypeStatusOperation() {
        this.allTypesStatus = [
            {
                operationType: "Compra",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "Atualizacao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "BoletimSubscricao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "AlteracaoDistribuidor",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "MovimentacaoDiaria",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "Incorporacao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "Cisao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "IntegralizacaoId",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "Masterizacao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "TransferenciaAdmin",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "ResgateDeComeCotas",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "CessaoDeCotasCompra",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "CessaoDeCotasDoacao",
                canceledCount: 0,
                doneCount: 0,
            },
            {
                operationType: "Middle",
                canceledCount: 0,
                doneCount: 0,
            }
        ]
    }

    async getOperations() {
        this.operations = [];
        this.operationsPending = [];
        this.allTypeStatusOperation();
        if (this.selectedReport?.value == 4) {
            this.operations = await this.operationService.chargesReport(this.consultYear, this.consultMonth);

            this.allTypesStatus = this.allTypesStatus.map((item) => {
                return {
                    operationType: item.operationType,
                    canceledCount: this.operations.filter(fill => fill.type.displayName == item.operationType
                        && fill.currentStatus.status.value == OperationStatusEnum.Cancelada).length,
                    doneCount: this.operations.filter(fill => fill.type.displayName == item.operationType).length,
                }
            });
            this.totalDone = this.allTypesStatus.reduce((a, b) => {
                return a + b.doneCount
            }, 0);
            this.totalCancel = this.allTypesStatus.reduce((a, b) => {
                return a + b.canceledCount
            }, 0);

            this.operationsExport = this.operations.map(operation => {
                return {
                    'ID': operation?.sequentialId,
                    'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                    'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                        operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                        this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Operação': operation?.type?.friendlyName,
                    'Fundo Cotista': `${operation?.shareholderFund?.name}`,
                    'CNPJ F. Cotista': `${operation?.shareholderFund?.documentNumber}`,
                    'Fundo Investido': `${operation?.investedFund?.name}`,
                    'CNPJ F. Investido': `${operation?.investedFund?.documentNumber}`,
                    'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Status': `${operation?.currentStatus?.status?.friendlyName}`

                }
            });
        }
        if (this.selectedReport?.value == 5) {
            this.operationsPending = await this.operationService.managerPendingsReport(this.consultYear, this.consultMonth);
            this.operationsExport = this.operationsPending.map(operation => {
                return {
                    'ID': operation?.sequentialId,
                    'Data da Entrada': operation?.createDate ? this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy') : '',
                    'Data Pendência': operation?.pendingDate ? this.datePipe.transform(operation?.pendingDate, 'dd/MM/yyyy') : '',
                    'Operação': operation?.operationType,
                    'Gestor': operation?.manager,
                    'Fundo Cotista': `${operation?.shareholderFund}`,
                    'CNPJ F. Cotista': `${operation?.shareholderFundDocumentNumber}`,
                    'Fundo Investido': `${operation?.investedFund}`,
                    'CNPJ F. Investido': `${operation?.investedFundDocumentNumber}`,
                    'Data Status': operation?.currentStatusDate ? this.datePipe.transform(operation?.currentStatusDate, 'dd/MM/yyyy') : '',
                    'Status': `${operation?.currentStatus}`,
                    'Data Conclusão': operation?.finishDate ? this.datePipe.transform(operation?.finishDate, 'dd/MM/yyyy') : '',
                    'Motivo Pendência': `${operation?.pendingReason}`,
                    'Descrição Pendência': `${operation?.pendingNotes}`
                }
            });
        }


    }

    getTypeReports() {
        this.typeReportOptions = [
            { label: 'Relatório Simples', value: 1 },
            { label: 'Relatório em Gráfico', value: 2 },
            { label: 'Relatório de Duração das Operações', value: 3 },
            { label: 'Relatório de Faturamento', value: 4 },
            { label: 'Relatório de Análise das Pendências', value: 5 },
            { label: 'Relatório Completo', value: 6 },
        ];
    }

    getStatusOfGrapichReport() {
        this.statusOperation = [
            { label: 'Concluídos', value: 1 },
            { label: 'Cancelados', value: 2 },
            { label: 'Todos', value: 3 },
        ];
    }

    allOperations() {
        this.allTypesOperation = [
            { label: 'Compra', labelResponse: 'Compra', value: OperationTypeEnum.Compra },
            { label: 'Atualização', labelResponse: 'Atualizacao', value: OperationTypeEnum.Atualizacao },
            { label: 'Boletim de Subscrições', labelResponse: 'BoletimSubscricao', value: OperationTypeEnum.BoletimSubscricao },
            { label: 'Alteração de Distribuidor', labelResponse: 'AlteracaoDistribuidor', value: OperationTypeEnum.AlteracaoDistribuidor },
            { label: 'Movimentação Diária', labelResponse: 'MovimentacaoDiaria', value: OperationTypeEnum.MovimentacaoDiaria },
            { label: 'Incorporação', labelResponse: 'Incorporacao', value: OperationTypeEnum.Incorporacao },
            { label: 'Cisão', labelResponse: 'Cisao', value: OperationTypeEnum.Cisao },
            { label: 'Integralização', labelResponse: 'IntegralizacaoId', value: OperationTypeEnum.Integralizacao },
            { label: 'Masterização', labelResponse: 'Masterizacao', value: OperationTypeEnum.Masterizacao },
            { label: 'Transferências de Administrador', labelResponse: 'TransferenciaAdmin', value: OperationTypeEnum.TransferenciaAdmin },
            { label: 'Resgate Come Cotas', labelResponse: 'ResgateDeComeCotas', value: OperationTypeEnum.ResgateDeComeCotas },
            { label: 'Todos', value: 100 },
        ];
    }

    getPeriods() {
        this.periods = [];
        this.periods = [
            { label: 'Hoje', value: 1 },
            { label: 'Essa semana', value: 2 },
            { label: 'Este mês', value: 3 },
            { label: 'Últimos 30 dias', value: 4 },
            { label: 'Escolha o período', value: 5 },
        ];
    }

    getPeriodDuration() {
        this.periods = [];
        this.periods = [
            { label: 'Este mês', value: 1 },
            { label: 'Mês anterior', value: 2 },
            { label: 'Este ano', value: 3 },
            { label: 'Escolha o período', value: 4 },
        ];
    }

    async changeOperation(event: SelectItem) {
        this.selectedPeriod = null;
        switch (event.value) {
            case 1:
                this.getPeriods();
                this.getTypesOperation();
                break;
            case 2:
                this.allOperations();
                this.getStatusOfGrapichReport();
                break;
            case 3:
                this.getPeriodDuration();
                this.allOperations();
                break;
            case 4:
                this.allMonthsAndYearsCharges();
                this.operations = [];
                this.operationsPending = [];
                this.consultMonth = null;
                this.consultYear = null;
                break;
            case 5:
                this.allMonthsAndYearsCharges();
                this.operations = [];
                this.operationsPending = [];
                this.consultMonth = null;
                this.consultYear = null;
                break;
            case 6:
                await this.getFullPeriod();
                break;
            default:
                break;
        }
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.operationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }


    getTypesOperation() {
        this.typeOperation = this.allTypesOperation;
        this.typeOperation.pop();
    }

    async changeReportDuration() {

        const selectedTypeOperation = this.selectedTypeOperation ? this.selectedTypeOperation?.value : null;
        const start = this.startDate
        const end = this.endDate
        this.amountTimeFrames = 0;
        this.daysAmountTimeFrames = 0;

        if (this.selectedTypeOperation.value == 100) {
            this.selectedPeriodTimeFrames = await this.operationService.finishedReportTimeFrames(start, end, null);
        } else if (this.selectedTypeOperation.value !== 100) {
            this.selectedPeriodTimeFrames = await this.operationService.finishedReportTimeFrames(start, end, selectedTypeOperation);
        }
        const quantityOfOperations = this.selectedPeriodTimeFrames.map(item => item.quantityOfOperations);
        const daysToComplete = this.selectedPeriodTimeFrames.map(item => item.daysToComplete);
        this.amountTimeFrames = quantityOfOperations.reduce((prev, curr) => prev + curr, 0);
        this.daysAmountTimeFrames = daysToComplete.reduce((prev, curr) => prev + curr, 0);


        this.stackedReportTimeFrames = {
            labels: daysToComplete,
            datasets: [{
                type: 'bar',
                label: "Concluídos",
                backgroundColor: '#FE7100',
                data: quantityOfOperations
            }]
        };

        this.stackedReportTimeFramesOptions = {
            tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                    title: () => null,
                }
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

    }

    filterPeriod() {

        if (this.selectedStatusOperation.value == 1 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonths: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonths.push(count.doneCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        backgroundColor: '#FE7100',
                        data: countMonths,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 2 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonths: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonths.push(count.canceledCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        backgroundColor: '#FE7100',
                        data: countMonths,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 3 && this.selectedTypeOperation.labelResponse == this.selectedPeriodYearly[0]?.operationType) {
            let countMonthsDone: number[] = [];
            let countMonthsCancel: number[] = [];
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonthsDone.push(count.doneCount);
            }
            for (let count of this.selectedPeriodYearly[0].monthlyOccurence) {
                countMonthsCancel.push(count.canceledCount);
            }
            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        // label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        label: `Concluídos`,
                        backgroundColor: 'var(--primary-color)',
                        data: countMonthsDone,
                    },
                    {
                        // type: 'horizontalBar',
                        // label: this.selectedTypeOperation.label ? this.selectedTypeOperation.label : ``,
                        label: `Cancelados`,
                        backgroundColor: '#F79C60',
                        data: countMonthsCancel,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 3 && this.selectedTypeOperation.value == 100) {

            let buy: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];
            let subscription: GetFinishedOperationsYearlyReportResponse[] = [];
            let distribution: GetFinishedOperationsYearlyReportResponse[] = [];
            let movement: GetFinishedOperationsYearlyReportResponse[] = [];
            let incorporation: GetFinishedOperationsYearlyReportResponse[] = [];
            let split: GetFinishedOperationsYearlyReportResponse[] = [];
            let payment: GetFinishedOperationsYearlyReportResponse[] = [];
            let mastering: GetFinishedOperationsYearlyReportResponse[] = [];
            let transfer: GetFinishedOperationsYearlyReportResponse[] = [];
            let ransom: GetFinishedOperationsYearlyReportResponse[] = [];

            buy = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Compra');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Atualizacao');
            subscription = this.selectedPeriodYearly.filter(fill => fill.operationType == 'BoletimSubscricao');
            distribution = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AlteracaoDistribuidor');
            movement = this.selectedPeriodYearly.filter(fill => fill.operationType == 'MovimentacaoDiaria');
            incorporation = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Incorporacao');
            split = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Cisao');
            payment = this.selectedPeriodYearly.filter(fill => fill.operationType == 'IntegralizacaoId');
            mastering = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Masterizacao');
            transfer = this.selectedPeriodYearly.filter(fill => fill.operationType == 'TransferenciaAdmin');
            ransom = this.selectedPeriodYearly.filter(fill => fill.operationType == 'ResgateDeComeCotas');

            let countBuy: number[] = [];
            for (let count of buy[0].monthlyOccurence) {
                countBuy.push(count.totalCount);
            }

            let countUp: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUp.push(count.totalCount);
            }

            let countSub: number[] = [];
            for (let count of subscription[0].monthlyOccurence) {
                countSub.push(count.totalCount);
            }

            let countDist: number[] = [];
            for (let count of distribution[0].monthlyOccurence) {
                countDist.push(count.totalCount);
            }

            let countMov: number[] = [];
            for (let count of movement[0].monthlyOccurence) {
                countMov.push(count.totalCount);
            }

            let countInc: number[] = [];
            for (let count of incorporation[0].monthlyOccurence) {
                countInc.push(count.totalCount);
            }

            let countSpl: number[] = [];
            for (let count of split[0].monthlyOccurence) {
                countSpl.push(count.totalCount);
            }

            let countPay: number[] = [];
            for (let count of payment[0].monthlyOccurence) {
                countPay.push(count.totalCount);
            }

            let countMas: number[] = [];
            for (let count of mastering[0].monthlyOccurence) {
                countMas.push(count.totalCount);
            }

            let countTran: number[] = [];
            for (let count of transfer[0].monthlyOccurence) {
                countTran.push(count.totalCount);
            }

            let countRan: number[] = [];
            for (let count of ransom[0].monthlyOccurence) {
                countRan.push(count.totalCount);
            }

            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: buy.map(item => item.operationType) ? 'Compra' : null,
                        backgroundColor: '#FFE399',
                        data: countBuy,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? 'Atualização' : null,
                        backgroundColor: '#FFC179',
                        data: countUp,
                    },
                    {
                        // type: 'horizontalBar',
                        label: subscription.map(item => item.operationType) ? `Boletim de Subscrições` : null,
                        backgroundColor: '#FFAB64',
                        data: countSub,
                    },
                    {
                        // type: 'horizontalBar',
                        label: distribution.map(item => item.operationType) ? `Alteração de Distribuidor` : null,
                        backgroundColor: '#FF9550',
                        data: countDist,
                    },
                    {
                        // type: 'horizontalBar',
                        label: movement.map(item => item.operationType) ? `Movimentação Diária` : null,
                        backgroundColor: '#EF8742',
                        data: countMov,
                    },
                    {
                        // type: 'horizontalBar',
                        label: incorporation.map(item => item.operationType) ? `Incorporação` : null,
                        backgroundColor: '#FFC966',
                        data: countInc,
                    },
                    {
                        // type: 'horizontalBar',
                        label: split.map(item => item.operationType) ? `Cisão` : null,
                        backgroundColor: '#FFB350',
                        data: countSpl,
                    },
                    {
                        // type: 'horizontalBar',
                        label: payment.map(item => item.operationType) ? `Integralização` : null,
                        backgroundColor: '#FFE399',
                        data: countPay,
                    },
                    {
                        // type: 'horizontalBar',
                        label: mastering.map(item => item.operationType) ? `Masterização` : null,
                        backgroundColor: '#FF9C3A',
                        data: countMas,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Transferências de Administrador` : null,
                        backgroundColor: '#FE7100',
                        data: countTran,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Resgate Come Cotas` : null,
                        backgroundColor: '#CE6C27',
                        data: countRan,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 1 && this.selectedTypeOperation.value == 100) {

            let buy: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];
            let subscription: GetFinishedOperationsYearlyReportResponse[] = [];
            let distribution: GetFinishedOperationsYearlyReportResponse[] = [];
            let movement: GetFinishedOperationsYearlyReportResponse[] = [];
            let incorporation: GetFinishedOperationsYearlyReportResponse[] = [];
            let split: GetFinishedOperationsYearlyReportResponse[] = [];
            let payment: GetFinishedOperationsYearlyReportResponse[] = [];
            let mastering: GetFinishedOperationsYearlyReportResponse[] = [];
            let transfer: GetFinishedOperationsYearlyReportResponse[] = [];
            let ransom: GetFinishedOperationsYearlyReportResponse[] = [];

            buy = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Compra');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Atualizacao');
            subscription = this.selectedPeriodYearly.filter(fill => fill.operationType == 'BoletimSubscricao');
            distribution = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AlteracaoDistribuidor');
            movement = this.selectedPeriodYearly.filter(fill => fill.operationType == 'MovimentacaoDiaria');
            incorporation = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Incorporacao');
            split = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Cisao');
            payment = this.selectedPeriodYearly.filter(fill => fill.operationType == 'IntegralizacaoId');
            mastering = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Masterizacao');
            transfer = this.selectedPeriodYearly.filter(fill => fill.operationType == 'TransferenciaAdmin');
            ransom = this.selectedPeriodYearly.filter(fill => fill.operationType == 'ResgateDeComeCotas');

            let countBuy: number[] = [];
            for (let count of buy[0].monthlyOccurence) {
                countBuy.push(count.doneCount);
            }

            let countUp: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUp.push(count.doneCount);
            }

            let countSub: number[] = [];
            for (let count of subscription[0].monthlyOccurence) {
                countSub.push(count.doneCount);
            }

            let countDist: number[] = [];
            for (let count of distribution[0].monthlyOccurence) {
                countDist.push(count.doneCount);
            }

            let countMov: number[] = [];
            for (let count of movement[0].monthlyOccurence) {
                countMov.push(count.doneCount);
            }

            let countInc: number[] = [];
            for (let count of incorporation[0].monthlyOccurence) {
                countInc.push(count.doneCount);
            }

            let countSpl: number[] = [];
            for (let count of split[0].monthlyOccurence) {
                countSpl.push(count.doneCount);
            }

            let countPay: number[] = [];
            for (let count of payment[0].monthlyOccurence) {
                countPay.push(count.doneCount);
            }

            let countMas: number[] = [];
            for (let count of mastering[0].monthlyOccurence) {
                countMas.push(count.doneCount);
            }

            let countTran: number[] = [];
            for (let count of transfer[0].monthlyOccurence) {
                countTran.push(count.doneCount);
            }

            let countRan: number[] = [];
            for (let count of ransom[0].monthlyOccurence) {
                countRan.push(count.doneCount);
            }

            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: buy.map(item => item.operationType) ? 'Compra' : null,
                        backgroundColor: '#FFE399',
                        data: countBuy,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? 'Atualização' : null,
                        backgroundColor: '#FFC179',
                        data: countUp,
                    },
                    {
                        // type: 'horizontalBar',
                        label: subscription.map(item => item.operationType) ? `Boletim de Subscrições` : null,
                        backgroundColor: '#FFAB64',
                        data: countSub,
                    },
                    {
                        // type: 'horizontalBar',
                        label: distribution.map(item => item.operationType) ? `Alteração de Distribuidor` : null,
                        backgroundColor: '#FF9550',
                        data: countDist,
                    },
                    {
                        // type: 'horizontalBar',
                        label: movement.map(item => item.operationType) ? `Movimentação Diária` : null,
                        backgroundColor: '#EF8742',
                        data: countMov,
                    },
                    {
                        // type: 'horizontalBar',
                        label: incorporation.map(item => item.operationType) ? `Incorporação` : null,
                        backgroundColor: '#FFC966',
                        data: countInc,
                    },
                    {
                        // type: 'horizontalBar',
                        label: split.map(item => item.operationType) ? `Cisão` : null,
                        backgroundColor: '#FFB350',
                        data: countSpl,
                    },
                    {
                        // type: 'horizontalBar',
                        label: payment.map(item => item.operationType) ? `Integralização` : null,
                        backgroundColor: '#FFE399',
                        data: countPay,
                    },
                    {
                        // type: 'horizontalBar',
                        label: mastering.map(item => item.operationType) ? `Masterização` : null,
                        backgroundColor: '#FF9C3A',
                        data: countMas,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Transferências de Administrador` : null,
                        backgroundColor: '#FE7100',
                        data: countTran,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Resgate Come Cotas` : null,
                        backgroundColor: '#CE6C27',
                        data: countRan,
                    },
                ]
            };
        } else if (this.selectedStatusOperation.value == 2 && this.selectedTypeOperation.value == 100) {

            let buy: GetFinishedOperationsYearlyReportResponse[] = [];
            let update: GetFinishedOperationsYearlyReportResponse[] = [];
            let subscription: GetFinishedOperationsYearlyReportResponse[] = [];
            let distribution: GetFinishedOperationsYearlyReportResponse[] = [];
            let movement: GetFinishedOperationsYearlyReportResponse[] = [];
            let incorporation: GetFinishedOperationsYearlyReportResponse[] = [];
            let split: GetFinishedOperationsYearlyReportResponse[] = [];
            let payment: GetFinishedOperationsYearlyReportResponse[] = [];
            let mastering: GetFinishedOperationsYearlyReportResponse[] = [];
            let transfer: GetFinishedOperationsYearlyReportResponse[] = [];
            let ransom: GetFinishedOperationsYearlyReportResponse[] = [];

            buy = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Compra');
            update = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Atualizacao');
            subscription = this.selectedPeriodYearly.filter(fill => fill.operationType == 'BoletimSubscricao');
            distribution = this.selectedPeriodYearly.filter(fill => fill.operationType == 'AlteracaoDistribuidor');
            movement = this.selectedPeriodYearly.filter(fill => fill.operationType == 'MovimentacaoDiaria');
            incorporation = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Incorporacao');
            split = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Cisao');
            payment = this.selectedPeriodYearly.filter(fill => fill.operationType == 'IntegralizacaoId');
            mastering = this.selectedPeriodYearly.filter(fill => fill.operationType == 'Masterizacao');
            transfer = this.selectedPeriodYearly.filter(fill => fill.operationType == 'TransferenciaAdmin');
            ransom = this.selectedPeriodYearly.filter(fill => fill.operationType == 'ResgateDeComeCotas');

            let countBuy: number[] = [];
            for (let count of buy[0].monthlyOccurence) {
                countBuy.push(count.canceledCount);
            }

            let countUp: number[] = [];
            for (let count of update[0].monthlyOccurence) {
                countUp.push(count.canceledCount);
            }

            let countSub: number[] = [];
            for (let count of subscription[0].monthlyOccurence) {
                countSub.push(count.canceledCount);
            }

            let countDist: number[] = [];
            for (let count of distribution[0].monthlyOccurence) {
                countDist.push(count.canceledCount);
            }

            let countMov: number[] = [];
            for (let count of movement[0].monthlyOccurence) {
                countMov.push(count.canceledCount);
            }

            let countInc: number[] = [];
            for (let count of incorporation[0].monthlyOccurence) {
                countInc.push(count.canceledCount);
            }

            let countSpl: number[] = [];
            for (let count of split[0].monthlyOccurence) {
                countSpl.push(count.canceledCount);
            }

            let countPay: number[] = [];
            for (let count of payment[0].monthlyOccurence) {
                countPay.push(count.canceledCount);
            }

            let countMas: number[] = [];
            for (let count of mastering[0].monthlyOccurence) {
                countMas.push(count.canceledCount);
            }

            let countTran: number[] = [];
            for (let count of transfer[0].monthlyOccurence) {
                countTran.push(count.canceledCount);
            }


            let countRan: number[] = [];
            for (let count of ransom[0].monthlyOccurence) {
                countRan.push(count.canceledCount);
            }

            this.stackedData = {
                labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                datasets: [
                    {
                        // type: 'horizontalBar',
                        label: buy.map(item => item.operationType) ? 'Compra' : null,
                        backgroundColor: '#FFE399',
                        data: countBuy,
                    },
                    {
                        // type: 'horizontalBar',
                        label: update.map(item => item.operationType) ? 'Atualização' : null,
                        backgroundColor: '#FFC179',
                        data: countUp,
                    },
                    {
                        // type: 'horizontalBar',
                        label: subscription.map(item => item.operationType) ? `Boletim de Subscrições` : null,
                        backgroundColor: '#FFAB64',
                        data: countSub,
                    },
                    {
                        // type: 'horizontalBar',
                        label: distribution.map(item => item.operationType) ? `Alteração de Distribuidor` : null,
                        backgroundColor: '#FF9550',
                        data: countDist,
                    },
                    {
                        // type: 'horizontalBar',
                        label: movement.map(item => item.operationType) ? `Movimentação Diária` : null,
                        backgroundColor: '#EF8742',
                        data: countMov,
                    },
                    {
                        // type: 'horizontalBar',
                        label: incorporation.map(item => item.operationType) ? `Incorporação` : null,
                        backgroundColor: '#FFC966',
                        data: countInc,
                    },
                    {
                        // type: 'horizontalBar',
                        label: split.map(item => item.operationType) ? `Cisão` : null,
                        backgroundColor: '#FFB350',
                        data: countSpl,
                    },
                    {
                        // type: 'horizontalBar',
                        label: payment.map(item => item.operationType) ? `Integralização` : null,
                        backgroundColor: '#FFE399',
                        data: countPay,
                    },
                    {
                        // type: 'horizontalBar',
                        label: mastering.map(item => item.operationType) ? `Masterização` : null,
                        backgroundColor: '#FF9C3A',
                        data: countMas,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Transferências de Administrador` : null,
                        backgroundColor: '#FE7100',
                        data: countTran,
                    },
                    {
                        // type: 'horizontalBar',
                        label: transfer.map(item => item.operationType) ? `Resgate Come Cotas` : null,
                        backgroundColor: '#CE6C27',
                        data: countRan,
                    },
                ]
            };
        }

        this.stackedOptions = {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            responsive: true,
            scales: {
                xAxes: [{
                    stacked: true,
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        };

    }

    async changeReport() {
        this.selectedPeriodYearly = null;
        const year = String(this.selectedYear);
        this.selectedPeriodYearly = await this.operationService.finishedReportYearly(year);
        if (this.selectedTypeOperation.value !== 100) {
            this.selectedPeriodYearly = this.selectedPeriodYearly.filter(fill => fill.operationType == this.selectedTypeOperation.labelResponse);
        }
        this.filterPeriod();
    }

    changePeriod(value: number) {
        this.selectedPeriod = null;
        if (this.selectedReport.value == 1) {
            switch (value) {
                case 1:
                    this.changeTodayPeriod();
                    break;
                case 2:
                    this.changeWeekPeriod();
                    break;
                case 3:
                    this.changeMonthPeriod();
                    break;
                case 4:
                    this.changeThirtyDaysPeriod();
                    break;
                case 5:
                    this.changeBetweenPeriod();
                    break;
                default:
            }
        } else if (this.selectedReport.value == 3) {
            switch (value) {
                case 1:
                    this.changeMonthPeriodTimeFrames();
                    break;
                case 2:
                    this.changeLastMonthPeriodTimeFrames();
                    break;
                case 3:
                    this.changeFirstDayCurrentYearPeriodTimeFrames();
                    break;
                case 4:
                    this.changeBetweenPeriod();
                    break;
                default:
            }
        }

    }

    async changeMonthPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        let start = firstDayCurrentMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;
    }

    async changeLastMonthPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        const today = new Date()
        const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1
        const year = lastMonth === 0 ? today.getFullYear() - 1 : today.getFullYear()

        let firstDayLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);

        const lastDayOfLastMonth = new Date(year, lastMonth + 1, 0)

        let start = firstDayLastMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = lastDayOfLastMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;
    }

    async changeFirstDayCurrentYearPeriodTimeFrames() {

        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayOfCurrentYear = new Date(date.getFullYear(), 0, 1);

        let start = firstDayOfCurrentYear.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.startDate = start;
        this.endDate = end;

    }

    async changeTodayPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        let period = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.operationService.finishedReportDates(period, period);
    }

    async changeWeekPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        date.setDate(date.getDate() - 7);
        let start = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.operationService.finishedReportDates(start, end);
    }

    async changeMonthPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();

        let firstDayCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);

        let start = firstDayCurrentMonth.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.operationService.finishedReportDates(start, end);
    }

    async changeThirtyDaysPeriod() {
        this.startPeriod = null;
        this.endPeriod = null;
        const date = new Date();
        date.setDate(date.getDate() - 30);
        let start = date.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = new Date().toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.selectedPeriod = await this.operationService.finishedReportDates(start, end);
    }

    changeBetweenPeriod() {
        this.displayPeriod = true;
        this.startPeriod = null;
        this.endPeriod = null;
    }

    changeStartPeriod(date: Date) {
        this.startPeriod = date;
    }

    changeStartAllPeriod(date: Date) {
        this.startAllPeriod = date;
    }

    async changeEndPeriod(date: Date) {

        if (this.startPeriod > this.endPeriod) {
            this.startPeriod = null;
            this.endPeriod = null;
            this.selectedPeriod = null;
            this.displayPeriod = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data início não pode ser maior que a data final.`, life: 5000 });
            return;
        }
        if (this.endPeriod > new Date()) {
            this.startPeriod = null;
            this.endPeriod = null;
            this.selectedPeriod = null;
            this.displayPeriod = false;
            this.messageService.add({ severity: 'error', summary: 'Houve um erro', detail: `A data final não pode ser maior que hoje.`, life: 5000 });
            return;
        }
        this.endPeriod = date;

        const start = this.startPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        const end = this.endPeriod.toLocaleDateString(`fr-CA`, {
            year: `numeric`,
            month: `2-digit`,
            day: `2-digit`
        }).replace('/', '-').replace('/', '-');

        this.displayPeriod = false;

        this.startDate = start;
        this.endDate = end;

        if (this.selectedReport.value == 1) {
            this.selectedPeriod = await this.operationService.finishedReportDates(start, end);
            return;
        }
    }

    viewOperationSummary(report: GetOperationsPendingSummaryResponse) {
        this.operationSummary = report;
        this.displayReport = true;
    }

    getMenuItems(operation: GetOperationsPendingSummaryResponse): MenuItem[] {
        return [
            { label: 'Ver pendência', command: e => this.viewOperationSummary(operation) },
        ]
    }

    async getFullPeriod() {
        const request: GetOperationsPendingToExportRequest = {
            operationSequentialId: null,
            operationTypeId: null,
            operationStatusId: null,
            shareholderFundId: null,
            investedFundId: null,
            investedFundManagerId: null,
            investedFundAdministradorId: null,
            investedFundDistributorDocumentNumber: null,
            startDate: null,
            endDate: null,
        }
        this.fullOperations = [];
        this.loadingService.show();
        this.fullOperations = await this.operationService.getReportFullOperations(request);
        this.loadingService.hide();
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    exportToExcel() {
        this.operationsExport = this.fullOperations.map(operation => {
            return {
                'ID': operation?.sequentialId,
                'Data da Entrada': operation?.createDate ? this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy') : '',
                'Data Encerramento': operation?.finishDate ? this.datePipe.transform(operation?.finishDate, 'dd/MM/yyyy') : '',
                'Operação': operation?.operationType,
                'Fundo Cotista': `${operation?.shareholderFund}`,
                'CNPJ F. Cotista': this.parseCNPJ(operation?.shareholderFundDocumentNumber),
                'Gestor': operation?.manager,
                'Fundo Investido': `${operation?.investedFund}`,
                'CNPJ F. Investido': this.parseCNPJ(operation?.investedFundDocumentNumber),
                'Data Status': operation?.currentStatusDate ? this.datePipe.transform(operation?.currentStatusDate, 'dd/MM/yyyy') : '',
                'Status': `${operation?.currentStatus}`,
                'Data Conclusão': operation?.finishDate ? this.datePipe.transform(operation?.finishDate, 'dd/MM/yyyy') : '',
                'Motivo Pendência': operation?.pendingReason ? `${operation?.pendingReason}` : "",
                'Descrição Pendência': operation?.pendingNotes ? `${operation?.pendingNotes}` : ""
            }
        });
        this.exportExcel();
    }

}