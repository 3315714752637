<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Corretora
            </div>
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Informações</div>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="borderOutlined">
                            <p><b class="text-bold">Nome:</b> <span> {{selectedFundBroker?.name}}</span></p>
                            <p><b class="text-bold">CNPJ:</b> <span> {{selectedFundBroker?.documentNumber}}</span></p>
                            <!-- <p><b class="text-bold">Data de Validade:</b> <span> {{selectedFundBroker?.expirationDate | date
                                : 'dd/MM/yyyy' }}</span></p> -->
                        </div>
                    </div>
                </div>
                <br>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Alterar Template da Ficha Cadastral</div>
                    </div>
                    <div class="p-col-12 p-md-10 p-lg-10">
                        <app-artifact-template-create (onArtifactFilled)="registrationFormTemplateFilled($event)">
                        </app-artifact-template-create>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="borderOutlined contentCenterColumn heightFix151"
                            *ngIf="selectedFundBroker?.registrationFormTemplate">
                            <div class="text-bold">
                                Modelo de Ficha Cadastral
                            </div>
                            <a [href]="selectedFundBroker?.registrationFormTemplate?.fileUrl" target="_blank"
                                class="linkPrimary">
                                Ver PDF
                            </a>
                        </div>
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Alterar Template da Ficha Cadastral de atualização</div>
                    </div>
                    <div class="p-col-12 p-md-10 p-lg-10">
                        <app-artifact-template-create (onArtifactFilled)="updateFormTemplateFilled($event)">
                        </app-artifact-template-create>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="borderOutlined contentCenterColumn heightFix151"
                            *ngIf="selectedFundBroker?.updateFormTemplate">
                            <div class="text-bold">
                                Modelo de Ficha Cadastral de atualização
                            </div>
                            <a [href]="selectedFundBroker?.updateFormTemplate?.fileUrl" target="_blank"
                                class="linkPrimary">
                                Ver PDF
                            </a>
                        </div>
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Alterar Template de Aditamento</div>
                    </div>
                    <div class="p-col-12 p-md-10 p-lg-10">
                        <app-artifact-template-create (onArtifactFilled)="additionFormTemplateFilled($event)">
                        </app-artifact-template-create>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="borderOutlined contentCenterColumn heightFix151"
                            *ngIf="selectedFundBroker?.additionTemplate">
                            <div class="text-bold">
                                Modelo Template de Aditamento
                            </div>
                            <a [href]="selectedFundBroker?.additionTemplate?.fileUrl" target="_blank"
                                class="linkPrimary">
                                Ver PDF
                            </a>
                        </div>
                    </div>
                </div>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">Alterar Template W8</div>
                    </div>
                    <div class="p-col-12 p-md-10 p-lg-10">
                        <app-artifact-template-create (onArtifactFilled)="w8FormTemplateFilled($event)">
                        </app-artifact-template-create>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        <div class="borderOutlined contentCenterColumn heightFix151"
                            *ngIf="selectedFundBroker?.operationTemplate">
                            <div class="text-bold">
                                Modelo W8
                            </div>
                            <a [href]="selectedFundBroker?.operationTemplate?.fileUrl" target="_blank"
                                class="linkPrimary">
                                Ver PDF
                            </a>
                        </div>
                    </div>
                </div>


                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                        <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                            (click)="onSave(entryForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>