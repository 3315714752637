import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, NgModel } from "@angular/forms";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class FormValidationService {

    constructor(private messageService: MessageService) { }

    validateForm(form: FormGroup): boolean {
        let isFormValid: boolean = true;

        Object.keys(form.controls).forEach(key => {
            const control: AbstractControl = form.get(key);            
            if (control.invalid) {
                control.markAsDirty({ onlySelf: true });
                isFormValid = false;
            }
        });

        if (!isFormValid) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `Verifique os campos do formulário para prosseguir.`, life: 5000 });
        }

        return isFormValid;
    }

    validateFields(fields: NgModel[]) {
        let isFieldsValid: boolean = true;

        fields.forEach(element => {
            if (element.invalid) {
                element.control.markAsDirty({ onlySelf: true });
                isFieldsValid = false;
            }
        });

        if (!isFieldsValid) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `Verifique os campos do formulário para prosseguir.`, life: 5000 });
        }

        return isFieldsValid;
    }

    resetFormFields(form: FormGroup) {
        Object.keys(form.controls).forEach(key => {
            const control: AbstractControl = form.get(key);            
            control.markAsPristine({ onlySelf: true });            
            control.markAsPending({ onlySelf: true });            
            control.markAsUntouched({ onlySelf: true });            
            //control.reset({ onlySelf: true });            
        });
    }
}