<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Corretora
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="borderOutlined">
                        <p><b class="text-bold">Nome:</b> <span> {{selectedFundBroker?.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{selectedFundBroker?.documentNumber }}</span></p>
                        <!-- <p><b class="text-bold">Data de Validade:</b> <span> {{selectedFundBroker?.expirationDate | date
                                : 'dd/MM/yyyy' }}</span></p> -->
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="selectedFundBroker?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="selectedFundBroker?.registrationFormTemplate?.fileUrl" target="_blank"
                            class="linkPrimary">
                            Ver PDF
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!selectedFundBroker?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo Ficha Cadastral
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="selectedFundBroker?.updateFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral de Atualização
                        </div>
                        <a [href]="selectedFundBroker?.updateFormTemplate?.fileUrl" target="_blank" class="linkPrimary">
                            Ver PDF
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!selectedFundBroker?.updateFormTemplate">
                        <div class="text-bold">
                            Modelo Ficha Cadastral de Atualização
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="selectedFundBroker?.additionTemplate">
                        <div class="text-bold">
                            Modelo Template de Aditamento
                        </div>
                        <a [href]="selectedFundBroker?.additionTemplate?.fileUrl" target="_blank" class="linkPrimary">
                            Ver PDF
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!selectedFundBroker?.additionTemplate">
                        <div class="text-bold">
                            Modelo Template de Aditamento
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="selectedFundBroker?.operationTemplate">
                        <div class="text-bold">
                            Modelo Template W8
                        </div>
                        <a [href]="selectedFundBroker?.operationTemplate?.fileUrl" target="_blank" class="linkPrimary">
                            Ver PDF
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100"
                        *ngIf="!selectedFundBroker?.operationTemplate">
                        <div class="text-bold">
                            Modelo Template W8
                        </div>
                        <a class="linkPrimary" (click)="updateTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Procuradores / Rep Legal / Contatos da Corretora</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">CPF</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">E-mail</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">Data de Validade</p>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2">
                                    <p class="text-bold">É Representante?</p>
                                </div>
                            </div>
                            <form #managerForm="ngForm" novalidate>
                                <div class="p-grid contentAlignItemsCenter"
                                    *ngFor="let representative of allRepresentatives; let index = index">
                                    <div class="p-col-12 p-md-2 p-lg-2">
                                        <span>{{ representative?.name}}</span>
                                    </div>
                                    <div class="p-col-12 p-md-2 p-lg-2">
                                        <span *ngIf="representative?.canSign">{{
                                            representative?.maskedPersonalDocumentNumber}}</span>
                                    </div>
                                    <div class="p-col-12 p-md-2 p-lg-2">
                                        <span>{{ representative?.email}}</span>
                                    </div>

                                    <div class="p-col-12 p-md-2 p-lg-2">
                                        <span *ngIf="representative?.canSign">{{ representative?.expireDate | date:
                                            'dd/MM/yyyy' }}</span>
                                    </div>
                                    <div class="p-col-12 p-md-2 p-lg-2">
                                        <span>{{ representative?.canSign ? "Sim" : "Não"}}</span>
                                    </div>
                                    <div class="p-col-1 p-md-1 p-lg-1">
                                        <button pButton pRipple type="button" icon="pi pi-user-edit"
                                            class="p-button-rounded p-button-outlined"
                                            (click)="editCurrentRepresentation(representative)"></button>
                                    </div>
                                    <div class="p-col-1 p-md-1 p-lg-1">
                                        <button pButton pRipple type="button" icon="pi pi-trash"
                                            class="p-button-rounded p-button-outlined-blue"
                                            (click)="removeCurrentRepresentation(representative)"></button>
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="line"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <br>
                    <form #adminForm="ngForm" novalidate>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="cardHeader text-bold">Adicionar Procuradores / Rep Legal / Contatos da
                                    Corretora</div>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="name" type="text" ngModel={{requestRepresentative?.name}} name="name"
                                            #name="ngModel" class="inputLiza" required pInputText>
                                        <label for="name">Nome</label>
                                    </span>
                                </div>
                                <mark *ngIf="name.invalid && name.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="email" type="text" ngModel={{requestRepresentative?.email}}
                                            name="email" #email="ngModel" class="inputLiza" required pInputText>
                                        <label for="email">E-mail</label>
                                    </span>
                                </div>
                                <mark *ngIf="email.invalid && email.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="selectedContact?.value == true">
                                <span class="p-float-label">
                                    <p-inputMask class="inputOctaMask" inputId="personalDocumentNumber" type="tel"
                                        mask="999.999.999-99" ngModel={{requestRepresentative?.personalDocumentNumber}}
                                        name="personalDocumentNumber" #personalDocumentNumber="ngModel" [unmask]="true"
                                        [required]="selectedContact?.value == true">
                                    </p-inputMask>
                                    <label class="labelOcta" for="documentNumber">CPF</label>
                                </span>
                                <mark *ngIf="personalDocumentNumber.invalid && personalDocumentNumber.dirty"
                                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="selectedContact?.value == true">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <p-calendar [(ngModel)]="requestExpireDate" name="expireDate"
                                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                            [yearNavigator]="true" #expireDate="ngModel"
                                            [required]="selectedContact?.value == true">
                                        </p-calendar>
                                        <label for="expireDate">Data de Validade</label>
                                    </span>
                                </div>
                                <mark *ngIf="expireDate.invalid && expireDate.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p>É Representante legal?</p>
                                <div *ngFor="let category of contactsOption" class="field-checkbox">
                                    <p-radioButton [inputId]="category.value" name="category" [value]="category"
                                        [(ngModel)]="selectedContact"></p-radioButton>
                                    <label class="labelCheckbox" [for]="category.value">{{category.label}}</label>
                                </div>
                            </div>

                            <div class="p-col-1 p-md-1 p-lg-1">
                                <button pButton pRipple type="button" icon="pi pi-plus"
                                    class="p-button-rounded p-button-outlined"
                                    (click)="addRepresentations(adminForm)"></button>
                            </div>
                        </div>

                        <!-- <div *ngIf="representations">
                            <div *ngFor="let rep of representations" class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.name}}
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.email}}
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.expireDate| date: 'dd/MM/yyyy' }}
                                </div>
                                <div class="p-col-1 p-md-1 p-lg-1">
                                    <button pButton pRipple type="button" icon="pi pi-trash"
                                        class="p-button-rounded p-button-outlined-blue"
                                        (click)="removeRepresentation(rep)"></button>
                                </div>
                                <div class="p-col-12 p-md-12 p-lg-10">
                                    <p-divider></p-divider>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                                    (click)="onCancel()"></button>
                                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                                    (click)="onSave(adminForm)"></button>
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
            <br>
            <form #notificationForm="ngForm" novalidate>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">E-mail de finalização de operação</div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="title" type="text"
                                    ngModel={{selectedFundBroker?.operationDoneNotificationTitle}} name="title"
                                    #title="ngModel" class="inputLiza" required pInputText>
                                <label for="title">Título do e-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="title.invalid && title.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="subject" type="text"
                                    ngModel={{selectedFundBroker?.operationDoneNotificationSubject}} name="subject"
                                    #subject="ngModel" class="inputLiza" required pInputText>
                                <label for="subject">Assunto do e-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="subject.invalid && subject.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                        <button pButton pRipple type="button" label="Salvar alterações" class="action-button p-ml-2"
                            (click)="onSaveConfigEmail(notificationForm)"></button>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2"></div>
                    <div class="p-col-12 p-md-4 p-lg-4 p-fluid" *ngIf="selectedFundBroker">
                        <p-chips [(ngModel)]="selectedFundBroker.operationDoneNotificationEmails" separator=","
                            (onAdd)="onSaveEmails($event.value)" (onRemove)="onRemoveEmail($event.value)"
                            [ngModelOptions]="{standalone: true}" placeholder="Email`s"></p-chips>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<p-dialog header="Editar Procurador/Rep Legal" [(visible)]="displayEditRep" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form #repForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text" ngModel={{selectedRepresentative?.name}} name="name"
                            #name="ngModel" class="inputLiza" required pInputText>
                        <label for="name">Nome</label>
                    </span>
                </div>
                <mark *ngIf="name.invalid && name.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" *ngIf="selectedContact?.value == true">
                <span class="p-float-label">
                    <p-inputMask class="inputOctaMask" inputId="maskedPersonalDocumentNumber" type="tel"
                        mask="999.999.999-99" ngModel={{selectedDocumentEncryptedRep}}
                        name="maskedPersonalDocumentNumber" #maskedPersonalDocumentNumber="ngModel" [unmask]="true"
                        [required]="true">
                    </p-inputMask>
                    <label class="labelOcta" for="documentNumber">CPF</label>
                </span>
                <mark *ngIf="maskedPersonalDocumentNumber.invalid && maskedPersonalDocumentNumber.dirty"
                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="email" type="text" ngModel={{selectedRepresentative?.email}} name="email"
                            #email="ngModel" class="inputLiza" required pInputText>
                        <label for="email">E-mail</label>
                    </span>
                </div>
                <mark *ngIf="email.invalid && email.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" *ngIf="selectedContact?.value == true">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="selectedExpirateDateRep" name="expireDate" appendTo="body"
                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            #expireDate="ngModel" [required]="true">
                        </p-calendar>
                        <label for="expireDate">Data de Validade</label>
                    </span>
                </div>
                <mark *ngIf="expireDate.invalid && expireDate.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <p>É representante legal?</p>
                <div *ngFor="let category of contactsOption" class="field-checkbox">
                    <p-radioButton [inputId]="category.value" name="category" [value]="category"
                        [(ngModel)]="selectedContact"></p-radioButton>
                    <label class="labelCheckbox" [for]="category.value">{{category.label}}</label>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                    (click)="onEditCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onEditRepresentative(repForm)"></button>
            </div>
        </div>
    </form>

</p-dialog>