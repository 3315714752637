import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { InviteUserRequest } from 'src/app/services/users/models/requests/invite-user-request';
import { UserRolesPropertiesViewModel } from 'src/app/services/users/models/view-models/user-roles-view-models';
import { UserService } from 'src/app/services/users/user-service';

@Component({
    templateUrl: './user-invite.component.html',
    styleUrls: ['../../../../../../assets/layout/customized/custom-card.scss']
})
export class UserInviteComponent implements OnInit {

    inviteUserRequest: InviteUserRequest;
    roleOptionsFoF: SelectItem[] = [];
    roleOptionsBroker: SelectItem[] = [];
    selectedRolesFoF: SelectItem = null;
    selectedRolesBroker: SelectItem = null;

    constructor(
        private router: Router,
        private userService: UserService,
        private contextService: ContextService,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService
    ) {
    }

    async ngOnInit() {
        this.loadingService.show();
        this.setFormProperties();
        await this.getCombos();
        this.loadingService.hide();
    }

    setFormProperties() {
        this.inviteUserRequest = {
            userPlayerTypeId: PlayerTypeEnum.Customer,
            entityId: this.contextService.getUserInfo().user.playerEntityId,
            fullName: null,
            email: null,
            cellphone: null,
            isSendingWelcomeEmail: true,
            userRoles: []
        };
    }

    handleContactType() {
        this.inviteUserRequest.email = null;
        this.inviteUserRequest.cellphone = null;
    }

    async getCombos() {
        this.roleOptionsFoF = []
        this.roleOptionsBroker = []

        const user = this.contextService.getUserApplications();
        const roles = await this.userService.getRolesByPlayerType(this.inviteUserRequest.userPlayerTypeId);
        const rolesFof = roles.filter(fill => 
            fill.value == RoleOptionEnum.CustomerFofAdministrator ||
            fill.value == RoleOptionEnum.CustomerFofAnalist ||
            fill.value == RoleOptionEnum.CustomerFofOperator);
        const rolesBroker = roles.filter(fill => 
            fill.value == RoleOptionEnum.CustomerBrokerAdministrator ||
            fill.value == RoleOptionEnum.CustomerBrokerAnalist ||
            fill.value == RoleOptionEnum.CustomerBrokerOperator);

        user.forEach(item => {
            if (item.application.value == ApplicationTypeEnum.Broker) {
                this.roleOptionsBroker = rolesBroker.map(element => {
                    const roleProperties: UserRolesPropertiesViewModel = { roleId: element.value };
                    return { label: element.friendlyName, value: roleProperties }
                });
            }
            if (item.application.value == ApplicationTypeEnum.FoF) {
                this.roleOptionsFoF = rolesFof.map(element => {
                    const roleProperties: UserRolesPropertiesViewModel = { roleId: element.value };
                    return { label: element.friendlyName, value: roleProperties }
                });
            }
        })

    }

    handleRoleFoF(role: SelectItem) {
        this.selectedRolesFoF = role;
    }

    handleRoleBroker(role: SelectItem) {
        this.selectedRolesBroker = role;
    }

    async   onSave(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        if (this.selectedRolesFoF == null && this.selectedRolesBroker == null) {
            this.messageService.add({ severity: 'error', summary: 'Nenhuma função selecionada', detail: `Ao menos uma função deve ser selecioanda.`, life: 5000 });
            return;
        }

        if (this.selectedRolesFoF !== null && this.selectedRolesBroker == null) {
            this.inviteUserRequest.userRoles.push(this.selectedRolesFoF.value);
        } else if (this.selectedRolesFoF == null && this.selectedRolesBroker !== null) {
            this.inviteUserRequest.userRoles.push(this.selectedRolesBroker.value);
        } else if (this.selectedRolesFoF !== null && this.selectedRolesBroker !== null) {
            this.inviteUserRequest.userRoles.push(this.selectedRolesFoF.value);
            this.inviteUserRequest.userRoles.push(this.selectedRolesBroker.value);
        }

        try {
            this.loadingService.show();
            await this.userService.invite(this.inviteUserRequest);
            this.onCancel();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    onCancel() {
        return this.router.navigateByUrl('/app/customer/user/list');
    }
}