import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/providers/breadcrumb.service';
import { ContextService } from 'src/app/providers/context-service';
import { LoginResponseViewModel } from 'src/app/services/users/models/view-models/login-response-view-model';

@Component({
    templateUrl: './logged-user-profile.component.html',
    styleUrls: ['../../../assets/layout/customized/custom-card.scss']
})
export class LoggedUserProfileComponent implements OnInit {

    userInfo: LoginResponseViewModel = null;

    constructor(private contextService: ContextService, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Perfil'}
        ]); }

    ngOnInit() {
        this.userInfo = this.contextService.getUserInfo();
        this.userInfo.user.birthdate = new Date(this.userInfo.user.birthdate);
    }
}
