import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { UpdateFundBrokerRequest } from 'src/app/services/fund-brokers/models/requests/update-fund-broker-request';


@Component({
    templateUrl: './fund-broker-view.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundBrokerViewComponent implements OnInit {

    request: UpdateFundBrokerRequest = null;
    requestRepresentations: any[] = [];
    requestValidateDate: Date = null;
    allRepresentatives: RepresentativeViewModel[] = [];
    fundBroker: FundBrokerViewModel = null;
    displayEditFundBroker: boolean = false;

    constructor(
        private fundBrokerService: FundBrokerService,
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.loadingService.show();
        const id = this.route.snapshot.paramMap.get('id');
        this.setForms();
        await this.getData(id);
        await this.getRepresentativesInFundAdministrator();
        this.loadingService.hide();
    }

    setForms() {
        this.request = {
            fundBrokerId: null,
            name: null,
            email: null,
            phoneNumber: null,
        }
    }

    async getData(id: string) {
        this.fundBroker = await this.fundBrokerService.getById(id);
        this.request.fundBrokerId = this.fundBroker.id;
        this.request.name = this.fundBroker.name;
        this.request.email = this.fundBroker.email;
        this.request.phoneNumber = this.fundBroker.phoneNumber;
        this.fundBroker.operationSetups = this.fundBroker.operationSetups.sort((a, b) => {
            if (a.operationType.friendlyName > b.operationType.friendlyName) {
                return 1
            } else {
                return -1
            }
        });
    }

    async getRepresentativesInFundAdministrator() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundBrokerService.getRepresentativesInFundBroker(fundBrokerId);
    }

    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-broker/list`)
    }

    openEditFundBroker() {
        this.displayEditFundBroker = true;
    }

    async onSaveEditFundAdministrator(formComponent: NgForm) {
        this.displayEditFundBroker = false;
        this.loadingService.show();
        if(this.request.email == "") {
            this.request.email = this.fundBroker.email;
        }
        if(this.request.phoneNumber == "") {
            this.request.phoneNumber = this.fundBroker.phoneNumber;
        }
        try {
            await this.fundBrokerService.updateFundBroker(this.request);
            this.messageService.add({
                severity: 'success',
                summary: 'Cadastro alterado com sucesso',
                detail: `Os dados foram alterados com sucesso!`,
                life: 5000
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            const id = this.route.snapshot.paramMap.get('id');
            await this.getData(id);
        }
    }

    close() {
        this.displayEditFundBroker = false;
    }
}