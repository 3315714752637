import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class CustomvalidationService {

    patternValidator(userControl: AbstractControl) {
        return new Promise(resolve => {
            setTimeout(() => {
                const value: string = userControl.value;
                if (value !== null && value !== undefined) {
                    // {6,100}      - Assert password is between 6 and 100 characters
                    // (?=.*[0-9])  - Assert a string has at least one number
                    if (value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
                        resolve(null);
                    } else {
                        resolve({ invalidPassword: true });
                    }
                } else {
                    resolve(null);
                }

            }, 1000);
        });
    }

    matchPasswordValidator(password: string, confirmPassword: string) {
        return (formGroup: FormGroup) => {
            const passwordControl = formGroup.controls[password];
            const confirmPasswordControl = formGroup.controls[confirmPassword];

            if (!passwordControl || !confirmPasswordControl) {
                return null;
            }

            if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
                return null;
            }

            if (passwordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordMismatch: true });
            } else {
                confirmPasswordControl.setErrors(null);
            }
        }
    }

    userNameValidator(userControl: AbstractControl) {
        return new Promise(resolve => {
            setTimeout(() => {
                if (this.validateUserName(userControl.value)) {
                    resolve({ userNameNotAvailable: true });
                } else {
                    resolve(null);
                }
            }, 1000);
        });
    }

    fullNameValidator(userControl: AbstractControl) {
        return new Promise(resolve => {
            setTimeout(() => {
                if (this.validateFullName(userControl.value)) {
                    resolve({ fullNameNotValid: true });
                } else {
                    resolve(null);
                }
            }, 1000);
        });
    }

    corporateEmailValidator(userControl: AbstractControl) {
        return new Promise(resolve => {
            setTimeout(() => {
                const value: string = userControl.value;
                if (value !== null && value !== undefined && value.indexOf('@') > -1) {
                    if (this.validateCorporateEmail(value)) {
                        resolve({ publicEmail: true });
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }

            }, 1000);
        });
    }

    emailValidator(userControl: AbstractControl) {
        return new Promise(resolve => {
            setTimeout(() => {
                const value: string = userControl.value;
                if (this.validateEmail(value)) {
                    resolve({ invalidEmail: true });
                } else {
                    resolve(null);
                }
            }, 1000);
        });
    }

    private validateEmail(email: string): boolean {

        if (email == null || email === '') return false;
        // RFC 2822 compliant regex
        if (email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return false;
        } else {
            return true;
        }
    }

    private validateUserName(userName: string): boolean {
        const UserList = ['admin', 'user', 'superuser'];
        return (UserList.indexOf(userName) > -1);
    }

    private validateFullName(value: string): boolean {
        if (value == null || value == '') return false;
        return !(value.match(/[^,]+\s[^,]+/));
    }

    private validateCorporateEmail(email: string): boolean {
        const BlockedList = [
            'gmail',
            'yahoo',
            'hotmail',
            'aol',
            'msn',
            'wanadoo',
            'orange',
            'comcast',
            'live',
            'rediffmail',
            'free',
            'gmx',
            'web',
            'yandex',
            'ymail',
            'libero',
            'outlook',
            'uol',
            'bol',
            'cox',
            'mail',
            'sbcglobal',
            'sfr',
            'verizon',
            'googlemail',
            'ig',
            'bigpond',
            'terra',
            'neuf',
            'rocketmail',
            'att',
            'laposte',
            'facebook',
            'bellsouth',
            'charter',
            'rambler',
            'tiscali',
            'shaw',
            'sky',
            'earthlink',
            'optonline',
            'freenet'
        ];

        let blocked = false;

        BlockedList.forEach(element => {
            if (!blocked) {
                blocked = email.indexOf(element) > -1;
            }
        });

        return blocked;
    }
}