<div *ngIf="typeList == 'Select'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="managerContacts" dataKey="id"
            (onRowSelect)="selectedCheckbox($event.data)" (onRowUnselect)="unSelectCheckbox($event.data)"
            (onHeaderCheckboxToggle)="onHeaderCheckboxToggled($event.checked)" styleClass="p-datatable-customized"
            [(selection)]="selecetedManagerContact" responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            CPF
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th>
                        <div>
                            Data Validade
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-managerContact let-index>
                <tr style="cursor: pointer" [pSelectableRow]="managerContact">
                    <td>
                        <p-tableCheckbox [value]="managerContact"></p-tableCheckbox>
                    </td>
                    <td>
                        {{managerContact?.name}}
                    </td>
                    <td>
                        {{managerContact?.maskedPersonalDocumentNumber}}
                    </td>
                    <td>
                        {{managerContact?.email}}
                    </td>
                    <td>
                        {{managerContact?.expireDate | date: 'dd/MM/yyyy'}}
                    </td>
                    <!-- <td>
                        {{representation.dateValidity | date: 'yy/MM/yyyy'}}
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="typeList == 'None'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="managerSigners" dataKey="id" styleClass="p-datatable-customized"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            CPF
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th>
                        <div>
                            Data Validade
                        </div>
                    </th>
                    <!-- <th *ngIf="selectedOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                        <div>
                            Ações
                        </div>
                    </th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-managerContact let-index>
                <tr style="cursor: pointer" [pSelectableRow]="managerContact">
                    <td>
                        {{managerContact?.contactSigner?.name}}
                    </td>
                    <td>
                        {{managerContact?.contactSigner?.maskedPersonalDocumentNumber}}
                    </td>
                    <td>
                        {{managerContact?.contactSigner?.email}}
                    </td>
                    <td>
                        {{managerContact?.contactSigner?.expireDate | date: 'dd/MM/yyyy'}}
                    </td>
                    <!-- <td>
                        <div
                            *ngIf="selectedOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <a class="linkPrimary" (click)="resendEmail()">Reenviar Email</a>
                        </div>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>