import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { HttpClientService } from 'src/app/providers/http-client-service';
import { ActivateUserRequest } from './models/requests/activate-user-request';
import { ForgotPasswordUserRequest } from './models/requests/forgot-password-user-request';
import { InviteUserRequest } from './models/requests/invite-user-request';
import { LoginRequest } from './models/requests/login-request';
import { LoginSingleSignOnUserRequest } from './models/requests/login-sso-request';
import { ReactivateUserAccessRequest } from './models/requests/reactivate-user-access-request';
import { ResendOperationCodeRequest } from './models/requests/resend-operation-code-request';
import { ResetPasswordRequest } from './models/requests/reset-password-request';
import { UnblockEntityUserRequest } from './models/requests/unblock-entity-user-request';
import { UpdateUserAccessRequest } from './models/requests/update-user-access-request';
import { UpdateUserContactRequest } from './models/requests/update-user-contact-request';
import { CodeOperationResponseViewModel } from './models/view-models/code-operation-response-view-model';
import { DocumentNumberViewModel } from './models/view-models/document-number-view-model';
import { LoginResponseViewModel } from './models/view-models/login-response-view-model';
import { RoleOptionViewModel } from './models/view-models/role-option-view-model';
import { UserViewModel } from './models/view-models/user-view-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpClient: HttpClientService,
    private loadingService: LoadingService,
    private contextService: ContextService,
    private router: Router,
    private messageService: MessageService) { }

  async login(request: LoginRequest): Promise<void> {

    this.loadingService.show();

    try {
      const response: LoginResponseViewModel = await this.httpClient.post(`/users`, '/login', request, false);

      this.contextService.setLoginResponse(response);
      if(response.user.userType.value !== PlayerTypeEnum.Liza) {
        this.contextService.setUserApplication(response.applications[0]);
      }
      // const userApplication = this.contextService.getUserApplicationSelected();

      if (response.user.userType.value == PlayerTypeEnum.Liza) {
        this.contextService.setUserApplication(response.applications[0]);
        this.router.navigateByUrl('app/liza/home');
        return;
      } 
      if (response.user.userType.value == PlayerTypeEnum.Customer) {
        if(response.applications[0].application.value == ApplicationTypeEnum.FoF) {
          this.router.navigateByUrl('/app/customer/home');
          return;
        }
        this.router.navigateByUrl('/app/customer/broker-home');
      }

    } catch (error) {
      console.error('Login', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Não foi possível efetuar a alteração',
        detail: `${error?.messageError}!`,
        life: 5000
    });
    } finally {
      this.loadingService.hide();
    }
  }

  // updateUrlFof() {
  //   const currentRoute = userApplication?.application.value == ApplicationTypeEnum.Broker ? 'app/customer/home' : 'app/customer/broker-home'
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([currentRoute]);
  //   });
  // }

  async loginSingleSignOn(request: LoginSingleSignOnUserRequest): Promise<void> {
    this.loadingService.show();

    try {
      const response: LoginResponseViewModel = await this.httpClient.post(`/users`, '/login-sso', request, false);

      this.contextService.setLoginResponse(response);
  
      this.redirectAfterSsoLogin(response);

    } catch (error) {
      this.messageService.add({ severity: 'error', summary: 'Erro ao realizar login SSO.', detail: error?.messageError, life: 5000 });
    } finally {
      this.loadingService.hide();
    }
  }

  async redirectAfterSsoLogin(response: LoginResponseViewModel): Promise<void> {
    console.log('user-service', 'redirectAfterLogin()');

    this.contextService.setLoginResponse(response);
    this.contextService.setIsSingleSignOn(true);

    if (response.ssoInformation.isUserEntryCompleted) {

      if (response.user.userType.value !== PlayerTypeEnum.Liza) {
        this.contextService.setUserApplication(response.applications[0]);
      }
  
      if (response.user.userType.value == PlayerTypeEnum.Liza) {
        this.router.navigateByUrl('app/liza/home');
        return;
      } 
      if (response.user.userType.value == PlayerTypeEnum.Customer) {
        if(response.applications[0].application.value == ApplicationTypeEnum.FoF) {
          this.router.navigateByUrl('/app/customer/home');
          return;
        }
        this.router.navigateByUrl('/app/customer/broker-home');
      }
    } else {
      this.router.navigateByUrl('user-sso');
    }
  }

  async forgotPassword(request: ForgotPasswordUserRequest): Promise<CodeOperationResponseViewModel> {
    return this.httpClient.put(`/users`, '/passwords/recovery', request, false);
  }

  async resetPassword(request: ResetPasswordRequest): Promise<void> {
    return this.httpClient.put(`/users`, '/passwords/reset', request, false);
  }

  async activate(request: ActivateUserRequest): Promise<void> {
    return this.httpClient.put(`/users`, '/activation', request, false);
  }

  async inactive(userId: string): Promise<void> {
    return this.httpClient.delete('/users', `/activation/${userId}`);
  }

  async unblocks(userId: UnblockEntityUserRequest): Promise<UserViewModel> {
    return this.httpClient.put(`/users`, `/unblocks`, userId, true);
  }

  async resendOperationCode(request: ResendOperationCodeRequest): Promise<CodeOperationResponseViewModel> {
    return this.httpClient.put(`/users`, '/codes/recovery', request, true);
  }

  async getUsersByPlayerTypeAndEntity(playerTypeId: number, entityId: string): Promise<UserViewModel[]> {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}/entities/${entityId}`);
  }

  async getAllUsersByPlayerType(playerTypeId: number) {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}`);
  }

  async getRolesByPlayerType(playerTypeId: number): Promise<RoleOptionViewModel[]> {
    return this.httpClient.get('/users', `/player-types/${playerTypeId}/roles`);
  }

  async get(userId: string, hasToken = true): Promise<UserViewModel> {
    return this.httpClient.get('/users', `/${userId}`, hasToken);
  }

  async userAccesses(request: UpdateUserAccessRequest): Promise<UpdateUserAccessRequest> {
    return this.httpClient.put('/users', `/accesses`, request);
  }

  async invite(request: InviteUserRequest): Promise<UserViewModel> {
    return this.httpClient.post(`/users`, '/invitation', request);
  }

  async decriptDocumentNumber(userId: string): Promise<DocumentNumberViewModel> {
    return this.httpClient.get('/users', `/${userId}/document-number/decrypted`)
  }

  async resendEmailOrCellPhone(request: ReactivateUserAccessRequest): Promise<ReactivateUserAccessRequest> {
    return this.httpClient.put('/users', '/activation/recovery', request);
  }

  async resendEmailUser(request: UpdateUserContactRequest): Promise<UserViewModel> {
    return this.httpClient.put('/users', '/accesses/contacts', request);
  }

}
