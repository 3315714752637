import { AfterViewInit, Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { environment } from 'src/environments/environment';
import { OperationStatusEnum } from '../../../enums/operation-status-enum';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss', '../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit, AfterViewInit {

    buyOperationSetup: number = 0;
    buyOperationFillForm: number = 0;
    buyOperationSignatureProcess: number = 0;
    buyOperationManagerAnalysis: number = 0;
    buyOperationManagerPending: number = 0;
    buyOperationFinished: number = 0;

    dailyOperationSetup: number = 0;
    dailyOperationFillForm: number = 0;
    dailyOperationSignatureProcess: number = 0;
    dailyOperationManagerAnalysis: number = 0;
    dailyOperationManagerPending: number = 0;
    dailyOperationFinished: number = 0;

    updateOperationSetup: number = 0;
    updateOperationFillForm: number = 0;
    updateOperationSignatureProcess: number = 0;
    updateOperationManagerAnalysis: number = 0;
    updateOperationManagerPending: number = 0;
    updateOperationFinished: number = 0;

    transferAdminOperationSetup: number = 0;
    transferAdminOperationFillForm: number = 0;
    transferAdminOperationSignatureProcess: number = 0;
    transferAdminOperationManagerAnalysis: number = 0;
    transferAdminOperationManagerPending: number = 0;
    transferAdminOperationFinished: number = 0;

    splitOperationSetup: number = 0;
    splitOperationFillForm: number = 0;
    splitOperationSignatureProcess: number = 0;
    splitOperationManagerAnalysis: number = 0;
    splitOperationManagerPending: number = 0;
    splitOperationFinished: number = 0;

    incorporationOperationSetup: number = 0;
    incorporationOperationFillForm: number = 0;
    incorporationOperationSignatureProcess: number = 0;
    incorporationOperationManagerAnalysis: number = 0;
    incorporationOperationManagerPending: number = 0;
    incorporationOperationFinished: number = 0;

    integrationOperationSetup: number = 0;
    integrationOperationFillForm: number = 0;
    integrationOperationSignatureProcess: number = 0;
    integrationOperationManagerAnalysis: number = 0;
    integrationOperationManagerPending: number = 0;
    integrationOperationFinished: number = 0;

    masteringOperationSetup: number = 0;
    masteringOperationFillForm: number = 0;
    masteringOperationSignatureProcess: number = 0;
    masteringOperationManagerAnalysis: number = 0;
    masteringOperationManagerPending: number = 0;
    masteringOperationFinished: number = 0;

    subscriptionOperationSetup: number = 0;
    subscriptionOperationFillForm: number = 0;
    subscriptionOperationSignatureProcess: number = 0;
    subscriptionOperationManagerAnalysis: number = 0;
    subscriptionOperationManagerPending: number = 0;
    subscriptionOperationFinished: number = 0;

    distributorChangeOperationSetup: number = 0;
    distributorChangeOperationFillForm: number = 0;
    distributorChangeOperationSignatureProcess: number = 0;
    distributorChangeOperationManagerAnalysis: number = 0;
    distributorChangeOperationManagerPending: number = 0;
    distributorChangeOperationFinished: number = 0;

    ransomOperationSetup: number = 0;
    ransomOperationFillForm: number = 0;
    ransomOperationSignatureProcess: number = 0;
    ransomOperationManagerAnalysis: number = 0;
    ransomOperationManagerPending: number = 0;
    ransomOperationFinished: number = 0;

    cessionBuyOperationSetup: number = 0;
    cessionBuyOperationFillForm: number = 0;
    cessionBuyOperationSignatureProcess: number = 0;
    cessionBuyOperationManagerAnalysis: number = 0;
    cessionBuyOperationManagerPending: number = 0;
    cessionBuyOperationFinished: number = 0;

    cessionDonationOperationSetup: number = 0;
    cessionDonationOperationFillForm: number = 0;
    cessionDonationOperationSignatureProcess: number = 0;
    cessionDonationOperationManagerAnalysis: number = 0;
    cessionDonationOperationManagerPending: number = 0;
    cessionDonationOperationFinished: number = 0;

    buyWalletOperationSetup: number = 0;
    buyWalletOperationFillForm: number = 0;
    buyWalletOperationSignatureProcess: number = 0;
    buyWalletOperationManagerAnalysis: number = 0;
    buyWalletOperationManagerPending: number = 0;
    buyWalletOperationFinished: number = 0;

    middleOperationSetup: number = 0;
    middleOperationFillForm: number = 0;
    middleOperationSignatureProcess: number = 0;
    middleOperationManagerAnalysis: number = 0;
    middleOperationManagerPending: number = 0;
    middleOperationFinished: number = 0;

    allOperationSetup: number = 0;
    allOperationFillForm: number = 0;
    allOperationSignatureProcess: number = 0;
    allOperationManagerAnalysis: number = 0;
    allOperationManagerPending: number = 0;
    allOperationFinished: number = 0;
    // typeUser: string = null;
    user: UserViewModel = null;

    public operationTypeEnum = OperationTypeEnum;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private integrationFileService: IntegrationFilesService,
        private elementRef: ElementRef,
    ) {
    }


    ngAfterViewInit() {
        this.loadColorTheme();
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.loadData();
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        const user = this.contextService.getUserInfo().user;

        this.isUserOperator = user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async loadData() {
        const allOperations: OperationViewModel[] = await this.operationService.getOperationsOnGoing();
        const operationsFinished: OperationViewModel[] = await this.operationService.getOperationsFinished();

        // Buy
        const operationTypeBuy = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Compra);
        this.buyOperationSetup = operationTypeBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.buyOperationFillForm = operationTypeBuy.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.buyOperationSignatureProcess = operationTypeBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.buyOperationManagerAnalysis = operationTypeBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.buyOperationManagerPending = operationTypeBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.buyOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Compra).length;

        // daily movement
        const operationTypeDaily = allOperations.filter(fill => fill.type.value == OperationTypeEnum.MovimentacaoDiaria);
        this.dailyOperationSetup = operationTypeDaily.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.dailyOperationFillForm = operationTypeDaily.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.dailyOperationSignatureProcess = operationTypeDaily.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.dailyOperationManagerAnalysis = operationTypeDaily.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.dailyOperationManagerPending = operationTypeDaily.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.dailyOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.MovimentacaoDiaria).length;

        // update
        const operationTypeUpdate = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Atualizacao);
        this.updateOperationSetup = operationTypeUpdate.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.updateOperationFillForm = operationTypeUpdate.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.updateOperationSignatureProcess = operationTypeUpdate.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.updateOperationManagerAnalysis = operationTypeUpdate.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.updateOperationManagerPending = operationTypeUpdate.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.updateOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Atualizacao).length;

        // transferAdmin
        const operationTypeTransferAdmin = allOperations.filter(fill => fill.type.value == OperationTypeEnum.TransferenciaAdmin);
        this.transferAdminOperationSetup = operationTypeTransferAdmin.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.transferAdminOperationFillForm = operationTypeTransferAdmin.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.transferAdminOperationSignatureProcess = operationTypeTransferAdmin.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.transferAdminOperationManagerAnalysis = operationTypeTransferAdmin.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.transferAdminOperationManagerPending = operationTypeTransferAdmin.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.transferAdminOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.TransferenciaAdmin).length;

        // split
        const operationTypeSplit = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Cisao);
        this.splitOperationSetup = operationTypeSplit.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.splitOperationFillForm = operationTypeSplit.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.splitOperationSignatureProcess = operationTypeSplit.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.splitOperationManagerAnalysis = operationTypeSplit.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.splitOperationManagerPending = operationTypeSplit.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.splitOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Cisao).length;

        // incorporation
        const operationTypeIncorporation = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Incorporacao);
        this.incorporationOperationSetup = operationTypeIncorporation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.incorporationOperationFillForm = operationTypeIncorporation.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.incorporationOperationSignatureProcess = operationTypeIncorporation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.incorporationOperationManagerAnalysis = operationTypeIncorporation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.incorporationOperationManagerPending = operationTypeIncorporation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.incorporationOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Incorporacao).length;

        // integration
        const operationTypeIntegration = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Integralizacao);
        this.integrationOperationSetup = operationTypeIntegration.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.integrationOperationFillForm = operationTypeIntegration.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.integrationOperationSignatureProcess = operationTypeIntegration.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.integrationOperationManagerAnalysis = operationTypeIntegration.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.integrationOperationManagerPending = operationTypeIntegration.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.integrationOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Integralizacao).length;

        // mastering
        const operationTypeMastering = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Masterizacao);
        this.masteringOperationSetup = operationTypeMastering.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.masteringOperationFillForm = operationTypeMastering.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.masteringOperationSignatureProcess = operationTypeMastering.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.masteringOperationManagerAnalysis = operationTypeMastering.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.masteringOperationManagerPending = operationTypeMastering.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.masteringOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Masterizacao).length;

        // subscription
        const operationTypeSubscription = allOperations.filter(fill => fill.type.value == OperationTypeEnum.BoletimSubscricao);
        this.subscriptionOperationSetup = operationTypeSubscription.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.subscriptionOperationFillForm = operationTypeSubscription.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.subscriptionOperationSignatureProcess = operationTypeSubscription.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.subscriptionOperationManagerAnalysis = operationTypeSubscription.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.subscriptionOperationManagerPending = operationTypeSubscription.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.subscriptionOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.BoletimSubscricao).length;


        // distributorChange
        const operationTypeDistributorChange = allOperations.filter(fill => fill.type.value == OperationTypeEnum.AlteracaoDistribuidor);
        this.distributorChangeOperationSetup = operationTypeDistributorChange.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.distributorChangeOperationFillForm = operationTypeDistributorChange.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.distributorChangeOperationSignatureProcess = operationTypeDistributorChange.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.distributorChangeOperationManagerAnalysis = operationTypeDistributorChange.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.distributorChangeOperationManagerPending = operationTypeDistributorChange.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.distributorChangeOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.AlteracaoDistribuidor).length;

        // ransom
        const operationTypeRansom = allOperations.filter(fill => fill.type.value == OperationTypeEnum.ResgateDeComeCotas);
        this.ransomOperationSetup = operationTypeRansom.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.ransomOperationFillForm = operationTypeRansom.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.ransomOperationSignatureProcess = operationTypeRansom.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.ransomOperationManagerAnalysis = operationTypeRansom.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.ransomOperationManagerPending = operationTypeRansom.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.ransomOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.ResgateDeComeCotas).length;

        // cessionBuy
        const operationTypecessionBuy = allOperations.filter(fill => fill.type.value == OperationTypeEnum.CessaoDeCotasCompra);
        this.cessionBuyOperationSetup = operationTypecessionBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.cessionBuyOperationFillForm = operationTypecessionBuy.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.cessionBuyOperationSignatureProcess = operationTypecessionBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.cessionBuyOperationManagerAnalysis = operationTypecessionBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.cessionBuyOperationManagerPending = operationTypecessionBuy.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.cessionBuyOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.CessaoDeCotasCompra).length;

        // cessionDonation
        const operationTypecessionDonation = allOperations.filter(fill => fill.type.value == OperationTypeEnum.CessaoDeCotasDoacao);
        this.cessionDonationOperationSetup = operationTypecessionDonation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.cessionDonationOperationFillForm = operationTypecessionDonation.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.cessionDonationOperationSignatureProcess = operationTypecessionDonation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.cessionDonationOperationManagerAnalysis = operationTypecessionDonation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.cessionDonationOperationManagerPending = operationTypecessionDonation.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.cessionDonationOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.CessaoDeCotasDoacao).length;

        // Buy Wallet
        const operationTypeBuyWallet = allOperations.filter(fill => fill.type.value == OperationTypeEnum.CompraDeCarteira);
        this.buyWalletOperationSetup = operationTypeBuyWallet.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.buyWalletOperationFillForm = operationTypeBuyWallet.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.buyWalletOperationSignatureProcess = operationTypeBuyWallet.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.buyWalletOperationManagerAnalysis = operationTypeBuyWallet.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.buyWalletOperationManagerPending = operationTypeBuyWallet.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.buyWalletOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.CompraDeCarteira).length;

        // middle 
        const operationTypeMiddle = allOperations.filter(fill => fill.type.value == OperationTypeEnum.Middle);
        this.middleOperationSetup = operationTypeMiddle.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.middleOperationFillForm = operationTypeMiddle.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.middleOperationSignatureProcess = operationTypeMiddle.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.middleOperationManagerAnalysis = operationTypeMiddle.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.middleOperationManagerPending = operationTypeMiddle.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.middleOperationFinished = operationsFinished.filter(fill => fill.type.value == OperationTypeEnum.Middle).length;

        // All operations
        this.allOperationSetup = allOperations.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmSetup).length;
        this.allOperationFillForm = allOperations.filter(fill =>
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoManualNecessario ||
            fill.currentStatus.status.value ==
            OperationStatusEnum.PreenchimentoConcluido).length;
        this.allOperationSignatureProcess = allOperations.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.AguardandoAssinatura).length;
        this.allOperationManagerAnalysis = allOperations.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.EmAnalisePeloGestor).length;
        this.allOperationManagerPending = allOperations.filter(fill => fill.currentStatus.status.value == OperationStatusEnum.PendenciadaPeloGestor).length;
        this.allOperationFinished = operationsFinished.length;
    }

    async syncOperations() {
        try {
            this.loadingService.show();
            await this.integrationFileService.sftp();
            this.messageService.add({ severity: 'success', summary: 'Arquivos sincronizados', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            if (!this.isUserAnalist && !this.isUserOperator) {
                return;
            } else {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        } finally {
            this.loadingService.hide();
        }
    }

    async sendMailtoManagers() {
        try {
            this.loadingService.show();
            await this.operationService.resendEmailAllPendings()
            this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: `Emails enviados com sucesso.`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    loadColorTheme() {
        if (environment?.context !== "") {
            const selectors = [
                { boxCenter: 'boxCenter1', arrowRight: 'boxArrowRight1', opacity: '40%' },
                { boxCenter: 'boxCenter2', arrowRight: 'boxArrowRight2', opacity: '60%' },
                { boxCenter: 'boxCenter3', arrowRight: 'boxArrowRight3', opacity: '80%' },
                { boxCenter: 'boxCenter4', arrowRight: 'boxArrowRight4', opacity: '100%' }
            ];

            const primaryColorContrast = environment.primaryColorContrast;
            const primaryColor = environment.primaryColor;

            selectors.forEach(selector => {
                const boxCenterElements = this.elementRef.nativeElement.getElementsByClassName(selector.boxCenter);
                const arrowRightElements = this.elementRef.nativeElement.getElementsByClassName(selector.arrowRight);

                for (let i = 0; i < boxCenterElements.length; i++) {
                    const boxCenter = boxCenterElements[i];
                    const arrowRight = arrowRightElements[i];

                    boxCenter.style.backgroundColor = primaryColorContrast;
                    boxCenter.style.opacity = selector.opacity;
                    arrowRight.style.borderLeft = `11px solid ${primaryColorContrast}`;
                    arrowRight.style.opacity = selector.opacity;
                }
            });

            const boxCenter4Elements = this.elementRef.nativeElement.getElementsByClassName('boxCenter4');
            const arrowRight4Elements = this.elementRef.nativeElement.getElementsByClassName('boxArrowRight4');

            for (let i = 0; i < boxCenter4Elements.length; i++) {
                const boxCenter = boxCenter4Elements[i];
                const arrowRight = arrowRight4Elements[i];

                boxCenter.style.backgroundColor = primaryColor;
                arrowRight.style.borderLeft = `11px solid ${primaryColor}`;
            }
        }
    }
}

// BoletimSubscricao