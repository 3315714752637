<div class="containerLogin">
    <div class="boxLogin">
        <div class="leftBox">
            <div class="whiteLogo" (click)="handleAccessLiza()"></div>
            <div class="textLogin">
                Economize tempo e recursos na gestão dos documentos empresariais
            </div>
        </div>
        <div class="rightBox">
            <a href=# *ngIf="isFoF == ''">
                <div class="ocLogoBox" (click)="loginAzureActiveDirectory()">
                    <div class="ocLogo"></div>
                    <div class="ocLogoDescription">
                        <h4>Itaú Investment Services</h4>
                        <span>
                            Uma experiência completa para<br>
                            atender o mercado de capitais </span>
                    </div>
                </div>
            </a>
            <div class="ocLogo" *ngIf="isFoF !== ''"></div>

            <div class="boxForm" *ngIf="accessLiza">
                <form #loginForm="ngForm" novalidate>
                    <div class="subTitle">
                        Faça login para acessar sua conta
                    </div>
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-user"></i>
                        </span>
                        <span class="p-float-label">
                            <input inputId="emailInput" type="email" class="inputLogin"
                                [(ngModel)]="loginFormFields.userIdentification" name="emailInput" #emailInput="ngModel"
                                [required]="selectedMode.value == 'Email'" pInputText />
                            <label class="labelLogin" for="emailInput">Digite seu email</label>
                        </span>
                    </div>
                    <mark *ngIf="emailInput.invalid && emailInput.dirty"
                        class="ui-message ui-messages-error ui-corner-all markError">Campo obrigatório</mark>

                    <div class="p-inputgroup p-mt-3">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <input id="saltPassword" type="password" [(ngModel)]="loginFormFields.saltPassword"
                                class="inputLogin" name="saltPassword" required #saltPassword="ngModel" pInputText />
                            <label class="labelLogin" for="saltPassword">Senha</label>
                        </span>
                    </div>
                    <mark *ngIf="saltPassword.invalid && saltPassword.dirty"
                        class="ui-message ui-messages-error ui-corner-all markError">Campo obrigatório</mark>

                    <div class="forgotPassword" (click)="forgotPassword()">
                        Esqueci minha senha
                    </div>
                    <div class="p-mt-4">
                        <button class="buttonLogin" type="submit" (click)="login(loginForm)">
                            Acessar minha conta
                        </button>
                    </div>
                </form>
            </div>
            <!-- <div style="margin-top: 4rem; text-align: center;">
                <div>
                    <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                        target="_blank" style="color: var(--primary-color); cursor: pointer; font-weight: 500;">Política
                        de Privacidade</a>
                </div>
                <div class="infoText verySmall">
                    Versão {{version}}
                </div>
            </div> -->
            <div class="footer">
                <div>
                    <small class="text-center">Todos os direitos reservados. © Liza Conexões Digitais Ltda, 2024.
                        Nenhuma
                        parte deste site ou
                        seu conteúdo pode ser reproduzida ou distribuída de qualquer forma ou por qualquer meio, sem o
                        consentimento prévio por escrito do proprietário dos direitos autorais. Para obter informações
                        sobre
                        nossa política de privacidade, <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                        target="_blank" style="color: var(--primary-color); cursor: pointer; font-weight: 500;">consulte aqui</a>.</small>
                </div>
                <div>
                    <small class="text-center">All rights reserved. © Liza Conexões Digitais Ltda, 2024. No part of this
                        site or its content may be reproduced or distributed in any form or by any means, without the
                        prior
                        written permission of the copyright owner. For information on our privacy policy, <a href="https://s3-sa-east-1.amazonaws.com/dev-goliza-tech-files/416f6e22-df28-4d3d-a9d7-432731c72ab3.pdf"
                        target="_blank" style="color: var(--primary-color); cursor: pointer; font-weight: 500;">please click
                        here</a>.</small>
                </div>
                <div class="infoText verySmall">
                    Versão {{version}}
                </div>
            </div>
        </div>
    </div>
</div>