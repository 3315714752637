<!-- Operation -->

<div class="p-grid" *ngIf="selectedOperation">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="cardHeader text-bold">
            Rep. Legal/Procurador do Fundo Cotista
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">Nome</p>
                <p>{{selectedOperation?.shareholderFundSignerOne?.name}}</p>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">E-mail</p>
                <p>{{selectedOperation?.shareholderFundSignerOne?.email}}</p>
            </div>
            <!-- <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedOperation?.shareholderFundSignerOne?.documentFileUrl">
                    <p class="text-bold">Procuração</p>
                    <button (click)="openDocumentFile(selectedOperation?.shareholderFundSignerOne?.documentFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedOperation?.shareholderFundSignerOne?.procurationFileUrl">
                    <p class="text-bold">Documento Procurador</p>
                    <button (click)="openDocumentFile(selectedOperation?.shareholderFundSignerOne?.procurationFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div> -->
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">Nome</p>
                <p>{{selectedOperation?.shareholderFundSignerTwo?.name}}</p>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">E-mail</p>
                <p>{{selectedOperation?.shareholderFundSignerTwo?.email}}</p>
            </div>
            <!-- <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedOperation?.shareholderFundSignerTwo?.documentFileUrl">
                    <p class="text-bold">Procuração</p>
                    <button (click)="openDocumentFile(selectedOperation?.shareholderFundSignerTwo?.documentFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedOperation?.shareholderFundSignerTwo?.procurationFileUrl">
                    <p class="text-bold">Documento Procurador</p>
                    <button (click)="openDocumentFile(selectedOperation?.shareholderFundSignerTwo?.procurationFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div> -->
        </div>
        <p-divider></p-divider>
    </div>
</div>


<!-- Broker Operation -->


<div class="p-grid" *ngIf="selectedBrokerOperation">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="cardHeader text-bold">
            Rep. Legal/Procurador do Fundo Cotista
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">Nome</p>
                <p>{{selectedBrokerOperation?.shareholderFundSignerOne?.name}}</p>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">E-mail</p>
                <p>{{selectedBrokerOperation?.shareholderFundSignerOne?.email}}</p>
            </div>
            <!-- <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedBrokerOperation?.shareholderFundSignerOne?.documentFileUrl">
                    <p class="text-bold">Procuração</p>
                    <button
                        (click)="openDocumentFile(selectedBrokerOperation?.shareholderFundSignerOne?.documentFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedBrokerOperation?.shareholderFundSignerOne?.procurationFileUrl">
                    <p class="text-bold">Documento Procurador</p>
                    <button
                        (click)="openDocumentFile(selectedBrokerOperation?.shareholderFundSignerOne?.procurationFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div> -->
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">Nome</p>
                <p>{{selectedBrokerOperation?.shareholderFundSignerTwo?.name}}</p>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <p class="text-bold">E-mail</p>
                <p>{{selectedBrokerOperation?.shareholderFundSignerTwo?.email}}</p>
            </div>
            <!-- <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedBrokerOperation?.shareholderFundSignerTwo?.documentFileUrl">
                    <p class="text-bold">Procuração</p>
                    <button
                        (click)="openDocumentFile(selectedBrokerOperation?.shareholderFundSignerTwo?.documentFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="p-col-12 p-md-3 p-lg-3">
                <div [hidden]="!selectedBrokerOperation?.shareholderFundSignerTwo?.procurationFileUrl">
                    <p class="text-bold">Documento Procurador</p>
                    <button
                        (click)="openDocumentFile(selectedBrokerOperation?.shareholderFundSignerTwo?.procurationFileUrl)"
                        pButton pRipple type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div> -->
        </div>
        <p-divider></p-divider>
    </div>
</div>