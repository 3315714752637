import { Component, OnInit } from '@angular/core';

@Component({
    templateUrl: './liza-home.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss']
})
export class LizaHomeComponent implements OnInit {

    constructor() {}

    async ngOnInit() {
    }
}
