import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FileUploadModule } from "primeng/fileupload";
import { ChipModule } from "primeng/chip";
import { FileUploaderComponent } from "./fileuploader.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FileUploadModule,
        ChipModule
    ],
    declarations: [FileUploaderComponent],
    exports: [ FileUploaderComponent ]    
})
export class FileUploaderModule { }
