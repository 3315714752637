import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { DefaultEnumViewModel } from "src/app/providers/models/default-enum-view-model";
import { CreateFundAdministratorRequest } from "./models/requests/create-fund-administrator-request";
import { CreateOperationSetupRequest } from "./models/requests/create-operation-setup-request";
import { CreateRepresentativeRequest } from "./models/requests/create-representative-request";
import { SetSystemLegacyIntegrationRequest } from "./models/requests/set-system-legacy-integration-request";
import { UpdateFundAdministratorRequest } from "./models/requests/update-fund-administrator-request";
import { UpdateOperationSetupRequest } from "./models/requests/update-operation-setup-request";
import { UpdateRegistrationFormTemplateRequest } from "./models/requests/update-registration-form-template-request";
import { UpdateRepresentativeRequest } from "./models/requests/update-representative-request";
import { FundAdministratorViewModel } from "./models/view-models/fund-administrator-view-model";
import { OperationSetupViewModel } from "./models/view-models/operation-setup-view-model";
import { RepresentativeViewModel } from "./models/view-models/representative-view-model";

@Injectable({
    providedIn: 'root'
})

export class FundAdministratorService {
    constructor(private httpClient: HttpClientService) { }

    async getById(fundAdministratorId: string): Promise<FundAdministratorViewModel> {
        return this.httpClient.get(`/fund-administrators`, `/${fundAdministratorId}`);
    }

    async getAll(isActive: boolean): Promise<FundAdministratorViewModel[]> {
        return this.httpClient.get(`/fund-administrators`, `/all/${isActive}`);
    }

    async createFundAdministrator(request: CreateFundAdministratorRequest) : Promise<FundAdministratorViewModel> {
        return this.httpClient.post('/fund-administrators', '/', request);
    }

    async updateFundAdministrator(request: UpdateFundAdministratorRequest) : Promise<FundAdministratorViewModel> {
        return this.httpClient.put('/fund-administrators', '/', request);
    }

    async createOperationSetup(request: CreateOperationSetupRequest) : Promise<OperationSetupViewModel> {
        return this.httpClient.post('/fund-administrators', '/operation-setups', request);
    }

    async deleteOperationSetup(operationSetupId: string) : Promise<OperationSetupViewModel> {
        return this.httpClient.delete(`/fund-administrators`, `/operation-setups/${operationSetupId}`);
    }

    async updateRegistrationFormTemplate(request: UpdateRegistrationFormTemplateRequest) : Promise<FundAdministratorViewModel> {
        return this.httpClient.put('/fund-administrators', '/registration-form-templates', request);
    }

    async createRepresentative(request: CreateRepresentativeRequest): Promise<any> {
        return this.httpClient.post(`/fund-administrators`, `/representatives`, request);
    }

    async deleteRepresentative(representativeId: string): Promise<void> {
        return this.httpClient.delete(`/fund-administrators`, `/representatives/${representativeId}`);
    }

    async deleteFundAdministrator(fundAdministratorId: string): Promise<void> {
        return this.httpClient.delete(`/fund-administrators`, `/${fundAdministratorId}`, true);
    }

    async getRepresentativesInFundAdministrator(fundAdministratorId: string): Promise<RepresentativeViewModel[]> {
        return this.httpClient.get(`/fund-administrators/`, `${fundAdministratorId}/representatives`);
    }

    async updateRepresentativeFundAdmin(request: UpdateRepresentativeRequest) : Promise<any> {
        return this.httpClient.put(`/fund-administrators`, `/representatives`, request)
    }

    async decryptedDocumentRepresentativeFund(representativeId: string) : Promise<any> {
        return this.httpClient.get(`/fund-administrators`, `/representatives/${representativeId}/document-number/decrypted`);
    }

    async operationSetups(request: UpdateOperationSetupRequest): Promise<OperationSetupViewModel> {
        return this.httpClient.put(`/fund-administrators`, `/operation-setups`, request);
    }

    async systemLegacyIntegrationOptions(): Promise<DefaultEnumViewModel[]> {
        return this.httpClient.get(`/fund-administrators`, `/system-legacy-integrations/options`);
    }

    async updateSystemLegacyIntegration(request: SetSystemLegacyIntegrationRequest): Promise<FundAdministratorViewModel> {
        return this.httpClient.put(`/fund-administrators`, `/system-legacy-integrations`, request);
    }

    async removeSystemLegacyIntegration(fundAdministratorId: string): Promise<void> {
        return this.httpClient.delete(`/fund-administrators`, `/${fundAdministratorId}/system-legacy-integrations`, true);
    }
}
