<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                {{selectedExternalOperation?.type?.friendlyName}}
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <!-- <div class="line"></div> -->
                <!-- <div class="p-col-9 p-md-9 p-lg-9"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">ID</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedExternalOperation?.sequentialId}}
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">Público Alvo ANBIMA</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedExternalOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup }}
                </div>

                <ng-container *ngIf="
                    selectedExternalOperation?.type?.value == operationTypeEnum.CessaoDeCotasCompra || 
                    selectedExternalOperation?.type?.value == operationTypeEnum.CessaoDeCotasDoacao ||
                    selectedExternalOperation?.type?.value == operationTypeEnum.Incorporacao || 
                    selectedExternalOperation?.type?.value == operationTypeEnum.Cisao || 
                    selectedExternalOperation?.type?.value == operationTypeEnum.Integralizacao ||
                    selectedExternalOperation?.type?.value == operationTypeEnum.Masterizacao">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <p class="text-bold">Fundo de Origem:</p>
                    </div>

                    <div class="p-col-12 col-md-6 p-lg-6">
                        {{selectedExternalOperation?.destinationShareholderFund?.name}}
                    </div>

                    <div class="p-col-12 p-md-1 p-lg-1">
                        <p class="text-bold">CNPJ</p>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2">
                        {{selectedExternalOperation?.destinationShareholderFund?.documentNumber| documentNumber: 'cnpj'}}
                    </div>
                </ng-container>

                <!-- <div class="line"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Fundo Cotista:</p>
                </div>

                <div class="p-col-12 col-md-6 p-lg-6">
                    {{selectedExternalOperation?.shareholderFund?.name}}
                    <a class="linkPrimary leftSpacingText" target="_blank"
                        [routerLink]="['/app/customer/fund/view/', selectedExternalOperation?.shareholderFund?.id]">Ver</a>
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedExternalOperation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold"> Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedExternalOperation?.investedFund?.name}}
                </div>
                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedExternalOperation?.investedFund?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Distribuidor do fundo que receberá o rebate da aplicação:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedExternalOperation?.operationDistributorName}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedExternalOperation?.operationDistributorDocumentNumber| documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Administrador do Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedExternalOperation?.investedFund?.administrator?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedExternalOperation?.investedFund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                </div>

                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Gestora do Fundo Investido:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedExternalOperation?.investedFund?.manager?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedExternalOperation?.investedFund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedExternalOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                selectedExternalOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                    <div class="contentAlignItemsCenter">
                        <p class="textPrimary">Consulta CVM:</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    <div class="contentRowStartItems">
                        <p class="linkSecondary">OK</p>
                        <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                            target="_blank">Site CVM</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Etapas
            </div>
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-col-12 p-md-12 p-lg-12">

                    <p-steps class="contentSteps" #pStep [model]="steps" [readonly]="true" [activeIndex]="currentStep">
                    </p-steps>

                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12 text-center">
                            <div class="cardHeader">
                                <span class="text-bold">Status: </span>
                                <span [ngClass]="{
                                    'textPrimary': selectedExternalOperation?.currentStatus?.status?.value <= 6,
                                    'linkSecondary': selectedExternalOperation?.currentStatus?.status?.value == 8}">{{
                                    selectedExternalOperation?.currentStatus?.status?.friendlyName}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pStep.activeIndex == 0" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Selecione os Rep. Legais/Procuradores do fundo cotista
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid" *ngIf="selectedExternalOperation">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante A</h5>
                                        <app-legal-representative-fund-select [isRepresentativeA]="true"
                                            [selectedRepresentativeA]="selectedExternalOperation.shareholderFundSignerOne"
                                            [allRepresentativesA]="allRepresentativesA"
                                            (representativeAEmitter)="changedRepresentativeA($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante B</h5>
                                        <app-legal-representative-fund-select [isRepresentativeB]="true"
                                            [selectedRepresentativeB]="selectedExternalOperation.shareholderFundSignerTwo"
                                            [allRepresentativesB]="allRepresentativesB"
                                            (representativeBEmitter)="changedRepresentativeB($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                            <button (click)="setAsDone()" pButton pRipple type="button" label="Enviar" [disabled]="
                                !selectedExternalOperation?.shareholderFundSignerOne ||
                                 !selectedExternalOperation?.shareholderFundSignerTwo" class="action-button p-ml-2"></button>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 1" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold text-center">
                                Operação enviada
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>