<!-- Operation -->

<div class="p-grid" *ngIf="selectedArtifact && safeDocumentUrl && selectedOperation">
    <div class="p-col-12 p-md-12 p-lg-12">
        <!-- <h3 class="cardHeader text-bold">{{selectedArtifact?.status?.friendlyName}}</h3> -->
        <h3 class="text-bold">{{selectedArtifact.template.description}}</h3>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12">
        <h4 class="text-bold">Assinaturas</h4>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold">{{selectedOperation?.shareholderFundSignerOne?.name}}: 
                <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.signerOneHash !== null">{{'Assinado'}}</span>
                <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="selectedArtifact.signerOneHash == null">{{'Não assinado'}}</span>
            </p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold">{{selectedOperation?.shareholderFundSignerTwo?.name}}: 
                <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.signerTwoHash !== null">{{'Assinado'}}</span>
                <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="selectedArtifact.signerTwoHash == null">{{'Não assinado'}}</span>
            </p>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.administratorSigners.length > 0 || 
        selectedOperation?.managerSigners.length > 0">
        <h4 class="text-bold">Outras Assinaturas</h4>
        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.administratorSigners.length > 0">
            <div *ngFor="let administrator of selectedOperation?.administratorSigners; let index = index">
                <p class="text-bold">{{administrator?.representativeSigner?.name}}: 
                    <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="!selectedArtifact.administratorSignerHashes[index]?.includes(administrator?.representativeSigner?.name)">{{'Não assinado'}}</span>
                    <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.administratorSignerHashes[index]?.includes(administrator?.representativeSigner?.name)">{{'Assinado'}}</span></p>
            </div>
        </div>
    
        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedOperation?.managerSigners.length > 0">
            <div *ngFor="let manager of selectedOperation?.managerSigners; let index = index">
                <p class="text-bold">{{manager?.contactSigner?.name}}:
                    <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="!selectedArtifact.managerSignerHashes[index]?.includes(manager?.contactSigner?.name)">{{'Não assinado'}}</span>
                    <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.managerSignerHashes[index]?.includes(manager?.contactSigner?.name)">{{'Assinado'}}</span>
                </p>
            </div>
        </div>        
    </div>

    <div class="p-col-12 p-md-12 p-lg-12"
        *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente">
        <div *ngIf="extensionFile">
            <div class="contentButtonActions">
                <button pButton type="button" class="p-button-primary p-button-outlined"
                    label="O documento não apareceu? Clique Aqui" (click)="downloadFile()"></button>
                <button pButton type="button" class="p-button-primary p-button-outlined" label="Download do Arquivo"
                    (click)="downloadFile()" *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente"></button>
            </div>
            <object [data]="urlSafe | safeUrl" type="application/pdf" width="100%" height="600px"></object>
        </div>

        <div *ngIf="!extensionFile">
            <div class="contentButtonActions">
                <button pButton type="button" class="p-button-primary p-button-outlined"
                    label="O documento não apareceu? Clique Aqui" (click)="loadDocuments()"></button>
                <button pButton type="button" class="p-button-primary p-button-outlined" label="Download do Arquivo"
                    (click)="downloadFile()" *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente"></button>
            </div>
            <object [data]="urlSafe" type="application/pdf" width="100%" height="600px"></object>
        </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-6"
        *ngIf="selectedArtifact?.status?.value == artifactStatusEnum.PreenchimentoPendente">
        <div class="contentDocuments" *ngIf="extensionFile">
            <button pButton type="button" class="p-button-primary p-button-outlined"
                label="O documento não apareceu? Clique Aqui" (click)="downloadFile()"></button>
            <iframe *ngIf="!safeDocumentUrl.includes('.xlsx')" [src]="urlSafe | safeUrl" width="100%" height="600px"></iframe>
        </div>

        <div class="contentDocuments" *ngIf="!extensionFile">
            <button pButton type="button" class="p-button-primary p-button-outlined"
                label="O documento não apareceu? Clique Aqui" (click)="loadDocuments()"></button>
            <object [data]="urlSafe" type="application/pdf" width="100%" height="600px"></object>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6"
        *ngIf="selectedArtifact?.status?.value == artifactStatusEnum.PreenchimentoPendente">
        <!-- <div class="cardHeader text-bold">{{selectedArtifact?.template?.description}}</div> -->
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div [ngClass]="{'accordion-alert': fieldsPending(), 'accordion-none': !fieldsPending()}">
                    <p-panel header="Preenchimento Pendente" [toggleable]="true">
                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let field of request?.fields; let index = index" [ngClass]="{'hiddenDisplay': field?.variableField?.fieldType.fieldType == 'entity'}">
                            <div *ngIf="field?.variableField?.fieldType.fieldType == 'text'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <input type="text" [(ngModel)]="field.value" class="inputLiza" required pInputText>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'boolean'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <p-selectButton [options]="booleanOptions" [(ngModel)]="field.value" optionLabel="label"
                                            optionValue="value"></p-selectButton>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'checkbox'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <div *ngFor="let option of field.variableField.answerOptions; let i = index"
                                            class="p-field-checkbox">
                                            <p-checkbox name="category" [value]="option" [(ngModel)]="field.value"></p-checkbox>
                                            <label>{{option}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'radiobutton'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <div *ngFor="let option of field.variableField.answerOptions; let i = index"
                                            class="p-field-checkbox">
                                            <p-radioButton name="category" [value]="option" [(ngModel)]="field.value">
                                            </p-radioButton>
                                            <label>{{option}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'datetime'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <p-calendar [(ngModel)]="field.value" [showIcon]="true" yearRange="1950:2099"
                                            [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"></p-calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-g-12">
                            <div class="contentButtonActions">
                                <button pButton pRipple type="button" label="Salvar" iconPos="right" class="action-button p-ml-2"
                                    (click)="onSave()"></button>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="accordion-border">
                    <p-panel header="Campos Preenchidos pelo Sistema" [toggleable]="true" [collapsed]="activeState" (collapsedChange)="activeState = !activeState">
                        <ng-template pTemplate="icon">
                            <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let field of request?.fields; let index = index" [ngClass]="{'hiddenDisplay': activeState}">
                                <div *ngIf="field?.variableField?.fieldType.fieldType == 'entity'">
                                    <div class="p-fluid p-formgrid p-grid p-mt-3">
                                        <div class="p-col-12">
                                            <h6>{{field.variableField.label}} <span
                                                    style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                            <input type="text" [(ngModel)]="field.value" class="inputLiza" required pInputText readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-panel>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Broker Operation -->

<div class="p-grid" *ngIf="selectedArtifact && safeDocumentUrl && selectedBrokerOperation">
    <div class="p-col-12 p-md-12 p-lg-12">
        <!-- <h3 class="cardHeader text-bold">{{selectedArtifact?.status?.friendlyName}}</h3> -->
        <h3 class="text-bold">{{selectedArtifact.template.description}}</h3>
    </div>

    <div *ngIf="selectedArtifact?.isRepresentationSignatureRequired" class="p-col-12 p-md-12 p-lg-12">
        <h4 class="text-bold">Assinaturas</h4>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold">{{selectedBrokerOperation?.shareholderFundSignerOne?.name}}: 
                <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.signerOneHash !== null">{{'Assinado'}}</span>
                <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="selectedArtifact.signerOneHash == null">{{'Não assinado'}}</span>
            </p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold">{{selectedBrokerOperation?.shareholderFundSignerTwo?.name}}: 
                <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.signerTwoHash !== null">{{'Assinado'}}</span>
                <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="selectedArtifact.signerTwoHash == null">{{'Não assinado'}}</span>
            </p>
        </div>
    </div>

    <!-- <div *ngIf="selectedArtifact?.isBrokerRepresentativeSignatureRequired" class="p-col-12 p-md-12 p-lg-12">
        <div *ngFor="let hash of selectedArtifact.brokerSignerHashes; let i = index">
            <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let rep of selectedBrokerOperation?.brokerSigners">
                <p class="text-bold">{{rep?.representativeSigner?.name}}: 
                    <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact.brokerSignerHashes.length > 0 && hash.includes(rep.representativeSigner.name)">{{'Assinado'}}</span>
                    <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="!hash.includes(rep.representativeSigner.name)">{{'Não assinado'}}</span>
                </p>
            </div>
        </div>
    </div> -->

    <div *ngIf="selectedArtifact?.isBrokerRepresentativeSignatureRequired" class="p-col-12 p-md-12 p-lg-12">
        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let rep of selectedBrokerOperation?.brokerSigners">
            <p class="text-bold">{{rep?.representativeSigner?.name}}: 
                <span style="color: #140083; font-weight: bold;" *ngIf="selectedArtifact?.brokerSignerHashes?.length > 0 && checkSignature(rep)">{{'Assinado'}}</span>
                <span style="color: var(--primary-color-contrast); font-weight: bold;" *ngIf="selectedArtifact?.brokerSignerHashes?.length > 0 && !checkSignature(rep)">{{'Não assinado'}}</span>
            </p>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12"
        *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente">
        <div *ngIf="extensionFile">
            <div class="contentButtonActions">
                <button pButton type="button" class="p-button-primary p-button-outlined"
                    label="O documento não apareceu? Clique Aqui" (click)="downloadFile()"></button>
                <button pButton type="button" class="p-button-primary p-button-outlined" label="Download do Arquivo"
                    (click)="downloadFile()" *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente"></button>
            </div>
            <object [data]="urlSafe | safeUrl" type="application/pdf" width="100%" height="600px"></object>
        </div>

        <div *ngIf="!extensionFile">
            <div class="contentButtonActions">
                <button pButton type="button" class="p-button-primary p-button-outlined"
                    label="O documento não apareceu? Clique Aqui" (click)="loadDocuments()"></button>
                <button pButton type="button" class="p-button-primary p-button-outlined" label="Download do Arquivo"
                    (click)="downloadFile()" *ngIf="selectedArtifact?.status?.value !== artifactStatusEnum.PreenchimentoPendente"></button>
            </div>
            <object [data]="urlSafe" type="application/pdf" width="100%" height="600px"></object>
        </div>

    </div>

    <div class="p-col-12 p-md-6 p-lg-6"
        *ngIf="selectedArtifact?.status?.value == artifactStatusEnum.PreenchimentoPendente">
        <div class="contentDocuments" *ngIf="extensionFile">
            <button pButton type="button" class="p-button-primary p-button-outlined"
                label="O documento não apareceu? Clique Aqui" (click)="downloadFile()"></button>
            <iframe *ngIf="!safeDocumentUrl.includes('.xlsx')" [src]="urlSafe | safeUrl" width="100%" height="600px"></iframe>
        </div>

        <div class="contentDocuments" *ngIf="!extensionFile">
            <button pButton type="button" class="p-button-primary p-button-outlined"
                label="O documento não apareceu? Clique Aqui" (click)="loadDocuments()"></button>
            <object [data]="urlSafe" type="application/pdf" width="100%" height="600px"></object>
        </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6"
        *ngIf="selectedArtifact?.status?.value == artifactStatusEnum.PreenchimentoPendente">
        <!-- <div class="cardHeader text-bold">{{selectedArtifact?.template?.description}}</div> -->
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div [ngClass]="{'accordion-alert': fieldsPending(), 'accordion-none': !fieldsPending()}">
                    <p-panel header="Preenchimento Pendente" [toggleable]="true">
                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let field of request?.fields; let index = index" [ngClass]="{'hiddenDisplay': field?.variableField?.fieldType.fieldType == 'entity'}">
                            <div *ngIf="field?.variableField?.fieldType.fieldType == 'text'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <input type="text" [(ngModel)]="field.value" class="inputLiza" required pInputText>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'boolean'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <p-selectButton [options]="booleanOptions" [(ngModel)]="field.value" optionLabel="label"
                                            optionValue="value"></p-selectButton>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'checkbox'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <div *ngFor="let option of field.variableField.answerOptions; let i = index"
                                            class="p-field-checkbox">
                                            <p-checkbox name="category" [value]="option" [(ngModel)]="field.value"></p-checkbox>
                                            <label>{{option}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'radiobutton'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <div *ngFor="let option of field.variableField.answerOptions; let i = index"
                                            class="p-field-checkbox">
                                            <p-radioButton name="category" [value]="option" [(ngModel)]="field.value">
                                            </p-radioButton>
                                            <label>{{option}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
            
                            <div *ngIf="field.variableField.fieldType.fieldType == 'datetime'">
                                <div class="p-fluid p-formgrid p-grid p-mt-3">
                                    <div class="p-col-12">
                                        <h6>{{field.variableField.label}} <span
                                                style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                        <p-calendar [(ngModel)]="field.value" [showIcon]="true" yearRange="1950:2099"
                                            [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"></p-calendar>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-12 p-g-12">
                            <div class="contentButtonActions">
                                <button pButton pRipple type="button" label="Salvar" iconPos="right" class="action-button p-ml-2"
                                    (click)="onSave()"></button>
                            </div>
                        </div>
                    </p-panel>
                </div>
                <div class="accordion-border">
                    <p-panel header="Campos Preenchidos pelo Sistema" [toggleable]="true" [collapsed]="activeState" (collapsedChange)="activeState = !activeState">
                        <ng-template pTemplate="icon">
                            <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let field of request?.fields; let index = index" [ngClass]="{'hiddenDisplay': activeState}">
                                <div *ngIf="field?.variableField?.fieldType.fieldType == 'entity'">
                                    <div class="p-fluid p-formgrid p-grid p-mt-3">
                                        <div class="p-col-12">
                                            <h6>{{field.variableField.label}} <span
                                                    style="color: var(--primary-color);">({{field.variableField.replacer}})</span></h6>
                                            <input type="text" [(ngModel)]="field.value" class="inputLiza" required pInputText readonly>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-panel>
                </div>
            </div>
        </div>
    </div>
</div>