import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ContextService } from 'src/app/providers/context-service';

@Injectable()
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();

    constructor(private contextService: ContextService) { }

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }

    logout() {
        this.contextService.logout();
    }
}
