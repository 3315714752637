import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BrokerOperationStatusEnum } from 'src/app/enums/broker-operation-status-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';
import { BrokerOperationRecipientViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-recipient-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerRepresentativeViewModel } from 'src/app/services/brokers/models/view-models/broker-representative-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './broker-contacts-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-broker-contacts-list',
})
export class BrokerContactsListComponent implements OnInit {

    // @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    // @Input() actionButton: boolean = true;
    @Input() brokerContacts: BrokerRepresentativeViewModel[];
    @Input() recipients: BrokerOperationRecipientViewModel[];
    @Input() typeList: string | `Select`;

    @Output() checkboxToggleEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() unCheckboxToggleEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() onHeaderCheckboxToggledEmitter = new EventEmitter<boolean>();
    @Output() removeRepresentativeEmitter = new EventEmitter<BrokerRepresentativeViewModel>();
    @Output() resendEmailEmitter = new EventEmitter();

    selecetedBrokerContact: BrokerRepresentativeViewModel[] = [];
    representative: BrokerRepresentativeViewModel = null;
    assesments: BrokerOperationAssessmentSummaryViewModel[] = [];
    // assesments: BrokerOperationRecipientViewModel = null;

    public brokerOperationStatusEnum = BrokerOperationStatusEnum;
    responseBroker: BrokerOperationAssessmentSummaryViewModel = null;

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private router: Router,
        private loadingService: LoadingService,
        private brokerOperationService: BrokerService,
        private route: ActivatedRoute,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        await this.getOpertionAssessments();
        if (this.recipients !== null) {
            this.selecetedBrokerContact = [];
            for (let index = 0; index < this.recipients.length; index++) {
                const element = this.recipients[index];
                this.selecetedBrokerContact.push(element.representative);
            }
            // this.selecetedManagerContact = this.recipients.map(c => c.contact);
        }
    }

    // async getOpertionAssessments() {
    //     this.assesments = await this.brokerOperationService.getBrokerOperationAssessmentsByRepresentative(this.selectedBrokerOperation.id, this.selectedBrokerOperation.recipients[0].representative.id);
    //     if(this.assesments.assessments.length > 0) {
    //         this.representative = this.assesments.representative;
    //     }
    // }

    async getOpertionAssessments() {
        const brokerOperationId = this.route.snapshot.params.id;
        this.assesments = await this.brokerOperationService.getBrokerOperationAllAssessments(brokerOperationId);
        // this.responseBroker = this.assesments.filter(e => e.assessments.length > 0)[0];
        this.responseBroker = this.assesments.length > 0 ? this.assesments[0] : null;
    }

    selectedCheckbox(event: BrokerRepresentativeViewModel) {
        this.checkboxToggleEmitter.emit(event)
    }

    unSelectCheckbox(event: BrokerRepresentativeViewModel) {
        this.unCheckboxToggleEmitter.emit(event)
    }

    onHeaderCheckboxToggled(event: boolean) {
        this.onHeaderCheckboxToggledEmitter.emit(event);
    }

    removeContact(representative: BrokerRepresentativeViewModel) {
        this.removeRepresentativeEmitter.emit(representative);
    }

    resendEmail(representative: BrokerRepresentativeViewModel) {
        this.resendEmailEmitter.emit(representative);
    }


}