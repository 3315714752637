export enum OperationTypeEnum {
    Compra = 1,
    Atualizacao = 2,
    BoletimSubscricao = 3,
    AlteracaoDistribuidor = 4,
    MovimentacaoDiaria = 5,
    Incorporacao = 6,
    Cisao = 7,
    Integralizacao = 8,
    Masterizacao = 9,
    TransferenciaAdmin = 10,
    ResgateDeComeCotas = 11,
    CessaoDeCotasCompra = 12,
    CessaoDeCotasDoacao = 13,
    CompraDeCarteira = 14,
    Middle = 15,
}