import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { FileUploaderModule } from '../fileuploader/fileuploader.module';
import { AssessmentListComponent } from './assessment-list/assessment-list.component';

const COMPONENTS = [
  AssessmentListComponent
];

@NgModule({
  imports: [        
    DirectivesModule,
    PipesModule,
    ButtonModule,
    InputTextModule,
    FileUploaderModule,
    DropdownModule,
    ScrollPanelModule,
    CommonModule,
    ChipsModule,
    FormsModule,
  ],  
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location],
  exports: [COMPONENTS]
})
export class AssessmentModule { }
