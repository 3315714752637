import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { BrokerOperationStatusEnum } from 'src/app/enums/broker-operation-status-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './attachment-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/datatable.scss', '../../../../assets/layout/customized/custom-card.scss'],
    selector: `app-attachment-list`
})
export class AttachmentListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;
    @Input() canRemoveFile: boolean = true;
    @Output() openAddFileEmitter = new EventEmitter();
    @Output() onOperationUploadFileFundRegulationEmitter = new EventEmitter();
    @Output() onBrokerUploadFileFundRegulationEmitter = new EventEmitter();
    // @Output() removeFileEmitter = new EventEmitter<AttachmentViewModel>();
    @Output() openArtefactUrlEmitter = new EventEmitter<AttachmentViewModel | BrokerOperationAttachmentViewModel>();
    @Output() removeArtefactUrlEmitter = new EventEmitter<AttachmentViewModel | BrokerOperationAttachmentViewModel>();

    hasFundRegulationFile: boolean;
    hasBrokerFundRegulationFile: boolean;

    public operationStatusEnum = OperationStatusEnum;
    public brokerOperationStatusEnum = BrokerOperationStatusEnum;

    constructor(
    ) { }

    async ngOnInit() {
        if (this.selectedOperation?.attachments?.length > 0) {
            this.selectedOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
            });
            const uniqueId = new Set();
            this.selectedOperation.attachments = this.selectedOperation.attachments.filter(obj => {
                if (uniqueId.has(obj.description)) {
                    return false;
                }
                uniqueId.add(obj.description);
                return true;
            });
        }
        if (this.selectedBrokerOperation?.attachments?.length > 0) {
            this.selectedBrokerOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
            });
            const uniqueId = new Set();
            this.selectedBrokerOperation.attachments = this.selectedBrokerOperation.attachments.filter(obj => {
                if (uniqueId.has(obj.description)) {
                    return false;
                }
                uniqueId.add(obj.description);
                return true;
            });
        }
        this.verifyHasFundRegulationFile();
    }

    openArtefactUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        this.openArtefactUrlEmitter.emit(file)
    }

    removeFile(file: AttachmentViewModel) {
        // this.removeFileEmitter.emit(file)
    }

    openAddFile() {
        this.openAddFileEmitter.emit();
    }

    removeArtfact(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        this.removeArtefactUrlEmitter.emit(file)
    }

    onOperationUploadFileFundRegulation() {
        this.onOperationUploadFileFundRegulationEmitter.emit();
    }

    onBrokerUploadFileFundRegulation() {
        this.onBrokerUploadFileFundRegulationEmitter.emit();
    }

    verifyHasFundRegulationFile() {
        this.hasFundRegulationFile = this.selectedOperation?.attachments.some(c => c.description.includes("Regulamento do Fundo"));
        this.hasBrokerFundRegulationFile = this.selectedBrokerOperation?.attachments.some(c => c.description.includes("Regulamento do Fundo"));
    }
}
