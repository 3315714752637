import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { PrimeNGConfig, Translation } from 'primeng/api';

// Azure AD.
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'ng-app',
    templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {

    topbarTheme: string = 'blue';

    menuTheme: string = 'light';

    layoutMode: string = 'light';

    menuMode: string = 'static';

    inlineMenuPosition: string = 'top';

    inputStyle: string = 'filled';

    ripple: boolean = true;

    isRTL: boolean = false;

    // Azure AD
    isIframe = false;
    private readonly _destroying$ = new Subject<void>();
    loginDisplay = false;

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private primengConfig: PrimeNGConfig,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private elementRef: ElementRef,
        private titleService: Title,
    ) { }

    setTitle(){
        if(environment.context !== '') {
            this.titleService.setTitle("Liza Fundos");
        }
    }

    ngOnInit() {
        this.setTitle();
        this.primengConfig.ripple = true;
        this.loadTheme();
        const ptBRTranslate: Translation = {
            startsWith: 'Começa com',
            contains: 'Contém',
            notContains: 'Não contém',
            endsWith: 'Termina com',
            equals: 'Igual a',
            notEquals: 'Diferente de',
            noFilter: 'Sem filtro',
            lt: 'Menor',
            lte: 'Menor ou igual',
            gt: 'Maior',
            gte: 'Maior ou igual',
            is: 'É',
            isNot: 'Não é',
            before: 'Anter',
            after: 'Depois',
            clear: 'Limpar',
            apply: 'Aplicar',
            matchAll: 'Combinar todos',
            matchAny: 'Combinar qualquer',
            addRule: 'Adicionar regra',
            removeRule: 'Remover regra',
            accept: 'Aceitar',
            reject: 'Rejeitar',
            choose: 'Escolher',
            upload: 'Upload',
            cancel: 'Cancelar',
            dayNames: ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
            monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Aug", "Set", "Out", "Nov", "Dez"],
            today: 'Hoje',
            weekHeader: 'Sem',
        };

        this.primengConfig.setTranslation(ptBRTranslate);


        // Azure AD.
        this.isIframe = window !== window.parent && !window.opener;

        /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    loadTheme() {
        this.elementRef.nativeElement.style.setProperty('--primary-color', `${environment.primaryColor}`);
        this.elementRef.nativeElement.style.setProperty('--primary-color-contrast', `${environment.primaryColorContrast}`);
    }

}