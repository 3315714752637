<div class="p-grid">

    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Mesa de Operações
        </div>

        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="cardHeader text-bold">Upload Template Mesa de Operações</div>
                <p-fileUpload [multiple]="false" [customUpload]="true" [auto]="true"
                    (uploadHandler)="deskConfigsTemplate($event.files)"></p-fileUpload>
                <div class="p-mt-3">
                    <div class="p-d-flex p-jc-end">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" iconPos="right"
                            class="action-button p-ml-2" (click)="onSave()"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="p-col-12 p-md-12 p-lg-12" [hidden]="allDeskConfigs.length == 0">
        <div class="titleCustomCard">
            Lista Mesa de Operações
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">

                    <p-table [responsive]="true" #dt [value]="allDeskConfigs" dataKey="id"
                        styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                        [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                        [globalFilterFields]="['name','documentNumber']">
                        <ng-template pTemplate="caption">
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th colspan="1"></th>
                                <th colspan="2">BOVESPA</th>
                                <th colspan="2">CORRETAGEM</th>
                                <th colspan="2">BM&F</th>
                                <th colspan="2">CORRETAGEM</th>
                            </tr>
                            <tr>
                                <th pSortableColumn="name">
                                    <div>
                                        MESA DE GESTÃO
                                        <!-- <p-sortIcon field="name"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bovespaAccountCode">
                                    <div>
                                        CONTA MASTER
                                        <!-- <p-sortIcon field="documentNumber"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bovespaInternalAccountCode">
                                    <div>
                                        CÓD CONTA ITAUCOR
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bovespaExecutionBrokerage">
                                    <div>
                                        EXECUÇÃO %
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bovespaClearingBrokerage">
                                    <div>
                                        CLEARING %
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bmfAccountCode">
                                    <div>
                                        CONTA MASTER
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bmfInternalAccountCode">
                                    <div>
                                        CÓD ITAUCOR
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bmfExecutionBrokerage">
                                    <div>
                                        EXECUÇÃO
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                                <th pSortableColumn="bmfClearingBrokerage">
                                    <div>
                                        CLEARING
                                        <!-- <p-sortIcon field="expirationdate"></p-sortIcon> -->
                                    </div>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-deskConfig let-index>
                            <tr style="cursor: pointer" [pSelectableRow]="deskConfig">
                                <td>
                                    {{deskConfig.name}}
                                </td>
                                <td>
                                    {{deskConfig.bovespaAccountCode}}
                                </td>
                                <td>
                                    {{deskConfig.bovespaInternalAccountCode}}
                                </td>
                                <td>
                                    {{deskConfig.bovespaExecutionBrokerage == -1 ? "Padrão Asset" : deskConfig.bovespaExecutionBrokerage == -999 ? "ND" : deskConfig.bovespaExecutionBrokerage}}
                                </td>
                                <td>
                                    {{deskConfig.bovespaClearingBrokerage == -1 ? "Padrão Asset" : deskConfig.bovespaClearingBrokerage == -999 ? "ND" : deskConfig.bovespaClearingBrokerage}}
                                </td>
                                <td>
                                    {{deskConfig.bmfAccountCode}}
                                </td>
                                <td>
                                    {{deskConfig.bmfInternalAccountCode}}
                                </td>
                                <td>
                                    <!-- {{deskConfig.bmfExecutionBrokerage}} -->
                                    Padrão Asset
                                </td>
                                <td>
                                    <!-- {{deskConfig.bmfClearingBrokerage}} -->
                                    Padrão Asset
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Nada encontrado.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>

</div>