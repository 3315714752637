<div class="contentLiza">

    <div [class.navLizaCollapse]="collapse" [class.navLiza]="!collapse">
        <div [class.containerLogoCollapse]="collapse" [class.containerLogo]="!collapse">
            <div *ngIf="hasContext == ''" [class.logoLizaCollapse]="collapse" [class.logoLiza]="!collapse"></div>
            <div *ngIf="hasContext !== ''" [class.logoLizaCollapse]="collapse" [class.logoLiza]="!collapse" [ngStyle]="
            {
                'background-image': !collapse ? logoStyle.logo : logoSmallStyle.logo,
                'aspect-ratio': !collapse ? logoStyle.aspectRatio : logoSmallStyle.aspectRatio,
                'width': !collapse ? logoStyle.width : logoSmallStyle.width,
                'height': !collapse ? logoStyle.height : logoSmallStyle.height,
                'background-position': !collapse ? logoStyle.backgroundPosition : logoSmallStyle.backgroundPosition
            }"></div>
        </div>
        <div [class.userContainerCollapse]="collapse" [class.userContainer]="!collapse">
            <div [class.avatarUserCollapse]="collapse" [class.avatarUser]="!collapse"></div>
            <div [class.userInfoCollapse]="collapse" [class.userInfo]="!collapse">
                <div [class.nameCollapse]="collapse" [class.name]="!collapse">{{user.fullName}}</div>
                <div class="roleCollapse">
                </div>
            </div>
            <!-- <div class="containerArrowCollapse">
                <div class="arrowCollapse"></div>
            </div> -->
        </div>
        <div class="contentMenuCollapse">
            <app-menu (activeCollapse)="activeCollapse($event)" #menuComponent></app-menu>
        </div>
    </div>
    <div class="mainLiza">
        <div class="headerMainLiza">
            <div class="lizaMenuIcon">
                <button pButton type="button" (click)="handleMenu()" icon="pi pi-bars"
                    class="p-button-text menuCustomIcon"></button>
            </div>
            <app-topbar (changeApplicationEmitter)="changeApplication($event)"></app-topbar>
        </div>
        <div class="contentMainLiza">
            <router-outlet></router-outlet>
            <div class="version-content">
                <p class="text-bold"> Versão {{version}}</p>
            </div>
        </div>
    </div>
</div>
<!--<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-menu-static': isStatic(),
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': isSlim(),
        'layout-menu-active': menuActive,
        'layout-menu-mobile-active': mobileMenuActive,
        'layout-topbar-mobile-active': mobileTopbarActive,
        'layout-rightmenu-active': rightMenuActive,
        'layout-rtl': app.isRTL}" [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme">

    <app-topbar></app-topbar>

    <app-rightmenu></app-rightmenu>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <app-inline-menu *ngIf="app.inlineMenuPosition=='top' || app.inlineMenuPosition=='both'" key="top"></app-inline-menu>
            <app-menu></app-menu>
            <app-inline-menu *ngIf="app.inlineMenuPosition=='bottom' || app.inlineMenuPosition=='both'" key="bottom"></app-inline-menu>
        </div>
    </div>

    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>

    <app-config></app-config>

    <div *ngIf="mobileMenuActive" class="layout-mask modal-in"></div>

</div>
-->


<p-sidebar class="navLeft" [(visible)]="displayMenu" styleClass="navLizaIcon" position="left" styleClass="ui-sidebar-lg"
    [style]="{'overflow-y': 'scroll'}">
    <div class="navLizaIcon">
        <div class="containerLogo">
            <div class="logoLiza"></div>
        </div>
        <div class="userContainer">
            <div class="avatarUser"></div>
            <div class="userInfo">
                <div class="name">{{user.fullName}}</div>
                <div class="role">
                    <!-- {{user.userRole.friendlyName}} -->
                </div>
            </div>
            <div class="containerArrow">
                <div class="arrow"></div>
            </div>
        </div>
        <div class="contentMenu">
            <app-menu #menuComponent></app-menu>
        </div>
    </div>
</p-sidebar>