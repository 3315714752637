import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { ContactViewModel } from 'src/app/services/fund-manager/models/view-models/contact-view-model';
import { ManagerSignerViewModel } from 'src/app/services/fund-manager/models/view-models/manager-signer-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';

@Component({
    templateUrl: './manager-contacts-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-fund-manager-contacts-list',
})
export class ManagerContactsListComponent implements OnInit, AfterViewInit {

    @Input() managerSigners: ManagerSignerViewModel[];
    @Input() typeList: string | `Select`;
    @Input() managerContacts: ContactViewModel[];
    @Input() representativesManager: ManagerSignerViewModel[];
    selecetedManagerContact: ManagerSignerViewModel[] = [];

    @Output() checkboxManagerToggleEmitter = new EventEmitter<ContactViewModel>();
    @Output() unCheckboxManagerToggleEmitter = new EventEmitter<ContactViewModel>();
    @Output() onHeaderManagerCheckboxToggledEmitter = new EventEmitter<boolean>();

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private router: Router,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        
    }

    ngAfterViewInit(): void {
        if(this.representativesManager?.length !== 0) {
            for (let index = 0; index < this.representativesManager?.length; index++) {
                const element = this.representativesManager[index];
                this.selecetedManagerContact.push(element.contactSigner);
            }
        }
    }

    selectedCheckbox(event: ContactViewModel) {
        this.checkboxManagerToggleEmitter.emit(event)
    }

    unSelectCheckbox(event: ContactViewModel) {
        this.unCheckboxManagerToggleEmitter.emit(event)
    }

    onHeaderCheckboxToggled(event: boolean) {
        this.onHeaderManagerCheckboxToggledEmitter.emit(event);
    }

    // removeContact(contact: ContactProperties) {
    //     this.removeContactEmitter.emit(contact);
    // }

    // resendEmail() {
    //     this.resendEmailEmitter.emit();
    // }


}