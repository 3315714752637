<div class="content-analysis">
    <div class="boxLeft"></div>
    <div class="content-wrapp">
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        {{selectedBrokerOperation?.type?.friendlyName}}
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <!-- <div class="line"></div> -->
                        <!-- <div class="p-col-9 p-md-9 p-lg-9"></div> -->
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">ID</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.sequentialId}}
                        </div>

                        <div class="p-col-12 p-md-1 p-lg-1">
                            <!-- <p class="text-bold">Público Alvo ANBIMA</p> -->
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <!-- {{selectedBrokerOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup }} -->
                        </div>

                        <!-- <div class="line"></div> -->
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold">Fundo:</p>
                        </div>

                        <div class="p-col-12 col-md-6 p-lg-6">
                            {{selectedBrokerOperation?.fund?.name}}
                        </div>

                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.fund?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <p class="text-bold"> Corretora:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            {{selectedBrokerOperation?.broker?.name}}
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Administrador do Fundo:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedBrokerOperation?.fund?.administrator?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedBrokerOperation?.fund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                        </div>

                        <div class="line"></div>
                        <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Gestor do Fundo:</p>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                            <span>{{selectedBrokerOperation?.fund?.manager?.name}}</span>
                        </div>
                        <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                            <p class="text-bold">CNPJ</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                            {{selectedBrokerOperation?.fund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                        </div>
                        <div class="line"></div>
                        <!-- <div class="p-col-12 p-md-3 p-lg-3"
                            [hidden]="selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                        selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="contentAlignItemsCenter">
                                <p class="textPrimary">Consulta CVM:</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            <div class="contentCenterColumn1">
                                <p class="textPrimary">Consulta CVM:</p>
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://sistemas.cvm.gov.br/?CadGeral" target="_blank">Site
                                    CVM</a>
                            </div>
                        </div> -->

                        <div class="p-col-12 p-md-3 p-lg-3">
                            <div class="contentAlignItemsCenter">
                                <p class="textPrimary">Consulta CVM:</p>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-6 p-lg-6">
                            <div class="contentRowStartItems">
                                <p class="linkSecondary">OK</p>
                                <a class="aFakeButton" href="https://sistemas.cvm.gov.br/?CadGeral" target="_blank">Site
                                    CVM</a>
                            </div>
                        </div>

                        <!-- <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                            <p class="text-bold">Público Anbima:</p>
                        </div>
                        <div class="p-col-12 p-md-5 p-lg-5 contentAlignItemsCenter">
                            <span>{{selectedBrokerOperation?.shareholderFund?.ambimaTargetGroup}}</span>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="titleCustomCard">
                    <div class="titlePage">
                        Resumo
                    </div>
                </div>
                <div class="customCard">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-2 p-lg-2"
                                        *ngFor="let artefact of selectedBrokerOperation?.artifacts; let index = index">
                                        <div class="contentCenterColumn">
                                            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
                                            <p class="text-bold textPrimary">{{artefact?.status?.friendlyName }}</p>
                                            <!-- <button
                                                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="action-button p-ml-2"></button> -->
                                            <button
                                                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && !artefact.isSignatureRequired"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="p-button"></button>
                                            <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                                                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button"
                                                label="Ver" class="p-button"></button>
                                            <button
                                                *ngIf="artefact.status.value == artifactStatusEnum.Assinado && artefact.isSignatureRequired"
                                                (click)="downloadArtefact(artefact)" pButton pRipple type="button"
                                                label="Download" class="p-button"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [canRemoveFile]="false"
                                    [selectedBrokerOperation]="selectedBrokerOperation"
                                    (openArtefactUrlEmitter)="openAttachementUrl($event)"
                                    (openAddFileEmitter)="openAddFile()">
                                </app-attachment-list>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [brokerAssessments]="assesments"></app-assessment-list>
                            </div>
                            <br>
                        </div>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Ações
                            </div>
                            <div class="borderOutlined">
                                <div class="contentAlignItemsCenterGap">
                                    <button pButton pRipple type="button" label="Aprovar" class="p-button-primary"
                                        (click)="openApprovedAssesment()"></button>
                                    <button pButton pRipple type="button" label="Pendenciar" class="p-button-outlined"
                                        (click)="openAvailableAssesments()"></button>
                                    <!-- *ngIf="assesments?.assessments.length == 0" -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Aprovar" [(visible)]="displayApprovedAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <form #aprovedForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="brokerAccountInfo" type="text" [(ngModel)]="request.brokerAccountInfo" [maxlength]="10"
                            name="brokerAccountInfo" #brokerAccountInfo="ngModel" class="inputLiza" required pInputText>
                        <label for="brokerAccountInfo">Número da conta</label>
                    </span>
                </div>
                <mark *ngIf="brokerAccountInfo.invalid && brokerAccountInfo.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="request.operationExpirationDate" name="operationExpirationDate"
                            yearRange="2022:2099" [showIcon]="true" appendTo="body" [monthNavigator]="true"
                            [required]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            #operationExpirationDate="ngModel">
                        </p-calendar>
                        <label for="operationExpirationDate" class="labelOcta">Data de expiração</label>
                    </span>
                </div>
            </div>
        </div>
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Aprovar" class="action-button p-ml-2"
                (click)="approvedAssesments(aprovedForm)"></button>
        </div>
    </form>

</p-dialog>

<p-dialog header="Pendenciar" [(visible)]="displayAvailableAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <form #availableAssesmentForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div class="field-radiobutton">
                    <p-radioButton name="fillincorrecet" value="Preechimento Incorreto" [(ngModel)]="selectedAssesment">
                    </p-radioButton>
                    <label for="fillincorrecet"> Preechimento Incorreto</label>
                </div>
                <br>
                <div class="field-radiobutton">
                    <p-radioButton name="document" value="Documento Faltante" [(ngModel)]="selectedAssesment">
                    </p-radioButton>
                    <label for="document"> Documento Faltante</label>
                </div>
                <!-- <p-checkbox name="label" value="value" [value]="item" [(ngModel)]="selectedPendingItem">
                </p-checkbox>
                <label [for]="item.label"> {{item.label}}</label> -->
            </div>
            <br>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <!-- <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text" [(ngModel)]="requestAssesment.notes" name="notes" #notes="ngModel"
                            class="inputLiza" required pInputText>
                        <label for="name">Descrição</label>
                    </span>
                </div> -->
                <!-- <span class="p-float-label p-fluid"> -->
                <textarea id="float-input" pInputTextarea name="notes" required [style]="{'width': '100%'}"
                    placeholder="Descrição" [(ngModel)]="requestAssesment.notes" #notes="ngModel"></textarea>
                <!-- <label for="float-input">Descrição:</label> -->
                <!-- </span> -->
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <b>Obs: Caso o(s) documento(s) recebido(s) esteja(m) desatualizado(s), utilizar o campo Anexo dentro da
                    plataforma para subir o formulário correto.</b>
            </div>
        </div>
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                (click)="onAvailableAssesment(availableAssesmentForm)"></button>
        </div>
    </form>

</p-dialog>

<p-dialog header="Adicionar arquivo" [(visible)]="displayBrokerOperationFiles" [modal]="true" [responsive]="true"
    [style]="{width: '50vw'}">
    <form #filesForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <input id="name" type="text" [(ngModel)]="requestFiles.description" name="description"
                    #description="ngModel" placeholder="Descrição" class="inputLiza" required pInputText>

            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" style="display: flex; justify-content: center;">
                <p-fileUpload [files]="requestFiles.files" [multiple]="false" [customUpload]="true" [auto]="true"
                    (uploadHandler)="uploadFiles($event.files, filesForm)"></p-fileUpload>
            </div>
        </div>
    </form>

</p-dialog>