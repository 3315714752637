<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                {{selectedBrokerOperation?.type?.friendlyName}}
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <!-- <div class="line"></div> -->
                <!-- <div class="p-col-9 p-md-9 p-lg-9"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">ID</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedBrokerOperation?.sequentialId}}
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <!-- <p class="text-bold">Público Alvo ANBIMA</p> -->
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    <!-- {{selectedBrokerOperation?.shareholderFund?.currentCustomProperty?.ambimaTargetGroup }} -->
                </div>

                <!-- <div class="line"></div> -->
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold">Fundo:</p>
                </div>

                <div class="p-col-12 col-md-6 p-lg-6">
                    {{selectedBrokerOperation?.fund?.name}}
                    <a class="linkPrimary leftSpacingText" target="_blank"
                        [routerLink]="['/app/customer/fund/view/', selectedBrokerOperation?.fund?.id]">Ver</a>
                </div>

                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedBrokerOperation?.fund?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p class="text-bold"> Corretora:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    {{selectedBrokerOperation?.broker?.name}}
                </div>
                <div class="p-col-12 p-md-1 p-lg-1">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2">
                    {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <!-- <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Distribuidor do fundo que receberá o rebate da aplicação:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedBrokerOperation?.operationDistributorName}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedBrokerOperation?.operationDistributorDocumentNumber| documentNumber: 'cnpj' }}
                </div> -->
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Administrador do Fundo:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedBrokerOperation?.fund?.administrator?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedBrokerOperation?.fund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
                </div>

                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Gestor do Fundo:</p>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6 contentAlignItemsCenter">
                    <span>{{selectedBrokerOperation?.fund?.manager?.name}}</span>
                </div>
                <div class="p-col-12 p-md-1 p-lg-1 contentAlignItemsCenter">
                    <p class="text-bold">CNPJ</p>
                </div>
                <div class="p-col-12 p-md-2 p-lg-2 contentAlignItemsCenter">
                    {{selectedBrokerOperation?.fund?.manager?.documentNumber | documentNumber: 'cnpj' }}
                </div>
                <div class="line"></div>
                <div class="p-col-12 p-md-3 p-lg-3" [hidden]="selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                selectedBrokerOperation?.currentStatus.status.value == operationStatusEnum.EmAnalisePeloGestor">
                    <div class="contentAlignItemsCenter">
                        <p class="textPrimary">Consulta CVM:</p>
                    </div>
                </div>
                <div class="p-col-12 p-md-6 p-lg-6">
                    <div class="contentRowStartItems">
                        <p class="linkSecondary">OK</p>
                        <a class="aFakeButton" href="https://web.cvm.gov.br/app/fundosweb/#/consultaPublica"
                            target="_blank">Site CVM</a>
                    </div>
                </div>

                <!-- <div class="p-col-12 p-md-3 p-lg-3 contentAlignItemsCenter">
                    <p class="text-bold">Público Anbima:</p>
                </div>
                <div class="p-col-12 p-md-5 p-lg-5 contentAlignItemsCenter">
                    <span>{{selectedBrokerOperation?.shareholderFund?.ambimaTargetGroup}}</span>
                </div> -->

            </div>
        </div>
    </div>
</div>

<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Etapas
            </div>
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-col-12 p-md-12 p-lg-12">

                    <p-steps class="contentSteps" #pStep [model]="steps" [readonly]="true" [activeIndex]="currentStep">
                    </p-steps>

                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12 text-center">
                            <div class="cardHeader">
                                <span class="text-bold">Status: </span>
                                <span [ngClass]="{
                                    'textPrimary': selectedBrokerOperation?.currentStatus?.status?.value <= 6,
                                    'linkSecondary': selectedBrokerOperation?.currentStatus?.status?.value == 8}">{{
                                    selectedBrokerOperation?.currentStatus?.status?.friendlyName}}</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="pStep.activeIndex == 0" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Selecione os Rep. Legais/Procuradores do fundo
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid" *ngIf="selectedBrokerOperation">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante A</h5>
                                        <app-legal-representative-fund-select [isRepresentativeA]="true"
                                            [selectedRepresentativeA]="selectedBrokerOperation.shareholderFundSignerOne"
                                            [allRepresentativesA]="allRepresentativesA"
                                            (representativeAEmitter)="changedRepresentativeA($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        <h5 class="text-bold">Representante B</h5>
                                        <app-legal-representative-fund-select [isRepresentativeB]="true"
                                            [selectedRepresentativeB]="selectedBrokerOperation.shareholderFundSignerTwo"
                                            [allRepresentativesB]="allRepresentativesB"
                                            (representativeBEmitter)="changedRepresentativeB($event)">
                                        </app-legal-representative-fund-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedBrokerOperation">
                            <div class="cardHeader text-bold">
                                Selecione os Rep. Legais/Procuradores da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-signers-list [verifySigner]="!loadBrokerSignableRepresentatives()"
                                    [selecetedBrokerSigners]="selecetedBrokerSigners"
                                    [brokerSigners]="brokerSigners" [typeList]="'Select'"
                                    [signers]="selectedBrokerOperation?.brokerSigners"
                                    (checkboxToggleEmitter)="selectedCheckboxSigners($event)"
                                    (unCheckboxToggleEmitter)="unSelectCheckboxSigners($event)"
                                    (onHeaderCheckboxToggledEmitter)="toggleOnHeaderSigners($event)">
                                </app-broker-signers-list>
                                <!-- <button pButton pRipple type="button" label="Adicionar contato" class="p-button-primary"
                                    (click)="addBrokerContacts()"></button> -->
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedBrokerOperation">
                            <div class="cardHeader text-bold">
                                Selecione os contatos da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-contacts-list [brokerContacts]="brokerContacts"
                                    [recipients]="selectedBrokerOperation?.recipients" [typeList]="'Select'"
                                    (checkboxToggleEmitter)="selectedCheckboxContact($event)"
                                    (unCheckboxToggleEmitter)="unSelectCheckboxContact($event)"
                                    (onHeaderCheckboxToggledEmitter)="toggleOnHeaderContact($event)">
                                </app-broker-contacts-list>
                                <button pButton pRipple type="button" label="Adicionar contato" class="p-button-primary"
                                    (click)="addBrokerContacts()"></button>
                            </div>
                        </div>
                        <br>
                        <br>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-create [selectedBrokerOperation]="selectedBrokerOperation"
                                    [hasBrokerFundRegulationFile]="hasBrokerFundRegulationFile"
                                    (openAddFileEmitter)="openAddFile()" (removeFileEmitter)="removeFile($event)"
                                    (onBrokerUploadFileFundRegulationEmitter)="onBrokerUploadFundRegulation()"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"></app-attachment-create>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                            <button (click)="setAsDone()" pButton pRipple type="button" label="Próximo"
                                [disabled]="verifySetAsDone()" class="action-button p-ml-2"></button>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 1" class="p-grid">

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [step]="1" (fillInfoTemplateEmitter)="fillInfoTemplate($event)"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)"
                                    (updateBrokerOperation)="updateUrl()">
                                </app-artifact-template-list>

                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedBrokerOperation]="selectedBrokerOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Rep. Legais/Procuradores da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-signers-list [signers]="selectedBrokerOperation?.brokerSigners"
                                    [typeList]="'None'">
                                </app-broker-signers-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-contacts-list [recipients]="selectedBrokerOperation?.recipients"
                                    [typeList]="'None'">
                                </app-broker-contacts-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (removeArtefactUrlEmitter)="confirm($event)"
                                    (openAddFileEmitter)="openAddFile()"></app-attachment-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">

                            <button (click)="sendToSign()" pButton pRipple type="button"
                                [disabled]="selectedBrokerOperation.currentStatus.status.value !== operationStatusEnum.PreenchimentoConcluido || disabledButtonSignature"
                                label="Enviar para assinatura" class="action-button p-ml-2"></button>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 2" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [step]="2" (fillInfoTemplateEmitter)="fillInfoTemplate($event)"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)"
                                    (updateBrokerOperation)="updateUrl()">
                                </app-artifact-template-list>

                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedBrokerOperation]="selectedBrokerOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Rep. Legais/Procuradores da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-signers-list [signers]="selectedBrokerOperation?.brokerSigners"
                                    [typeList]="'None'">
                                </app-broker-signers-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-contacts-list [recipients]="selectedBrokerOperation?.recipients"
                                    [typeList]="'None'">
                                </app-broker-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (removeArtefactUrlEmitter)="confirm($event)"
                                    (openAddFileEmitter)="openAddFile()"></app-attachment-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">

                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 3" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [step]="3" (fillInfoTemplateEmitter)="fillInfoTemplate($event)"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)"
                                    (updateBrokerOperation)="updateUrl()">
                                </app-artifact-template-list>
                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedBrokerOperation]="selectedBrokerOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Rep. Legais/Procuradores da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-signers-list [signers]="selectedBrokerOperation?.brokerSigners"
                                    [typeList]="'None'">
                                </app-broker-signers-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-contacts-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [recipients]="selectedBrokerOperation?.recipients"
                                    (resendEmailEmitter)="resendEmail($event)"
                                    (removeRepresentativeEmitter)="removeRepresentative($event)" [typeList]="'None'">
                                </app-broker-contacts-list>
                            </div>
                            <br>
                            <button
                                *ngIf="selectedBrokerOperation?.currentStatus?.status?.value !== brokerOperationStatusEnum.PendenciadaPelaCorretora"
                                pButton pRipple type="button" label="Adicionar contato" class="p-button-primary"
                                (click)="addBrokerContacts()"></button>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (onBrokerUploadFileFundRegulationEmitter)="onBrokerUploadFundRegulation()"
                                    (removeArtefactUrlEmitter)="confirm($event)" (openAddFileEmitter)="openAddFile()">
                                </app-attachment-list>
                            </div>
                        </div>
                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="assesments">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [brokerAssessments]="assesments"></app-assessment-list>
                            </div>
                            <br>
                            <div class="p-grid"
                                *ngIf="selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.PendenciadaPeloGestor">
                                <div class="p-col-12 p-md-10 p-lg-10 p-fluid">
                                    <div class="p-inputgroup">
                                        <span class="p-float-label">
                                            <input id="name" type="text" [(ngModel)]="requestAssesmentReply.reply"
                                                name="reply" #reply="ngModel" class="inputLiza" required pInputText>
                                            <label for="name">Digite aqui a resposta</label>
                                        </span>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                    <button pButton pRipple type="button" label="Enviar" class="action-button p-ml-2"
                                        (click)="sendReplyAssesment()"></button>
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" 
                        *ngIf="selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.PendenciadaPeloGestor || 
                                selectedBrokerOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <div class="cardHeader text-bold">
                                Ações
                            </div>
                            <div class="borderOutlined">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="contentCenterColumn">
                                            <button pButton pRipple type="button" label="Aprovar"
                                                class="p-button-primary" (click)="openAlertDismissAssesment()"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="pStep.activeIndex == 4 || pStep.activeIndex == 5" class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Documentos
                            </div>
                            <div class="borderOutlined">
                                <app-artifact-template-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [step]="4" (fillInfoTemplateEmitter)="fillInfoTemplate($event)"
                                    (updateArtifactByIdEmitter)="updateArtifactById($event)"
                                    (updateBrokerOperation)="updateUrl()">
                                </app-artifact-template-list>

                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="borderOutlined">
                                <app-legal-representative-fund-list [selectedBrokerOperation]="selectedBrokerOperation">
                                </app-legal-representative-fund-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Rep. Legais/Procuradores da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-signers-list [signers]="selectedBrokerOperation?.brokerSigners"
                                    [typeList]="'None'">
                                </app-broker-signers-list>
                            </div>
                        </div>

                        <br>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Contatos da Corretora
                            </div>
                            <div class="borderOutlined">
                                <app-broker-contacts-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [recipients]="selectedBrokerOperation?.recipients" [typeList]="'None'">
                                </app-broker-contacts-list>
                            </div>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="cardHeader text-bold">
                                Anexos
                            </div>
                            <div class="borderOutlined">
                                <app-attachment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    (openArtefactUrlEmitter)="openArtefactUrl($event)"
                                    (removeArtefactUrlEmitter)="confirm($event)"
                                    (openAddFileEmitter)="openAddFile()"></app-attachment-list>
                            </div>
                        </div>

                        <br>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngIf="assesments">
                            <div class="cardHeader text-bold">
                                Mensagens
                            </div>
                            <div class="borderOutlined">
                                <app-assessment-list [selectedBrokerOperation]="selectedBrokerOperation"
                                    [brokerAssessments]="assesments"></app-assessment-list>

                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>


<p-dialog header="Adicionar arquivo" [(visible)]="displayOperationFiles" [modal]="true" [responsive]="true"
    [style]="{width: '50vw'}">
    <form #filesForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <input id="name" type="text" [(ngModel)]="requestFiles.description" name="description"
                    #description="ngModel" placeholder="Descrição" class="inputLiza" required pInputText>

            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" style="display: flex; justify-content: center;">
                <p-fileUpload [files]="requestFiles.files" [multiple]="false" [customUpload]="true" [auto]="true"
                    (uploadHandler)="uploadFiles($event.files)"></p-fileUpload>
            </div>
        </div>
    </form>

</p-dialog>

<p-dialog header=" " [(visible)]="displayFillInfoTemplate" [modal]="true" [responsive]="true" [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <app-fill-template-create [selectedBrokerOperation]="selectedBrokerOperation"
                [artefactObservable]="artefactObservable"
                (requestBrokerEmitter)="saveFillTemplate($event)"></app-fill-template-create>
        </div>
    </div>

</p-dialog>

<p-dialog header="Aprovar" [(visible)]="displyDismissAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <form #dismissForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="brokerAccountInfo" type="text" [(ngModel)]="requestDissmisAssesment.brokerAccountInfo" [maxlength]="10"
                            name="brokerAccountInfo" #brokerAccountInfo="ngModel" class="inputLiza" required pInputText>
                        <label for="brokerAccountInfo">Número da conta</label>
                    </span>
                </div>
                <mark *ngIf="brokerAccountInfo.invalid && brokerAccountInfo.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="requestDissmisAssesment.operationExpirationDate" name="operationExpirationDate"
                            yearRange="2022:2099" [showIcon]="true" appendTo="body" [monthNavigator]="true"
                            [required]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            #operationExpirationDate="ngModel">
                        </p-calendar>
                        <label for="operationExpirationDate" class="labelOcta">Data de expiração</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <textarea id="float-input" pInputTextarea name="notes" [style]="{'width': '100%'}" placeholder="Motivo"
                    [(ngModel)]="requestDissmisAssesment.notes" required #notes="ngModel"></textarea>
            </div>
        </div>
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Aprovar" class="action-button p-ml-2"
                (click)="dismissAssesments(dismissForm)"></button>
        </div>
    </form>

</p-dialog>

<p-dialog header="Adicionar Contato da Corretora" [(visible)]="displayCreateBrokerContact" [modal]="true"
    [responsive]="true" [style]="{width: '50vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="field-radiobutton">
                <p-radioButton name="existCopntact" value="existContact" [(ngModel)]="switchContact"
                    inputId="existContact"></p-radioButton>
                <label for="existContact"> Selecionar contato existente</label>
            </div>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="field-radiobutton">
                <p-radioButton name="newContact" value="newContact" [(ngModel)]="switchContact"
                    inputId="newContact"></p-radioButton>
                <label for="newContact"> Adicionar novo contato</label>
            </div>
        </div>
    </div>

    <div class="p-grid" *ngIf="switchContact == 'existContact'">
        <div class="p-col-12 p-md-12 p-lg-12">
            <span class="p-float-label">
                <p-dropdown [options]="allContacts" placeholder="Selecione um contato existente" [required]="true"
                    [(ngModel)]="selectedContatIncludeOperation" optionLabel="label" #ambimaTargetGroup="ngModel"
                    optionValue="value" appendTo="body" name="targetAudience">
                </p-dropdown>
            </span>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class=" p-button-outlined"
                    (click)="onCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onSaveExistContactInBrokerOperation(selectedContatIncludeOperation)"></button>
            </div>
        </div>
    </div>

    <div class="p-grid" *ngIf="switchContact == 'newContact'">
        <div class="p-col-12 p-md-12 p-lg-12">
            <app-broker-contacts-create (brokerContactEmitter)="saveBrokerContact($event, $event)"
                (buttonCancelEmitter)="onCancel()" [displayCreateBrokerContacts]="displayCreateBrokerContacts">
            </app-broker-contacts-create>
        </div>
    </div>

</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>

<p-dialog header=" " [(visible)]="displayMessage" [modal]="true" [responsive]="true" [style]="{width: '25vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <h5 class="text-bold text-center">Mensagem enviada ao destinatário.</h5>
        </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="contentButtonActions">
            <button pButton pRipple type="button" label="Fechar" class=" p-button-outlined"
                (click)="displayMessage = false"></button>
        </div>
    </div>

</p-dialog>

<p-dialog header=" " [(visible)]="displayAlertDismissAssesment" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}" [closable]="true">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <h3 style="text-align: center;">Aviso!</h3>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold text-center">Você tem certeza que deseja aprovar esta operação?</p>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold" style="text-align: justify;">Uma vez aprovando esta operação de cadastro, a mesma mudará para o status “<span class="textSecondary">Concluído</span>” não havendo mais oportunidade de alteração ou comunicação com a casa externa.</p>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
            <button class="p-button-outlined" (click)="displayAlertDismissAssesment = false" pButton pRipple type="button" label="Cancelar"></button>
            <button class="p-button" (click)="openDismissAssesment()" pButton pRipple type="button" label="Entendi"></button>
        </div>
    </div>
</p-dialog>