<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar Usuário
            </div>
            <div class="subTitle">
                Informe os dados do usuário a ser convidado
            </div>
        </div>

        <form #entryForm="ngForm" novalidate>
            <div class="customCard">
                <div class="rowForm flexRow">
                    <label class="labelMini">Dados Pessoais</label>
                </div>
                <div class="p-fluid p-formgrid p-grid p-mt-3">
                    <div class="p-col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="fullName" type="text" ngModel={{userSelected?.fullName}}
                                    name="fullName" class="inputLiza" required pInputText readonly>
                                <label for="fullName">Nome Completo</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-mt-3 p-col-12">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input type="text" class="inputLiza" name="email" id="email"
                                    ngModel={{userSelected?.email}} name="email" #email="ngModel" pInputText readonly>
                                <label for="contactType">E-mail</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="rowForm flexRow">
                    <label class="labelMini">Funções no sistema</label>
                </div>
                <div *ngFor="let optionFoF of roleOptionsFoF" class="field-checkbox">
                    <p-radioButton [inputId]="optionFoF.value" [name]="optionFoF.label" [value]="optionFoF"
                        [(ngModel)]="selectedRolesFoF" (ngModelChange)="handleRoleFoF($event)"></p-radioButton>
                    <label class="labelCheckbox" [for]="optionFoF.label">{{optionFoF.label}}</label>
                </div>
                <br>
                <div *ngFor="let optionBroker of roleOptionsBroker" class="field-checkbox">
                    <p-radioButton [inputId]="optionBroker.value" [name]="optionBroker.label" [value]="optionBroker"
                        [(ngModel)]="selectedRolesBroker" (ngModelChange)="handleRoleBroker($event)"></p-radioButton>
                    <label class="labelCheckbox" [for]="optionBroker.label">{{optionBroker.label}}</label>
                </div>
                <!-- <div class="rowForm flexRow p-mt-3">
                    <p-pickList [source]="roleOptions" [target]="selectedRoles"
                        sourceHeader="Funções Disponíveis" targetHeader="Funções Selecionados" [dragdrop]="true"
                        [responsive]="true" [sourceStyle]="{'height':'30rem'}"
                        [targetStyle]="{'height':'30rem'}" filterBy="label" sourceFilterPlaceholder="Filtrar" [showSourceControls]="false" [showTargetControls]="false"
                        targetFilterPlaceholder="Buscar">
                        <ng-template let-infoType pTemplate="item">
                            <div class="infoType-item">
                                <div class="infoType-list-detail">
                                    <h5 class="p-mb-2">{{infoType.label}}</h5>
                                </div>
                            </div>
                        </ng-template>
                    </p-pickList>
                </div> -->
            </div>
            <div class="customCard">
                <div class="p-d-flex p-jc-end">
                    <!-- <button pButton pRipple type="button" label="Cancelar"
                        class="p-button-plain action-button p-button-outlined" (click)="onCancel()"></button> -->
                    <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                        (click)="onSave(entryForm)"></button>
                </div>
            </div>
        </form>
    </div>
</div>