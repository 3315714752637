<div class="pages-body contact-page p-d-flex p-flex-column">
    <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
        <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
            <div class="logo">
                <img src="assets/layout/images/logo2x.png" alt=""/>
            </div>
        </div>
        <div class="topbar-right p-mr-3 p-d-flex">
            <button pButton pRipple type="button" label="ENTRAR" [routerLink]="['/login']"
                    class="p-button-text p-button-plain topbar-button"></button>
        </div>
    </div>

    <div class="map">
        <span></span>
    </div>

    <div class="pages-panel">
        <div class="card p-grid">
            <div class="right-panel p-lg-6 p-md-6 p-sm-12 p-mx-auto">
                <div class="p-grid">
                    <div class="p-md-offset-3 p-md-6 p-md-offset-3 p-sm-offset-2 p-sm-8 p-sm-offset-2">
                        <i class="pi pi-briefcase p-mb-2 p-mt-5"></i>
                        <p class="title p-my-3">Eu tenho uma frota de veículos!</p>
                        <p>Quero um canal seguro para vender meus veículos em final de vida</p>
                        <p-button class="p-mb-3" label="QUERO SER UM PARCEIRO" [routerLink]="['/entry-customer-account']"></p-button>
                    </div>
                </div>
            </div>
            <div class="right-panel p-lg-6 p-md-6 p-sm-12 p-mx-auto">
                <div class="p-grid">
                    <div class="p-md-offset-3 p-md-6 p-md-offset-3 p-sm-offset-2 p-sm-8 p-sm-offset-2">
                        <i class="pi pi-home p-mb-2 p-mt-5"></i>
                        <p class="title p-my-3">Sou um desmanche de veículo!</p>
                        <p>Quero um canal seguro para fazer as minhas aquisições</p>
                        <p-button class="p-mb-3" label="QUERO SER UM CLIENTE" [routerLink]="['/entry-customer-account']"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
