<!-- operation -->

<div class="p-grid" *ngIf="step == 1 && selectedOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedOperation?.artifacts; let index = index">
        <div class="contentCenterColumn" style="gap: 10px;">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                                                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                                                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                                                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>
            <button *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente "
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="action-button p-ml-2"></button>
            <button *ngIf="selectedOperation?.currentStatus?.status?.value == operationStatusEnum.PreenchimentoManualNecessario || 
                selectedOperation?.currentStatus?.status?.value == operationStatusEnum.PreenchimentoConcluido" pButton
                pRipple type="button" label="Atualizar Preenchimento" (click)="reprocessArtifact(artefact)"
                class="action-button p-button-outlined p-ml-2"></button>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 2 && selectedOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                                                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                                                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                                                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>

            <div class="contentAlignItemsCenterGap">
                <button
                    *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && !artefact.isSignatureRequired"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="p-button"></button>
                <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="p-button"></button>
                <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado && artefact.isSignatureRequired"
                    (click)="downloadArtefact(artefact)" pButton pRipple type="button" label="Download"
                    class="p-button"></button>
                <button
                    *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && artefact.isSignatureRequired"
                    (click)="updateArtifactById(artefact)" pButton pRipple type="button" label="Atualizar status"
                    class="p-button-outlined"></button>
            </div>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 3 && selectedOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>
            <button
                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && !artefact.isSignatureRequired"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="action-button p-ml-2"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="p-button"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado && artefact.isSignatureRequired"
                (click)="downloadArtefact(artefact)" pButton pRipple type="button" label="Download"
                class="p-button"></button>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 4 && selectedOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>

            <button
                *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && !artefact.isSignatureRequired"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="action-button p-ml-2"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="p-button"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado && artefact.isSignatureRequired"
                (click)="downloadArtefact(artefact)" pButton pRipple type="button" label="Download"
                class="p-button"></button>
        </div>
    </div>
</div>

<!-- broker Operation -->

<div class="p-grid" *ngIf="step == 1 && selectedBrokerOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedBrokerOperation?.artifacts; let index = index">
        <div class="contentCenterColumn" style="gap: 10px;">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                                                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                                                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                                                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>
            <button *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente "
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="action-button p-ml-2"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoConcluido" pButton pRipple
                (click)="reprocessBrokerArtifact(artefact)" type="button" label="Atualizar Preenchimento"
                class="action-button p-button-outlined p-ml-2"></button>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 2 && selectedBrokerOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedBrokerOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                                                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                                                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                                                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>

            <div class="contentAlignItemsCenterGap">
                <button
                    *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && artefact.status.value !== artifactStatusEnum.Assinado || artefact.status.value == artifactStatusEnum.EmAssinatura"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="p-button"></button>
                <!-- <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura && artefact.isBrokerRepresentativeSignatureRequired || artefact.isBrokerRepresentationSignatureRequired"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="p-button"></button> -->
                <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado"
                    (click)="downloadArtefact(artefact)" pButton pRipple type="button" label="Download"
                    class="p-button"></button>
                <button
                    *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente && artefact.status.value !== artifactStatusEnum.Assinado"
                    (click)="updateArtifactById(artefact)" pButton pRipple type="button" label="Atualizar status"
                    class="p-button-outlined"></button>
            </div>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 3 && selectedBrokerOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedBrokerOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <div class="contentButtonsArtifacts">
                <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                    class="action-button"></button>
                <!-- <button *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="action-button"></button> -->
                <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                    (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                    class="p-button"></button>
                <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado"
                    (click)="downloadArtefact(artefact)" pButton pRipple type="button" label="Download"
                    class="p-button"></button>
            </div>

        </div>
    </div>
</div>

<div class="p-grid" *ngIf="step == 4 && selectedBrokerOperation">
    <div class="p-col-12 p-md-2 p-lg-2" *ngFor="let artefact of selectedBrokerOperation?.artifacts; let index = index">
        <div class="contentCenterColumn">
            <h5 class="cardheader text-bold">{{artefact?.template?.description }}</h5>
            <p class="text-bold" [ngClass]="{
                'textPrimary': artefact?.status?.value !== artifactStatusEnum.Assinado,
                'textSecondary': artefact?.status?.value == artifactStatusEnum.Assinado
                }">{{artefact?.status?.friendlyName }}</p>
            <p class="text-bold">{{artefact?.createDate | date : 'dd/MM/yyyy - HH:mm'}}</p>

            <button *ngIf="artefact.status.value == artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Preencher"
                class="action-button p-ml-2"></button>

            <!-- <button *ngIf="artefact.status.value !== artifactStatusEnum.PreenchimentoPendente"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="action-button p-ml-2"></button> -->
            <button *ngIf="artefact.status.value == artifactStatusEnum.EmAssinatura"
                (click)="fillInfoTemplate(artefact)" pButton pRipple type="button" label="Ver"
                class="p-button"></button>
            <button *ngIf="artefact.status.value == artifactStatusEnum.Assinado" (click)="downloadArtefact(artefact)"
                pButton pRipple type="button" label="Download" class="p-button"></button>
        </div>
    </div>
</div>