import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { ArtifactTemplateService } from 'src/app/services/artifact-templates/artifact-template-service';
import { CreateArtifactTemplateRequest, VariableFieldProperties } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { ExtractDocumentVariablesRequest } from 'src/app/services/artifact-templates/models/requests/extract-document-variables-request';
import { FieldTypeOptionViewModel } from 'src/app/services/artifact-templates/models/view-models/field-type-option-view-model';
import { MappedEntityOptionViewModel } from 'src/app/services/artifact-templates/models/view-models/mapped-entity-option-view-model';
import { FileUploadResponse } from 'src/app/services/file-uploads/models/responses/file-upload-response';

@Component({
    templateUrl: './artifact-template-create.component.html',
    styleUrls: [
        './artifact-template-create.component.scss',
        './../../../../assets/layout/customized/custom-card.scss',
    ],
    selector: 'app-artifact-template-create',
    encapsulation: ViewEncapsulation.None,
})
export class ArtifactTemplateCreateComponent implements OnInit {

    fieldTypeOptions: FieldTypeOptionViewModel[];
    mappedEntityOptions: MappedEntityOptionViewModel[] = null;
    currentDocument: FileUploadResponse[] = null;
    filledRequest: CreateArtifactTemplateRequest = null;
    isRequestTemplateSent: Boolean = false;

    @Output() onArtifactFilled: EventEmitter<CreateArtifactTemplateRequest> = new EventEmitter<CreateArtifactTemplateRequest>();

    constructor(
        private artifactTemplateService: ArtifactTemplateService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private messageService: MessageService
    ) {
    }

    async ngOnInit() {
        this.setForms();
        await this.getCombos();
    }

    setForms() {
        this.currentDocument = null;
        this.filledRequest = {
            description: null,
            fileUrl: null,
            variableFields: []
        };
    }

    resetComponent() {
        // console.log('resetComponent');
        this.setForms();
        this.isRequestTemplateSent = false;
    }

    async getCombos() {
        this.loadingService.show();

        this.fieldTypeOptions = await this.artifactTemplateService.getFieldTypeOptions();
        this.mappedEntityOptions = await this.artifactTemplateService.getMappedEntityOptions();
        this.loadingService.hide();
    }

    async getArtifactTemplateVariables(responses: FileUploadResponse[]) {

        if (responses != null) {
            this.loadingService.show();
            this.currentDocument = responses;
            const extractDocumentVariablesRequest: ExtractDocumentVariablesRequest = {
                fileUrl: responses[0].fileUrl
            }
            try {
                const extractionResponse = await this.artifactTemplateService.getArtifactTemplateVariables(extractDocumentVariablesRequest);
                this.filledRequest.fileUrl = responses[0].fileUrl;
                extractionResponse.variables.forEach(variable => {
                    const field: VariableFieldProperties = {
                        replacer: variable,
                        label: variable,
                        fieldTypeId: null,
                        answerOptions: [],
                        entityMapping: null,
                        fieldType: null,
                        mappedEntityName: null,
                        mappedEntityProperty: null,
                        defaultReplacerValue: null,
                        entityPropertiesOptions: []
                    };
                    this.filledRequest.variableFields.push(field);
                });
            } catch (error) {
                this.currentDocument = null;
                console.error(error); // TODO: colocar mensagem de texto
            } finally {
                this.loadingService.hide();
            }
        }
    }

    onFieldTypeSelected(item: VariableFieldProperties) {
        item.fieldType = this.fieldTypeOptions.find(c => c.value == item.fieldTypeId);
        item.mappedEntityName = null;
        item.mappedEntityProperty = null;
    }
    
    onMappedEntitySelected(item: VariableFieldProperties) {
        item.entityPropertiesOptions = [];

        const selectedEntity = this.mappedEntityOptions.find(c => c.displayName == item.mappedEntityName);

        selectedEntity.entityPropertiesNames.forEach(element => {
            const property: SelectItem = { label: element.screenName, value: element.name };
            item.entityPropertiesOptions.push(property);
        });
        item.mappedEntityProperty = null;
    }

    onCancel() {
        this.isRequestTemplateSent = false;
    }

    async onSave(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;

        for (let index = 0; index < this.filledRequest.variableFields.length; index++) {
            const field = this.filledRequest.variableFields[index];

            if (field.fieldType == null) {
                this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo ${field.replacer} não foi mapeado.`, life: 5000 });
                return;
            }

            if (field.fieldType.hasAnswerOptions && !field.answerOptions) {
                this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo ${field.replacer} deve possuir opções de resposta.`, life: 5000 });
                return;
            }

            if (field.fieldType.isEntityMapping) {
                if ((!field.mappedEntityName || !field.mappedEntityProperty)) {
                    this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo ${field.replacer} deve ter a entidade mapeada.`, life: 5000 });
                    return;
                } else {
                    field.entityMapping = `${field.mappedEntityName}.${field.mappedEntityProperty}`
                }
            }
        }

        // console.log('request', this.filledRequest);
        this.onArtifactFilled.emit(this.filledRequest);
        this.isRequestTemplateSent = true;
    }
}
