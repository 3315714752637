<form #requestFormManagerContact="ngForm" novalidate>
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input id="name" type="text" [(ngModel)]="request.name" name="name" #name="ngModel"
                        class="inputLiza" required pInputText>
                    <label for="name">Nome</label>
                </span>
            </div>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input id="email" type="email" [(ngModel)]="request.email" name="email" #email="ngModel"
                        class="inputLiza" required pInputText>
                    <label for="email">E-mail</label>
                </span>
            </div>
            <mark *ngIf="email.invalid && email.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>

        <div class="p-col-12 p-md-12 p-lg-12">
            <div class="contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class=" p-button-outlined"
                    (click)="onCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onSaveManagerContact(requestFormManagerContact)"></button>
            </div>
        </div>
    </div>
</form>