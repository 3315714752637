<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Editar data de validade
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="p-grid p-fluid p-formgrid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="requestModel.expirationDate"
                                    name="expirationDate" yearRange="2022:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true" [required]="true"
                                    #expirationDate="ngModel">
                                </p-calendar>
                                <label for="expirationDate" class="labelOcta">Data de Validade</label>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="p-mt-3">
                    <div class="p-d-flex p-jc-end">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" iconPos="right"
                            class="action-button p-ml-2" (click)="onSave(entryForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>