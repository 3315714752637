<div *ngIf="typeList == 'Select'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="adminContacts" dataKey="id"
        (onRowSelect)="selectedCheckbox($event.data)" (onRowUnselect)="unSelectCheckbox($event.data)"
        (onHeaderCheckboxToggle)="onHeaderCheckboxToggled($event.checked)" styleClass="p-datatable-customized"
        [(selection)]="selecetedAdminContact" responsiveLayout="scroll"
            >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            CPF
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th>
                        <div>
                            Data Validade
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-adminContact let-index>
                <tr style="cursor: pointer" [pSelectableRow]="adminContact">
                    <td>
                        <p-tableCheckbox [value]="adminContact"></p-tableCheckbox>
                    </td>
                    <td>
                        {{adminContact?.name}}
                    </td>
                    <td>
                        {{adminContact?.maskedPersonalDocumentNumber}}
                    </td>
                    <td>
                        {{adminContact?.email}}
                    </td>
                    <td>
                        {{adminContact?.expireDate | date: 'dd/MM/yyyy'}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<div *ngIf="typeList == 'None'" class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <p-table [responsive]="true" #dt [value]="administratorSigners" dataKey="id" styleClass="p-datatable-customized"
            responsiveLayout="scroll">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <div>
                            Nome
                        </div>
                    </th>
                    <th>
                        <div>
                            CPF
                        </div>
                    </th>
                    <th>
                        <div>
                            E-mail
                        </div>
                    </th>
                    <th>
                        <div>
                            Data Validade
                        </div>
                    </th>
                    <!-- <th *ngIf="selectedOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                        <div>
                            Ações
                        </div>
                    </th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-adminContact let-index>
                <tr style="cursor: pointer" [pSelectableRow]="adminContact">
                    <td>
                        {{adminContact?.representativeSigner?.name}}
                    </td>
                    <td>
                        {{adminContact?.representativeSigner?.maskedPersonalDocumentNumber}}
                    </td>
                    <td>
                        {{adminContact?.representativeSigner?.email}}
                    </td>
                    <td>
                        {{adminContact?.representativeSigner?.expireDate | date: 'dd/MM/yyyy'}}
                    </td>
                    <!-- <td>
                        <div
                            *ngIf="selectedOperation?.currentStatus?.status.value == operationStatusEnum.EmAnalisePeloGestor">
                            <a class="linkPrimary" (click)="resendEmail()">Reenviar Email</a>
                        </div>
                    </td> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Nenhum usuário encontrado.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>