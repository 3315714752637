import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterValidationService } from 'src/app/providers/router-validation-service';
import { LizaUserListComponent } from './admin/user/liza-user-list/liza-user-list.component';
import { AuditListComponent } from './audit/audit-list/audit-list.component';
import { LizaHomeComponent } from './home/liza-home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'prefix'
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: LizaHomeComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: LizaUserListComponent,
        canActivate: [RouterValidationService]
      }          
    ]
  },
  {
    path: 'audit',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: AuditListComponent,
        canActivate: [RouterValidationService]
      }          
    ]
  }
  // {
  //   path: 'admin',
  //   children: [
  //     {
  //       path: 'user',
  //       children: [
  //         {
  //           path: '',
  //           redirectTo: 'list',
  //           pathMatch: 'prefix'
  //         },
  //         {
  //           path: 'list',
  //           component: LizaUserListComponent,
  //           canActivate: [RouterValidationService]
  //         }          
  //       ]
  //     },
  //     {
  //       path: 'audit',
  //       children: [
  //         {
  //           path: '',
  //           redirectTo: 'list',
  //           pathMatch: 'prefix'
  //         },
  //         {
  //           path: 'list',
  //           component: AuditListComponent,
  //           canActivate: [RouterValidationService]
  //         }          
  //       ]
  //     }
  //   ]
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LizaRoutingModule { }
