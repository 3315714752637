import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MegaMenuModule } from 'primeng/megamenu';
import { DropdownModule } from 'primeng/dropdown';
import {PanelModule} from 'primeng/panel';
import {AccordionModule} from 'primeng/accordion';

// Components
import { AppRootComponent } from './app.root/app.root.component';
import { AppBreadcrumbComponent } from './breadcrumb/app.breadcrumb.component';
import { AppConfigComponent } from './config/app.config.component';
import { AppFooterComponent } from './footer/app.footer.component';
import { AppMenuComponent } from './menu/app.menu.component';
import { AppMenuitemComponent } from './menuitem/app.menuitem.component';
import { AppTopBarComponent } from './topbar/app.topbar.component';
import { AppRightMenuComponent } from './rightmenu/app.rightmenu.component';
import { AppInlineMenuComponent } from './inlinemenu/app.inlinemenu.component';

// Services
import { BreadcrumbService } from '../providers/breadcrumb.service';
import { MenuService } from '../providers/app.menu.service';
import { LoadingService } from '../modules/loading/app.loading.service';
import { PipesModule } from '../pipes/pipes-module';

const COMPONENTS = [
  AppRootComponent,
  AppBreadcrumbComponent,
  AppConfigComponent,
  AppFooterComponent,
  AppMenuComponent,
  AppMenuitemComponent,
  AppRightMenuComponent,
  AppTopBarComponent,
  AppInlineMenuComponent  
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FormsModule,
    RouterModule,
    TabViewModule,
    InputTextModule,
    BreadcrumbModule,
    SidebarModule,
    RadioButtonModule,
    InputSwitchModule,
    ButtonModule,
    TooltipModule,
    MegaMenuModule,
    DropdownModule,
    PipesModule,
    PanelModule,
    AccordionModule
  ],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [BreadcrumbService, MenuService, LoadingService]
})
export class NavigationModule { }
