import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { UpdateBrokerAdditionTemplateRequest } from 'src/app/services/fund-brokers/models/requests/update-broker-addition-template-request';
import { UpdateBrokerOperationTemplateRequest } from 'src/app/services/fund-brokers/models/requests/update-broker-operation-template-request';
import { UpdateBrokerRegistrationFormTemplateRequest } from 'src/app/services/fund-brokers/models/requests/update-broker-registration-form-template-request';
import { UpdateBrokerUpdateFormTemplateRequest } from 'src/app/services/fund-brokers/models/requests/update-broker-update-form-template-request';

@Component({
    templateUrl: './fund-broker-edit-form-template.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundBrokerEditFormTemplateComponent implements OnInit {

    requestModelRegistration: UpdateBrokerRegistrationFormTemplateRequest = null;
    requestModelUpdate: UpdateBrokerUpdateFormTemplateRequest = null;
    requestModelAddition: UpdateBrokerAdditionTemplateRequest = null;
    requestModelW8: UpdateBrokerOperationTemplateRequest = null;
    selectedFundBroker: FundBrokerViewModel = null;


    constructor(
        private fundBrokerService: FundBrokerService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setforms();
        await this.getFundBroker();
    }

    setforms() {
        this.requestModelRegistration = {
            fundBrokerId: null,
            registrationFormTemplateRequest: null,
        }

        this.requestModelUpdate = {
            fundBrokerId: null,
            updateFormTemplateRequest: null,
        }

        this.requestModelAddition = {
            fundBrokerId: null,
            additionTemplateRequest: null,
        }

        this.requestModelW8 = {
            fundBrokerId: null,
            operationTemplateRequest: null,
        }
    }

    async getFundBroker() {
        const fundBrokerId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundBrokerId).then(() => {
            this.selectedFundBroker.documentNumber = this.parseCNPJ(this.selectedFundBroker.documentNumber)
        });
    }

    async getFundAdministratorById(fundBrokerId: string) {
        this.selectedFundBroker = await this.fundBrokerService.getById(fundBrokerId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }


    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelRegistration.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }

    updateFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelUpdate.updateFormTemplateRequest = createArtifactTemplateRequest;
    }

    additionFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelAddition.additionTemplateRequest = createArtifactTemplateRequest;
    }

    w8FormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelW8.operationTemplateRequest = createArtifactTemplateRequest;
    }


    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-broker/list`);
    }


    async onSave(formComponent: NgForm) {


        if (!this.formValidationService.validateForm(formComponent.form)) return;

        if (this.requestModelRegistration.registrationFormTemplateRequest == null &&
            this.requestModelUpdate.updateFormTemplateRequest == null &&
            this.requestModelAddition.additionTemplateRequest == null &&
            this.requestModelW8.operationTemplateRequest == null) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `Ao menos um template é obrigatório.`, life: 5000 });
            return;
        }

        try {
            this.loadingService.show();
            if (this.requestModelRegistration.registrationFormTemplateRequest !== null) {
                const requestRegistration: UpdateBrokerRegistrationFormTemplateRequest = {
                    fundBrokerId: this.selectedFundBroker.id,
                    registrationFormTemplateRequest: this.requestModelRegistration.registrationFormTemplateRequest,
                }
                await this.fundBrokerService.updateRegistrationFormTemplate(requestRegistration);
            }
            if (this.requestModelUpdate.updateFormTemplateRequest != null) {
                const requestUpdate: UpdateBrokerUpdateFormTemplateRequest = {
                    fundBrokerId: this.selectedFundBroker.id,
                    updateFormTemplateRequest: this.requestModelUpdate.updateFormTemplateRequest,
                }
                await this.fundBrokerService.updateFormTemplate(requestUpdate)
            }
            if (this.requestModelAddition.additionTemplateRequest !== null) {
                const requestAddition: UpdateBrokerAdditionTemplateRequest = {
                    fundBrokerId: this.selectedFundBroker.id,
                    additionTemplateRequest: this.requestModelAddition.additionTemplateRequest,
                }

                await this.fundBrokerService.additionFormTemplate(requestAddition);
            }
            if (this.requestModelW8.operationTemplateRequest !== null) {
                const requestW8: UpdateBrokerOperationTemplateRequest = {
                    fundBrokerId: this.selectedFundBroker.id,
                    operationTemplateRequest: this.requestModelW8.operationTemplateRequest,
                }

                await this.fundBrokerService.operationFormTemplate(requestW8)

            }
            await this.getFundBroker();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    // updateRegistrationFormTemplate() {
    //     const fundAdministratorId = this.route.snapshot.params.id;
    //     this.router.navigateByUrl(`/app/customer/fund-administrator/edit-form-template/${fundAdministratorId}`)
    // }

}