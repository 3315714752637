import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { ActivateRepresentationRequest } from "./models/requests/activate-representation-request";
import { CreateRepresentationFromIntegrationRequest } from "./models/requests/create-representation-from-integration-request";
import { UpdateRepresentationRequest } from "./models/requests/update-representation-request";
import { RepresentationViewModel } from "./models/view-models/representation-view-model";

@Injectable({
    providedIn: 'root'
})

export class RepresentationService {
    
    constructor(private httpClient: HttpClientService) { }

    async createRepresentation(request: CreateRepresentationFromIntegrationRequest): Promise<RepresentationViewModel> {
        return this.httpClient.post(`/representations`, `/`, request);
    }

    async getAllRepresentations(isActive: boolean): Promise<RepresentationViewModel[]> {
        return this.httpClient.get(`/representations`, `/all/${isActive}`)
    }

    async getRepresentationById(representationId: string): Promise<RepresentationViewModel> {
        return this.httpClient.get(`/representations`, `/${representationId}`);
    }

    async updateRepresentation(request: UpdateRepresentationRequest): Promise<RepresentationViewModel> {
        return this.httpClient.put(`/representations`, `/`, request);
    }

    async deleteRepresentationById(representationId: string): Promise<void> {
        return this.httpClient.delete(`/representations`, `/${representationId}`);
    }

    async activeRepresentation(request: ActivateRepresentationRequest): Promise<RepresentationViewModel> {
        return this.httpClient.put(`/representations`, `/actives`, request);
    }

}