import { Component, Input, OnInit } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { BrokerOperationAssessmentSummaryViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-summary-view-model';
import { BrokerOperationAssessmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-assessment-view-model';
import { BrokerOperationRecipientViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-recipient-view-model';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { BrokerRepresentativeViewModel } from 'src/app/services/brokers/models/view-models/broker-representative-view-model';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { RecipientViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { environment } from 'src/environments/environment';

Calendar.prototype.getDateFormat = () => 'dd/mm/yy';

@Component({
    templateUrl: './assessment-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/datatable.scss', '../../../../assets/layout/customized/custom-card.scss'],
    selector: `app-assessment-list`
})
export class AssessmentListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel;
    @Input() selectedBrokerOperation: BrokerOperationViewModel;
    @Input() assessments: AssessmentSummaryViewModel[] = [];
    @Input() brokerAssessments: BrokerOperationAssessmentSummaryViewModel[] = [];

    hasContext = environment.context;

    constructor(
    ) { }

    ngOnInit() {
    }
}
