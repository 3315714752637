import { NgModule } from '@angular/core';
import { AppLoadingComponent } from './app.loading.component';
import { LoadingService } from './app.loading.service';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [ CommonModule ],
    declarations: [AppLoadingComponent],
    exports: [ AppLoadingComponent ],
    providers: [LoadingService]
})
export class LoadingModule { }
