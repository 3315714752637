import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { FileUploaderModule } from '../fileuploader/fileuploader.module';
import { AdminContactsListComponent } from './admin-contacts-list/admin-contacts-list.component';
import { ManagerContactsListComponent } from './manager-contacts-list/manager-contacts-list.component';


const COMPONENTS = [
  AdminContactsListComponent,
  ManagerContactsListComponent,
];

@NgModule({
  imports: [        
    DirectivesModule,
    PipesModule,
    ButtonModule,
    InputTextModule,
    FileUploaderModule,
    DropdownModule,
    CommonModule,
    ChipsModule,
    FormsModule,
    TableModule,
    CalendarModule
  ],  
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location],
  exports: [AdminContactsListComponent, ManagerContactsListComponent]
})
export class InvestedFundModule { }
