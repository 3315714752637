import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { CancelBrokerOperationRequest } from 'src/app/services/brokers/models/request/cancel-broker-operation-request';
import { GetBrokerOperationsByFilterRequest } from 'src/app/services/brokers/models/request/get-broker-operations-by-filter-request';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { OperationStatusEnum } from '../../../../enums/operation-status-enum';

@Component({
    templateUrl: './broker-operation-consult.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class BrokerOperationConsultComponent implements OnInit {

    request: GetBrokerOperationsByFilterRequest = null;
    operationTypes: DefaultEnumViewModel[] = [];
    operationStatus: SelectItem[] = [];
    funds: FundViewModel[] = [];
    shareholderFunds: SelectItem[] = [];
    shareholderFundsResult: string[] = [];
    shareholderFundsDocumentResult: string[] = [];
    investedFunds: SelectItem[] = [];
    investedFundsResult: string[] = [];
    investedFundsDocumentResult: string[] = [];
    OptionBrokers: SelectItem[] = [];
    fundDistributorDocumentNumbers: SelectItem[] = [];
    operations: BrokerOperationViewModel[] = [];
    operationsExport: any[] = [];
    firstPage: number = 0;

    selectedStatusOperation: SelectItem = null;
    selectedShareholderFundType: string = null;
    selectedInvestedFundType: string = null;
    selectedShareholderFund: string = null;
    selectedShareholderFundDocument: string = null;
    selectedInvestedFund: string = null;
    selectedInvestedFundDocument: string = null;
    cols: any[] = [];

    selectedBrokerOperation: BrokerOperationViewModel = null;
    displayOperationDetail: boolean = false;
    displayOperationCancel: boolean = false;
    displayReasonOperation: boolean = false;
    displayExportFull: boolean = false;
    selectedOperationCancel: BrokerOperationViewModel = null;
    selectedOperationDisplay: BrokerOperationViewModel = null;
    requestOperationCancel = null;
    selectedOperationSituation: string = null;
    user: UserViewModel = null;

    public operationStatusEnum = OperationStatusEnum;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private brokerService: BrokerService,
        private fundService: FundService,
        private contextService: ContextService,
        private fundBrokerService: FundBrokerService,
        private datePipe: DatePipe,
        private router: Router,
        private documentNumberPipe: DocumentNumberPipe,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.setForms();
        this.getOperationStatus();
        this.setFormCancelOperation();
        await this.getOperationTypes();
        await this.getFunds();
        await this.getFundBrokers();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async consult() {

        this.operations = [];
        const request: GetBrokerOperationsByFilterRequest = {
            legacySystemId: this.request.legacySystemId ? this.request.legacySystemId : null,
            brokerOperationSequentialId: this.request.brokerOperationSequentialId ? Number(this.request.brokerOperationSequentialId) : null,
            operationTypeId: this.request.operationTypeId ? Number(this.request.operationTypeId) : null,
            operationStatusId: this.request.operationStatusId ? Number(this.request.operationStatusId) : null,
            brokerId: this.request.brokerId,
            fundId: this.request.fundId,
            fundManagerId: this.request.fundManagerId,
            fundAdministradorId: this.request.fundAdministradorId,
            startDate: this.request.startDate,
            endDate: this.request.endDate,
        }
        try {
            this.loadingService.show();
            this.operations = await this.brokerService.getOperationsByFilter(request);
            this.operationsExport = this.operations.map(operation => {
                return {
                    'ID LIZA': operation?.sequentialId,
                    'ID LEGADO': operation?.legacySystemId,
                    'Data da Entrada': this.datePipe.transform(operation?.createDate, 'dd/MM/yyyy'),
                    'Data Conclusão/Cancelamento': operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída ||
                        operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada ?
                        this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Operação': operation?.type?.friendlyName,
                    'Fundo Cotista': `${operation?.fund?.name}`,
                    'CNPJ F. Cotista': this.parseCNPJ(`${operation?.fund?.documentNumber}`),
                    'SIGLA SAC': `${operation?.frozenFundSAC}`,
                    'CBLC': `${operation?.cblc}`,
                    'Aditamento': `${operation?.addtionNumber}`,
                    'Corretora': `${operation?.broker?.name}`,
                    'CNPJ Corretora': this.parseCNPJ(`${operation?.broker?.documentNumber}`),
                    'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                    'Status': `${operation?.currentStatus?.status?.friendlyName}`,
                    'CCI': operation?.fund?.brokerEntries.length > 0 ? `${operation?.fund?.brokerEntries[0]?.notes}` : '',
                    'Data de Validade': operation?.fund?.brokerEntries.length > 0 ? `${this.datePipe.transform(operation?.fund?.brokerEntries[0]?.expirationDate, 'dd/MM/yyyy')}` : '',
                }
            });
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: `${error.messageError}`, life: 5000 });
        } finally {
            // this.setForms();
            this.loadingService.hide();
        }
    }

    openFullConsult() {
        this.displayExportFull = true;
        this.selectedOperationSituation = null;
    }


    existOperationParams(): boolean {
        if (
            this.request.brokerOperationSequentialId == null &&
            this.request.operationTypeId == null &&
            this.request.operationStatusId == null &&
            this.request.brokerId == null &&
            this.request.fundId == null &&
            this.request.fundManagerId == null &&
            this.request.fundAdministradorId == null &&
            this.request.startDate == null &&
            this.request.endDate == null &&
            this.selectedShareholderFundType == null) {
            return false
        } else {
            return true
        }
    }

    async exportAllSelectedOperation() {
        this.operations = [];
        const request: GetBrokerOperationsByFilterRequest = {
            legacySystemId: null,
            brokerOperationSequentialId: null,
            operationTypeId: null,
            operationStatusId: null,
            brokerId: null,
            fundId: null,
            fundManagerId: null,
            fundAdministradorId: null,
            startDate: null,
            endDate: null,
        }
        try {
            this.loadingService.show();
            this.displayExportFull = false;
            this.operations = await this.brokerService.getOperationsByFilter(request);
            if (this.selectedOperationSituation == 'progress') {
                this.operations = this.operations.filter(fill =>
                    fill.currentStatus.status.value !== 8 &&
                    fill.currentStatus.status.value !== this.operationStatusEnum.Cancelada)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID LIZA': operation.sequentialId,
                        'ID LEGADO': operation?.legacySystemId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Fundo Cotista': `${operation.fund.name}`,
                        'CNPJ F. Cotista': this.parseCNPJ(`${operation.fund.documentNumber}`),
                        'SIGLA SAC': `${operation?.frozenFundSAC}`,
                        'CBLC': `${operation?.cblc}`,
                        'Aditamento': `${operation?.addtionNumber}`,
                        'Corretora': `${operation?.broker?.name}`,
                        'CNPJ Corretora': this.parseCNPJ(`${operation?.broker?.documentNumber}`),
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`,
                        'CCI': operation?.fund?.brokerEntries.length > 0 ? `${operation?.fund?.brokerEntries[0]?.notes}` : '',
                        'Data de Validade': operation?.fund?.brokerEntries.length > 0 ? `${this.datePipe.transform(operation?.fund?.brokerEntries[0]?.expirationDate, 'dd/MM/yyyy')}` : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'completed') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Concluída)
                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID LIZA': operation.sequentialId,
                        'ID LEGADO': operation?.legacySystemId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Fundo Cotista': `${operation.fund.name}`,
                        'CNPJ F. Cotista': this.parseCNPJ(`${operation.fund.documentNumber}`),
                        'SIGLA SAC': `${operation?.frozenFundSAC}`,
                        'CBLC': `${operation?.cblc}`,
                        'Aditamento': `${operation?.addtionNumber}`,
                        'Corretora': `${operation?.broker?.name}`,
                        'CNPJ Corretora': this.parseCNPJ(`${operation?.broker?.documentNumber}`),
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`,
                        'CCI': operation?.fund?.brokerEntries.length > 0 ? `${operation?.fund?.brokerEntries[0]?.notes}` : '',
                        'Data de Validade': operation?.fund?.brokerEntries.length > 0 ? `${this.datePipe.transform(operation?.fund?.brokerEntries[0]?.expirationDate, 'dd/MM/yyyy')}` : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'cancelled') {
                this.operations = this.operations.filter(fill => fill.currentStatus.status.value == this.operationStatusEnum.Cancelada)

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID LIZA': operation.sequentialId,
                        'ID LEGADO': operation?.legacySystemId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Fundo Cotista': `${operation.fund.name}`,
                        'CNPJ F. Cotista': this.parseCNPJ(`${operation.fund.documentNumber}`),
                        'SIGLA SAC': `${operation?.frozenFundSAC}`,
                        'CBLC': `${operation?.cblc}`,
                        'Aditamento': `${operation?.addtionNumber}`,
                        'Corretora': `${operation?.broker?.name}`,
                        'CNPJ Corretora': this.parseCNPJ(`${operation?.broker?.documentNumber}`),
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`,
                        'CCI': operation?.fund?.brokerEntries.length > 0 ? `${operation?.fund?.brokerEntries[0]?.notes}` : '',
                        'Data de Validade': operation?.fund?.brokerEntries.length > 0 ? `${this.datePipe.transform(operation?.fund?.brokerEntries[0]?.expirationDate, 'dd/MM/yyyy')}` : '',
                    }
                });

            } else if (this.selectedOperationSituation == 'all') {

                this.operationsExport = this.operations.map(operation => {
                    return {
                        'ID LIZA': operation.sequentialId,
                        'ID LEGADO': operation?.legacySystemId,
                        'Data da Entrada': this.datePipe.transform(operation.createDate, 'dd/MM/yyyy'),
                        'Data Conclusão/Cancelamento': operation.currentStatus.status.value == OperationStatusEnum.Concluída ||
                            operation.currentStatus.status.value == OperationStatusEnum.Cancelada ?
                            this.datePipe.transform(operation.currentStatus.createDate, 'dd/MM/yyyy') : '',
                        'Operação': operation.type.friendlyName,
                        'Fundo Cotista': `${operation.fund.name}`,
                        'CNPJ F. Cotista': this.parseCNPJ(`${operation.fund.documentNumber}`),
                        'SIGLA SAC': `${operation?.frozenFundSAC}`,
                        'CBLC': `${operation?.cblc}`,
                        'Aditamento': `${operation?.addtionNumber}`,
                        'Corretora': `${operation?.broker?.name}`,
                        'CNPJ Corretora': this.parseCNPJ(`${operation?.broker?.documentNumber}`),
                        'Data status': this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') ? this.datePipe.transform(operation?.currentStatus?.createDate, 'dd/MM/yyyy') : '',
                        'Status': `${operation.currentStatus.status.friendlyName}`,
                        'CCI': operation?.fund?.brokerEntries.length > 0 ? `${operation?.fund?.brokerEntries[0]?.notes}` : '',
                        'Data de Validade': operation?.fund?.brokerEntries.length > 0 ? `${this.datePipe.transform(operation?.fund?.brokerEntries[0]?.expirationDate, 'dd/MM/yyyy')}` : '',
                    }
                });
            }

            this.exportExcel();
            this.reset();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            console.log(error)
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async getOperationTypes() {
        this.operationTypes = await this.brokerService.getBrokerOperationsType();
    }

    async getFunds() {
        this.funds = await this.fundService.getAllFunds(null);

        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);

        shareholderFundsFilter.map(fund => {
            this.shareholderFunds.push({ label: fund.name, value: fund.id });
        });
        this.shareholderFunds = this.shareholderFunds.sort((a, b) => {
            if (a.label > b.label) {
                return 1
            } else {
                return -1
            }
        });
    }

    async getFundBrokers() {
        let isActive = true;
        let brokers = await this.fundBrokerService.getAll(isActive);
        brokers.forEach(broker => {
            this.OptionBrokers.push({ label: broker.name, value: broker.id });
        });
        this.OptionBrokers = this.OptionBrokers.sort((a, b) => {
            if (a.label > b.label) {
                return 1
            } else {
                return -1
            }
        });
    }

    getOperationStatus() {
        this.operationStatus = [
            { label: 'Em Setup', value: OperationStatusEnum.EmSetup },
            { label: 'Preenchimento Manual Necessário', value: OperationStatusEnum.PreenchimentoManualNecessario },
            { label: 'Preenchimento Concluído', value: OperationStatusEnum.PreenchimentoConcluido },
            { label: 'Aguardando Assinatura', value: OperationStatusEnum.AguardandoAssinatura },
            { label: 'Em Análise Pela Corretora', value: OperationStatusEnum.EmAnalisePeloGestor },
            { label: 'Pendenciada Pela Corretora', value: OperationStatusEnum.PendenciadaPeloGestor },
            { label: 'Cancelada', value: OperationStatusEnum.Cancelada },
            { label: 'Concluído', value: OperationStatusEnum.Concluída },
            { label: 'Erro Cadastral', value: OperationStatusEnum.ErroCadastral },
            { label: 'Erro de Preenchimento', value: OperationStatusEnum.ErroDePreenchimento },
        ]
    }

    setForms() {
        this.request = {
            legacySystemId: null,
            brokerOperationSequentialId: null,
            operationTypeId: null,
            operationStatusId: null,
            brokerId: null,
            fundId: null,
            fundManagerId: null,
            fundAdministradorId: null,
            startDate: null,
            endDate: null,
        }
    }

    onClear() {
        this.setForms();
    }

    clearFunds() {
        this.selectedShareholderFund = null;
        this.selectedShareholderFundDocument = null;
    }

    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
        this.displayExportFull = false;
    }

    async operationDetails(operation: OperationViewModel) {
        this.displayOperationDetail = true;
        // this.selectedOperation = operation;
        try {
            // this.loadingService.show();
            const operationId = operation.id;
            this.selectedBrokerOperation = await this.brokerService.getBrokerOperationById(operationId);
            this.selectedBrokerOperation.history.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
            });
            this.selectedBrokerOperation.history = this.selectedBrokerOperation.history.sort((a, b) => {
                if (a.createDate > b.createDate) {
                    return 1;
                } else {
                    return -1;
                }
            });
            // this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationDetail = false;
        }
    }

    async retryOperation(operation: OperationViewModel) {
        try {
            this.loadingService.show();
            const operationId = operation.id;
            await this.brokerService.retriesBrokerOperation(operationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.consult();

            this.loadingService.hide();
        }
    }

    cancelOperation(operation: BrokerOperationViewModel) {
        this.displayOperationCancel = true;
        this.selectedOperationCancel = operation;
    }

    reasonOperation(operation: BrokerOperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedOperationDisplay = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayOperationCancel = false;
            this.loadingService.show();
            const request: CancelBrokerOperationRequest = {
                brokerOperationId: this.selectedOperationCancel.id,
                notes: this.requestOperationCancel.notes,
            };
            this.selectedBrokerOperation = await this.brokerService.cancelBrokerOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelOperation();
            await this.consult();
            this.loadingService.hide();
        }
    }

    getMenuItems(operation): MenuItem[] {
        if (operation?.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral ||
            operation?.currentStatus?.status?.value == OperationStatusEnum.ErroDePreenchimento && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum.Concluída && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.viewOperation(operation) },
                // { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum.Cancelada && !this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
            ]
        } else if (operation?.currentStatus?.status?.value == OperationStatusEnum.EmSetup && !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Iniciar', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido ||
            operation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            !this.isUserOperator) {
            return [
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.viewOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (!this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.viewOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
            ]
        } else if (this.isUserOperator) {
            return [
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) }
            ]
        }

    }


    viewOperation(operation: OperationViewModel) {
        const url = window.location.origin
        window.open(`${url}/#/app/customer/broker-operation/setup/${operation.id}`, '_blank');
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return x ? !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '') : '';
    }

    reset() {
        this.firstPage = 0
    }

    handleTypeFund(event: string) {
        this.request.fundId = null;
    }

    searchInvestedFund(query: string) {
        this.investedFundsResult = [];
        this.investedFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsResult.push(property);
                return
            }
        })
    }

    searchInvestedFundDocument(query: string) {
        this.investedFundsDocumentResult = [];
        let investedFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Investido);
        investedFundsFilter.forEach(item => {
            const property = item.documentNumber;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.investedFundsDocumentResult.push(property);
                return
            }
        })
    }


    searchShareholderFund(query: string) {
        this.shareholderFundsResult = [];
        this.shareholderFunds.forEach(item => {
            const property = item.label;
            if (property.includes(query) ||
                property.toLowerCase().includes(query) ||
                property.toUpperCase().includes(query) ||
                property.toLocaleLowerCase().includes(query) ||
                property.toLocaleUpperCase().includes(query)) {
                this.shareholderFundsResult.push(property);
                return
            }
        })
    }

    searchShareholderFundDocument(query: string) {
        this.shareholderFundsDocumentResult = [];
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.forEach(item => {
            const documentNumber = item.documentNumber;
            if (documentNumber.includes(query) ||
                documentNumber.toLowerCase().includes(query) ||
                documentNumber.toUpperCase().includes(query) ||
                documentNumber.toLocaleLowerCase().includes(query) ||
                documentNumber.toLocaleUpperCase().includes(query) || 
                this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(query)) {
                this.shareholderFundsDocumentResult.push(documentNumber);
                return
            }
        })
    }

    onShareholderFund(search: string) {
        this.shareholderFunds.filter(fill => fill.label == search).forEach(item => {
            this.request.fundId = item.value
        })
    }

    onShareholderFundDocument(search: string) {
        let shareholderFundsFilter = this.funds.filter(fill => fill.type.value == FundTypeEnum.Cotista);
        shareholderFundsFilter.filter(fill => fill.documentNumber == search).forEach(item => {
            this.request.fundId = item.id
        })
    }

    exportExcel() {
        import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(this.operationsExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Operações");
        });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }

    breakTextLine(notes: string) {
        if(notes.includes('/n') || notes.includes('\n\n')) {
            notes = notes.split('\n' || '\n\n').join('<br>');
            return notes;
        }
        return notes;
    }


}