import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MessageService } from "primeng/api";
import { FileUploadService } from "src/app/services/file-uploads/file-upload-service";
import { FileUploadResponse } from "src/app/services/file-uploads/models/responses/file-upload-response";
import { LoadingService } from "../loading/app.loading.service";

@Component({
    selector: 'app-fileuploader',
    templateUrl: 'fileuploader.component.html',
    styleUrls: ['fileuploader.component.scss']
})
export class FileUploaderComponent {

    @Input() isMultipleFiles = false;
    @Input() label = 'Upload de arquivo';
    @Input() chooseLabel = 'Selecionar arquivo';
    @Input()    
    set fileSize(size: number) {
        if(size){
            this.maxFileSize = size;
        }
    };
    @Input()
    set currentFiles(filesResponses: FileUploadResponse[]) {
        this.uploadResponses = filesResponses;
    };

    @Input()
    set isImage(flag: boolean) {
        if (flag) {
            this.acceptType = 'image/*';
        } else {
            this.acceptType = null;
        }
    }

    @Output() onUploadResponse: EventEmitter<FileUploadResponse[]> = new EventEmitter<FileUploadResponse[]>();
    @Output() onItemRemoved: EventEmitter<FileUploadResponse> = new EventEmitter<FileUploadResponse>();
    @Output() onItemAdded: EventEmitter<FileUploadResponse> = new EventEmitter<FileUploadResponse>();

    componentFiles: File[] = [];
    acceptType: string = null;
    maxFileSize = 8388608;
    uploadResponses: FileUploadResponse[] = null;

    constructor(
        private fileUploadService: FileUploadService,
        private loadingService: LoadingService,
        private messageService: MessageService) {
    }

    async uploadFiles(files: File[]) {

        this.loadingService.show();

        if (this.isMultipleFiles) {

            for (let index = 0; index < files.length; index++) {
                const file = files[index];
                const response = await this.fileUploadService.upload(file);
                this.handleUploadResponse(response, file.name);
            }

        } else {
            const response = await this.fileUploadService.upload(files[0]);
            this.handleUploadResponse(response, files[0].name);
        }

        this.componentFiles = [];
        this.loadingService.hide();
        this.onUploadResponse.emit(this.uploadResponses);
    }

    handleUploadResponse(response: FileUploadResponse, fileName: string) {
        if (response.isSuccess) {
            response.fileId = fileName;

            if (this.isMultipleFiles) {
                if (this.uploadResponses == null) {
                    this.uploadResponses = [];
                }
                this.uploadResponses.push(response);
            } else {
                if (this.uploadResponses != null) {
                    this.onItemRemoved.emit(this.uploadResponses[0]);
                }
                this.uploadResponses = [response];
            }

        } else {
            this.messageService.add({ severity: 'error', summary: 'Upload de arquivo', detail: response.message, life: 4000 });
        }
        this.onItemAdded.emit(response);
    }

    fileUploadedRemoved(item: FileUploadResponse) {
        const itemIndex = this.uploadResponses.findIndex(c => c.fileId == item.fileId);
        if (itemIndex > -1) {
            this.uploadResponses.splice(itemIndex, 1);
            if (this.uploadResponses.length == 0) {
                this.uploadResponses = null;
            }
            this.onItemRemoved.emit(item);
            this.onUploadResponse.emit(this.uploadResponses);
        }
    }
}