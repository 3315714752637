import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ContextService } from './context-service';
import { SiteMapItem } from './models/sitemap-item';

@Injectable({
    providedIn: 'root'
})
export class RouterValidationService implements CanActivate {

    constructor(
        private router: Router,
        private contextService: ContextService,
        private messageService: MessageService
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

        if (!this.contextService.isLoggedIn()) {
            console.error('RouterValidationService.canActivate() -> route not allowed, No user logged in');
            this.messageService.add({ severity: 'error', summary: 'Token Expirado', detail: 'O seu token expirou, faça o login novamente', life: 5000 });
            this.router.navigate(['login']);
            return false;
        }

        const desiredRoute = state.url
            .replace('/', '')
            .replace(/\b\/[a-f\d-]{36}\b/, '');

        console.log('RouterValidationService.canActivate() - desiredRoute', desiredRoute);

        const allowedLoggedInRoutes = ['app/my-profile', 'app'];

        if (allowedLoggedInRoutes.includes(desiredRoute)) {
            console.log('RouterValidationService.canActivate() -> route allowed for loggedin user');
            return true;
        }

        const userSiteMap = this.contextService.getUserSitemap();

        if (userSiteMap == null || userSiteMap.length == 0) {
            console.error('RouterValidationService.canActivate() -> user site map is null or empty');
            this.router.navigateByUrl('access-denied');
            return false;
        }

        const userSiteMapItems: SiteMapItem[] = [];

        userSiteMap.forEach(element => {
            element.items.forEach(item => {
                item.route = `app${item.route}`
                userSiteMapItems.push(item);
            });
        });

        // Verify if user Sitemap has the desired route, if yes it is allowed
        //Mudar Aqui
        return true;
        /*
        if (userSiteMapItems.find(c => c.route == desiredRoute)) {
            console.log('RouterValidationService.canActivate() -> route allowed');
            return true;
        } else {
            console.error('RouterValidationService.canActivate() -> route not allowed');
            this.router.navigateByUrl('access-denied');
            return false;
        }*/
    }
}
