<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Gestoras
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p-table [responsive]="true" #dt [value]="fundManagers" dataKey="id"
                        styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                        [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                        [globalFilterFields]="['name','documentNumber']">
                        <ng-template pTemplate="caption">
                            <div class="contentButtonActions p-fluid">
                                <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search iconPosition"></i>
                                        <input pInputText type="text" [(ngModel)]="search" class="searchGlobalFilter"
                                            (input)="globalFilter(search)"
                                            placeholder="Filtrar" />
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2 p-fluid" [hidden]="isUserOperator">
                                    <button pButton type="button" label="Nova Gestora" (click)="addManager()"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="name">
                                    <div>
                                        Nome da Gestora
                                        <p-sortIcon field="name"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="documentNumber">
                                    <div>
                                        CNPJ
                                        <p-sortIcon field="documentNumber"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="phoneNumber">
                                    <div>
                                        Telefone
                                        <p-sortIcon field="phoneNumber"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="isIntegrationEnabled">
                                    <div>
                                        Integracão Habilitada
                                        <p-sortIcon field="isIntegrationEnabled"></p-sortIcon>
                                    </div>
                                </th>
                                <th>Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-manager let-index>
                            <tr style="cursor: pointer" [pSelectableRow]="manager">
                                <td>
                                    {{manager.name}}
                                </td>
                                <td>
                                    {{manager.documentNumber | documentNumber: 'cnpj' }}
                                </td>
                                <td>
                                    {{manager.phoneNumber | phoneNumber}}
                                </td>
                                <td>
                                    {{manager.isIntegrationEnabled ? 'Sim' : 'Não'}}
                                </td>
                                <td>
                                    <p-menu #menu [popup]="true" [model]="getMenuItems(manager)"></p-menu>
                                    <button type="button" pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text"
                                        (click)="menu.toggle($event)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Nenhum usuário encontrado.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>

    </div>
</div>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<p-dialog header="Informações" [(visible)]="displayCredential" [modal]="true" [responsive]="true"
    [style]="{width: '25vw'}">
    <div class="p-grid">
        <div class="p-col-12">
            <p><b>Client ID:</b> {{credentialIntegration?.clientId}}</p>
            <p><b>Client Secret:</b> {{credentialIntegration?.clientSecret}}</p>
        </div>
    </div>
</p-dialog>