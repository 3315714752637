import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import {TableModule} from 'primeng/table';
import { BrokerContactsListComponent } from './broker-contacts-list/broker-contacts-list.component';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { FileUploaderModule } from '../fileuploader/fileuploader.module';
import { DropdownModule } from 'primeng/dropdown';
import { BrokerContactsCreateComponent } from './broker-contacts-create/broker-contacts-create.component';


const COMPONENTS = [
  BrokerContactsListComponent,
  BrokerContactsCreateComponent
];

@NgModule({
  imports: [        
    DirectivesModule,
    PipesModule,
    ButtonModule,
    InputTextModule,
    FileUploaderModule,
    DropdownModule,
    CommonModule,
    ChipsModule,
    FormsModule,
    TableModule,
    CalendarModule
  ],  
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location],
  exports: [BrokerContactsListComponent, BrokerContactsCreateComponent]
})
export class ContactBrokerModule { }
