<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Visualizar Administrador
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined" (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Editar" class="p-button-primary" (click)="editFundAdministrator()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8 ">
                    <div class="borderOutlined heightFix100">
                        <p><b class="text-bold">Nome:</b> <span> {{selectedFundAdministrator?.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{selectedFundAdministrator?.documentNumber }}</span></p>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="selectedFundAdministrator?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="selectedFundAdministrator?.registrationFormTemplate?.fileUrl" target="_blank" class="linkPrimary">
                            Ver PDF
                        </a>
                    </div>
                    <div class="borderOutlined contentCenterColumn heightFix100" *ngIf="!selectedFundAdministrator?.registrationFormTemplate">
                        <div class="text-bold">
                           Modelo Ficha Cadastral
                        </div>
                        <a class="linkPrimary" (click)="updateRegistrationFormTemplate()">
                            Cadastrar
                        </a>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Procuradores / Rep Legal</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">CPF</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">E-mail</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Data de Validade</p>
                                </div>
                            </div>
                            <form #managerForm="ngForm" novalidate>
                                <div class="p-grid" *ngFor="let representative of allRepresentatives">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.name}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.maskedPersonalDocumentNumber}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.email}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.expireDate | date: 'dd/MM/yyyy' }}
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="line"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- <form #adminForm="ngForm" novalidate>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="fullName" type="text" ngModel={{request?.fullName}} name="fullName"
                                            #fullName="ngModel" class="inputLiza" required pInputText>
                                        <label for="fullName">Nome</label>
                                    </span>
                                </div>
                                <mark *ngIf="fullName.invalid && fullName.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <input id="email" type="text" ngModel={{request?.email}} name="email"
                                            #email="ngModel" class="inputLiza" required appValidateEmail pInputText>
                                        <label for="email">E-mail</label>
                                    </span>
                                </div>
                                <mark *ngIf="email.invalid && email.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>
                            <div class="p-col-12 p-md-3 p-lg-3">
                                <div class="p-inputgroup">
                                    <span class="p-float-label">
                                        <p-calendar [(ngModel)]="requestValidateDate" name="validateDate"
                                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                            [yearNavigator]="true" #validateDate="ngModel" [required]="true">
                                        </p-calendar>
                                        <label for="validateDate">Data de Validade</label>
                                    </span>
                                </div>
                                <mark *ngIf="validateDate.invalid && validateDate.dirty"
                                    class="ui-message ui-messages-error ui-corner-all">Campo
                                    obrigatório</mark>
                            </div>

                            <div class="p-col-1 p-md-1 p-lg-1">
                                <button pButton pRipple type="button" icon="pi pi-plus"
                                    class="p-button-rounded p-button-outlined"
                                    (click)="addRepresentations(adminForm)"></button>
                            </div>
                        </div>

                        <div *ngIf="requestRepresentations">
                            <div *ngFor="let rep of requestRepresentations" class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.fullName}}
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.email}}
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    {{ rep?.validateDate | date: 'dd/MM/yyyy' }}
                                </div>
                                <div class="p-col-1 p-md-1 p-lg-1">
                                    <button pButton pRipple type="button" icon="pi pi-trash"
                                        class="p-button-rounded p-button-outlined-blue" (click)="removeRepresentation(rep)"></button>
                                </div>
                                <div class="p-col-12 p-md-12 p-lg-10">
                                    <p-divider></p-divider>
                                </div>
                            </div>
                        </div>

                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                                    (click)="onCancel()"></button>
                                <button pButton pRipple type="button" label="Convidar" class="action-button p-ml-2"
                                    (click)="onSave(adminForm)"></button>
                            </div>
                        </div>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</div>