import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { PagedSearchResult } from 'src/app/providers/models/paged-search-result';
import { BrokerService } from 'src/app/services/brokers/broker-service';
import { CancelBrokerOperationRequest } from 'src/app/services/brokers/models/request/cancel-broker-operation-request';
import { GetBrokerOperationsByFilterRequest } from 'src/app/services/brokers/models/request/get-broker-operations-by-filter-request';
import { UpdateBrokerOperationLegacySystemIdRequest } from 'src/app/services/brokers/models/request/update-broker-operation-legacy-system-id-request';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { BrokerOperationViewModel } from '../../../../services/brokers/models/view-models/broker-operation-view-model';


@Component({
    templateUrl: './broker-operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class BrokerOperationListComponent implements OnInit {

    requestBrokerOperationCancel = null;
    brokerOperationsOnGoingResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsOnGoing: BrokerOperationViewModel[] = [];

    brokerOperationsFinishedResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsFinished: BrokerOperationViewModel[] = [];

    brokerOperationsCanceledResult: PagedSearchResult<BrokerOperationViewModel>;
    brokerOperationsCanceled: BrokerOperationViewModel[] = [];

    displayBrokerOperationDetail: boolean = false;
    displayBrokerOperationInit: boolean = false;
    displayBrokerOperationCancel: boolean = false;
    displayReasonBrokerOperation: boolean = false;

    selectedBrokerOperation: BrokerOperationViewModel = null;
    selectedBrokerOperationCancel: BrokerOperationViewModel = null;
    selectedBrokerOperationDisplay: BrokerOperationViewModel = null;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    displayCancelBrokerOperations: boolean = false;
    selectedNoteCancelBrokerOperations: string = null;
    selectedCancelBrokerOperation: string = null;
    selectedCancelBrokerOperations: SelectItem[] = [];
    displayLegacyId: boolean = false;
    selectedBrokerOperationLegacyId: BrokerOperationViewModel = null;
    brokerOperationlegacyId: string = null;
    menuItems: MenuItem[] = [];

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private brokerService: BrokerService,
        private contextService: ContextService,
        private messageService: MessageService) {
    }


    setFormCancelBrokerOperation() {
        this.requestBrokerOperationCancel = {
            notes: null,
        }
    }

    async ngOnInit() {
        this.setFormCancelBrokerOperation();
        this.loadingService.show();
        await this.verifyUserPermissions();

        //await this.getOperationsOnGoingData();

        if (this.route.snapshot.queryParams.tab) {
            await this.changeTab(this.route.snapshot.queryParams.tab)
        }
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async getBrokerOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsOnGoingData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.brokerOperationsOnGoingResult = await this.brokerService.getBrokerOperationsOnGoingSearch(pagedSearchRequest);
        this.brokerOperationsOnGoing = this.brokerOperationsOnGoingResult.searchResult;

        this.brokerOperationsOnGoing.forEach(it => {
            if (it.frozenFundSAC == null) {
                it.frozenFundSAC = "-"
            }
        })

        this.brokerOperationsOnGoing = this.brokerOperationsOnGoing.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsOnGoingData()', 'operationsOnGoingResult', this.brokerOperationsOnGoingResult);

    }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsFinishedData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.brokerOperationsFinishedResult = await this.brokerService.getBrokerOperationsFinishedPaged(pagedSearchRequest);
        this.brokerOperationsFinished = this.brokerOperationsFinishedResult.searchResult;

        this.brokerOperationsFinished = this.brokerOperationsFinished.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsFinishedData()', 'operationsFinishedResult', this.brokerOperationsFinishedResult);

    }

    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsCanceledData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.brokerOperationsCanceledResult = await this.brokerService.getBrokerOperationsCanceledSearch(pagedSearchRequest);
        this.brokerOperationsCanceled = this.brokerOperationsCanceledResult.searchResult;

        this.brokerOperationsCanceled = this.brokerOperationsCanceled.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsCanceledData()', 'operationsCanceledResult', this.brokerOperationsCanceledResult);

    }

    async changeTab(index: number) {
        this.loadingService.show();
        this.tabView = index;
        if (index == 0) {
            this.getBrokerOperationsOnGoingData(null);
        } else if (index == 1) {
            this.getOperationsFinishedData(null);
        } else if (index == 2) {
            this.getOperationsCanceledData(null);
        }
        this.loadingService.hide();
    }

    close() {
        this.displayBrokerOperationDetail = false;
        this.displayBrokerOperationInit = false;
        this.displayBrokerOperationCancel = false;
        this.displayReasonBrokerOperation = false;
    }


    async operationDetails(operation: BrokerOperationViewModel) {
        console.log(operation)
        this.displayBrokerOperationDetail = true;
        try {
            const operationId = operation.id;
            this.selectedBrokerOperation = await this.brokerService.getBrokerOperationById(operationId);
            this.selectedBrokerOperation.history.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
            });
            this.selectedBrokerOperation.history = this.selectedBrokerOperation.history.sort((a, b) => {
                if (a.createDate > b.createDate) {
                    return 1;
                } else {
                    return -1;
                }
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayBrokerOperationDetail = false;
        } finally {
        }
    }

    operationErrorInit(operation: BrokerOperationViewModel) {
        this.selectedBrokerOperation = operation;
    }

    operationSetup(operation) {
        if (operation.currentStatus.status.displayName == 'ErroCadastral') {
            this.displayBrokerOperationInit = true;
            this.operationErrorInit(operation);
            return;
        }
        return this.router.navigateByUrl(`app/customer/broker-operation/setup/${operation.id}`);
    }

    async retryOperation(brokerOperation: BrokerOperationViewModel) {
        try {
            this.loadingService.show();
            const brokerOperationId = brokerOperation.id;
            await this.brokerService.retriesBrokerOperation(brokerOperationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.getBrokerOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    reasonOperation(operation: BrokerOperationViewModel) {
        this.displayReasonBrokerOperation = true;
        this.selectedBrokerOperationDisplay = operation;
    }

    openDownloadFile(operation: BrokerOperationViewModel) {
        window.open(operation.sourceFileUrl, "_blank");
    }

    getMenuItems(operation: BrokerOperationViewModel): MenuItem[] {
        this.menuItems = [];
        if (operation.sourceFileUrl !== null) {
            this.menuItems.push(
                { label: 'Download do Arquivo', command: e => this.openDownloadFile(operation) },
            )
        }
        if (operation.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral ||
            operation.currentStatus?.status?.value == OperationStatusEnum.ErroDePreenchimento &&
            this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Concluída &&
            this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.Cancelada &&
            this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Ver', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.EmSetup &&
            this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Iniciar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido ||
            operation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            operation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Atualizar Setup', command: e => this.retryOperation(operation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Motivo', command: e => this.reasonOperation(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (this.user.userRoles.filter(c => c.role.value !== RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
                { label: 'Continuar', command: e => this.operationSetup(operation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(operation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(operation) },
            )
            return this.menuItems;
        } else if (this.user.userRoles.filter(c => c.role.value == RoleOptionEnum.CustomerBrokerOperator)) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(operation) },
            )
            return this.menuItems;
        }

    }

    cancelOperation(operation: BrokerOperationViewModel) {
        this.displayBrokerOperationCancel = true;
        this.selectedBrokerOperationCancel = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayBrokerOperationCancel = false;
            this.loadingService.show();
            const request: CancelBrokerOperationRequest = {
                brokerOperationId: this.selectedBrokerOperationCancel.id,
                notes: this.requestBrokerOperationCancel.notes,
            };
            this.selectedBrokerOperation = await this.brokerService.cancelBrokerOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelBrokerOperation();
            await this.getBrokerOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    openCancelBrokerOperations() {
        this.selectedCancelBrokerOperations = [];
        this.displayCancelBrokerOperations = true;
    }

    async handleSaveCancelBrokerOperations() {
        if (this.selectedNoteCancelBrokerOperations == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `Informe um motivo`,
                life: 5000
            });
            return;
        }
        this.displayCancelBrokerOperations = false;
        this.loadingService.show();
        const uniqueId = new Set();
        const cancelOperations = this.selectedCancelBrokerOperations.filter(obj => {
            if (uniqueId.has(obj.value)) {
                return false;
            }
            uniqueId.add(obj.value);
            return true;
        });
        for (let item of cancelOperations) {
            try {
                this.displayBrokerOperationCancel = false;
                this.loadingService.show();
                const request: CancelBrokerOperationRequest = {
                    brokerOperationId: item.value,
                    notes: this.selectedNoteCancelBrokerOperations
                };
                this.selectedBrokerOperation = await this.brokerService.cancelBrokerOperation(request);
                this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
                this.selectedCancelBrokerOperations = [];
                this.selectedCancelBrokerOperation = null;
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        }
        this.loadingService.hide();
        this.getBrokerOperationsOnGoingData(null);
    }

    async handleSearchBrokerOperation() {
        const request: GetBrokerOperationsByFilterRequest = {
            legacySystemId: null,
            brokerOperationSequentialId: Number(this.selectedCancelBrokerOperation),
            operationTypeId: null,
            operationStatusId: null,
            brokerId: null,
            fundId: null,
            fundManagerId: null,
            fundAdministradorId: null,
            startDate: null,
            endDate: null,
        }
        const response = await this.brokerService.getOperationsByFilter(request);
        if (response.length == 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Houve um erro',
                detail: `ID da operação não existe!`,
                life: 5000
            });
            return;
        }
        response.forEach(c => {
            if (c.currentStatus.status.value == OperationStatusEnum.Cancelada || c.currentStatus.status.value == OperationStatusEnum.Concluída) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Houve um erro',
                    detail: `A operação já está com o status ${c.currentStatus.status.friendlyName}`,
                    life: 5000
                });
                return;
            }
            this.selectedCancelBrokerOperations.push({
                label: `ID LIZA: ${c.sequentialId} - Operação: ${c.type.friendlyName} - Status: ${c.currentStatus.status.friendlyName}`,
                value: c.id
            });
        });
        this.selectedCancelBrokerOperation = null;
    }

    removeSelectedCancelBrokerOperation(operation: SelectItem) {
        const indexBrokerOperation = this.selectedCancelBrokerOperations.findIndex(c => c.value == operation.value);
        this.selectedCancelBrokerOperations.splice(indexBrokerOperation, 1);
    }

    handleEnterKeyPress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            this.handleSearchBrokerOperation();
        }
    }

    breakTextLine(notes: string) {
        if (notes.includes('/n') || notes.includes('\n\n')) {
            notes = notes.split('\n' || '\n\n').join('<br>');
            return notes;
        }
        return notes;
    }

    openEditLegacyId(brokerOperation: BrokerOperationViewModel) {
        this.displayLegacyId = true;
        this.selectedBrokerOperationLegacyId = brokerOperation;
    }

    async onSaveLegacyId() {
        if (this.brokerOperationlegacyId == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `O campo ID Legado é obrigatório!`,
                life: 5000
            });
            return;
        }
        const request: UpdateBrokerOperationLegacySystemIdRequest = {
            brokerOperationId: this.selectedBrokerOperationLegacyId.id,
            legacySystemId: this.brokerOperationlegacyId,
        };
        this.displayLegacyId = false;
        this.loadingService.show();
        try {
            await this.brokerService.updateSystemLegacyId(request);
            this.messageService.add({
                severity: 'success',
                summary: 'Alteração efetuada com sucesso',
                detail: `Os dados foram atualizados com sucesso!`,
                life: 5000
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.getBrokerOperationsOnGoingData(null);
        }
    }


}