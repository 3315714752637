<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Fila de Integrações
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <p-table [responsive]="true" #dt [value]="integrationFiles" dataKey="id"
                        styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                        [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                        [globalFilterFields]="['type.friendlyName']">
                        <ng-template pTemplate="caption">
                            <div class="contentButtonActions p-fluid">
                                <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search iconPosition"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Filtrar" />
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                    <button pButton type="button" label="Enviar Novo Arquivo"
                                        (click)="addIntegration()"></button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="createDate">
                                    <div>
                                        Data da Criação
                                        <p-sortIcon field="createDate"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="type.friendlyName">
                                    <div>
                                        Tipo
                                        <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="status.friendlyName">
                                    <div>
                                        Status
                                        <p-sortIcon field="status.friendlyName"></p-sortIcon>
                                    </div>
                                </th>
                                <th>Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-integration let-index>
                            <tr style="cursor: pointer" [pSelectableRow]="integration">
                                <td>
                                    {{integration.createDate | date: 'dd/MM/yyyy'}}
                                </td>
                                <td>
                                    {{integration.type.friendlyName}}
                                </td>
                                <td>
                                    {{integration.status.friendlyName}}
                                </td>
                                <td>
                                    <p-menu #menu [popup]="true" [model]="getMenuItems(integration)"></p-menu>
                                    <div>
                                        <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                            class="p-button-text" (click)="menu.toggle($event)"></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Nenhum registro encontrado.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>

    </div>
</div>

<p-dialog header="Detalhes do erro" [(visible)]="displayErrorDetails" [modal]="true" [responsive]="true"
    [style]="{width: '50vw'}">
    <p-scrollPanel [style]="{width: '100%', height: '300px'}" *ngIf="displayErrorDetails">
        <div class="p-grid" *ngIf="displayErrorDetails">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <p class="text-bold lineHeight" >{{notes}}</p>
            </div>
        </div>
    </p-scrollPanel>

</p-dialog>