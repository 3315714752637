import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { PagedSearchRequest } from "src/app/providers/models/paged-search-request";
import { PagedSearchResult } from "src/app/providers/models/paged-search-result";
import { GetDoneOperationsTimeframeByDateRangeResponse } from "../operations/models/responses/get-done-operations-timeframe-by-date-range-response";
import { GetFinishedOperationsByDateRangeResponse } from "../operations/models/responses/get-finished-operations-by-date-range-response";
import { GetFinishedOperationsYearlyReportResponse } from "../operations/models/responses/get-finished-operations-yearly-report-response";
import { AddBrokerOperationAttachmentRequest } from "./models/request/add-broker-operation-attachment-request";
import { AddBrokerOperationFundRegulationAsAttachmentRequest } from "./models/request/add-broker-operation-fund-regulation-as-attachment-request";
import { AddBrokerOperationRecipientsRequest } from "./models/request/add-broker-operation-recipients-request";
import { AddBrokerOperationSignersRequest } from "./models/request/add-broker-operation-signers-request";
import { AddBrokerSignersRequest } from "./models/request/add-broker-signers-request";
import { AssessBrokerOperationByManagerRequest } from "./models/request/assess-broker-operation-by-manager-request";
import { CancelBrokerOperationRequest } from "./models/request/cancel-broker-operation-request";
import { DismissBrokerOperationAssessmentRequest } from "./models/request/dismiss-broker-operation-assessment-request";
import { FillBrokerOperationArtifactRequest } from "./models/request/fill-broker-pperation-artifact-request";
import { GetBrokerOperationsByFilterRequest } from "./models/request/get-broker-operations-by-filter-request";
import { GetBrokerOperationsPendingToExportRequest } from "./models/request/get-broker-operations-pending-to-export-request";
import { ReplyBrokerOperationAssessmentRequest } from "./models/request/reply-broker-operation-assessment-request";
import { ResendBrokerRepresentativeNotificationRequest } from "./models/request/resend-broker-representative-notification-request";
import { SendToBrokerOperationSignatureRequest } from "./models/request/send-to-broker-operation-signature-request";
import { SetBrokerOperationSetupAsDoneRequest } from "./models/request/set-broker-operation-setup-as-done-request";
import { UpdateBrokerOperationLegacySystemIdRequest } from "./models/request/update-broker-operation-legacy-system-id-request";
import { GetBrokerOperationsPendingSummaryResponse } from "./models/responses/get-broker-operations-pending-summary-response";
import { BrokerOperationArtifactViewModel } from "./models/view-models/broker-operation-artifact-view-model";
import { BrokerOperationAssessmentSummaryViewModel } from "./models/view-models/broker-operation-assessment-summary-view-model";
import { BrokerOperationRecipientViewModel } from "./models/view-models/broker-operation-recipient-view-model";
import { BrokerOperationViewModel } from "./models/view-models/broker-operation-view-model";

@Injectable({
    providedIn: 'root'
})

export class BrokerService {
    constructor(private httpClient: HttpClientService) { }

    async getBrokerOperationById(operationId: string): Promise<BrokerOperationViewModel> {
        return this.httpClient.get(`/broker-operations`, `/${operationId}`);
    }

    async getAllBrokerOperations(): Promise<BrokerOperationViewModel[]> {
        return this.httpClient.get(`/broker-operations`, `/on-going`, true);
    }

    async getAllBrokerOperationsFinished(): Promise<BrokerOperationViewModel[]> {
        return this.httpClient.get(`/broker-operations`, `/finished`, true);
    }

    async getBrokerOperationsOnGoingSearch(request: PagedSearchRequest): Promise<PagedSearchResult<BrokerOperationViewModel>> {
        return this.httpClient.post(`/broker-operations`, `/on-going/search`, request, true);
    }

    async getBrokerOperationsFinishedPaged(request: PagedSearchRequest): Promise<PagedSearchResult<BrokerOperationViewModel>> {
        return this.httpClient.post(`/broker-operations`, `/finished/search`, request, true);
    }

    async getBrokerOperationsCanceledSearch(request: PagedSearchRequest): Promise<PagedSearchResult<BrokerOperationViewModel>> {
        return this.httpClient.post(`/broker-operations`, `/canceled/search`, request, true);
    }

    async getBrokerOperationsType(): Promise<DefaultEnumViewModel[]> {
        return this.httpClient.get(`/broker-operations`, `/types`, true);
    }

    async resendEmailAllPendings(): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/recipients/assessments/resend-emails/all-pendings`, {})
    }

    async brokerOperationAttachments(request: AddBrokerOperationAttachmentRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/attachments`, request);
    }

    async brokerOperationAttachmentsOnFundRegulation(request: AddBrokerOperationFundRegulationAsAttachmentRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/attachments/fund-regulations`, request);
    }

    async deleteAttachment(brokerOperationAttachmentId: string): Promise<void> {
        return this.httpClient.delete(`/broker-operations`, `/attachments/${brokerOperationAttachmentId}`);
    }

    async retriesBrokerOperation(brokerOperationId: string): Promise<BrokerOperationViewModel> {
        try {
            const response = await this.httpClient.path(`/broker-operations`, `/${brokerOperationId}/retries`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async cancelBrokerOperation(request: CancelBrokerOperationRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/cancelation`, request);
    }

    async operationSigners(request: AddBrokerOperationSignersRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/signers`, request);
    }

    async operationBrokerSigners(request: AddBrokerSignersRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/broker-signers`, request);
    }

    async deleteBrokerSigner(brokerOperationId: string, signerId: string): Promise<void> {
        return this.httpClient.delete(`/broker-operations`, `/${brokerOperationId}/broker-signers/${signerId}`);
    }

    async operationBrokerRecipients(request: AddBrokerOperationRecipientsRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/recipients`, request);
    }

    async deleteBrokerRecipients(brokerOperationRecipientId: string): Promise<void> {
        return this.httpClient.delete(`/broker-operations`, `/recipients/${brokerOperationRecipientId}`);
    }

    async setupDone(request: SetBrokerOperationSetupAsDoneRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/setup-done`, request);
    }

    async changeSingatures(brokerOperationArtifactId: string): Promise<BrokerOperationArtifactViewModel> {
        return this.httpClient.path(`/broker-operations`, `/artifacts/${brokerOperationArtifactId}/signatures`, true);
    }

    async reprocessArtifact(brokerOperationArtifactId: string): Promise<BrokerOperationArtifactViewModel> {
        return this.httpClient.path(`/broker-operations`, `/artifacts/${brokerOperationArtifactId}/reprocesses`, true);
    }

    async signatureReady(request: SendToBrokerOperationSignatureRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/signature-ready`, request, true);
    }

    async updateArtifacts(request: FillBrokerOperationArtifactRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/artifacts`, request);
    }

    async resendMailAssesment(request: ResendBrokerRepresentativeNotificationRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/recipients/assessments/resend-emails`, request);
    }

    async getBrokerOperationAssessmentsByRepresentative(brokerOperationId: string, representativeId: string): Promise<BrokerOperationRecipientViewModel> {
        return this.httpClient.get(`/broker-operations`, `/${brokerOperationId}/representatives/${representativeId}/assessments`);
    }

    async getBrokerOperationAllAssessments(brokerOperationId: string): Promise<BrokerOperationAssessmentSummaryViewModel[]> {
        return this.httpClient.get(`/broker-operations`, `/${brokerOperationId}/representatives/assessments`);
    }

    async createAssessment(request: AssessBrokerOperationByManagerRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.post(`/broker-operations`, `/recipients/assessments`, request);
    }

    async deleteRecipients(brokerOperationRecipientId: string): Promise<void> {
        return this.httpClient.delete(`/broker-operations`, `/recipients/${brokerOperationRecipientId}`);
    }

    async replyAssesment(request: ReplyBrokerOperationAssessmentRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.post(`/broker-operations`, `/recipients/assessments/replies`, request);
    }

    async dismissAssessment(request: DismissBrokerOperationAssessmentRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.post(`/broker-operations`, `/recipients/assessments/dismiss`, request);
    }

    async getOperationsByFilter(request: GetBrokerOperationsByFilterRequest): Promise<BrokerOperationViewModel[]> {
        return this.httpClient.put(`/broker-operations`, `/reports/filter`, request);
    }

    async chargesReport(year: number, month: number): Promise<BrokerOperationViewModel[]> {
        return this.httpClient.get(`/broker-operations`, `/reports/charges/year/${year}/month/${month}`);
    }

    async finishedReportTimeFrames(startDate: string, endDate: string, brokerOperationTypeId: string | null): Promise<GetDoneOperationsTimeframeByDateRangeResponse[]> {
        return this.httpClient.get(`/broker-operations`, `/reports/finisheds/type/${brokerOperationTypeId}/start/${startDate}/end/${endDate}/timeframes`);
    }

    async finishedReportYearly(year: string): Promise<GetFinishedOperationsYearlyReportResponse[]> {
        return this.httpClient.get(`/broker-operations`, `/reports/finisheds/yearly/${year}`);
    }

    async finishedReportDates(startDate: string, endDate: string): Promise<GetFinishedOperationsByDateRangeResponse> {
        return this.httpClient.get(`/broker-operations`, `/reports/finisheds/start/${startDate}/end/${endDate}`);
    }

    async getReportFullBrokerOperations(request: GetBrokerOperationsPendingToExportRequest): Promise<GetBrokerOperationsPendingSummaryResponse[]> {
        return this.httpClient.put(`/broker-operations`, `/reports/pendings`, request);
    }

    async updateSystemLegacyId(request: UpdateBrokerOperationLegacySystemIdRequest): Promise<BrokerOperationViewModel> {
        return this.httpClient.put(`/broker-operations`, `/system-legacy-ids`, request);
    }
}
