import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LizaRoutingModule } from './liza.routing.module';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ListboxModule } from 'primeng/listbox';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { TimelineModule } from 'primeng/timeline';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { FileUploadModule } from "primeng/fileupload";
import { AddressFormModule } from 'src/app/modules/address-form/address-form.module';
import { TabViewModule } from 'primeng/tabview';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { FileUploaderModule } from 'src/app/modules/fileuploader/fileuploader.module';
import { LizaUserListComponent } from './admin/user/liza-user-list/liza-user-list.component';
import { MenuModule } from 'primeng/menu';
import { ChipsModule } from 'primeng/chips';
import { InputSwitchModule } from 'primeng/inputswitch';
import { LizaHomeComponent } from './home/liza-home.component';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AuditListComponent } from './audit/audit-list/audit-list.component';


const COMPONENTS = [
  LizaUserListComponent,
  LizaHomeComponent,
  AuditListComponent
];

@NgModule({
  imports: [
  LizaRoutingModule,
    CommonModule,
    FormsModule,
    DirectivesModule,
    PipesModule,
    InputTextModule,
    CalendarModule,
    InputMaskModule,
    DropdownModule,
    MultiSelectModule,
    ListboxModule,
    ToggleButtonModule,
    TableModule,
    TooltipModule,
    SliderModule,
    DividerModule,
    InputTextareaModule,
    PasswordModule,
    AddressFormModule,
    TabViewModule,
    FileUploadModule,
    FileUploaderModule,
    SelectButtonModule,
    TimelineModule,
    InputNumberModule,    
    MenuModule,
    ChipsModule,
    InputSwitchModule,
    CalendarModule,    
    DialogModule,
    RadioButtonModule    
  ],
  declarations: COMPONENTS,
  entryComponents: COMPONENTS
})
export class LizaModule { }
