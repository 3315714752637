import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { AdministratorSignerViewModel } from 'src/app/services/fund-administrators/models/view-models/administrator-signer-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { RepresentativeViewModel } from '../../../services/fund-administrators/models/view-models/representative-view-model';

@Component({
    templateUrl: './admin-contacts-list.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-fund-admin-contacts-list',
})
export class AdminContactsListComponent implements OnInit, AfterViewInit {

    @Input() administratorSigners: AdministratorSignerViewModel[];
    @Input() adminContacts: RepresentativeViewModel[];
    @Input() representativesAdmin: AdministratorSignerViewModel[];
    @Input() typeList: string | `Select`;
    selecetedAdminContact: RepresentativeViewModel[] = [];

    @Output() checkboxAdminToggleEmitter = new EventEmitter<RepresentativeViewModel>();
    @Output() unCheckboxAdminToggleEmitter = new EventEmitter<RepresentativeViewModel>();
    @Output() onHeaderAdminCheckboxToggledEmitter = new EventEmitter<boolean>();

    constructor(
        private messageService: MessageService,
        private operationService: OperationService,
        private router: Router,
        private loadingService: LoadingService,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        
    }

    ngAfterViewInit(): void {
        if(this.representativesAdmin?.length !== 0) {
            for (let index = 0; index < this.representativesAdmin?.length; index++) {
                const element = this.representativesAdmin[index];
                this.selecetedAdminContact.push(element.representativeSigner);
            }
        }
    }

    selectedCheckbox(event: RepresentativeViewModel) {
        this.checkboxAdminToggleEmitter.emit(event)
    }

    unSelectCheckbox(event: RepresentativeViewModel) {
        this.unCheckboxAdminToggleEmitter.emit(event)
    }

    onHeaderCheckboxToggled(event: boolean) {
        this.onHeaderAdminCheckboxToggledEmitter.emit(event);
    }

    // removeContact(contact: ContactProperties) {
    //     this.removeContactEmitter.emit(contact);
    // }

    // resendEmail() {
    //     this.resendEmailEmitter.emit();
    // }


}