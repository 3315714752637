import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { CryptoService } from 'src/app/providers/crypto-service';
import { ForgotPasswordUserRequest } from 'src/app/services/users/models/requests/forgot-password-user-request';
import { ResendOperationCodeRequest } from 'src/app/services/users/models/requests/resend-operation-code-request';
import { ResetPasswordRequest } from 'src/app/services/users/models/requests/reset-password-request';
import { CodeOperationResponseViewModel } from 'src/app/services/users/models/view-models/code-operation-response-view-model';
import { UserService } from 'src/app/services/users/user-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements AfterViewInit {

  stepsItems: MenuItem[];
  currentStep = 0;
  userCredential: string = null;
  code: string = null;
  newPassword: string = null;
  confirmPassword: string = null;
  codeOperationResponse: CodeOperationResponseViewModel = null;

  constructor(
    private contextService: ContextService,
    private cryptoService: CryptoService,
    private loadingService: LoadingService,
    private router: Router,
    private userService: UserService,
    private elementRef: ElementRef,
    private messageService: MessageService,
  ) {
    this.stepsItems = [
      {
        label: 'Receber Código'
      },
      {
        label: 'Resetar Senha'
      }
    ];
    this.contextService.clearUserInfo();
  }
  ngAfterViewInit() {
    this.verifyContext();
  }

  async sendCode(userCredentialInput: NgModel) {
    if (userCredentialInput.valid) {

      this.loadingService.show();
      const request: ForgotPasswordUserRequest = {
        emailOrCellPhone: this.userCredential
      };

      this.codeOperationResponse = await this.userService.forgotPassword(request);
      this.codeOperationResponse.codeExpirationDate = new Date(this.codeOperationResponse.codeExpirationDate);

      this.currentStep = 1;
      this.loadingService.hide();
    }
  }

  async resetPassword(confirmPasswordInput: NgModel) {

    if (confirmPasswordInput.valid) {

      this.loadingService.show();
      const request: ResetPasswordRequest = {
        emailOrCellPhone: this.codeOperationResponse.contactValue,
        activationCode: this.code,
        saltPassword: this.cryptoService.encryptMessage(this.confirmPassword)
      };

      try {
        await this.userService.resetPassword(request);
        this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Não foi possível efetuar a alteração',
          detail: `${error?.messageError}!`,
          life: 5000
        });
      } finally {
        this.loadingService.hide();
        this.router.navigateByUrl('login');
      }
    }
  }

  async resendCode() {

    this.loadingService.show();
    const request: ResendOperationCodeRequest = {
      EmailOrCellPhone: this.codeOperationResponse.contactValue,
      operationType: 'Password'
    };

    this.codeOperationResponse = await this.userService.resendOperationCode(request);
    this.codeOperationResponse.codeExpirationDate = new Date(this.codeOperationResponse.codeExpirationDate);

    this.loadingService.hide();
  }

  loginPage() {
    this.router.navigateByUrl('login')
  }

  verifyContext() {
    if (environment?.context !== "") {
      let logo = this.elementRef.nativeElement.getElementsByClassName('ocLogo');
      logo[0].style.backgroundImage = `url(${environment.logoUrl})`;
      logo[0].style.height = `auto`;
      logo[0].style.aspectRatio = `16 / 9`;
      logo[0].style.width = `220px`;
    }
  }
}
