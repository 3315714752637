import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressFormComponent } from './address-form.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        DropdownModule,
        ButtonModule,
        InputMaskModule,
        SelectButtonModule
    ],
    declarations: [AddressFormComponent],
    exports: [ AddressFormComponent ]    
})
export class AddressFormModule { }
