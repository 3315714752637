import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from "primeng/chip";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PipesModule } from "src/app/pipes/pipes-module";
import { AttachmentCreateComponent } from "./attachment-create/attachment-create.component";
import { AttachmentListComponent } from "./attachment-list/attachment-list.component";
import { DividerModule } from "primeng/divider";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FileUploadModule,
        ChipModule,
        InputTextModule,
        SelectButtonModule,
        CheckboxModule,
        RadioButtonModule,
        CalendarModule,
        PipesModule,
        DividerModule
    ],
    declarations: [AttachmentCreateComponent, AttachmentListComponent],
    exports: [ AttachmentCreateComponent, AttachmentListComponent]    
})
export class AttachmentModule { }
