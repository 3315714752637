<div class="layout-topbar">
    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
        </div>
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item layout-search-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')"
                        *ngIf="appMain.search == false" pRipple>
                        <i class="pi pi-briefcase fs-large"></i> &nbsp;&nbsp;&nbsp;
                        <span>{{selectedApplication?.application?.friendlyName}}</span>
                    </a>
                    <div class="layout-search-panel p-inputgroup" [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.search">
                        <span class="p-inputgroup-addon"><i class="pi pi-briefcase"></i></span>
                        <p-dropdown #searchInput [options]="applications" optionLabel="name" [(ngModel)]="selectedApplication"
                            (onChange)="selectedApplicationChanged($event.value)" (onShow)="appMain.searchClick = true">
                            <ng-template let-item pTemplate="item">
                                <div>{{item?.application?.friendlyName}}</div>
                            </ng-template>
                        </p-dropdown>
                        <span class="p-inputgroup-addon">
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-text p-button-plain"
                                (click)="appMain.searchClick = false;"></button>
                        </span>
                    </div>
                </li>
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2 rounded-circle"
                        (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <img src="assets/layout/images/avatar.png" alt="avatar" style="width: 32px; height: 32px;">
                    </a>

                    <ul class="layout-topbar-action-panel p-shadow-6" [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="appMain.activeTopbarItem === 'profile'">
                        <li class="layout-topbar-action-item">
                            <a (click)="myData()" class="p-d-flex p-flex-row p-ai-center"
                                pRipple>
                                <i class="pi pi-user" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Meus dados</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a href="#" (click)="appMain.logout($event)" class="p-d-flex p-flex-row p-ai-center"
                                pRipple>
                                <i class="pi pi-power-off" [ngClass]="{'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL}"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>