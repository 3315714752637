<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            Usuários
        </div>
        <div class="customCard">
            <div class="contentButtonActions">
                <button pButton pRipple type="button" label="Adicionar Usuário Liza" class="action-button p-ml-2"
                    (click)="addUser()"></button>
            </div>
            <p-tabView>
                <p-tabPanel header="Usuários Liza">
                    <p-table #dt [value]="users" dataKey="id" styleClass="p-datatable-customized" [rowHover]="true"
                        [rows]="10" [paginator]="true" [filterDelay]="0"
                        [globalFilterFields]="['fullName','maskedDocumentNumber','email']">
                        <ng-template pTemplate="caption">
                            <div class="contentButtonActions p-fluid">
                                <div class="p-col-12 p-md-5 p-lg-5 p-fluid">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search iconPosition"></i>
                                        <input pInputText type="text"
                                            (input)="dt.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Filtrar" />
                                    </span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="fullName">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        Nome
                                        <p-sortIcon field="fullName"></p-sortIcon>
                                        <p-columnFilter type="text" field="fullName" display="menu" class="p-ml-auto">
                                        </p-columnFilter>
                                    <!-- </div> -->
                                </th>
                                <th pSortableColumn="maskedDocumentNumber">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        CPF
                                        <p-sortIcon field="maskedDocumentNumber"></p-sortIcon>
                                        <p-columnFilter type="text" field="maskedDocumentNumber" display="menu"
                                            class="p-ml-auto"></p-columnFilter>
                                    <!-- </div> -->
                                </th>
                                <th pSortableColumn="birthdate">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        Data de Nascimento
                                        <p-sortIcon field="birthdate"></p-sortIcon>
                                        <p-columnFilter type="date" field="birthdate" display="menu" class="p-ml-auto">
                                        </p-columnFilter>
                                    <!-- </div> -->
                                </th>
                                <th pSortableColumn="email">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        Email
                                        <p-sortIcon field="email"></p-sortIcon>
                                        <p-columnFilter type="text" field="email" display="menu" class="p-ml-auto">
                                        </p-columnFilter>
                                    <!-- </div> -->
                                </th>
                                <th pSortableColumn="cellPhone">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        Celular
                                        <p-sortIcon field="cellPhone"></p-sortIcon>
                                        <p-columnFilter type="text" field="cellPhone" display="menu" class="p-ml-auto">
                                        </p-columnFilter>
                                    <!-- </div> -->
                                </th>
                                <th pSortableColumn="isAccessActivated">
                                    <!-- <div class="p-d-flex p-jc-between p-ai-center"> -->
                                        Status
                                        <p-sortIcon field="isAccessActivated"></p-sortIcon>
                                    <!-- </div> -->
                                </th>
                                <th style="width: 8rem">Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-user>
                            <tr class="p-selectable-row rowLiza">
                                <td>
                                    <span class="p-column-title">Nome</span>
                                    {{user.fullName}}
                                </td>
                                <td>
                                    <!-- <span class="p-column-title">CPF</span> -->
                                    {{user.maskedDocumentNumber ? user.maskedDocumentNumber : 'Não informado'}}
                                </td>
                                <td>
                                    <!-- <span class="p-column-title">Data de Nascimento</span> -->
                                    {{user.birthdat ? (user.birthdate | date: 'dd/MM/yyyy') : 'Não informado'}}
                                </td>
                                <td>
                                    <!-- <span class="p-column-title">Email</span> -->
                                    {{user.email ? user.email : 'Não informado'}}
                                </td>
                                <td>
                                    <!-- <span class="p-column-title">Celular</span> -->
                                    {{user.cellPhone ? user.cellPhone : 'Não informado'}}
                                </td>
                                <td>
                                    <!-- <span class="p-column-title">Status</span> -->
                                    <span
                                        [class]="user.isAccessActivated != null && user.isAccessActivated ? 'datatable-customized-badge status-qualified' : 'datatable-customized-badge status-unqualified'">
                                        {{user.isAccessActivated != true && user.isAccessActivated != false ? 'Pendente Ativação' : (user.isAccessActivated ? 'Ativo' : 'Inativo')}}</span>
                                </td>
                                <td style="text-align: center">
                                    <!-- <button pButton type="button" class="p-button-secondary" icon="pi pi-cog"></button> -->
                                    <!-- <p-menu #menu [popup]="true" [model]="getMenuItems(user)"></p-menu> -->
                                    <button type="button" pButton pRipple icon="pi pi-ellipsis-h" class="p-button-text"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="8">Nenhum usuário encontrado.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>