import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { MenuItem } from 'primeng/api';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { NgForm } from '@angular/forms';
import { FormValidationService } from 'src/app/providers/form-validation-service';

@Component({
    templateUrl: './manager-contacts-create.component.html',
    styleUrls: ['../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-manager-contacts-create'
})
export class ManagerContactsCreateComponent implements OnInit {

    @Input() displayCreateManagerContacts: boolean = false;
    request: any = null;
    @Output() managerContactEmitter: EventEmitter<any> = new EventEmitter();
    @Output() buttonCancelEmitter: EventEmitter<any> = new EventEmitter();


    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        this.setForms();
    }

    setForms() {
        this.request = {
            name: null,
            emai: null,
        }
    }

    onCancel() {
        this.buttonCancelEmitter.emit();
    }

    onSaveManagerContact(requestFormManagerContact: NgForm) {
        const request = {
            name: requestFormManagerContact.value.name,
            email: requestFormManagerContact.value.email,
        }
        this.managerContactEmitter.emit({request: request, form: requestFormManagerContact});
        this.setForms();
    }


}