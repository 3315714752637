<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Editar email de finalização
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="borderOutlined">
                        <p><b class="text-bold">Nome:</b> <span> {{selectedFundBroker?.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{selectedFundBroker?.documentNumber }}</span></p>
                        <!-- <p><b class="text-bold">Data de Validade:</b> <span> {{selectedFundBroker?.expirationDate | date
                                : 'dd/MM/yyyy' }}</span></p> -->
                    </div>
                </div>

            </div>
            <br>
            <div class="p-grid" *ngIf="selectedFundBroker?.operationDoneNotificationEmails?.length > 0">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Emails Cadastrados</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <form #managerForm="ngForm" novalidate>
                        <div class="p-grid" *ngFor="let email of selectedFundBroker?.operationDoneNotificationEmails">
                            <div class="p-col-12 p-md-10 p-lg-10">
                                <span>{{ email}}</span>
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2">
                                <button pButton class="p-button-outlined-blue p-button-rounded" icon="pi pi-trash"
                                    (click)="requestRemoveEmail(email)"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <form #notificationForm="ngForm" novalidate>

                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <div class="cardHeader text-bold">E-mail de finalização de operação</div>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="subject" type="text"
                                    ngModel={{selectedFundBroker?.operationDoneNotificationSubject}} name="subject"
                                    #subject="ngModel" class="inputLiza" required pInputText>
                                <label for="subject"></label>
                                <label for="subject">Título do e-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="subject.invalid && subject.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="title" type="text"
                                    ngModel={{selectedFundBroker?.operationDoneNotificationTitle}} name="title"
                                    #title="ngModel" class="inputLiza" required pInputText>
                                <label for="title">Texto corpo do email</label>
                            </span>
                        </div>
                        <mark *ngIf="title.invalid && title.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-10 p-lg-10 p-fluid">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="currentEmail" type="currentEmail" [(ngModel)]="currentEmails" name="currentEmail" #currentEmail="ngModel"
                                    class="inputLiza" pInputText>
                                <label for="currentEmail">Lista de E-mails</label>
                            </span>
                        </div>
                        <mark *ngIf="currentEmail.invalid && currentEmail.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                        <button pButton pRipple type="button" icon="pi pi-plus"
                            class="p-button-rounded p-button-outlined" (click)="addEmail(notificationForm)"></button>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let item of allEmails">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-6 p-lg-6 p-fluid">
                                <p><b class="text-bold">Email:</b> {{item}}</p>
                            </div>
                            <div class="p-col-12 p-md-6 p-lg-6 p-fluid">
                                <button pButton class="p-button-outlined-blue p-button-rounded" icon="pi pi-trash"
                                    (click)="removeEmail(item)"></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                        <button pButton pRipple type="button" label="Salvar alterações" class="action-button"
                            (click)="onSaveConfigEmail(notificationForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<p-dialog header="Editar Procurador/Rep Legal" [(visible)]="displayEditRep" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form #repForm="ngForm" novalidate>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="name" type="text" ngModel={{selectedRepresentative?.name}} name="name"
                            #name="ngModel" class="inputLiza" required pInputText>
                        <label for="name">Nome</label>
                    </span>
                </div>
                <mark *ngIf="name.invalid && name.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" *ngIf="selectedContact?.value == true">
                <span class="p-float-label">
                    <p-inputMask class="inputOctaMask" inputId="maskedPersonalDocumentNumber" type="tel"
                        mask="999.999.999-99" ngModel={{selectedDocumentEncryptedRep}}
                        name="maskedPersonalDocumentNumber" #maskedPersonalDocumentNumber="ngModel" [unmask]="true"
                        [required]="true">
                    </p-inputMask>
                    <label class="labelOcta" for="documentNumber">CPF</label>
                </span>
                <mark *ngIf="maskedPersonalDocumentNumber.invalid && maskedPersonalDocumentNumber.dirty"
                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="email" type="text" ngModel={{selectedRepresentative?.email}} name="email"
                            #email="ngModel" class="inputLiza" required pInputText>
                        <label for="email">E-mail</label>
                    </span>
                </div>
                <mark *ngIf="email.invalid && email.dirty" class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid" *ngIf="selectedContact?.value == true">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="selectedExpirateDateRep" name="expireDate" appendTo="body"
                            yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            #expireDate="ngModel" [required]="true">
                        </p-calendar>
                        <label for="expireDate">Data de Validade</label>
                    </span>
                </div>
                <mark *ngIf="expireDate.invalid && expireDate.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <p>É representante legal?</p>
                <div *ngFor="let category of contactsOption" class="field-checkbox">
                    <p-radioButton [inputId]="category.value" name="category" [value]="category"
                        [(ngModel)]="selectedContact"></p-radioButton>
                    <label class="labelCheckbox" [for]="category.value">{{category.label}}</label>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                    (click)="onEditCancel()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onEditRepresentative(repForm)"></button>
            </div>
        </div>
    </form>

</p-dialog>