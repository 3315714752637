import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { DocumentNumberPipe } from 'src/app/pipes/number-formatting/document-number-pipe';
import { ContextService } from 'src/app/providers/context-service';
import { DefaultEnumViewModel } from 'src/app/providers/models/default-enum-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { SetSystemLegacyIntegrationRequest } from 'src/app/services/fund-administrators/models/requests/set-system-legacy-integration-request';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './fund-administrator-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FundAdministratorListComponent implements OnInit {

    fundAdministrators: FundAdministratorViewModel[] = [];
    filterFundAdministrator: FundAdministratorViewModel[] = [];
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    systemLegacyIntegrationOption: DefaultEnumViewModel[] = [];

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private contextService: ContextService,
        private documentNumberPipe: DocumentNumberPipe,
        private fundAdministratorService: FundAdministratorService) {
    }

    async ngOnInit() {
        await this.verifyUserPermissions();
        await this.loadData();
        await this.getSystemLegacyIntegrationOptions();
    }

    async getSystemLegacyIntegrationOptions() {
        this.systemLegacyIntegrationOption = await this.fundAdministratorService.systemLegacyIntegrationOptions();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;

        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async loadData() {
        let isActive = true;
        this.loadingService.show();
        this.fundAdministrators = await this.fundAdministratorService.getAll(isActive);
        this.fundAdministrators = this.fundAdministrators.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        })
        this.loadingService.hide();
    }

    addAdministrator() {
        return this.router.navigateByUrl(`app/customer/fund-administrator/create`);
    }

    viewAdministrator(fundAdministrator: FundAdministratorViewModel) {
        return this.router.navigateByUrl(`app/customer/fund-administrator/view/${fundAdministrator.id}`);
    }

    procuratorAdministrator(fundAdministrator: FundAdministratorViewModel) {
        return this.router.navigateByUrl(`app/customer/fund-administrator/procurator/${fundAdministrator.id}`);
    }

    async removeFundAdministrator(fundAdministrator: FundAdministratorViewModel) {
        try {
            this.loadingService.show();
            await this.fundAdministratorService.deleteFundAdministrator(fundAdministrator.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            await this.loadData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    confirm(fund: FundAdministratorViewModel) {
        this.confirmationService.confirm({
            header: `${fund.name}`,
            message: `Tem certeza que deseja excluir ${fund.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeFundAdministrator(fund);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    async updateSystemLegacyIntegration(fundAdministrator: FundAdministratorViewModel) {
        this.loadingService.show();
        try {
            const request: SetSystemLegacyIntegrationRequest = {
                fundAdministratorId: fundAdministrator.id,
                administratorLegacySystemProviderId: this.systemLegacyIntegrationOption[0].value,
                systemLegacyCredentials: "none",
            }
            await this.fundAdministratorService.updateSystemLegacyIntegration(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            console.log(error);
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async removeSystemLegacyIntegration(fundAdministrator: FundAdministratorViewModel) {
        this.loadingService.show();
        try {
            const fundAdministratorId = fundAdministrator.id;
            await this.fundAdministratorService.removeSystemLegacyIntegration(fundAdministratorId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            console.log(error);
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }


    getMenuItems(fundAdministrator: FundAdministratorViewModel): MenuItem[] {

        const items: MenuItem[] = [];

        if (fundAdministrator.administratorLegacySystemProvider) {
            items.push({ label: 'Desativar Op. Externa', command: e => this.removeSystemLegacyIntegration(fundAdministrator) });
        }
        if (!fundAdministrator.administratorLegacySystemProvider) {
            items.push({ label: 'Ativar Op. Externa', command: e => this.updateSystemLegacyIntegration(fundAdministrator) });
        }

        if (fundAdministrator.registrationFormTemplate && this.isUserAdministrator) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundAdministrator) });
            items.push({ label: 'Procuradores', command: e => this.procuratorAdministrator(fundAdministrator) });
            items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/operation-setup/${fundAdministrator.id}`) });
            items.push({ label: 'Editar Ficha Cadastral', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/edit-form-template/${fundAdministrator.id}`) });
            items.push({ label: 'Excluir', command: e => this.confirm(fundAdministrator) });

        } else if (!fundAdministrator.registrationFormTemplate && this.isUserAdministrator) {
            items.push({ label: 'Procuradores', command: e => this.viewAdministrator(fundAdministrator) });
            // items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/operation-setup/${fundAdministrator.id}`) });
            items.push({ label: 'Editar Ficha Cadastral', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/edit-form-template/${fundAdministrator.id}`) });
            items.push({ label: 'Excluir', command: e => this.confirm(fundAdministrator) });

        } else if (this.isUserOperator) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundAdministrator) });

        } else if (this.isUserAnalist) {
            items.push({ label: 'Ver', command: e => this.viewAdministrator(fundAdministrator) });
            items.push({ label: 'Procuradores', command: e => this.procuratorAdministrator(fundAdministrator) });
            items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/operation-setup/${fundAdministrator.id}`) });
            items.push({ label: 'Editar Ficha Cadastral', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/edit-form-template/${fundAdministrator.id}`) });

        } else if (!fundAdministrator.registrationFormTemplate && this.isUserAnalist) {
            items.push({ label: 'Procuradores', command: e => this.viewAdministrator(fundAdministrator) });
            // items.push({ label: 'Setup', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/operation-setup/${fundAdministrator.id}`) });
            items.push({ label: 'Editar Ficha Cadastral', command: e => this.router.navigateByUrl(`app/customer/fund-administrator/edit-form-template/${fundAdministrator.id}`) });
        }

        return items;
    }

    async filter(filtered: string) {
        let filterFundAdministrators = [];
        if (filtered) {
            this.fundAdministrators.forEach(item => {
                if (item.name.toUpperCase().includes(filtered) ||
                    item.name.toLowerCase().includes(filtered) ||
                    item.documentNumber.includes(filtered) ||
                    this.documentNumberPipe.parseCNPJ(item.documentNumber).includes(filtered)) {
                    filterFundAdministrators.push(item)
                    this.fundAdministrators = filterFundAdministrators;
                }
            })
        } else if (!filtered) {
            await this.loadData();
        }
    }


}