import {Component} from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppRootComponent } from '../app.root/app.root.component';

@Component({
    selector: 'app-rightmenu',
    templateUrl: 'app.rightmenu.component.html'
})
export class AppRightMenuComponent {
    constructor(public appMain: AppRootComponent, public app: AppComponent) {}
}
