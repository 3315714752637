import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
  
@Injectable({  
  providedIn: 'root'  
})  
export class VersionService  {
  version: string = 'v20241105.1305';
  constructor() { }

  getVersion() : string {  
    return `${this.version}-${environment.envName}`;
  }  
}  