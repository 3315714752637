<div class="p-grid" *ngIf="selectedOperation !== null">
    <div class="p-col-12 p-md-4 p-lg-4">
        <div *ngFor="let file of selectedOperation?.attachments; let i = index"
            class="p-grid">
            <div class="col-10 p-md-4 p-lg-4 ">
                <div class="upload-files-button">
                    <p class="text-bold">Descrição:</p>
                    <span>{{file?.description}}</span>
                </div>
            </div>
            <div class="col-10 p-md-6 p-lg-6">
                <div class="upload-files-content">
                    <p class="text-bold">Anexo:</p>
                    <button (click)="openArtefactUrl(file)" pButton pRipple
                        type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="col-2 p-md-2 p-lg-2 upload-files-button">
                <div>
                    <button pButton pRipple type="button" class="p-button-outlined-blue"
                        icon="pi pi-trash" (click)="removeFile(file)"></button>
                </div>
            </div>
        </div>
        <br>
        <div class="p-grid">
            <div class="col-12 col-md-4 col-lg-4">
                <button (click)="openAddFile()" pButton pRipple type="button"
                    label="Adicionar anexo" class="action-button p-ml-2"></button>
            </div>
            <div class="col-12 col-md-4 col-lg-4" *ngIf="!hasFundRegulationFile">
                <button (click)="onOperationUploadFileFundRegulation()" pButton pRipple type="button"
                    label="Adicionar Regulamento do Fundo" class="p-button-outlined p-ml-2"></button>
            </div>
        </div>
    </div>
</div>

<div class="p-grid" *ngIf="selectedBrokerOperation !== null">
    <div class="p-col-12 p-md-4 p-lg-4">
        <div *ngFor="let file of selectedBrokerOperation?.attachments; let i = index"
            class="p-grid">
            <div class="col-10 p-md-4 p-lg-4 ">
                <div class="upload-files-button">
                    <p class="text-bold">Descrição:</p>
                    <span>{{file?.description}}</span>
                </div>
            </div>
            <div class="col-10 p-md-6 p-lg-6">
                <div class="upload-files-content">
                    <p class="text-bold">Anexo:</p>
                    <button (click)="openArtefactUrl(file)" pButton pRipple
                        type="button" label="Ver" class="action-button p-ml-2"></button>
                </div>
            </div>
            <div class="col-2 p-md-2 p-lg-2 upload-files-button">
                <div>
                    <button pButton pRipple type="button" class="p-button-outlined-blue"
                        icon="pi pi-trash" (click)="removeFile(file)"></button>
                </div>
            </div>
        </div>
        <br>
        <div class="p-grid">
            <div class="col-12 col-md-4 col-lg-4">
                <button (click)="openAddFile()" pButton pRipple type="button"
                    label="Adicionar anexo" class="action-button p-ml-2"></button>
            </div>
            <div class="col-12 col-md-4 col-lg-4" *ngIf="!hasBrokerFundRegulationFile">
                <button (click)="onBrokerUploadFileFundRegulation()" pButton pRipple type="button"
                    label="Adicionar Regulamento do Fundo" class="p-button-outlined p-ml-2"></button>
            </div>
        </div>
    </div>
</div>