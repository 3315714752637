import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-access-denied',
  templateUrl: './app.access-denied.component.html',
})
export class AppAccessdeniedComponent {

  constructor(private location: Location) {
  }

  goBack() {
    this.location.back();
  }
}
