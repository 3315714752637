<div class="titleCustomCard">
    Novo Procurador
</div>
<div class="p-grid">
    <div class="p-col-12">
        <form #entryForm="ngForm" novalidate>
            <div class="customCard">
                <div class="cardHeader text-bold">Validade</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.expireDate" name="expireDate" yearRange="2022:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" [required]="true" #expireDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="expireDate" class="labelOcta">Validade</label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="customCard">
                <div class="cardHeader text-bold">Informações</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="request.name" #name="ngModel"
                                    [ngModelOptions]="{standalone: true}" class="inputLiza" required pInputText>
                                <label for="name">Nome</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="personalDocumentNumber" type="text" class="inputLizaMask"
                                    mask="999.999.999-99" [(ngModel)]="request.personalDocumentNumber"
                                    name="personalDocumentNumber" #personalDocumentNumber="ngModel" [unmask]="true"
                                    [required]="true">
                                </p-inputMask>
                                <label for="personalDocumentNumber" class="labelLiza">CPF</label>
                            </span>
                        </div>
                        <mark *ngIf="personalDocumentNumber.invalid && personalDocumentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>


                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.birthDate" name="birthDate" yearRange="1920:2099"
                                    [showIcon]="true" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                    [yearNavigator]="true" [required]="true" #birthDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="birthDate" class="labelOcta">Data de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthDate.invalid && birthDate.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="genderOptions" [(ngModel)]="selectedGender" placeholder="Sexo"
                                    name="label" #gender="ngModel" [required]="true">
                                </p-dropdown>
                                <!-- <label for="gender">
                                    sexo
                                </label> -->
                            </span>
                        </div>
                        <mark *ngIf="gender.invalid && gender.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-8 p-lg-8">
                        <app-address-form [editAddressParams]="null" autocompleteId="mainAddress"
                            [isCompleteAddressRequired]="true" (onSelect)="onAddressSelect($event)"></app-address-form>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12"></div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allAddressState" [(ngModel)]="request.addressState"
                                    placeholder="UF" name="addressState" #addressState="ngModel" [required]="true">
                                </p-dropdown>
                                <!-- <label for="uf">
                                    UF
                                </label> -->
                            </span>
                        </div>
                        <mark *ngIf="addressState.invalid && addressState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="addressCountry" type="text" [(ngModel)]="request.addressCountry"
                                    name="addressCountry" #addressCountry="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="addressCountry">País</label>
                            </span>
                        </div>
                        <mark *ngIf="addressCountry.invalid && addressCountry.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="birthCity" type="text" [(ngModel)]="request.birthCity" name="birthCity"
                                    #birthCity="ngModel" class="inputLiza" required pInputText>
                                <label for="birthCity">Cidade de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthCity.invalid && birthCity.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allBirthState" [(ngModel)]="selectedBirthState" name="label"
                                    #birthState="ngModel" [required]="true">
                                </p-dropdown>
                                <label for="birthState">
                                    UF de Nascimento
                                </label>
                            </span>
                        </div>
                        <mark *ngIf="birthState.invalid && birthState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="birthCountry" type="text" [(ngModel)]="request.birthCountry"
                                    name="birthCountry" #birthCountry="ngModel" class="inputLiza" required pInputText>
                                <label for="birthCountry">País de Nascimento</label>
                            </span>
                        </div>
                        <mark *ngIf="birthCountry.invalid && birthCountry.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="nationality" type="text" [(ngModel)]="request.nationality" name="nationality"
                                    #nationality="ngModel" class="inputLiza" required pInputText>
                                <label for="nationality">Nacionalidade</label>
                            </span>
                        </div>
                        <mark *ngIf="nationality.invalid && nationality.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                                    [(ngModel)]="request.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                                    [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="phoneNumber" class="labelLiza">Telefone</label>
                            </span>
                        </div>
                        <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="email" type="email" [(ngModel)]="request.email" name="email" #email="ngModel"
                                    class="inputLiza" required pInputText appValidateEmail>
                                <label for="email">E-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="email.invalid && email.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="cardHeader text-bold">Documento</div>
                <div class="p-grid">
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="typeDocuments" [(ngModel)]="selectedAdditionalDocumentType"
                                    [ngModelOptions]="{standalone: true}" placeholder="Tipo de Documento"
                                    optionLabel="label" optionValue="value" #additionalDocumentType="ngModel"
                                    [required]="true">
                                </p-dropdown>
                                <!-- <label for="additionalDocumentType">
                                    Tipo de Documento
                                </label> -->
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentType.invalid && additionalDocumentType.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="additionalDocumentNumber" type="text"
                                    [(ngModel)]="request.additionalDocumentNumber" name="additionalDocumentNumber"
                                    #additionalDocumentNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="additionalDocumentNumber">Número Documento</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentNumber.invalid && additionalDocumentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="request.additionalDocumentIssueDate"
                                    name="additionalDocumentIssueDate" yearRange="1920:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    [required]="true" #additionalDocumentIssueDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="additionalDocumentIssueDate" class="labelOcta">Data de Emissão</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentIssueDate.invalid && additionalDocumentIssueDate.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="additionalDocumentIssuingBody" type="text"
                                    [(ngModel)]="request.additionalDocumentIssuingBody"
                                    name="additionalDocumentIssuingBody" #additionalDocumentIssuingBody="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="additionalDocumentIssuingBody">Orgão Emissor</label>
                            </span>
                        </div>
                        <mark *ngIf="additionalDocumentIssuingBody.invalid && additionalDocumentIssuingBody.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-3 p-lg-3">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allAdditionalDocumentIssuingBodyState"
                                    [(ngModel)]="selectedAdditionalDocumentIssuingBodyState" name="label"
                                    #additionalDocumentIssuingBodyState="ngModel" [required]="true">
                                </p-dropdown>
                                <label for="additionalDocumentIssuingBodyState">
                                    UF orgão emissor
                                </label>
                            </span>
                        </div>
                        <mark
                            *ngIf="additionalDocumentIssuingBodyState.invalid && additionalDocumentIssuingBodyState.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="cardHeader text-bold">Anexos</div>
                <div class="p-grid">
                    <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate">
                        <div class="customCardOutlined" style="width: 100%;">
                            <h5 class="text-center">Procuração</h5>
                            <app-fileuploader [isMultipleFiles]="false" [label]="''"
                                (onUploadResponse)="onUploadProcurationFile($event)">
                            </app-fileuploader>
                        </div>
                    </div>
                    <div class="p-col-6 p-lg-6 p-sm-6 p-sm-12 customItem alignContentArtifactTemplate">
                        <div class="customCardOutlined" style="width: 100%;">
                            <h5 class="text-center">Documento do Procurador</h5>
                            <app-fileuploader [isMultipleFiles]="false" [label]="''"
                                (onUploadResponse)="onUploadDocumentFile($event)">
                            </app-fileuploader>
                        </div>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Cancelar" class=" p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                        (click)="onSave(entryForm)"></button>
                </div>
            </div>
        </form>
    </div>
</div>