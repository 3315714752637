<div class="p-mb-2">
    <label>{{label}}</label>
</div>
<div class="p-fluid p-formgrid p-grid">
    <div class="p-col-12">
        <div class="p-inputgroup">
            <span class="p-float-label boxFileUpload">
                <p-fileUpload
                    chooseIcon="pi pi-upload"
                    class="p-fluid"
                    mode="basic"
                    [auto]="true"
                    [customUpload]="true"
                    [files]="componentFiles"
                    (uploadHandler)="uploadFiles($event.files)"
                    [chooseLabel]="chooseLabel" [multiple]="isMultipleFiles" [accept]="acceptType"
                    cancelLabel="Limpar"
                    uploadLabel="Upload" invalidFileSizeMessageSummary="O tamanho do arquivo não é permitido"
                    invalidFileSizeMessageDetail="O tamanho máximo do permitido é {0}"
                    invalidFileTypeMessageSummary="O tipo de arquivo não é válido"
                    invalidFileTypeMessageDetail="O tipo de arquivo permitido é {0}" [maxFileSize]="maxFileSize">
                </p-fileUpload>
            </span>
        </div>
    </div>
</div>
<div class="p-fluid p-formgrid p-grid p-mt-2 rowBox" style="justify-content: center;" *ngIf="uploadResponses != null">
    <div [class]="i != 0 ? 'p-mt-2 boxDiv' : 'boxDiv'" *ngFor="let item of uploadResponses; let i = index">
        <div class="p-inputgroup containerFileUpload">
            <span class="p-float-label">
                <span class="boxFileContainer">
                    <p-chip *ngIf="item.fileTypeId != 2" [label]="item.fileId" [image]="'assets/layout/images/file.png'" styleClass="custom-chip"></p-chip>
                    <p-chip *ngIf="item.fileTypeId == 2" [label]="item.fileId" [image]="item.fileUrl" styleClass="custom-chip"></p-chip>
                </span>
            </span>
            <button pButton pRipple type="button" label="Remover" icon="pi pi-times" iconPos="left"
                class="p-pr-6 remove-button buttonUpload" (click)="fileUploadedRemoved(item)"></button>
        </div>
    </div>
</div>
