<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Nova Corretora
        </div>
        <div class="customCard">
            <form #entryForm="ngForm" novalidate>
                <div class="cardHeader text-bold">Dados do Administrador</div>
                <div class="p-grid p-fluid p-formgrid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="requestModel.name" name="name" #name="ngModel"
                                    class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="documentNumber" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" [(ngModel)]="requestModel.documentNumber"
                                    name="documentNumber" #documentNumber="ngModel" [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                        </div>
                        <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <!-- <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="requestModel.expirationDate"
                                    name="expirationDate" yearRange="2022:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    #expirationDate="ngModel">
                                </p-calendar>
                                <label for="expirationDate" class="labelOcta">Data de Validade</label>
                            </span>
                        </div>
                    </div> -->
                </div>

                <div class="cardHeader text-bold">Template da Ficha Cadastral</div>
                <app-artifact-template-create
                    (onArtifactFilled)="registrationFormTemplateFilled($event)"></app-artifact-template-create>

                <div class="cardHeader text-bold">Ficha Cadastral de atualização</div>
                <app-artifact-template-create
                    (onArtifactFilled)="updateFormTemplateFilled($event)"></app-artifact-template-create>

                <div class="cardHeader text-bold">Template de Aditamento</div>
                <app-artifact-template-create
                    (onArtifactFilled)="additionTemplateFilled($event)"></app-artifact-template-create>

                <div class="cardHeader text-bold">Template W8</div>
                <app-artifact-template-create
                    (onArtifactFilled)="operationTemplateFilled($event)"></app-artifact-template-create>

                <div class="cardHeader text-bold">Adicionar Procuradores / Rep Legal / Contatos da Corretora</div>
                <form #representativeForm="ngForm" novalidate>

                    <div class="p-grid">
                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="name" type="text" [(ngModel)]="representativeRequestForm.name"
                                        name="name" #name="ngModel" class="inputLiza" required pInputText>
                                    <label for="name">Nome</label>
                                </span>
                            </div>
                            <mark *ngIf="name.invalid && name.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>

                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="email" type="text" [(ngModel)]="representativeRequestForm.email"
                                        name="email" #email="ngModel" class="inputLiza" required pInputText>
                                    <label for="email">E-mail</label>
                                </span>
                            </div>
                            <mark *ngIf="email.invalid && email.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>

                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="selectedContact?.value == true">
                            <span class="p-float-label">
                                <p-inputMask class="inputOctaMask" inputId="personalDocumentNumber" type="tel"
                                    mask="999.999.999-99" [(ngModel)]="representativeRequestForm.personalDocumentNumber"
                                    name="personalDocumentNumber" #personalDocumentNumber="ngModel" [unmask]="true"
                                    [required]="selectedContact?.value == true">
                                </p-inputMask>
                                <label class="labelOcta" for="documentNumber">CPF</label>
                            </span>
                            <mark *ngIf="personalDocumentNumber.invalid && personalDocumentNumber.dirty"
                                class="ui-message ui-messages-error ui-corner-all markError">Campo
                                obrigatório</mark>
                        </div>


                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid" *ngIf="selectedContact?.value == true">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-calendar [(ngModel)]="representativeRequestForm.expireDate" name="expireDate"
                                        yearRange="1950:2099" [monthNavigator]="true" dateFormat="dd/mm/yy"
                                        [yearNavigator]="true" #expireDate="ngModel"
                                        [required]="selectedContact?.value == true">
                                    </p-calendar>
                                    <label for="expireDate">Data de Validade</label>
                                </span>
                            </div>
                            <mark *ngIf="expireDate.invalid && expireDate.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>

                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                            <p>É Representante legal?</p>
                            <div *ngFor="let category of contactsOption" class="field-checkbox">
                                <p-radioButton [inputId]="category.value" name="category" [value]="category"
                                    [(ngModel)]="selectedContact"></p-radioButton>
                                <label class="labelCheckbox" [for]="category.value">{{category.label}}</label>
                            </div>
                        </div>
                        <div class="p-col-1 p-md-1 p-lg-1">
                            <button pButton pRipple type="button" icon="pi pi-plus"
                                class="p-button-rounded p-button-outlined"
                                (click)="addRepresentativeRequest(representativeRequestForm, representativeForm)"></button>
                        </div>
                    </div>
                </form>

                <div *ngIf="requestModel != null && requestModel.representatives.length > 0">
                    <!-- <p-scrollPanel [style]="{width: '100%', height: '200px'}"> -->
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">Nome</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">E-mail</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">CPF</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">Data de Expiração</p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2">
                            <p class="text-bold">É Representante?</p>
                        </div>
                    </div>
                    <div class="p-grid p-formgrid" *ngFor="let representative of requestModel.representatives">

                        <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                            <span>
                                {{representative.name}}
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                            <p>
                                {{representative.email}}
                            </p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                            <p *ngIf="representative?.canSign">
                                {{representative.personalDocumentNumber}}
                            </p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                            <p *ngIf="representative?.canSign">
                                {{representative.expireDate | date: 'dd/MM/yyyy' }}
                            </p>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                            <p>{{ representative?.canSign ? "Sim" : "Não"}}</p>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-2 inputItem">
                            <button pButton class="p-button-outlined-blue p-button-rounded" icon="pi pi-trash"
                                (click)="removeRepresentativeRequest(representative)"></button>
                        </div>
                        <div class="line"></div>
                    </div>
                    <!-- </p-scrollPanel> -->
                </div>

                <div class="cardHeader text-bold">Configurar E-mail de finalização de operação</div>
                <form #notificationForm="ngForm" novalidate>

                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="subject" type="text"
                                        [(ngModel)]="requestModel.operationDoneNotificationSubject" name="subject"
                                        #subject="ngModel" class="inputLiza" required pInputText>
                                    <label for="subject">Título do e-mail</label>
                                </span>
                            </div>
                            <mark *ngIf="subject.invalid && subject.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>

                        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="title" type="text"
                                        [(ngModel)]="requestModel.operationDoneNotificationTitle" name="title"
                                        #title="ngModel" class="inputLiza" required pInputText>
                                    <label for="title">Texto corpo do e-mail</label>
                                </span>
                            </div>
                            <mark *ngIf="title.invalid && title.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>
                        <div class="p-col-12 p-md-10 p-lg-10 p-fluid">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="currentEmail" type="currentEmail" [(ngModel)]="currentEmails" name="currentEmail" #currentEmail="ngModel"
                                        class="inputLiza" required pInputText>
                                    <label for="currentEmail">Lista de E-mails</label>
                                </span>
                            </div>
                            <mark *ngIf="currentEmail.invalid && currentEmail.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>
                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                            <button pButton pRipple type="button" icon="pi pi-plus"
                                class="p-button-rounded p-button-outlined" (click)="addEmail(notificationForm)"></button>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let item of requestModel.operationDoneNotificationEmails">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6 p-lg-6 p-fluid">
                                    <p><b class="text-bold">Email:</b> {{item}}</p>
                                </div>
                                <div class="p-col-12 p-md-6 p-lg-6 p-fluid">
                                    <button pButton class="p-button-outlined-blue p-button-rounded" icon="pi pi-trash"
                                        (click)="removeEmail(item)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="p-mt-3">
                    <div class="p-d-flex p-jc-end">
                        <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                            (click)="onCancel()"></button>
                        <button pButton pRipple type="button" label="Salvar" iconPos="right"
                            class="action-button p-ml-2" (click)="onSave(entryForm)"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>