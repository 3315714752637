import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreatePagedSearchRequest } from 'src/app/providers/models/paged-search-request';
import { PagedSearchResult } from 'src/app/providers/models/paged-search-result';
import { ExternalOperationService } from 'src/app/services/external-operations/external-operation-service';
import { ExternalOperationViewModel } from 'src/app/services/external-operations/models/view-models/external-operation-view-model';
import { CancelOperationRequest } from 'src/app/services/operations/models/request/cancel-operation-request';
import { GetOperationsByFilterRequest } from 'src/app/services/operations/models/request/get-operations-by-filter-request';
import { UpdateOperationLegacySystemIdRequest } from 'src/app/services/operations/models/request/update-operation-legacy-system-id-request';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';


@Component({
    templateUrl: './external-operation-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class ExternalOperationListComponent implements OnInit {

    operationsOnGoingResult: PagedSearchResult<ExternalOperationViewModel>;
    externalOperationsOnGoing: ExternalOperationViewModel[] = [];

    externalOperationsFinishedResult: PagedSearchResult<ExternalOperationViewModel>;
    externalOperationsFinished: ExternalOperationViewModel[] = [];

    externalOperationsCanceledResult: PagedSearchResult<ExternalOperationViewModel>;
    externalOperationsCanceled: ExternalOperationViewModel[] = [];

    displayOperationDetail: boolean = false;
    displayOperationInit: boolean = false;
    displayOperationCancel: boolean = false;
    selectedExternalOperation: ExternalOperationViewModel = null;
    requestOperationCancel = null;
    selectedOperationCancel: ExternalOperationViewModel = null;
    selectedExternalOperationDisplay: ExternalOperationViewModel = null;
    displayReasonOperation: boolean = false;
    displayCancelOperations: boolean = false;
    // typeUser: string = null;
    tabView: number = 0;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    selectedCancelOperation: string = null;
    selectedCancelOperations: SelectItem[] = [];
    selectedNoteCancelOperations: string = null;
    displayLegacyId: boolean = false;
    selectedExternalOperationLegacyId: ExternalOperationViewModel = null;
    operationlegacyId: string = null;
    menuItems: MenuItem[] = [];

    public operationStatusEnum = OperationStatusEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private externalOperationService: ExternalOperationService,
        private contextService: ContextService,
        private messageService: MessageService) {
    }


    setFormCancelOperation() {
        this.requestOperationCancel = {
            notes: null,
        }
    }

    async ngOnInit() {
        this.setFormCancelOperation();
        this.loadingService.show();
        await this.verifyUserPermissions();

        //await this.getOperationsOnGoingData();

        if (this.route.snapshot.queryParams.tab) {
            await this.changeTab(this.route.snapshot.queryParams.tab)
        }
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async getOperationsOnGoingData(lazyLoadEvent: LazyLoadEvent) {

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.operationsOnGoingResult = await this.externalOperationService.getOperationsOnGoingSearch(pagedSearchRequest);
        this.externalOperationsOnGoing = this.operationsOnGoingResult.searchResult;

        this.externalOperationsOnGoing = this.externalOperationsOnGoing.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsOnGoingData()', 'operationsOnGoingResult', this.operationsOnGoingResult);

    }

    async getOperationsFinishedData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsFinishedData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.externalOperationsFinishedResult = await this.externalOperationService.getOperationsFinishedPaged(pagedSearchRequest);
        this.externalOperationsFinished = this.externalOperationsFinishedResult.searchResult;

        this.externalOperationsFinished = this.externalOperationsFinished.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsFinishedData()', 'operationsFinishedResult', this.operationsFinishedResult);

    }

    async getOperationsCanceledData(lazyLoadEvent: LazyLoadEvent) {
        // console.log('operation-list', 'getOperationsCanceledData()', 'lazyLoadEvent', lazyLoadEvent);

        let pagedSearchRequest = CreatePagedSearchRequest(lazyLoadEvent);

        this.externalOperationsCanceledResult = await this.externalOperationService.getOperationsCanceledSearch(pagedSearchRequest);
        this.externalOperationsCanceled = this.externalOperationsCanceledResult.searchResult;

        this.externalOperationsCanceled = this.externalOperationsCanceled.sort((a, b) => {
            if (a.sequentialId > b.sequentialId) {
                return -1
            } else {
                return 1
            }
        });

        // console.log('operation-list', 'getOperationsCanceledData()', 'operationsCanceledResult', this.operationsCanceledResult);

    }

    async changeTab(index: number) {
        this.loadingService.show();
        this.tabView = index;
        if (index == 0) {
            this.getOperationsOnGoingData(null);
        } else if (index == 1) {
            this.getOperationsFinishedData(null);
        } else if (index == 2) {
            this.getOperationsCanceledData(null);
        }
        this.loadingService.hide();
    }

    close() {
        this.displayOperationDetail = false;
        this.displayOperationInit = false;
        this.displayOperationCancel = false;
        this.displayReasonOperation = false;
    }


    async operationDetails(externalOperation: ExternalOperationViewModel) {
        this.displayOperationDetail = true;
        // this.selectedOperation = operation;
        try {
            // this.loadingService.show();
            const operationId = externalOperation.id;
            this.selectedExternalOperation = await this.externalOperationService.getOperationById(operationId);
            this.selectedExternalOperation.history.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
            });
            this.selectedExternalOperation.history = this.selectedExternalOperation.history.sort((a, b) => {
                if (a.createDate > b.createDate) {
                    return 1;
                } else {
                    return -1;
                }
            });
            // this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationDetail = false;
        } finally {
            // await this.getOperationsData();
            // this.loadingService.hide();
        }
    }

    operationErrorInit(operation: ExternalOperationViewModel) {
        this.selectedExternalOperation = operation;
    }

    operationSetup(externalOperation: ExternalOperationViewModel) {
        if (externalOperation.currentStatus.status.displayName == 'ErroCadastral') {
            this.displayOperationInit = true;
            this.operationErrorInit(externalOperation);
            return;
        }
        return this.router.navigateByUrl(`app/customer/external-operation/setup/${externalOperation.id}`);
    }

    async retryOperation(operation: ExternalOperationViewModel) {
        try {
            this.loadingService.show();
            const operationId = operation.id;
            await this.externalOperationService.retriesOperation(operationId);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.getOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }

    reasonOperation(operation: ExternalOperationViewModel) {
        this.displayReasonOperation = true;
        this.selectedExternalOperationDisplay = operation;
    }

    openDownloadFile(operation: OperationViewModel) {
        window.open(operation.sourceFileUrl, "_blank");
    }

    getMenuItems(externalOperation: ExternalOperationViewModel): MenuItem[] {
        this.menuItems = [];
        if (externalOperation.currentStatus?.status?.value == OperationStatusEnum.ErroCadastral ||
            externalOperation.currentStatus?.status?.value == OperationStatusEnum.ErroDePreenchimento &&
            !this.isUserOperator) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Tentar novamente', command: e => this.retryOperation(externalOperation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(externalOperation) },
                { label: 'Motivo', command: e => this.reasonOperation(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (externalOperation.currentStatus?.status?.value == OperationStatusEnum.Concluída &&
            !this.isUserOperator) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Ver', command: e => this.operationSetup(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (externalOperation.currentStatus?.status?.value == OperationStatusEnum.Cancelada &&
            !this.isUserOperator) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Ver', command: e => this.operationSetup(externalOperation) },
                { label: 'Motivo', command: e => this.reasonOperation(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (externalOperation.currentStatus?.status?.value == OperationStatusEnum.EmSetup &&
            !this.isUserOperator) {
            this.menuItems.push(
                { label: 'Atualizar Setup', command: e => this.retryOperation(externalOperation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Iniciar', command: e => this.operationSetup(externalOperation) },
                { label: 'Motivo', command: e => this.reasonOperation(externalOperation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (externalOperation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoManualNecessario ||
            externalOperation.currentStatus?.status?.value == OperationStatusEnum.PreenchimentoConcluido ||
            externalOperation.currentStatus?.status?.value == OperationStatusEnum.AguardandoAssinatura ||
            externalOperation.currentStatus?.status?.value == OperationStatusEnum.PendenciadaPeloGestor &&
            !this.isUserOperator) {
            this.menuItems.push(
                { label: 'Atualizar Setup', command: e => this.retryOperation(externalOperation) },
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Continuar', command: e => this.operationSetup(externalOperation) },
                { label: 'Motivo', command: e => this.reasonOperation(externalOperation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (!this.isUserOperator) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
                { label: 'Continuar', command: e => this.operationSetup(externalOperation) },
                { label: 'Cancelar Operação', command: e => this.cancelOperation(externalOperation) },
                { label: 'Editar ID Legado', command: e => this.openEditLegacyId(externalOperation) },
            )
            return this.menuItems;
        } else if (this.isUserOperator) {
            this.menuItems.push(
                { label: 'Ver detalhes', command: e => this.operationDetails(externalOperation) },
            )
            return this.menuItems;
        }

    }

    createOperation() {
        this.router.navigate([`app/customer/integration/create`], { queryParams: { integration: 'operation' } })
    }

    cancelOperation(operation: ExternalOperationViewModel) {
        this.displayOperationCancel = true;
        this.selectedOperationCancel = operation;
    }

    async confirmCancelOperation(formComponent: NgForm) {
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        try {
            this.displayOperationCancel = false;
            this.loadingService.show();
            const request: CancelOperationRequest = {
                operationId: this.selectedOperationCancel.id,
                notes: this.requestOperationCancel.notes,
            };
            this.selectedExternalOperation = await this.externalOperationService.cancelOperation(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormCancelOperation();
            await this.getOperationsOnGoingData(null);
            this.loadingService.hide();
        }
    }


    openCancelOperations() {
        this.selectedCancelOperations = [];
        this.displayCancelOperations = true;
    }

    async handleSaveCancelOperations() {
        if (this.selectedNoteCancelOperations == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `Informe um motivo`,
                life: 5000
            });
            return;
        }
        this.displayCancelOperations = false;
        this.loadingService.show();
        const uniqueId = new Set();
        const cancelOperations = this.selectedCancelOperations.filter(obj => {
            if (uniqueId.has(obj.value)) {
                return false;
            }
            uniqueId.add(obj.value);
            return true;
        });
        for (let item of cancelOperations) {
            try {
                const request: CancelOperationRequest = {
                    operationId: item.value,
                    notes: this.selectedNoteCancelOperations
                };
                this.selectedExternalOperation = await this.externalOperationService.cancelOperation(request);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Alteração efetuada com sucesso',
                    detail: `Os dados foram atualizados com sucesso!`,
                    life: 5000
                });
                this.selectedCancelOperations = [];
                this.selectedCancelOperation = null;
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            }
        }
        this.getOperationsOnGoingData(null);
        this.loadingService.hide();

    }


    async handleSearchOperation() {
        const request: GetOperationsByFilterRequest = {
            legacySystemId: null,
            operationSequentialId: Number(this.selectedCancelOperation),
            operationTypeId: null,
            operationStatusId: null,
            shareholderFundId: null,
            investedFundId: null,
            investedFundManagerId: null,
            investedFundAdministradorId: null,
            investedFundDistributorDocumentNumber: null,
            startDate: null,
            endDate: null,
            destinationShareholderFundId: null,
        }
        const response = await this.externalOperationService.getOperationsByFilter(request);
        if (response.length == 0) {
            this.messageService.add({
                severity: 'error',
                summary: 'Houve um erro',
                detail: `ID da operação não existe!`,
                life: 5000
            });
            return;
        }
        response.forEach(c => {
            if (c.currentStatus.status.value == OperationStatusEnum.Cancelada || c.currentStatus.status.value == OperationStatusEnum.Concluída) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Houve um erro',
                    detail: `A operação já está com o status ${c.currentStatus.status.friendlyName}`,
                    life: 5000
                });
                return;
            }
            this.selectedCancelOperations.push({
                label: `ID LIZA: ${c.sequentialId} - Operação: ${c.type.friendlyName} - Status: ${c.currentStatus.status.friendlyName}`,
                value: c.id
            });
        });
        this.selectedCancelOperation = null;
    }

    removeSelectedCancelOperation(operation: SelectItem) {
        const indexOperation = this.selectedCancelOperations.findIndex(c => c.value == operation.value);
        this.selectedCancelOperations.splice(indexOperation, 1);
    }

    handleEnterKeyPress(event: KeyboardEvent) {
        if (event.key == 'Enter') {
            this.handleSearchOperation();
        }
    }

    breakTextLine(notes: string) {
        if (notes.includes('\n')) {
            notes = notes.split(/\n+/).join('<br>');
        }
        return notes;
    }

    openEditLegacyId(operation: ExternalOperationViewModel) {
        this.displayLegacyId = true;
        this.selectedExternalOperationLegacyId = operation;
    }

    async onSaveLegacyId() {
        if (this.operationlegacyId == null) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `O campo ID Legado é obrigatório!`,
                life: 5000
            });
            return;
        }
        const request: UpdateOperationLegacySystemIdRequest = {
            operationId: this.selectedExternalOperationLegacyId.id,
            legacySystemId: this.operationlegacyId,
        };
        this.displayLegacyId = false;
        this.loadingService.show();
        try {
            await this.externalOperationService.updateSystemLegacyId(request);
            this.messageService.add({
                severity: 'success',
                summary: 'Alteração efetuada com sucesso',
                detail: `Os dados foram atualizados com sucesso!`,
                life: 5000
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.getOperationsOnGoingData(null);
        }
    }
}