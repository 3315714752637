import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './representation-view.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class RepresentationViewComponent implements OnInit {

    procuratorSeleceted: RepresentationViewModel = null;
    // typeUser: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private representationService: RepresentationService) { }

    async ngOnInit() {
        this.loadingService.show();
        await this.verifyUserPermissions();
        await this.getProcurator();
        this.loadingService.hide();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        
        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    async getProcurator() {
        const procuratorId = this.route.snapshot.params.id;
        await this.getProcuratorById(procuratorId).then(() => {
            this.procuratorSeleceted.expireDate = new Date(this.procuratorSeleceted.expireDate);
            // this.selectedExpireDate = new Date(this.procuratorSeleceted.expireDate);
            // this.selectedBirthDate = new Date(this.procuratorSeleceted.birthDate);
            // this.selectedAdditionalDocumentIssueDate = new Date(this.procuratorSeleceted.additionalDocumentIssueDate)
            // this.selectedGender = this.procuratorSeleceted.gender;
            // this.selectedAddressState = this.procuratorSeleceted.addressState;
            // this.selectedBirthState = this.procuratorSeleceted.birthState;
            // this.selectedAdditionalDocumentType = this.procuratorSeleceted.additionalDocumentType;
            // this.selectedAdditionalDocumentIssuingBodyState = this.procuratorSeleceted.additionalDocumentIssuingBodyState;
        });
    }

    async getProcuratorById(procuratorId: string) {
        this.procuratorSeleceted = await this.representationService.getRepresentationById(procuratorId);
    }

    onCancel() {
        this.router.navigateByUrl('app/customer/representation/list');
    }

    onEdit(procuratorSeleceted: RepresentationViewModel) {
        this.router.navigateByUrl(`app/customer/representation/edit/${procuratorSeleceted.id}`);
    }

    async inactive(procurator: RepresentationViewModel) {
        try {
            this.loadingService.show();
            await this.representationService.deleteRepresentationById(procurator.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.router.navigateByUrl('app/customer/representation/list');
            this.loadingService.hide();
        }
    }

    confirm(procurator: RepresentationViewModel) {
        this.confirmationService.confirm({
            header: `Procurador ${procurator.name}`,
            message: `Tem certeza que deseja Inativar ${procurator.name}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.inactive(procurator);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

}