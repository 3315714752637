import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { CryptoService } from 'src/app/providers/crypto-service';
import { EnvironmentService } from 'src/app/providers/environment-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { ActivateUserRequest } from 'src/app/services/users/models/requests/activate-user-request';
import { ResendOperationCodeRequest } from 'src/app/services/users/models/requests/resend-operation-code-request';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { UserService } from 'src/app/services/users/user-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss'],
})
export class UserActivationComponent implements OnInit, AfterViewInit {

  activateFormFields: any = { documentNumber: null, activationCode: null, saltPassword: null, birthdate: null };
  confirmPassword: string = null;
  maxDate: Date = null;
  user: UserViewModel = null;

  constructor(
    private contextService: ContextService,
    private cryptoService: CryptoService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private formValidationService: FormValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private envService: EnvironmentService,
    private elementRef: ElementRef,
  ) {
    this.contextService.clearUserInfo();
    const today = new Date();
    this.maxDate = new Date(today.getUTCFullYear() - 18, today.getUTCMonth() - 1, today.getUTCDate());
  }


  ngAfterViewInit() {
    this.verifyContext();
  }

  ngOnInit() {
    this.getUser();
  }

  async getUser() {

    this.loadingService.show();
    const userId = this.route.snapshot.paramMap.get('userId');
    
    this.user = await this.userService.get(userId, false);
    this.loadingService.hide();

    if (this.user.birthdate != null) {
      this.activateFormFields.birthdate = new Date(this.user.birthdate);
    }

    if (this.user.emailActivationCodeExpirationDate == null && this.user.cellPhoneActivationCodeExpirationDate == null) {      
      this.messageService.add({ severity: 'warn', summary: 'Não Permitido', detail: `O usuário ${this.user.fullName} não está em processo de ativação`, life: 5000 });
      this.router.navigateByUrl('login');
    }
    
  }

  async activateUser(formComponent: NgForm) {
    
    if (!this.formValidationService.validateForm(formComponent.form)) return;

    this.loadingService.show();
    try {
      const ip: any = await this.envService.getIPAddress();

      const request : ActivateUserRequest = {
        userId: this.user.id,
        documentNumber: this.activateFormFields.documentNumber,
        activationCode: this.activateFormFields.activationCode,
        saltPassword: this.cryptoService.encryptMessage(this.confirmPassword),
        birthdate: this.activateFormFields.birthdate,
        activationIp: ip.ip,
        activationBrowser: this.envService.myBrowser(),
        activationOperationalSystem: this.envService.getOS()
      };
      
      await this.userService.activate(request);
      this.messageService.add({ severity: 'success', summary: 'Usuário Ativado', detail: `O usuário ${this.user.fullName} foi ativado! Prossiga com o Login`, life: 5000 });    
      this.router.navigateByUrl('login');      
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Não foi possível efetuar a alteração',
        detail: `${error?.messageError}!`,
        life: 5000
    });
    } finally {
      this.loadingService.hide(200);
    }

  }


  async resendCode() {

    this.loadingService.show();
    const request: ResendOperationCodeRequest = {
      EmailOrCellPhone: this.user.email != null ? this.user.email : this.user.cellPhone,
      operationType: 'Activation'
    };

    const response = await this.userService.resendOperationCode(request);
    this.user.emailActivationCodeExpirationDate = new Date(response.codeExpirationDate);
    this.user.resendCodesAllowed = response.remaingResendAttempts;

    this.loadingService.hide();
  }

  verifyContext() {
    if(environment?.context !== "") {
      let logo = this.elementRef.nativeElement.getElementsByClassName('ocLogo');
        logo[0].style.backgroundImage = `url(${environment.logoUrl})`;
        logo[0].style.height = `auto`;
        logo[0].style.aspectRatio = `16 / 9`;
        logo[0].style.width = `220px`;
    }
  }
}
