<div class="p-inputgroup">
    <!--<p-selectButton [options]="searchModes" [(ngModel)]="selectedMode" optionLabel="label" (onChange)="handleSearchMode()" class="p-mr-2"></p-selectButton>-->
    <span class="p-float-label">
        <input type="text" name="address" [id]="autocompleteId" [(ngModel)]="addressInformation.inputValue"
            (focus)="clearCurrentAddressParamsFromUI()" #address="ngModel"
            [class]="isCompleteAddressRequired && address.invalid && address.dirty ? 'ng-invalid ng-dirty' : ''" placeholder=""
            pInputText [hidden]="selectedMode.value != 'address'">

        <p-inputMask
            
            class="inputLizaMask" inputId="postalCodeSearch" type="tel" mask="99999-999" [unmask]="true"
            [(ngModel)]="addressInformation.inputValue" #postalCodeSearch="ngModel"  [hidden]="selectedMode.value == 'address'">
            </p-inputMask>

        <label for="CustomerCustomerDocumentNumber">{{selectedMode.value == 'address' ? 'Digite o endereço' : 'Digite o CEP'}}</label>
    </span>
    <!-- <button pButton type="button" label="Pesquisar" class="p-button-outlined"></button> -->
    <button pButton type="button" class="p-button-primary p-button-outlined p-col-12 p-md-6 p-lg-4" label="Pesquisar" (click)="checkValue($event)"></button>
</div>


<div [hidden]="!addressSelected" class="customCard p-mt-2">
    <h6>Revise os dados do endereço</h6>
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-col-9">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input class="inputLiza" type="text" name="street" id="street" [(ngModel)]="addressInformation.street"
                        (ngModelChange)="validateAddressAndSend()" #street="ngModel"
                        [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && street.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''"
                        pInputText>
                    <label for="street">Logadouro</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && street.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
        <div class="p-col-3">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input class="inputLiza" type="text" name="addressNumber" id="addressNumber"
                        [(ngModel)]="addressInformation.addressNumber" (ngModelChange)="validateAddressAndSend()"
                        #addressNumber="ngModel" [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && addressNumber.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''"
                        pInputText>
                    <label for="addressNumber">Número</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && addressNumber.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
    </div>
    <div class="p-grid p-formgrid p-mt-3">
        <div class="p-col-4">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input class="inputLiza" type="text" name="complement" id="complement" [(ngModel)]="addressInformation.complement"
                        (ngModelChange)="validateAddressAndSend()" #complement="ngModel" pInputText>
                    <label for="complement">Complemento</label>
                </span>
            </div>
        </div>
        <div class="p-col-8">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input class="inputLiza" type="text" name="neighborhood" id="neighborhood"
                        [(ngModel)]="addressInformation.neighborhood" (ngModelChange)="validateAddressAndSend()"
                        #neighborhood="ngModel" [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && neighborhood.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''"
                        pInputText>
                    <label for="neighborhood">Bairro</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && neighborhood.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
    </div>
    <div class="p-grid p-formgrid p-mt-3">
        <div class="p-col-3">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <p-inputMask class="inputLizaMask" inputId="postalCode" type="tel" mask="99999-999" [unmask]="true"
                        [(ngModel)]="addressInformation.postalCode" (ngModelChange)="validateAddressAndSend()"
                        #postalCode="ngModel" [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && postalCode.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''">
                    </p-inputMask>
                    <label for="postalCode">CEP</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && postalCode.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
        <div class="p-col-5">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input class="inputLiza" type="text" name="city" id="city" [(ngModel)]="addressInformation.city"
                        (ngModelChange)="validateAddressAndSend()" #city="ngModel"
                        [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && city.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''"
                        pInputText>
                    <label for="city">Cidade</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && city.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
        <div class="p-col-4">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <p-dropdown [options]="provinceOptions" [(ngModel)]="selectedProvince" optionLabel="name"
                        (onChange)="provinceChanged()" [filter]="true" filterBy="label"
                        emptyFilterMessage="Nenhum item encontrado" filterPlaceholder="Digite para filtrar" name="province"
                        #province="ngModel" [required]="true">
                    </p-dropdown>
                    <label for="province">Estado</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && province.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
    </div>
    <!-- <div class="p-grid p-form-grid p-mt-3">
        <div class="p-col-2">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input type="text" name="provinceAbbreviation" id="provinceAbbreviation"
                        [(ngModel)]="addressInformation.provinceAbbreviation" (ngModelChange)="validateAddressAndSend()"
                        #provinceAbbreviation="ngModel" [required]="isCompleteAddressRequired"
                        [class]="isCompleteAddressRequired && provinceAbbreviation.invalid && addressSelected ? 'ng-invalid ng-dirty' : ''"
                        pInputText>
                    <label for="provinceAbbreviation">Estado</label>
                </span>
            </div>
            <mark *ngIf="isCompleteAddressRequired && provinceAbbreviation.invalid && addressSelected"
                class="ui-message ui-messages-error ui-corner-all">Campo
                obrigatório</mark>
        </div>
    </div> -->
</div>
