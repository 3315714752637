import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { RoleOptionEnum } from 'src/app/enums/role-option-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundService } from 'src/app/services/funds/fund-service';
import { UpdateAdhesionTermTemplateRequest } from 'src/app/services/funds/models/request/update-adhesion-term-template-request';
import { UpdateSubscriptionFormTemplateRequest } from 'src/app/services/funds/models/request/update-subscription-form-template-request';
import { BrokerEntryViewModel } from 'src/app/services/funds/models/view-models/broker-entry-view-model';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';

@Component({
    templateUrl: './fund-view.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundViewComponent implements OnInit {

    selectedFund: FundViewModel = null;
    selectedApplication: ApplicationResponseViewModel = null;
    requestModelAdhesion: CreateArtifactTemplateRequest = null;
    requestModelSubscription: CreateArtifactTemplateRequest = null;
    displayAdhesionTermTemplate: boolean = false;
    displaySubscriptionFormTemplate: boolean = false;
    // typeUser: string = null;
    user: UserViewModel = null;
    isUserOperator: boolean = false;
    isUserAnalist: boolean = false;
    isUserAdministrator: boolean = false;
    brokerEntries: BrokerEntryViewModel[] = [];
    expiredBrokerEntries: BrokerEntryViewModel[] = [];
    aboutToExpireBrokerEntries: BrokerEntryViewModel[] = [];

    public applicationTypeEnum = ApplicationTypeEnum;
    public fundTypeEnum = FundTypeEnum;

    constructor(
        private fundService: FundService,
        private router: Router,
        private messageService: MessageService,
        private loadingService: LoadingService,
        private contextService: ContextService,
        private route: ActivatedRoute) {
    }

    async ngOnInit() {
        this.setForms();
        await this.verifyUserPermissions();
        await this.getFund();
    }

    async verifyUserPermissions() {
        this.user = this.contextService.getUserInfo().user;
        this.selectedApplication = this.contextService.getUserApplicationSelected();
        // this.typeUser = user.userRoles[0].role.friendlyName;

        this.isUserOperator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofOperator ||
            c.role.value == RoleOptionEnum.CustomerBrokerOperator).length > 0 ? true : false;

        this.isUserAnalist = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAnalist ||
            c.role.value == RoleOptionEnum.CustomerBrokerAnalist).length > 0 ? true : false;

        this.isUserAdministrator = this.user.userRoles.filter(c =>
            c.role.value == RoleOptionEnum.CustomerFofAdministrator ||
            c.role.value == RoleOptionEnum.CustomerBrokerAdministrator).length > 0 ? true : false;
    }

    setForms() {
        this.requestModelAdhesion = null
        this.requestModelSubscription = null
    }


    async getFund() {
        const fundId = this.route.snapshot.params.id;
        this.selectedFund = await this.fundService.getFundById(fundId);
        this.selectedFund.documentNumber = this.parseCNPJ(this.selectedFund.documentNumber);
        this.selectedFund.administrator ? this.selectedFund.administrator.documentNumber = this.parseCNPJ(this.selectedFund?.administrator?.documentNumber) : "-";
        this.selectedFund.manager ? this.selectedFund.manager.documentNumber = this.parseCNPJ(this.selectedFund?.manager?.documentNumber) : "-";
        this.selectedFund.fundDistributorDocumentNumber = this.parseCNPJ(this.selectedFund?.fundDistributorDocumentNumber);
        this.selectedFund.custodianDocumentNumber = this.parseCNPJ(this.selectedFund?.custodianDocumentNumber);
        this.selectedFund.validBrokerEntries = this.selectedFund.validBrokerEntries.sort((a, b) => {
            if (a.broker.name > b.broker.name) {
                return 1
            } else {
                return -1
            }
        });
        this.selectedFund.expiredBrokerEntries = this.selectedFund.expiredBrokerEntries.sort((a, b) => {
            if (a.broker.name > b.broker.name) {
                return 1
            } else {
                return -1
            }
        });
        this.selectedFund.aboutToExpireBrokerEntries = this.selectedFund.aboutToExpireBrokerEntries.sort((a, b) => {
            if (a.broker.name > b.broker.name) {
                return 1
            } else {
                return -1
            }
        });
        this.selectedFund.validBrokerEntries.map(c => {
            if (c.notes.includes('Aprovado')) {
                let cutNotes: string[] = null;
                cutNotes = c.notes.split(' ')
                c.notes = cutNotes[2];
            } else {
                c.notes = c.notes
            }
        });
        this.selectedFund.expiredBrokerEntries.map(c => {
            if (c.notes.includes('Aprovado')) {
                let cutNotes: string[] = null;
                cutNotes = c.notes.split(' ')
                c.notes = cutNotes[2];
            } else {
                c.notes = c.notes
            }
        });
        this.selectedFund.aboutToExpireBrokerEntries.map(c => {
            if (c.notes.includes('Aprovado')) {
                let cutNotes: string[] = null;
                cutNotes = c.notes.split(' ')
                c.notes = cutNotes[2];
            } else {
                c.notes = c.notes
            }
        });
        this.brokerEntries = this.selectedFund.validBrokerEntries;
        this.expiredBrokerEntries = this.selectedFund.expiredBrokerEntries;
        this.aboutToExpireBrokerEntries = this.selectedFund.aboutToExpireBrokerEntries;
    }


    parseCNPJ(value: string): string {
        if (value) {
            var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
            return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
        } else {
            return "-";
        }
    }

    async letterTemplateFilledAdhesion(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelAdhesion = createArtifactTemplateRequest;

        const requestAdhesion: UpdateAdhesionTermTemplateRequest = {
            fundId: this.selectedFund.id,
            adhesionTermTemplateRequest: this.requestModelAdhesion
        }

        try {
            this.loadingService.show();
            await this.fundService.createAdhesionTermTemplates(requestAdhesion);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.router.navigateByUrl('app/customer/fund/list');
        }
    }

    async letterTemplateFilledSubscription(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModelSubscription = createArtifactTemplateRequest;

        const requestSubscription: UpdateSubscriptionFormTemplateRequest = {
            fundId: this.selectedFund.id,
            subscriptionFormTemplateRequest: this.requestModelSubscription
        }

        try {
            this.loadingService.show();
            await this.fundService.createSubscriptionFormTemplates(requestSubscription);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.router.navigateByUrl('app/customer/fund/list');
        }
    }

    onCancel() {
        // this.router.navigateByUrl('app/customer/fund/list');
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            this.router.navigate([`app/customer/fund/list`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            this.router.navigate([`app/customer/fund/list`]);
        }
    }

    onEdit() {
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            return this.router.navigate([`app/customer/fund/edit/${this.selectedFund.id}`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            return this.router.navigate([`app/customer/fund/edit/${this.selectedFund.id}`]);
        }
    }

    async updateCVM() {
        this.loadingService.show();
        const fundId = this.route.snapshot.params.id;
        try {
            this.selectedFund = await this.fundService.updateCVM(fundId);
            this.messageService.add({
                severity: 'success',
                summary: 'Alteração efetuada com sucesso',
                detail: `Os dados foram atualizados com sucesso!`,
                life: 5000
            });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.getFund();
        }
    }

    openChangeAdhesionTermTemplate() {
        this.displayAdhesionTermTemplate = true;
    }

    openChangeSubscriptionTemplate() {
        this.displaySubscriptionFormTemplate = true;
    }

    openFundEdit() {
        const fundId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`app/customer/fund/edit/${fundId}`);
    }


}