import { MapsAPILoader } from "@agm/core";
import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output } from "@angular/core";
import { MessageService, SelectItem } from "primeng/api";
import { LocationService } from "src/app/services/locations/location-service";
import { ProvinceViewModel } from "src/app/services/locations/models/view-models/province-view-model";
import { LoadingService } from "../loading/app.loading.service";

export class AddressInformation {
    inputValue: string;
    street: string;
    neighborhood: string;
    addressNumber: string;
    complement: string;
    postalCode: string;
    city: string;
    province: string;
    provinceAbbreviation: string;
    latitudeLocation: number;
    longitudeLocation: number;
    placeId: string;
    isValid: boolean;
    country: string;
}

@Component({
    selector: 'app-address-form',
    templateUrl: 'address-form.component.html'
})
export class AddressFormComponent implements AfterViewInit {

    @Input() isCompleteAddressRequired = false;

    @Input() autocompleteId: string = null;

    @Input()
    set editAddressParams(addressReceived: AddressInformation) {
        this.addressInformation = addressReceived;
        this.handleReceivedAddress();
    }

    @Output() onSelect: EventEmitter<AddressInformation> = new EventEmitter<AddressInformation>();

    provinceOptions: ProvinceViewModel[] = [];
    selectedProvince: ProvinceViewModel = null;

    searchModes: SelectItem[] = [{ label: 'CEP', value: 'postalCode' }, { label: 'Rua', value: 'address' }];
    selectedMode: SelectItem = { label: 'CEP', value: 'postalCode' };

    addressAutoComplete: HTMLInputElement = null;
    addressAutoCompleteHandler: any;

    addressInformation: AddressInformation = null;
    addressSelected = false;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private locationService: LocationService,
        private loadingService: LoadingService,
        private messageService: MessageService
    ) { }

    async ngAfterViewInit() {
        if (this.selectedMode.value == 'address') {
            await this.prepareAddressAutoComplete();
        }

        setTimeout(() => {
            this.addressSelected = this.addressInformation != null && this.addressInformation.isValid;
        }, 200);
    }

    async prepareAddressAutoComplete() {
        this.addressAutoComplete = <HTMLInputElement>document.getElementById(this.autocompleteId);

        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.addressAutoComplete);
            autocomplete.addListener("place_changed", () => {
                this.ngZone.run(async () => {
                    let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    if (place.address_components != null && place.address_components.length > 0) {

                        this.addressInformation.placeId = place.place_id;
                        this.addressInformation.inputValue = place.formatted_address;
                        this.addressInformation.street = place.address_components.find(c => c.types.findIndex(c => c == 'route') != -1)?.long_name;
                        this.addressInformation.neighborhood = place.address_components.find(c => c.types.findIndex(c => c == 'sublocality') != -1)?.long_name;
                        this.addressInformation.city = place.address_components.find(c => c.types.findIndex(c => c == 'administrative_area_level_2') != -1)?.long_name;
                        this.addressInformation.addressNumber = place.address_components.find(c => c.types.findIndex(c => c == 'street_number') != -1)?.long_name;
                        this.addressInformation.postalCode = place.address_components.find(c => c.types.findIndex(c => c == 'postal_code') != -1)?.long_name;
                        this.addressInformation.province = place.address_components.find(c => c.types.findIndex(c => c == 'administrative_area_level_1') != -1)?.long_name;
                        this.addressInformation.provinceAbbreviation = place.address_components.find(c => c.types.findIndex(c => c == 'administrative_area_level_1') != -1)?.short_name;
                        this.addressInformation.latitudeLocation = place.geometry.location.lat();
                        this.addressInformation.longitudeLocation = place.geometry.location.lng();
                        this.addressInformation.country = place.address_components.find(c => c.types.findIndex(c => c == 'country') != -1)?.long_name;

                        this.addressSelected = true;
                        await this.handleProvince();
                        this.validateAddressAndSend();

                    } else {
                        this.messageService.add({ severity: 'error', summary: 'CEP Inválido', detail: `O CEP ${this.addressInformation.inputValue} não é valido`, life: 5000 });
                        this.clearCurrentAddressParams();
                    }
                });
            });
        });
    }

    async getAddressByPostalCode() {
        if (this.addressInformation.inputValue) {

            this.loadingService.show();

            const regex = /\D/g;

            const postalCode = this.addressInformation.inputValue.replace(regex, '');

            if (postalCode.length == 8) {

                const addressResult = await this.locationService.getAddressByPostalCode(postalCode);

                this.addressInformation.inputValue = postalCode;
                this.addressInformation.street = addressResult.street;
                this.addressInformation.neighborhood = addressResult.neighborhood;
                this.addressInformation.city = addressResult.city;
                this.addressInformation.addressNumber = addressResult.addressNumber;
                this.addressInformation.postalCode = addressResult.postalCode;
                this.addressInformation.province = addressResult.province
                this.addressInformation.provinceAbbreviation = addressResult.provinceAbbreviation
                this.addressInformation.latitudeLocation = addressResult.latitudeLocation;
                this.addressInformation.longitudeLocation = addressResult.longitudeLocation;

                this.addressSelected = true;
                await this.handleProvince();
                this.validateAddressAndSend();
                this.loadingService.hide();

            } else {
                this.messageService.add({ severity: 'error', summary: 'CEP Inválido', detail: `O CEP ${this.addressInformation.inputValue} não é valido`, life: 5000 });
                this.loadingService.hide(4000);
            }
        }
    }

    validateAddressAndSend() {
        if (this.addressInformation != null && this.isCompleteAddressRequired) {
            this.addressInformation.isValid = (
                this.addressInformation.addressNumber != null &&
                this.addressInformation.city != null &&
                // this.addressInformation.latitudeLocation != null &&
                // this.addressInformation.longitudeLocation != null &&
                this.addressInformation.neighborhood != null &&
                this.addressInformation.postalCode != null &&
                this.addressInformation.province != null &&
                this.addressInformation.provinceAbbreviation != null &&
                this.addressInformation.street != null);
        }
        this.addressSelected = true;
        this.onSelect.emit(this.addressInformation);
    }

    async handleReceivedAddress() {
        if (this.addressInformation != null && this.addressInformation.inputValue != null) {
            await this.handleProvince();
            this.validateAddressAndSend()
        } else {
            this.clearCurrentAddressParams();
        }
    }

    clearCurrentAddressParams(selectedAddressOption: string = null) {

        this.addressSelected = false;
        this.selectedProvince = null;

        this.addressInformation = new AddressInformation();
        this.addressInformation.inputValue = selectedAddressOption;
        this.addressInformation.city = null;
        this.addressInformation.neighborhood = null;
        this.addressInformation.complement = null;
        this.addressInformation.addressNumber = null;
        this.addressInformation.addressNumber = null;
        this.addressInformation.postalCode = null;
        this.addressInformation.province = null;
        this.addressInformation.provinceAbbreviation = null;
        this.addressInformation.street = null;
        this.addressInformation.latitudeLocation = null;
        this.addressInformation.longitudeLocation = null;
        this.addressInformation.isValid = false;
    }

    clearCurrentAddressParamsFromUI() {
        // console.log('address-form-component/clearCurrentAddressParamsFromUI()');
        if (this.addressSelected) {
            this.clearCurrentAddressParams(this.addressInformation.inputValue);
            this.onSelect.emit(this.addressInformation);
        }
    }

    async handleProvince() {
        if (this.provinceOptions.length == 0) {
            this.provinceOptions = await this.locationService.getProvices();
            this.provinceOptions.unshift({ name: 'Selecione', abbreviation: null });
        }
        if (this.addressInformation.provinceAbbreviation) {
            this.selectedProvince = this.provinceOptions.find(c => c.abbreviation == this.addressInformation.provinceAbbreviation);
            this.addressInformation.province = this.selectedProvince.name;
        } else if (this.addressInformation.province) {
            this.selectedProvince = this.provinceOptions.find(c => c.name == this.addressInformation.province);
            this.addressInformation.provinceAbbreviation = this.selectedProvince.abbreviation;
        } else {
            this.selectedProvince = null;
        }
    }

    provinceChanged() {
        if (this.selectedProvince.abbreviation == null) {
            this.selectedProvince = null;
        } else {
            this.addressInformation.province = this.selectedProvince.name;
            this.addressInformation.provinceAbbreviation = this.selectedProvince.abbreviation;
            this.validateAddressAndSend();
        }
    }

    checkValue($event){
        if(this.addressInformation.inputValue){
            if(this.addressInformation.inputValue.length == 8){
                this.getAddressByPostalCode();
            }
        }

    }
}
