import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { CryptoService } from 'src/app/providers/crypto-service';
import { UserService } from 'src/app/services/users/user-service';

import { VersionService } from 'src/app/providers/version-service';
import { LoginRequest } from 'src/app/services/users/models/requests/login-request';

// Azure AD.
import { Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, RedirectRequest } from '@azure/msal-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginSingleSignOnUserRequest } from 'src/app/services/users/models/requests/login-sso-request';
import { environment } from 'src/environments/environment';
import { HttpHandledError } from './../../providers/models/http-handled-error';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss'],
})
export class AppLoginComponent implements AfterViewInit {

  version: string = '';
  loginFormFields: LoginRequest = { identificationType: null, userIdentification: null, saltPassword: null };
  idModes: SelectItem[] = [{ label: 'Email', value: 'Email' }, { label: 'Celular', value: 'Celular' }, { label: 'Cpf', value: 'Cpf' }];
  selectedMode: SelectItem = { label: 'Email', value: 'Email' };
  accessLiza: boolean = false;

  // Azure AD
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  isFoF = environment.logoUrl;
  
  constructor(
    private cryptoService: CryptoService,
    private userService: UserService,
    private router: Router,
    private loadingService: LoadingService,
    private versionService: VersionService,
    // Azure AD.
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private elementRef: ElementRef,
    private messageService: MessageService,
  ) {
    this.version = this.versionService.getVersion();
  }


  ngAfterViewInit(): void {
    this.verifyContext();
  }

  async login(loginForm: NgForm) {

    if (loginForm.valid) {
      this.loadingService.show();

      const loginRequest: LoginRequest = {
        identificationType: this.selectedMode.value,
        userIdentification: this.loginFormFields.userIdentification,
        saltPassword: this.cryptoService.encryptMessage(this.loginFormFields.saltPassword)
      };
      this.userService.login(loginRequest);
    }
  }

  forgotPassword() {
    this.router.navigateByUrl('forgot-password')
  }

  registerForm() {
    this.router.navigateByUrl('entry-customer-account')
  }

  verifyContext() {
    if(environment?.context !== "") {
      this.accessLiza = true;
      let logo = this.elementRef.nativeElement.getElementsByClassName('ocLogo');
        logo[0].style.backgroundImage = `url(${environment.logoUrl})`;
        logo[0].style.height = `auto`;
        logo[0].style.aspectRatio = `16 / 9`;
        logo[0].style.width = `220px`;
    }
  }

  ngOnInit() {
    if(environment?.context !== "") {
      this.accessLiza = true;
    }

    // Azure AD.
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log('app.login', 'result', result);

        const payload = result.payload as AuthenticationResult;
        console.log('app.login', 'payload', payload);

        this.performSingleSignOnLogin(payload);
      });
  }

  performSingleSignOnLogin(authenticationPayload: AuthenticationResult) {
    console.log('app.login', 'performSingleSignOnLogin()', 'authenticationPayload', authenticationPayload);

    this.loadingService.show();

    const loginRequest: LoginSingleSignOnUserRequest = {
        token: authenticationPayload.idToken,
        userEntry: null
    };

    const response: void | HttpHandledError | any = this.userService.loginSingleSignOn(loginRequest);
    if (response?.statusCode >= 400 || response?.statusCode >= 500) {
      this.messageService.add({ severity: 'error', summary: 'Erro ao realizar login SSO.', detail: `${response?.messageError}`, life: 5000 });
    }
    this.authService.instance.setActiveAccount(authenticationPayload.account);
  }

  loginAzureActiveDirectory() {

    // // TODO: Itau: Remover após testes.
    // const loginRequest: LoginSingleSignOnUserRequest = {
    //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6IlQxU3QtZExUdnlXUmd4Ql82NzZ1OGtyWFMtSSJ9.eyJhdWQiOiI4NDkzM2U5Ni02OTM5LTQ1OWYtOWQ2ZC05YTYzZGNhNzE3YjEiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vNTkxNjY5YTAtMTgzZi00OWE1LTk4ZjQtOWFhMGQwYjYzZDgxL3YyLjAiLCJpYXQiOjE3MDEyMDM3OTYsIm5iZiI6MTcwMTIwMzc5NiwiZXhwIjoxNzAxMjA3Njk2LCJncm91cHMiOlsiNGNmZTYzMGItYzg4MC00OTQxLTg5MmYtMjcxYzgyYWY4YTY1IiwiZDg0MzU1Y2QtMGM3My00YzUwLTgyYzAtNTQ3OGQ0NzBkM2U3Il0sIm5hbWUiOiJXZWxpbmd0b24gQmF0aXN0YSBTb3V6YSIsIm5vbmNlIjoiZjFmMDJiNjgtZTZlYS00MDViLTliYjEtMzc4Njk1OTQ2YzlhIiwib2lkIjoiZWQwMDVkMWItOTliYi00YzlkLTg3NjAtYjMwZTkzMGYxYzVkIiwicHJlZmVycmVkX3VzZXJuYW1lIjoid2VsaW5ndG9uLnNvdXphQGl0YXUtdW5pYmFuY28uY29tLmJyIiwicmgiOiIwLkFTVUFvR2tXV1Q4WXBVbVk5SnFnMExZOWdaWS1rNFE1YVo5Rm5XMmFZOXluRjdFbEFGQS4iLCJyb2xlcyI6WyJWUDctVlA3LVNfT3BlcmFkb3JDb3JyZXRvcmFzX0hNTCIsIlZQNy1WUDctU19PcGVyYWRvckZvZl9ITUwiXSwic3ViIjoiU1d1UGpyUk90OEtaSENNYnhHeDBBQ1E2VXFBMjlyNE9FbE9lYUtEVFYzNCIsInRpZCI6IjU5MTY2OWEwLTE4M2YtNDlhNS05OGY0LTlhYTBkMGI2M2Q4MSIsInV0aSI6IklnQW1WeWtrZDBDUkM0czltc3BXQWciLCJ2ZXIiOiIyLjAiLCJlbXBsb3llZWlkIjoiOTg3MzM4MjQwIiwib25wcmVtaXNlc3NhbWFjY291bnRuYW1lIjoid2VsaWJhdCJ9.FyRmgxE2v606-47c8KKducbV3BzNttrXM_fyfl-lVpYnWrn8xQnaPbnRQqz4QSHVZzHVriVy_OjiiNuPcVCn5ajR9viZWszLVy-MFB6p5dpLqy49zQmRIrB99d1tUGZFjQPdB13SVpjG4ApfE6PZU3b5fZoGe2Y4-bm8ZqHad5RCrpCtrMISM_znOfBeyczB8DFu2xCToKxTaJo-5j0p640XeVocpeaWkWAchtBguXyHKVSwPaES-X4iwx5h_ntq5s9jCTn73ZjRV1JC9zilHvr19Ylf3ibcs7eRlDRtMY57_oBqX7DvbKJJ_zKTuCxmwHKwxwLTGT5TydG_AXKZXQ",
    //   userEntry: null
    // };

    // this.userService.loginSingleSignOn(loginRequest);

    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
}

  logoutAzureActiveDirectory() {
    this.authService.logout();
  }
  
  handleAccessLiza() {
    this.accessLiza = true;
  }
}
