import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { PlayerTypeEnum } from 'src/app/enums/player-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { InviteUserRequest } from 'src/app/services/users/models/requests/invite-user-request';
import { UpdateUserAccessRequest } from 'src/app/services/users/models/requests/update-user-access-request';
import { UserRolesPropertiesViewModel } from 'src/app/services/users/models/view-models/user-roles-view-models';
import { UserViewModel } from 'src/app/services/users/models/view-models/user-view-model';
import { UserService } from 'src/app/services/users/user-service';

@Component({
    templateUrl: './user-edit.component.html',
    styleUrls: ['../../../../../../assets/layout/customized/custom-card.scss']
})
export class UserEditComponent implements OnInit {


    inviteUserRequest: InviteUserRequest;
    roleOptions: SelectItem[] = [];
    selectedRoles: SelectItem[] = [];

    customerOptions: SelectItem[] = [];

    selectedCustomers: SelectItem[] = [];

    contactTypes: SelectItem[] = [{ label: 'Email', value: 'email' }, { label: 'Celular', value: 'cellphone' }];
    selectedContactType: SelectItem = { label: 'Email', value: 'email' };


    userSelected: UserViewModel = null;


    roleOptionsFoF: SelectItem[] = [];
    roleOptionsBroker: SelectItem[] = [];
    roleFoF: SelectItem[] = [];
    roleBroker: SelectItem[] = [];
    selectedRolesFoF: SelectItem = null;
    selectedRolesBroker: SelectItem = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private contextService: ContextService,
        private loadingService: LoadingService,
        private messageService: MessageService
    ) {
    }

    async ngOnInit() {
        this.loadingService.show();
        await this.getUserInfo();
        this.loadingService.hide();
    }

    async getUserInfo() {
        const userId = this.route.snapshot.params.id;
        this.userSelected = await this.userService.get(userId);
        await this.getCombos();
    }

    handleRoleFoF(role: SelectItem) {
        if(this.selectedRoles.length > 0) {
            this.roleOptionsFoF.forEach(role => {
                this.selectedRoles = this.selectedRoles.filter(c => c.value !== role.value);                
            })
            this.selectedRoles.push(role);
        } else if (this.selectedRoles.length == 0) {
            this.selectedRoles.push(role);
        }
    }

    handleRoleBroker(role: SelectItem) {
        if(this.selectedRoles.length > 0) {
            this.roleOptionsBroker.forEach(role => {
                this.selectedRoles = this.selectedRoles.filter(c => c.value !== role.value);                
            })
            this.selectedRoles.push(role);
        } else if (this.selectedRoles.length == 0) {
            this.selectedRoles.push(role);
        }
    }

    async getCombos() {

        // const Allroles = await this.userService.getRolesByPlayerType(PlayerTypeEnum.Customer);

        const user = this.contextService.getUserApplications();
        const roles = await this.userService.getRolesByPlayerType(PlayerTypeEnum.Customer);
        const rolesFof = roles.filter(fill => fill.value < 6000);
        const rolesBroker = roles.filter(fill => fill.value > 6000);

        user.forEach(item => {
            if (item.application.value == ApplicationTypeEnum.Broker) {
                this.roleOptionsBroker = rolesBroker.map(element => {
                    const roleProperties: UserRolesPropertiesViewModel = { roleId: element.value };
                    return { label: element.friendlyName, value: roleProperties }
                });
            }
            if (item.application.value == ApplicationTypeEnum.FoF) {
                this.roleOptionsFoF = rolesFof.map(element => {
                    const roleProperties: UserRolesPropertiesViewModel = { roleId: element.value };
                    return { label: element.friendlyName, value: roleProperties }
                });
            }
        })

        // this.selectedCustomers.map(available => {
        //     this.customerOptions = this.customerOptions.filter(fill => fill.value !== available.value)
        // })

        // // Roles
        // this.roleOptions = Allroles.map(element => {
        //     const roleProperties: UserRolesPropertiesViewModel = { roleId: element.value };
        //     return { label: element.friendlyName, value: roleProperties }
        // });

        // this.selectedRoles = this.userSelected.userRoles.map(element => {
        //     const roleProperties: UserRolesPropertiesViewModel = { roleId: element.role.value };
        //     return { label: element.role.friendlyName, value: roleProperties }
        // });

        // this.selectedRoles.map(available => {
        //     this.roleOptions = this.roleOptions.filter(fill => fill.label !== available.label);
        // });
    }

    async removeRolesUser(event) {
        if (this.selectedRoles.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Nenhuma função selecionada', detail: `Ao menos uma função deve ser selecioanda.`, life: 5000 });
            this.getCombos();
            return;
        }
        const request: UpdateUserAccessRequest = {
            userId: this.userSelected.id,
            userRoles: this.selectedRoles.map(item => {
                return item.value
            }),
            activationStatus: true
        }
        this.loadingService.show();
        await this.userService.userAccesses(request);
        await this.getUserInfo();
        this.loadingService.hide();
    }

    async includeRolesUser(event) {
        const request: UpdateUserAccessRequest = {
            userId: this.userSelected.id,
            userRoles: this.selectedRoles.map(item => {
                return item.value
            }),
            activationStatus: true
        }
        this.loadingService.show();
        await this.userService.userAccesses(request);
        await this.getUserInfo();
        this.loadingService.hide();
    }

    onCancel() {

    }

    async onSave(formCompnent: NgForm) {

        if (this.selectedRoles.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Nenhuma função selecionada', detail: `Uma única função deve ser selecioanda.`, life: 5000 });
            this.getCombos();
            return;
        }
        const request: UpdateUserAccessRequest = {
            userId: this.userSelected.id,
            userRoles: this.selectedRoles.map(item => {
                return item.value
            }),
            activationStatus: true
        }
        this.loadingService.show();
        await this.userService.userAccesses(request);
        await this.getUserInfo();
        this.loadingService.hide();

    }






}