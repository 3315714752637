<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Relatório
            </div>
        </div>
        <div class="customCard">
            <div class="cardHeader text-bold">Tipo de relatório</div>
            <div class="p-grid">

                <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="typeReportOptions" placeholder="Selecione o tipo de relatório"
                                [showClear]="true" (onChange)="changeOperation($event.value)" [required]="true"
                                [(ngModel)]="selectedReport" optionLabel="label" #report="ngModel">
                            </p-dropdown>
                        </span>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 1">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="periods" placeholder="Selecione o período" [required]="true"
                                        [showClear]="true" [(ngModel)]="filteredPeriod" [resetFilterOnHide]="true"
                                        optionLabel="label" (onChange)="changePeriod($event.value)" optionValue="value"
                                        #period="ngModel" name="period">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid" *ngIf="startPeriod && endPeriod">
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">De: {{startPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">Até: {{endPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="customCardOutlined" *ngIf="selectedPeriod?.summaries.length > 0">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Operação</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12"
                                            *ngFor="let summary of selectedPeriod.summaries">
                                            <p>{{summary.operationType | typesOperation}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">Concluídos</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let summary of selectedPeriod.summaries; let i = index">
                                            <p>{{selectedPeriod.summaries[i].doneCount}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">Cancelados</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let summary of selectedPeriod.summaries; let i = index">
                                            <p>{{selectedPeriod.summaries[i].canceledCount}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="line"></div>
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Total</h5>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">{{selectedPeriod.totalDone}}</h5>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">{{selectedPeriod.totalCanceled}}</h5>
                                </div>
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="statusOperation" placeholder="Selecione o status"
                                        [required]="true" [(ngModel)]="selectedStatusOperation" optionLabel="label"
                                        #status="ngModel" name="status">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="allTypesOperation" placeholder="Selecione o tipo de operação"
                                        [required]="true" [(ngModel)]="selectedTypeOperation" optionLabel="label"
                                        #type="ngModel" name="type">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="year" type="number" [(ngModel)]="selectedYear" #year="ngModel"
                                        name="year" class="inputLiza" required pInputText>
                                    <label for="year">Ano</label>
                                </span>
                            </div>
                            <mark *ngIf="year.invalid && year.dirty"
                                class="ui-message ui-messages-error ui-corner-all">Campo
                                obrigatório</mark>
                        </div>
                        <div class="p-col-2 p-md-3 p-lg-3">
                            <button pButton pRipple type="button" label="Filtrar" class="p-button-primary"
                                (click)="changeReport()"></button>
                        </div>
                    </div>
                    <div class="customCardOutlined" *ngIf="selectedPeriodYearly">
                        <p-chart type="bar" [data]="stackedData" [options]="stackedOptions"></p-chart>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 3">
                    <div class="p-grid" *ngIf="startPeriod && endPeriod">
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">De: {{startPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                        <div class="customCardOutlined">
                            <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                <p class="text-bold">Até: {{endPeriod | date: 'dd/MM/yyyy'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="periods" placeholder="Selecione o período" [required]="true"
                                        [showClear]="true" [(ngModel)]="filteredPeriod" [resetFilterOnHide]="true"
                                        optionLabel="label" (onChange)="changePeriod($event.value)" optionValue="value"
                                        #period="ngModel" name="period">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3 inputItem">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="allTypesOperation" placeholder="Selecione o tipo de operação"
                                        [required]="true" [(ngModel)]="selectedTypeOperation" optionLabel="label"
                                        [showClear]="true" #type="ngModel" name="type" [disabled]="!filteredPeriod">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-2 p-md-3 p-lg-3" *ngIf="selectedPeriodTimeFrames">
                            <button pButton pRipple type="button" label="Filtrar" class="p-button-primary"
                                (click)="changeReportDuration()" [disabled]="!filteredPeriod"></button>
                        </div>
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <h5 class="text-bold">Quantidade</h5>
                            <p-chart type="bar" [data]="stackedReportTimeFrames"
                                [options]="stackedReportTimeFramesOptions"></p-chart>
                            <h5 class="text-bold">Dias</h5>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 4 || selectedReport?.value == 5">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="allMonthsCharges" optionValue="value" optionLabel="label"
                                        [showClear]="true" [(ngModel)]="consultMonth" placeholder="Selecione o Mês"
                                        name="label" #operationType="ngModel">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-lg-3">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="allYearsCharges" optionValue="value" optionLabel="label"
                                        [showClear]="true" [(ngModel)]="consultYear" placeholder="Selecione o Ano"
                                        name="label" #operationType="ngModel">
                                    </p-dropdown>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-2 p-md-3 p-lg-3">
                            <button pButton pRipple type="button" label="Buscar" class="p-button-primary"
                                [disabled]="consultMonth == null && consultYear == null"
                                (click)="getOperations()"></button>
                        </div>
                    </div>
                    <br>

                    <div class="p-grid" *ngIf="operations?.length > 0">
                        <div class="customCardOutlined">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Operação</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12"
                                            *ngFor="let sumary of allTypesStatus; let index as index">
                                            <p>{{sumary.operationType | typesOperation}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-12 p-md-6 p-lg-6 text-center">
                                    <h5 class="text-bold">Criadas</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12"
                                            *ngFor="let item of allTypesStatus; let index">
                                            <p>{{item?.doneCount}}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">Cancelados</h5>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12 p-lg-12" *ngFor="let item of allTypesStatus; let index">
                                            <p>{{item?.canceledCount}}</p>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="line"></div>
                                <div class="p-col-12 p-md-6 p-lg-6">
                                    <h5 class="text-bold">Total</h5>
                                </div>
                                <div class="p-col-12 p-md-6 p-lg-6 text-center">
                                    <h5 class="text-bold">{{totalDone}}</h5>
                                </div>
                                <!-- <div class="p-col-12 p-md-3 p-lg-3 text-center">
                                    <h5 class="text-bold">{{totalCancel}}</h5>
                                </div> -->
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>

                    <div class="p-grid" *ngIf="operations?.length > 0">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-table [responsive]="true" #dt [value]="operations" dataKey="id" [(first)]="firstPage"
                                styleClass="p-datatable-customized" selectionMode="single" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'shareholderFund.documentNumber', 'investedFund.name', 'investedFund.documentNumber', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid" [hidden]="!operations.length">
                                            <button pButton type="button" class="p-button-outlined"
                                                label="Exportar operações" (click)="exportExcel()"></button>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operations>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Encerramento
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund.name">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund.documentNumber">
                                            <div>
                                                CNPJ F. Cotista
                                                <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.name">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.documentNumber">
                                            <div>
                                                CNPJ F. Investido
                                                <p-sortIcon field="investedFund.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Status
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            <span>
                                                {{ (operation?.currentStatus?.status?.value == 8 ||
                                                operation?.currentStatus?.status?.value ==
                                                operationStatusEnum.Cancelada) ?
                                                (operation?.currentStatus?.createDate | date: 'dd/MM/yyyy') :
                                                ''}}</span>
                                        </td>
                                        <td>
                                            {{operation?.type?.friendlyName}}
                                        </td>

                                        <td>
                                            {{operation?.shareholderFund?.name}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFund?.documentNumber | documentNumber: 'cnpj' }}
                                        </td>
                                        <td>
                                            {{operation?.investedFund?.name}}
                                        </td>
                                        <td>
                                            {{operation?.investedFund?.documentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.createDate | date: 'dd/MM/yyyy' : ''}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus?.status?.friendlyName}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>

                    <div class="p-grid" *ngIf="operationsPending?.length > 0">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-table [responsive]="true" #dt [value]="operationsPending" dataKey="id"
                                [(first)]="firstPage" styleClass="p-datatable-customized" selectionMode="single"
                                [rowHover]="true" [rows]="10" [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'manager', 'shareholderFund.name', 'shareholderFund.documentNumber', 'investedFund.name', 'investedFund.documentNumber', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid"
                                            [hidden]="!operationsPending.length">
                                            <button pButton type="button" class="p-button-outlined"
                                                label="Exportar operações" (click)="exportExcel()"></button>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operations>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Encerramento
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="type.friendlyName">
                                            <div>
                                                Operação
                                                <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund.name">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund.documentNumber">
                                            <div>
                                                CNPJ F. Cotista
                                                <p-sortIcon field="shareholderFund.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="manager">
                                            <div>
                                                Gestor F. Inv.
                                                <p-sortIcon field="manager"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.name">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund.name"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund.documentNumber">
                                            <div>
                                                CNPJ F. Investido
                                                <p-sortIcon field="investedFund.documentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.createDate">
                                            <div>
                                                Data Status
                                                <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus.status.friendlyName">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                            </div>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.pendingDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.operationType}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFund}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFundDocumentNumber | documentNumber: 'cnpj' }}
                                        </td>
                                        <td>
                                            {{operation?.manager}}
                                        </td>
                                        <td>
                                            {{operation?.investedFund}}
                                        </td>
                                        <td>
                                            {{operation?.investedFundDocumentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatusDate | date: 'dd/MM/yyyy' : ''}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus}}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>

                </div>

                <div class="p-col-12 p-md-12 p-lg-12" *ngIf="selectedReport?.value == 6">
                    <div class="p-grid" *ngIf="fullOperations.length > 0">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <p-table [responsive]="true" #dt [value]="fullOperations" dataKey="id" [(first)]="firstPage"
                                styleClass="p-datatable-customized" selectionMode="none" [rowHover]="true" [rows]="10"
                                [autoLayout]="true" [paginator]="true" [filterDelay]="0"
                                [globalFilterFields]="['sequentialId','type.friendlyName', 'shareholderFund.name', 'shareholderFund.documentNumber', 'investedFund.name', 'investedFund.documentNumber', 'currentStatus.status.friendlyName']">
                                <ng-template pTemplate="caption">
                                    <div class="contentButtonActions p-fluid">
                                        <div class="p-col-12 p-md-2 p-lg-2 p-fluid">
                                            <button pButton type="button" class="p-button-outlined"
                                                label="Exportar operações" (click)="exportToExcel()"></button>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-operations>
                                    <tr>
                                        <th pSortableColumn="sequentialId">
                                            <div>
                                                ID LIZA
                                                <p-sortIcon field="sequentialId"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="createDate">
                                            <div>
                                                Data da Entrada
                                                <p-sortIcon field="createDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="finishDate">
                                            <div>
                                                Data Encerramento
                                                <p-sortIcon field="finishDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="operationType">
                                            <div>
                                                Operação
                                                <p-sortIcon field="operationType"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFund">
                                            <div>
                                                Fundo Cotista
                                                <p-sortIcon field="shareholderFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="shareholderFundDocumentNumber">
                                            <div>
                                                CNPJ F. Cotista
                                                <p-sortIcon field="shareholderFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="manager">
                                            <div>
                                                Gestor F. Inv.
                                                <p-sortIcon field="manager"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFund">
                                            <div>
                                                Fundo Investido
                                                <p-sortIcon field="investedFund"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="investedFundDocumentNumber">
                                            <div>
                                                CNPJ F. Investido
                                                <p-sortIcon field="investedFundDocumentNumber"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatusDate">
                                            <div>
                                                Data Status
                                                <p-sortIcon field="currentStatusDate"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th pSortableColumn="currentStatus">
                                            <div>
                                                Status
                                                <p-sortIcon field="currentStatus"></p-sortIcon>
                                            </div>
                                        </th>
                                        <th>Pendência</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-operation let-index>
                                    <tr style="cursor: pointer" [pSelectableRow]="operation">
                                        <td>
                                            {{operation?.sequentialId}}
                                        </td>
                                        <td>
                                            {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{ operation?.finishDate | date: 'dd/MM/yyyy'}}
                                        </td>
                                        <td>
                                            {{operation?.operationType}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFund}}
                                        </td>
                                        <td>
                                            {{operation?.shareholderFundDocumentNumber | documentNumber: 'cnpj' }}
                                        </td>
                                        <td>
                                            {{operation?.manager}}
                                        </td>
                                        <td>
                                            {{operation?.investedFund}}
                                        </td>
                                        <td>
                                            {{operation?.investedFundDocumentNumber | documentNumber: 'cnpj'}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatusDate | date: 'dd/MM/yyyy' : ''}}
                                        </td>
                                        <td>
                                            {{operation?.currentStatus}}
                                        </td>
                                        <td *ngIf="operation?.pendingNotes != null">
                                            <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                                            <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                                class="p-button-text" (click)="menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">Nenhuma operação encontrada.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Selecione o período" [(visible)]="displayPeriod" [modal]="true" [responsive]="true">
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="start">Data Início</label>
            <p-calendar inputId="start" [(ngModel)]="startPeriod" appendTo="body" (onSelect)="changeStartPeriod($event)"
                dateFormat="yyy/mm/dd"></p-calendar>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <label for="end">Data Final</label>
            <p-calendar inputId="end" [(ngModel)]="endPeriod" appendTo="body" (onSelect)="changeEndPeriod($event)"
                dateFormat="yyy/mm/dd" [disabled]="!startPeriod"></p-calendar>
        </div>
    </div>
</p-dialog>

<p-dialog header="Pendência" [(visible)]="displayReport" [modal]="true" [responsive]="true" [style]="{width: '30vw'}">
    <div class="p-grid p-fluid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <p class="text-bold">Tipo:</p>
            <p>{{operationSummary?.pendingReason}}</p>
            <p class="text-bold">Descritivo:</p>
            <p>{{operationSummary?.pendingNotes}}</p>
        </div>
    </div>
</p-dialog>