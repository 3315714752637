import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { FundTypeEnum } from 'src/app/enums/fund-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { ContextService } from 'src/app/providers/context-service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { FundService } from 'src/app/services/funds/fund-service';
import { UpdateFundBasicInfoRequest } from 'src/app/services/funds/models/request/update-fund-basic-info-request';
import { UpdateShareholderFundRequest } from 'src/app/services/funds/models/request/update-shareholder-fund-request';
import { FundViewModel } from 'src/app/services/funds/models/view-models/fund-view-model';
import { ApplicationResponseViewModel } from 'src/app/services/users/models/view-models/application-response-view-model';

@Component({
    templateUrl: './fund-edit.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundEditComponent implements OnInit {

    selectedFund: FundViewModel = null;
    establishmentDate: Date = null;
    optionsBovespa: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    optionsBMF: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    selectedApplication: ApplicationResponseViewModel = null;
    selectedBovespa: SelectItem = null;
    selectedBMF: SelectItem = null;
    selectedDeskConfig: string = null;
    optionsDeskConfig: SelectItem[] = [];

    public applicationTypeEnum = ApplicationTypeEnum;
    public fundTypeEnum = FundTypeEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fundService: FundService,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private messageService: MessageService,
        private contextService: ContextService,
    ) { }

    async ngOnInit() {
        this.loadingService.show();
        this.verifyUserPermissions();
        this.getDeskConfigOptions();
        await this.getData();
        this.loadingService.hide();
    }

    getDeskConfigOptions() {
        this.optionsDeskConfig = [
            { label: "Nenhuma das alternativas", value: "" },
            { label: "ALOC", value: "ALOC" },
            { label: "ARTAX", value: "ARTAX" },
            { label: "ASGARD", value: "ASGARD" },
            { label: "CHARLIE", value: "CHARLIE" },
            { label: "CRÉDITO ESTRUTURADO", value: "CRÉDITO ESTRUTURADO" },
            { label: "DELTA", value: "DELTA" },
            { label: "DUNAMIS", value: "DUNAMIS" },
            { label: "EXTERNA", value: "EXTERNA" },
            { label: "FOX", value: "FOX" },
            { label: "GIS", value: "GIS" },
            { label: "GOLF", value: "GOLF" },
            { label: "INDEXADOS / EXTERNA", value: "INDEXADOS / EXTERNA" },
            { label: "INDEXADOS / SFC / SOLUTIONS", value: "INDEXADOS / SFC / SOLUTIONS" },
            { label: "INDEXADOS / SOLUTIONS", value: "INDEXADOS / SOLUTIONS" },
            { label: "INDEXADOS", value: "INDEXADOS" },
            { label: "MAC", value: "MAC" },
            { label: "MOMENTO", value: "MOMENTO" },
            { label: "OPTIMUS / EXTERNA", value: "OPTIMUS / EXTERNA" },
            { label: "OPTIMUS", value: "OPTIMUS" },
            { label: "QUANTAMENTAL", value: "QUANTAMENTAL" },
            { label: "SFC / SOLUTIONS", value: "SFC / SOLUTIONS" },
            { label: "SFC / SRF", value: "SFC / SRF" },
            { label: "SFC", value: "SFC" },
            { label: "SFF", value: "SFF" },
            { label: "SHA / EXTERNA", value: "SHA / EXTERNA" },
            { label: "SHA", value: "SHA" },
            { label: "SOLUTIONS / EXTERNA", value: "SOLUTIONS / EXTERNA" },
            { label: "SOLUTIONS / SHA", value: "SOLUTIONS / SHA" },
            { label: "SOLUTIONS / SRF", value: "SOLUTIONS / SRF" },
            { label: "SOLUTIONS", value: "SOLUTIONS" },
            { label: "SRF", value: "SRF" },
            { label: "SRT", value: "SRT" },
            { label: "SRV / EXTERNA", value: "SRV / EXTERNA" },
        ]
    }

    verifyUserPermissions() {
        this.selectedApplication = this.contextService.getUserApplicationSelected();
    }

    handleBovespa(bovespa: SelectItem) {
        this.selectedBovespa = bovespa;
    }

    handleBMF(bmf: SelectItem) {
        this.selectedBMF = bmf
    }

    verifyBovespa() {
        if (this.selectedFund.isBovespa == true) {
            this.selectedBovespa = this.optionsBovespa[0];
        } else if (this.selectedFund.isBovespa == false) {
            this.selectedBovespa = this.optionsBovespa[1];
        }
    }

    verifyBMF() {
        if (this.selectedFund.isBMF == true) {
            this.selectedBMF = this.optionsBMF[0];
        } else if (this.selectedFund.isBMF == false) {
            this.selectedBMF = this.optionsBMF[1];
        }
    }

    async getData() {
        const fundId = this.route.snapshot.params.id;
        this.selectedFund = await this.fundService.getFundById(fundId);
        this.selectedDeskConfig = this.selectedFund?.brokerOperationDeskName;
        this.establishmentDate = new Date(this.selectedFund.establishmentDate);
        this.verifyBovespa();
        this.verifyBMF();
    }


    onCancel() {
        // this.router.navigateByUrl('app/customer/fund/list');
        const search = this.route.snapshot.queryParams.search;
        const tab = this.route.snapshot.queryParams.tab;
        if (search && tab) {
            this.router.navigate([`app/customer/fund/list`], { queryParams: { search: search, tab: tab } });
        } else if (!search) {
            this.router.navigate([`app/customer/fund/list`]);
        }
    }

    changeEstablishmentDate(event: Date) {
        this.establishmentDate = event;
    }

    async onSave(componentForm: NgForm) {
        if (!this.formValidationService.validateForm(componentForm.form)) return;
        const request: UpdateShareholderFundRequest = {
            documentNumber: this.selectedFund?.documentNumber,
            name: componentForm.controls?.name?.value,
            establishmentDate: this.establishmentDate,
            isBovespa: this.selectedBovespa ? this.selectedBovespa.value : this.selectedFund.isBovespa,
            isBMF: this.selectedBMF ? this.selectedBMF.value : this.selectedFund.isBMF,
            sac: componentForm.controls?.sac?.value ? componentForm.controls.sac.value : null,
            deskConfig: this.selectedDeskConfig ? this.selectedDeskConfig : null,
            bankAccount: {
                bankCode: componentForm.controls.bankCode.value,
                bankName: componentForm.controls.bankName.value,
                agencyNumber: componentForm.controls.agencyNumber.value,
                accountNumber: componentForm.controls.accountNumber.value,
                accountDigit: componentForm.controls.accountDigit.value,
            },
            customProperty: {
                stiCode: componentForm.controls.stiCode.value,
                cetipAccount: componentForm.controls.cetipAccount.value,
                giinNumber: componentForm.controls.giinNumber.value,
                ambimaTargetGroup: componentForm.controls.ambimaTargetGroup.value,
                fundOrManagerReversion: componentForm.controls.fundOrManagerReversion.value,
            }
        }
        try {
            this.loadingService.show();
            await this.fundService.updateShareholderFund(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuado com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            this.router.navigateByUrl('app/customer/fund/list');
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async onSaveInvestedFund(investedFundForm: NgForm) {
        if (!this.formValidationService.validateForm(investedFundForm.form)) return;
        const fundId = this.route.snapshot.params.id;
        let request: UpdateFundBasicInfoRequest = {
            id: fundId,
            name: investedFundForm.controls?.name?.value,
        }
        try {
            this.loadingService.show();
            await this.fundService.updateInvestedFund(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            this.router.navigate([`app/customer/fund/list`], { queryParams: { tab: 1 } });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

}