import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { CreateRepresentativeRequest } from 'src/app/services/fund-administrators/models/requests/create-representative-request';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { CreateBrokerRepresentativeRequest } from 'src/app/services/fund-brokers/models/requests/create-broker-representative-request';
import { CreateFundBrokerRequest } from 'src/app/services/fund-brokers/models/requests/create-fund-broker-request';
import { UpdateBrokerRepresentativeRequest } from 'src/app/services/fund-brokers/models/requests/update-broker-representative-request';
import { UpdateFundBrokerRequest } from 'src/app/services/fund-brokers/models/requests/update-fund-broker-request';
import { BrokerRepresentativeProperties } from 'src/app/services/fund-brokers/models/view-models/broker-representative-properties';

@Component({
    templateUrl: './fund-broker-email-completion-view.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundBrokerEmailCompletionViewComponent implements OnInit {

    requestModel: CreateFundBrokerRequest = null;
    requestRepresentative: CreateRepresentativeRequest = null;
    representations: BrokerRepresentativeProperties[] = [];
    selectedFundBroker: FundBrokerViewModel = null;
    requestExpireDate: Date = null;
    // displayRegistrationFormTemplate: boolean = false;
    allRepresentatives: RepresentativeViewModel[] = null;
    selectedRepresentative: RepresentativeViewModel = null;
    displayEditRep: boolean = false;
    selectedExpirateDateRep: Date = null;
    selectedDocumentEncryptedRep: string = null;
    contactsOption: SelectItem[] = [{ label: 'Sim', value: true }, { label: 'Não', value: false }];
    selectedContact: SelectItem = null;

    currentEmail: string = null;
    allEmails: string[] = [];

    constructor(
        private fundBrokerService: FundBrokerService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setMainRequestForm();
        await this.getFundAdministrator();
        await this.getRepresentativesInFundBroker();
    }

    setMainRequestForm() {
        this.requestModel = {
            name: null,
            documentNumber: null,
            // expirationDate: null,
            registrationFormTemplateRequest: null,
            updateFormTemplateRequest: null,
            additionTemplateRequest: null,
            operationTemplateRequest: null,
            hasOperationDoneNotification: false,
            operationDoneNotificationEmails: [],
            operationDoneNotificationSubject: null,
            operationDoneNotificationTitle: null,
            representatives: this.representations
        };
    }

    async getFundAdministrator() {
        const fundBrokerId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundBrokerId).then(() => {
            this.selectedFundBroker.documentNumber = this.parseCNPJ(this.selectedFundBroker.documentNumber)
        });
    }

    async getFundAdministratorById(fundBrokerId: string) {
        this.selectedFundBroker = await this.fundBrokerService.getById(fundBrokerId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    async addRepresentations(form: NgForm) {

        if (this.selectedContact == null) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Preencha o campo Representante legal', life: 5000 });
            return;
        }

        if (!this.formValidationService.validateForm(form.form)) return;

        const request: CreateBrokerRepresentativeRequest = {
            fundBrokerId: this.selectedFundBroker.id,
            name: form.value.name,
            personalDocumentNumber: form.value.personalDocumentNumber,
            email: form.value.email,
            expireDate: form.value.expireDate,
            canSign: this.selectedContact.value,
        }
        try {
            this.loadingService.show();
            await this.fundBrokerService.createRepresentative(request);
            await this.getRepresentativesInFundBroker();
            this.selectedContact = null;
            this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: `O CPF ${form.value.personalDocumentNumber} já está em uso por outro representante`, life: 5000 });
        } finally {
            form.reset();
            this.loadingService.hide();
        }
    }

    // removeRepresentation(representation: any) {
    //     this.representations = this.representations.filter(item => {
    //         return item !== representation
    //     })
    // }

    async removeCurrentRepresentation(representation: RepresentativeViewModel) {

        if (this.allRepresentatives.length == 1) {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: `É necessário ter ao menos um representante.`, life: 5000 });
            return;
        } else {
            // this.allRepresentatives = this.allRepresentatives.filter(item => {
            //     return item !== representation;
            // });
            try {
                this.loadingService.show();
                const representativeId = representation.id;
                await this.fundBrokerService.deleteRepresentative(representativeId);
                await this.getRepresentativesInFundBroker();
                this.messageService.add({ severity: 'success', summary: 'Cadastro efetuado com sucesso', detail: `Os dados foram cadastrados com sucesso!`, life: 5000 });
            } catch (error) {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Não foi possível efetuar a alteração',
                    detail: `${error?.messageError}!`,
                    life: 5000
                });
            } finally {
                this.loadingService.hide();
            }
        }


    }

    // openRegistrationFormTemplate() {
    //     this.displayRegistrationFormTemplate = true;
    // }

    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }

    async getRepresentativesInFundBroker() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundBrokerService.getRepresentativesInFundBroker(fundBrokerId);
    }


    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-broker/list`)
    }

    onEditCancel() {
        this.selectedContact = null;
        this.selectedExpirateDateRep = null;
        this.displayEditRep = false;
    }


    async onEditRepresentative(form: NgForm) {

        if (!this.formValidationService.validateForm(form.form)) return;

        if (this.selectedContact?.value == null) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Preencha o campo Representante legal', life: 5000 });
            return;
        }

        this.loadingService.show();
        this.displayEditRep = false;
        const request: UpdateBrokerRepresentativeRequest = {
            representativeId: this.selectedRepresentative.id,
            name: form.value.name,
            email: form.value.email,
            personalDocumentNumber: form.value.maskedPersonalDocumentNumber,
            expireDate: this.selectedExpirateDateRep,
            canSign: this.selectedContact.value,
        }
        try {
            await this.fundBrokerService.updateRepresentativeFundAdmin(request);
            await this.getRepresentativesInFundBroker();
            this.selectedContact = null;
            this.selectedExpirateDateRep = null;
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.selectedContact = null;
            this.selectedExpirateDateRep = null;
            this.displayEditRep = false;
            this.loadingService.hide();
        }
    }

    async onSaveConfigEmail(form: NgForm) {
        const request: UpdateFundBrokerRequest = {
            fundBrokerId: this.selectedFundBroker.id,
            name: this.selectedFundBroker.name,
            operationDoneNotificationEmails: this.allEmails,
            operationDoneNotificationTitle: form?.controls?.title?.value,
            operationDoneNotificationSubject: form?.controls?.subject?.value,
        }


        try {
            this.loadingService.show();
            await this.fundBrokerService.updateFundBroker(request);
            this.router.navigateByUrl(`app/customer/fund-broker/email-completion-view/${this.route.snapshot.params.id}`);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuado com sucesso', detail: `Os dados foram alterados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async onSaveEmails(email: string) {

        const request: UpdateFundBrokerRequest = {
            fundBrokerId: this.selectedFundBroker.id,
            name: this.selectedFundBroker.name,
            operationDoneNotificationEmails: this.selectedFundBroker.operationDoneNotificationEmails,
            operationDoneNotificationTitle: this.selectedFundBroker.operationDoneNotificationTitle,
            operationDoneNotificationSubject: this.selectedFundBroker.operationDoneNotificationSubject,
        }

        try {
            this.loadingService.show();
            await this.fundBrokerService.updateFundBroker(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuado com sucesso', detail: `E-mail salvo com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async onRemoveEmail(email: string) {

        const request: UpdateFundBrokerRequest = {
            fundBrokerId: this.selectedFundBroker.id,
            name: this.selectedFundBroker.name,
            operationDoneNotificationEmails: this.selectedFundBroker.operationDoneNotificationEmails,
            operationDoneNotificationTitle: this.selectedFundBroker.operationDoneNotificationTitle,
            operationDoneNotificationSubject: this.selectedFundBroker.operationDoneNotificationSubject,
        }

        try {
            this.loadingService.show();
            await this.fundBrokerService.updateFundBroker(request);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuado com sucesso', detail: `E-mail removido com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }


    updateTemplate() {
        const fundBrokerId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-broker/edit-form-template/${fundBrokerId}`)
    }

    async editCurrentRepresentation(representation: RepresentativeViewModel) {

        this.selectedRepresentative = representation;
        if (this.selectedRepresentative.canSign) {
            this.selectedContact = this.contactsOption[0]
        } else if (this.selectedRepresentative.canSign == false) {
            this.selectedContact = this.contactsOption[1]
        }
        if (this.selectedRepresentative?.expireDate) {
            this.selectedExpirateDateRep = new Date(this.selectedRepresentative?.expireDate)
        }
        if (this.selectedRepresentative.maskedPersonalDocumentNumber) {
            await this.fundBrokerService.decryptedDocumentRepresentativeFund(representation.id).then((response) => {
                this.selectedDocumentEncryptedRep = response.decryptedDocumentNumber
            });
        } else {
            this.selectedDocumentEncryptedRep = this.selectedRepresentative.maskedPersonalDocumentNumber
        }
        this.displayEditRep = true;
    }


    addEmail(form: NgForm) {
        if (form?.controls?.email?.value == null) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'E-mail inválido', life: 5000 });
            return;
        }
        const email = form.controls.email.value;
        this.allEmails.push(email);
        this.currentEmail = null;
    }

    removeEmail(email: string) {
        const indexMail = this.allEmails.findIndex(c => c == email);
        this.allEmails.splice(indexMail, 1);
    }

    editConfigEmail() {
        this.router.navigateByUrl(`app/customer/fund-broker/email-completion/${this.route.snapshot.params.id}`);
    }

}