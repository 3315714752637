<div class="p-grid">
    <div class="p-col-12 p-md-12 p-lg-12">
        <div class="titleCustomCard">
            Novo Fundo Cotista
        </div>
        <div class="customCard">
            <form #fundForm="ngForm" novalidate>
                <div class="cardHeader text-bold">Informações</div>
                <div class="p-grid p-fluid p-formgrid">

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="name" type="text" [(ngModel)]="requestModelShareholder.name" name="name"
                                    #name="ngModel" class="inputLiza" required pInputText>
                                <label for="name">Razão Social</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label p-fluid">
                                <p-inputMask inputId="documentNumber" type="text" class="inputLizaMask"
                                    mask="99.999.999/9999-99" [(ngModel)]="requestModelShareholder.documentNumber"
                                    name="documentNumber" #documentNumber="ngModel" [unmask]="true" [required]="true">
                                </p-inputMask>
                                <label for="documentNumber" class="labelLiza">CNPJ</label>
                            </span>
                        </div>
                        <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="stiCode" type="text" [(ngModel)]="requestModelShareholder.customProperty.stiCode"
                                    name="stiCode" #stiCode="ngModel" class="inputLiza" required pInputText>
                                <label for="stiCode">Código STI / Galgo</label>
                            </span>
                        </div>
                        <mark *ngIf="stiCode.invalid && stiCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="cetipAccount" type="text" [(ngModel)]="requestModelShareholder.customProperty.cetipAccount"
                                    name="cetipAccount" #cetipAccount="ngModel" class="inputLiza" pInputText>
                                <label for="cetipAccount">Conta CETIP</label>
                            </span>
                        </div>
                        <mark *ngIf="cetipAccount.invalid && cetipAccount.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="giinNumber" type="text" [(ngModel)]="requestModelShareholder.customProperty.giinNumber"
                                    name="giinNumber" #giinNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="giinNumber">Número GIIN</label>
                            </span>
                        </div>
                        <mark *ngIf="giinNumber.invalid && giinNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-dropdown [options]="allTargetAudience" placeholder="Público Alvo ANBIMA"
                                    [required]="true" [(ngModel)]="requestModelShareholder.customProperty.ambimaTargetGroup"
                                    optionLabel="label" #ambimaTargetGroup="ngModel" optionValue="value"
                                    name="targetAudience">
                                </p-dropdown>
                            </span>
                        </div>
                        <mark *ngIf="ambimaTargetGroup.invalid && ambimaTargetGroup.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-4 p-lg-4">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <p-calendar [(ngModel)]="requestModelShareholder.establishmentDate"
                                    name="establishmentDate" yearRange="2022:2099" [showIcon]="true"
                                    [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                                    [required]="true" #establishmentDate="ngModel" [required]="true">
                                </p-calendar>
                                <label for="establishmentDate" class="labelOcta">Data de Constituição</label>
                            </span>
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="sac" type="text" [(ngModel)]="requestModelShareholder.sac" name="sac"
                                    #sac="ngModel" class="inputLiza" required pInputText>
                                <label for="sac">SAC</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="fundOrManagerReversion" type="text" [(ngModel)]="requestModelShareholder.customProperty.fundOrManagerReversion" name="fundOrManagerReversion"
                                    #fundOrManagerReversion="ngModel" class="inputLiza" required pInputText>
                                <label for="fundOrManagerReversion">Reversão do Fundo</label>
                            </span>
                        </div>
                        <mark *ngIf="name.invalid && name.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <br>
                <div class="cardHeader text-bold">Informações da conta</div>
                <div class="p-grid p-fluid p-formgrid">
                    <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankName" type="text"
                                    [(ngModel)]="requestModelShareholder.bankAccount.bankName" name="bankName"
                                    #bankName="ngModel" class="inputLiza" required pInputText>
                                <label for="bankName">Nome do Banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankName.invalid && bankName.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="bankCode" type="text"
                                    [(ngModel)]="requestModelShareholder.bankAccount.bankCode" name="bankCode"
                                    #bankCode="ngModel" class="inputLiza" required pInputText>
                                <label for="bankCode">Número do Banco</label>
                            </span>
                        </div>
                        <mark *ngIf="bankCode.invalid && bankCode.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="agencyNumber" type="text"
                                    [(ngModel)]="requestModelShareholder.bankAccount.agencyNumber" name="agencyNumber"
                                    #agencyNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="agencyNumber">Agência</label>
                            </span>
                        </div>
                        <mark *ngIf="agencyNumber.invalid && agencyNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>


                    <div class="p-col-12 p-md-2 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountNumber" type="text"
                                    [(ngModel)]="requestModelShareholder.bankAccount.accountNumber" name="accountNumber"
                                    #accountNumber="ngModel" class="inputLiza" required pInputText>
                                <label for="accountNumber">Conta Corrente</label>
                            </span>
                        </div>
                        <mark *ngIf="accountNumber.invalid && accountNumber.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>

                    <div class="p-col-12 p-md-3 p-lg-2 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="accountDigit" type="text"
                                    [(ngModel)]="requestModelShareholder.bankAccount.accountDigit" name="accountDigit"
                                    #accountDigit="ngModel" class="inputLiza" required pInputText>
                                <label for="accountDigit">Dígito</label>
                            </span>
                        </div>
                        <mark *ngIf="accountDigit.invalid && accountDigit.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Cancelar" iconPos="right" class="p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Salvar" iconPos="right" class="action-button p-ml-2"
                        (click)="onSave(fundForm)"></button>
                </div>
            </form>
        </div>
    </div>
</div>