import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'textSplit'
})

export class TextSplit implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any) {
        let result = null
        result = value.split('|');
        return `${result[0]}`
    }
}













