import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { ExternalOperationService } from 'src/app/services/external-operations/external-operation-service';
import { AddExternalOperationSignersRequest } from 'src/app/services/external-operations/models/request/add-signers-request';
import { SetExternalOperationSetupAsDoneRequest } from 'src/app/services/external-operations/models/request/set-external-operation-setup-as-done-request';
import { ExternalOperationViewModel } from 'src/app/services/external-operations/models/view-models/external-operation-view-model';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { ContactProperties } from 'src/app/services/fund-manager/models/view-models/contact-properties';
import { ContactViewModel } from 'src/app/services/fund-manager/models/view-models/contact-view-model';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { FillOperationArtifactRequest } from 'src/app/services/operations/models/request/fill-operation-artifact-request';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';


@Component({
    templateUrl: './external-operation-setup.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss', './external-operation-setup.component.scss']
})
export class ExternalOperationSetupComponent implements OnInit {

    steps: MenuItem[] = [{ label: 'Setup' }, { label: 'Concluído' }];
    currentStep: number = 0;
    managerContacts: ContactProperties[] = [];
    allRepresentativesA: RepresentationViewModel[] = [];
    allRepresentativesB: RepresentationViewModel[] = [];
    displayCreateManagerContacts: boolean = false;
    displayPendingOperation: boolean = false;
    displayOperationFiles: boolean = false;
    displayOperationFundRegulationFile: boolean = false;
    displayApprovedAssesment: boolean = false;
    requestFiles = null;
    requestFundRegulationFile = null;
    requestAssesmentReply = null;
    requestDissmisAssesment = null;
    selectedExternalOperation: ExternalOperationViewModel = null;
    displayFillInfoTemplate: boolean = false;
    artefactObservable = new Subject<ArtifactViewModel>();
    requestArtefacts: FillOperationArtifactRequest = null;
    displayButtonArtefacts: boolean = false;
    displyDismissAssesment: boolean = false;
    displayAlertDismissAssesment: boolean = false;
    stepDescription: string = null;
    selectedContacts: [] = null;
    recipients: AssessmentSummaryViewModel[] = [];

    allRepresentativesAdminInvestedFund: RepresentativeViewModel[] = [];
    switchRepresentativeAdminInvestedFund: boolean = false;
    representativesAdminInvestedFund: string[] = [];

    allRepresentativesManagerInvestedFund: ContactViewModel[] = [];
    switchRepresentativeManagerInvestedFund: boolean = false;
    representativesManagerInvestedFund: string[] = [];

    switchContact: string = null;
    allContacts: SelectItem[] = [];
    selectedContatIncludeOperation: ContactViewModel = null;
    displayMessage: boolean = false;
    displayAlertDocuments: boolean = false;
    displayAttachment: boolean = false;
    hasFundRegulationFile: boolean = false;
    disabledButtonSignature: boolean = false;

    public artifactStatusEnum = ArtifactStatusEnum;
    public operationStatusEnum = OperationStatusEnum;
    public operationTypeEnum = OperationTypeEnum;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private representationService: RepresentationService,
        private externalOperationService: ExternalOperationService,
        private fundManagerService: FundManagerService,
        private fileUploadService: FileUploadService,
        private confirmationService: ConfirmationService,
        private fundAdministratorService: FundAdministratorService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        this.setFormUpload();
        this.setFundRegulationFormUpload();
        await this.loadData();
        await this.loadAllRepresentatives();
        this.loadingService.hide();
    }

    setFundRegulationFormUpload() {
        this.requestFundRegulationFile = {
            description: "Regulamento do Fundo",
            files: []
        }
    }

    setFormUpload() {
        this.requestFiles = {
            description: null,
            files: []
        }
        this.requestAssesmentReply = {
            reply: null,
        }
        this.requestDissmisAssesment = {
            notes: null,
        }
    }

    defineStep() {
        switch (this.selectedExternalOperation.currentStatus.status.value) {
            case OperationStatusEnum.EmSetup:
                this.currentStep = 0;
                this.stepDescription = 'Em Setup';
                break;
            case OperationStatusEnum.PreenchimentoManualNecessario:
            case OperationStatusEnum.PreenchimentoConcluido:
                this.currentStep = 1;
                this.stepDescription = 'Conclkuído';
                break;
            default:
                this.messageService.add({ severity: 'warn', summary: 'Status não permitido', detail: 'O status atual da operação não permite operação', life: 5000 });
                this.router.navigateByUrl(`app/customer/external-operation/list`);
                break;
        }
    }

    async getOpertionAssessments() {
        const operationId = this.selectedExternalOperation.id;
        this.recipients = await this.externalOperationService.getOperationAssessments(operationId);
        this.recipients = this.recipients.sort((a, b) => {
            if (new Date(a.createDate) < new Date(b.createDate)) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    async loadData() {
        const operationId = this.route.snapshot.params.id;
        this.selectedExternalOperation = await this.externalOperationService.getOperationById(operationId);
        this.defineStep();
    }

    async uploadFiles(event: File, formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) {
            this.requestFiles.files = [];
            return;
        };

        try {
            this.displayOperationFiles = false;
            this.loadingService.show();
            const response = await this.fileUploadService.upload(event[0]);
            const request: AddAttachmentRequest = {
                operationId: this.selectedExternalOperation.id,
                description: this.requestFiles.description,
                fileUrl: response.fileUrl
            }
            this.selectedExternalOperation = await this.externalOperationService.operationAttachments(request);
            this.updateUrl();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationFiles = false;
        } finally {
            // this.updateUrl();
            this.setFormUpload();
            this.setFundRegulationFormUpload();
            this.loadingService.hide();
        }
    }

    async removeFile(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        try {
            this.loadingService.show();
            // const fileIndex = this.selectedOperation.recipients.findIndex(c => c.id == file.id);
            // const attachment = this.selectedOperation.recipients[fileIndex];
            await this.externalOperationService.deleteAttachment(file.id);
            this.updateUrl();
            // this.selectedOperation.recipients.splice(fileIndex, 1);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            await this.loadData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async loadAllRepresentatives() {
        const active = true;
        this.allRepresentativesA = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesB = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesA = this.allRepresentativesA.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
        this.allRepresentativesB = this.allRepresentativesB.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });

        this.allRepresentativesAdminInvestedFund = await this.fundAdministratorService.getRepresentativesInFundAdministrator(this.selectedExternalOperation.investedFund.administrator.id);
        this.allRepresentativesManagerInvestedFund = await this.fundManagerService.getContactsByFundManagerId(this.selectedExternalOperation.investedFund.manager.id);
        this.allRepresentativesManagerInvestedFund = this.allRepresentativesManagerInvestedFund.filter(fill => fill.maskedPersonalDocumentNumber);

    }

    async changedRepresentativeA(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddExternalOperationSignersRequest = {
                externalOperationId: this.selectedExternalOperation.id,
                representationSignerOneId: event.id,
                representationSignerTwoId: this.selectedExternalOperation?.shareholderFundSignerTwo?.id ? this.selectedExternalOperation?.shareholderFundSignerTwo?.id : null,
            }
            this.selectedExternalOperation = await this.externalOperationService.operationSigners(request);
            this.allRepresentativesB = this.allRepresentativesB.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async changedRepresentativeB(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddExternalOperationSignersRequest = {
                externalOperationId: this.selectedExternalOperation.id,
                representationSignerOneId: this.selectedExternalOperation?.shareholderFundSignerOne?.id ? this.selectedExternalOperation?.shareholderFundSignerOne?.id : null,
                representationSignerTwoId: event.id,
            }
            this.selectedExternalOperation = await this.externalOperationService.operationSigners(request);
            this.allRepresentativesA = this.allRepresentativesA.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }


    async setAsDone() {

        try {
            this.loadingService.show();
            const request: SetExternalOperationSetupAsDoneRequest = {
                externalOperationId: this.selectedExternalOperation.id,
            }
            this.selectedExternalOperation = await this.externalOperationService.setupDone(request);
            this.defineStep();
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

}