import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouterValidationService } from 'src/app/providers/router-validation-service';
import { UserEditComponent } from './account/user/user-edit/user-edit.component';
import { UserInviteComponent } from './account/user/user-invite/user-invite.component';
import { UserListComponent } from './account/user/user-list/user-list.component';
import { UserViewComponent } from './account/user/user-view/user-view.component';
import { BrokerHomeComponent } from './broker-home/broker-home.component';
import { BrokerOperationConsultComponent } from './broker-operation/broker-operation-consult/broker-operation-consult.component';
import { BrokerOperationListComponent } from './broker-operation/broker-operation-list/broker-operation-list.component';
import { BrokerOperationReportComponent } from './broker-operation/broker-operation-report/broker-operation-report.component';
import { BrokerOperationSetupComponent } from './broker-operation/broker-operation-setup/broker-operation-setup.component';
import { ExternalOperationListComponent } from './external-operation/external-operation-list/external-operation-list.component';
import { ExternalOperationSetupComponent } from './external-operation/external-operation-setup/external-operation-setup.component';
import { FundAdministratorCreateComponent } from './fund-administrator/fund-administrator-create/fund-administrator-create.component';
import { FundAdministratorEditFormTemplateComponent } from './fund-administrator/fund-administrator-edit-form-template/fund-administrator-edit-form-template.component';
import { FundAdministratorEditComponent } from './fund-administrator/fund-administrator-edit/fund-administrator-edit.component';
import { FundAdministratorListComponent } from './fund-administrator/fund-administrator-list/fund-administrator-list.component';
import { FundAdministratorOperationSetupComponent } from './fund-administrator/fund-administrator-operation-setup/fund-administrator-operation-setup.component';
import { FundAdministratorProcuratorComponent } from './fund-administrator/fund-administrator-procurator/fund-administrator-procurator.component';
import { FundAdministratorViewComponent } from './fund-administrator/fund-administrator-view/fund-administrator-view.component';
import { FundBrokerCreateComponent } from './fund-broker/fund-broker-create/fund-broker-create.component';
import { FundBrokerDeskConfigsComponent } from './fund-broker/fund-broker-desk-configs/fund-broker-desk-configs.component';
import { FundBrokerEditFormTemplateComponent } from './fund-broker/fund-broker-edit-form-template/fund-broker-edit-form-template.component';
import { FundBrokerEditValidationComponent } from './fund-broker/fund-broker-edit-validation/fund-broker-edit-validation.component';
import { FundBrokerEditComponent } from './fund-broker/fund-broker-edit/fund-broker-edit.component';
import { FundBrokerEmailCompletionViewComponent } from './fund-broker/fund-broker-email-completion-view/fund-broker-email-completion-view.component';
import { FundBrokerEmailCompletionComponent } from './fund-broker/fund-broker-email-completion/fund-broker-email-completion.component';
import { FundBrokerListComponent } from './fund-broker/fund-broker-list/fund-broker-list.component';
import { FundBrokerOperationSetupComponent } from './fund-broker/fund-broker-operation-setup/fund-broker-operation-setup.component';
import { FundBrokerProcuratorComponent } from './fund-broker/fund-broker-procurator/fund-broker-procurator.component';
import { FundBrokerViewComponent } from './fund-broker/fund-broker-view/fund-broker-view.component';
import { FundManagerCreateComponent } from './fund-manager/fund-manager-create/fund-manager-create.component';
import { FundManagerEditComponent } from './fund-manager/fund-manager-edit/fund-manager-edit.component';
import { FundManagerListComponent } from './fund-manager/fund-manager-list/fund-manager-list.component';
import { FundManagerViewComponent } from './fund-manager/fund-manager-view/fund-manager-view.component';
import { FundCreateComponent } from './fund/fund-create/fund-create.component';
import { FundEditComponent } from './fund/fund-edit/fund-edit.component';
import { FundListComponent } from './fund/fund-list/fund-list.component';
import { FundViewComponent } from './fund/fund-view/fund-view.component';
import { HomeComponent } from './home/home.component';
import { IntegrationCreateComponent } from './integration/integration-create/integration-create.component';
import { IntegrationListComponent } from './integration/integration-list/integration-list.component';
import { IntegrationViewComponent } from './integration/integration-view/integration-view.component';
import { OperationConsultComponent } from './operation/operation-consult/operation-consult.component';
import { OperationListComponent } from './operation/operation-list/operation-list.component';
import { OperationReportComponent } from './operation/operation-report/operation-report.component';
import { OperationSetupComponent } from './operation/operation-setup/operation-setup.component';
import { RepresentationCreateComponent } from './representation/representation-create/representation-create.component';
import { RepresentationEditComponent } from './representation/representation-edit/representation-edit.component';
import { RepresentationListComponent } from './representation/representation-list/representation-list.component';
import { RepresentationViewComponent } from './representation/representation-view/representation-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'prefix'
  },
  {
    path: 'home',
    children: [
      {
        path: '',
        component: HomeComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'broker-home',
    children: [
      {
        path: '',
        component: BrokerHomeComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'create',
        component: UserInviteComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'list',
        component: UserListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: UserViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: UserEditComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'representation',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: RepresentationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view',
        component: RepresentationViewComponent,
      },
      {
        path: 'create',
        component: RepresentationCreateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: RepresentationEditComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: RepresentationViewComponent,
        canActivate: [RouterValidationService]
      },
    ]
  },
  {
    path: 'fund-administrator',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: FundAdministratorListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'procurator/:id',
        component: FundAdministratorProcuratorComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: FundAdministratorViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: FundAdministratorEditComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit-form-template/:id',
        component: FundAdministratorEditFormTemplateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'create',
        component: FundAdministratorCreateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'operation-setup/:id',
        component: FundAdministratorOperationSetupComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'fund-broker',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: FundBrokerListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'procurator/:id',
        component: FundBrokerProcuratorComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: FundBrokerViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: FundBrokerEditComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'email-completion/:id',
        component: FundBrokerEmailCompletionComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'email-completion-view/:id',
        component: FundBrokerEmailCompletionViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit-validation/:id',
        component: FundBrokerEditValidationComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit-form-template/:id',
        component: FundBrokerEditFormTemplateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'create',
        component: FundBrokerCreateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'operation-setup/:id',
        component: FundBrokerOperationSetupComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'desk-configs/:id',
        component: FundBrokerDeskConfigsComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'fund-manager',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: FundManagerListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: FundManagerViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: FundManagerEditComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'create',
        component: FundManagerCreateComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'fund',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: FundListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view/:id',
        component: FundViewComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'create',
        component: FundCreateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'edit/:id',
        component: FundEditComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'integration',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'create',
        component: IntegrationCreateComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'list',
        component: IntegrationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view',
        component: IntegrationViewComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'operation',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: OperationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'create',
        component: OperationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'report',
        component: OperationReportComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'view',
        component: OperationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'setup/:id',
        component: OperationSetupComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'consult',
        component: OperationConsultComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'broker-operation',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: BrokerOperationListComponent,
        canActivate: [RouterValidationService]
      },
      // {
      //   path: 'create',
      //   component: OperationListComponent,
      //   canActivate: [RouterValidationService]
      // },
      {
        path: 'report',
        component: BrokerOperationReportComponent,
        canActivate: [RouterValidationService]
      },
      // {
      //   path: 'view',
      //   component: OperationListComponent,
      //   canActivate: [RouterValidationService]
      // },
      {
        path: 'setup/:id',
        component: BrokerOperationSetupComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'consult',
        component: BrokerOperationConsultComponent,
        canActivate: [RouterValidationService]
      }
    ]
  },
  {
    path: 'external-operation',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'prefix'
      },
      {
        path: 'list',
        component: ExternalOperationListComponent,
        canActivate: [RouterValidationService]
      },
      {
        path: 'setup/:id',
        component: ExternalOperationSetupComponent,
        canActivate: [RouterValidationService]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerRoutingModule { }
