import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documentNumber'
})

export class DocumentNumberPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value ? value.replace(/\D/g, '') : '';

    if (!value || !args || value.length < 11 || value.length > 14 || (args.toLowerCase() !== 'cpf' && args.toLowerCase() !== 'cnpj')) {
      return '';
    }

    if (args.toLowerCase() === 'cpf') {
      return this.parseCPF(value);
    } else { // cnpj
      return this.parseCNPJ(value);
    }
    
  }

  // private parseRG(value: string): string {
  //   value=value.replace(/\D/g,"");
  //   value=value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})$/,"$1.$2.$3-$4");
  //   return `${value}`;
  // }

  private parseCPF(value: string): string {
    return value ? `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9)}` : '';
  }

  public parseCNPJ(value: string): string {
    var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    return x ? !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '') : '';
  }

}