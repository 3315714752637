import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/providers/http-client-service';
import { UtilsService } from 'src/app/providers/utils.service';
import { ChunckFileUploadRequest } from './models/requests/chunck-file-upload-request';
import { UploadFileForDocumentReaderRequest } from './models/requests/upload-file-document-reader-request';
import { ChunckTag, FileUploadResponse } from './models/responses/file-upload-response';
import { UploadFileForDocumentReaderResponse } from './models/responses/upload-file-document-reader-response';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(private httpClient: HttpClientService, private utilsService: UtilsService) { }

  async upload(file: File): Promise<FileUploadResponse> {
    return this.httpClient.postFile(`/files`, '/', file);
    // if (file.size < 20971520) {
    //   return this.httpClient.postFile(`/files`, '/', file);
    // }else {
    //   return this.uploadLargeFile(file);
    // }
  }

  async uploadDocumentForDocumentReader(request: UploadFileForDocumentReaderRequest): Promise<UploadFileForDocumentReaderResponse> {
    return this.httpClient.post('/files/document-reader', '/', request, false);
  }

  private async uploadLargeFile(file: File): Promise<FileUploadResponse> {

    const fileExtension = `.${file.name.split('.').pop()}`;
    const fileId = this.utilsService.getUniqueId(5);
    const fileName = fileId + fileExtension;

    let uploadId: string = null;
    let createdTags: ChunckTag[] = [];

    const fileBytes = await this.getFileBytes(file);

    const chunckSize = 5242880; //5MB is the smaller part size allowed by Multpart upload on S3
    var totalChunks = fileBytes.length / chunckSize;
    var readSize = 0;

    let chunckIndex : {key: number, value: number}[] = []

    for (let i = 0; i < totalChunks; i++) {      
      chunckIndex.push({key:i, value: chunckSize});      
    }

    const remaing = fileBytes.length % chunckSize;
    if (remaing > 0) {
        chunckIndex[chunckIndex.length - 1].value = chunckSize + remaing;
    }

    for (let index = 0; index < chunckIndex.length; index++) {
      const chunck = chunckIndex[index];

      const array = new Uint8Array(new ArrayBuffer(chunck.value));

      for (let i = 0; i < chunck.value; i++) {
        array[i] = fileBytes[readSize + i];
      }
      
      const chunckBlob = new Blob([array.buffer]);
      
      const request: ChunckFileUploadRequest = {
        chunckBytes: null,
        fileName: fileName,
        folder: "PlatformLargeFiles",
        uploadId: uploadId,
        chunkIndex: index,
        chunkMax: chunckIndex.length,
        chunckTags: createdTags
      }
      
      const formData = new FormData();
      formData.append("ChunkFile", chunckBlob);
      formData.append("Payload", JSON.stringify(request));

      const response = await this.uploadChunck(formData);
      
      if (!response.isSuccess || response.isLastChunck) return response;

      readSize += chunck.value;
      uploadId = response.uploadId;
      createdTags = response.createdPartTags;
    }

  }

  private async getFileBytes(file: File): Promise<number[]> {
    var fileByteArray : number[] = [];

    var arrayBuffer = await file.arrayBuffer();

    var array = new Uint8Array(arrayBuffer);

    for (var i = 0; i < array.length; i++) {
      fileByteArray.push(array[i]);
    }

    // console.log('fileByteArray', fileByteArray);
    return fileByteArray;
  }

  private async uploadChunck(formData: FormData): Promise<FileUploadResponse> {
    return this.httpClient.post(`/files`, '/chunck', formData);
  }

}