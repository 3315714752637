import { Component } from '@angular/core';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './app.loading.component.html',
    styleUrls: ['./app.loading.component.scss']

})

export class AppLoadingComponent {
    constructor(public loadingSercice: LoadingService) {
    }
}