import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { DirectivesModule } from 'src/app/directives/directives-module';
import { PipesModule } from 'src/app/pipes/pipes-module';
import { FileUploaderModule } from '../fileuploader/fileuploader.module';
import { ArtifactTemplateCreateComponent } from './artifact-template-create/artifact-template-create.component';
import { ArtifactTemplateListComponent } from './artifact-template-list/artifact-template-list.component';
import {TooltipModule} from 'primeng/tooltip';


const COMPONENTS = [
  ArtifactTemplateCreateComponent,
  ArtifactTemplateListComponent
];

@NgModule({
  imports: [        
  DirectivesModule,
    PipesModule,
    ButtonModule,
    InputTextModule,
    FileUploaderModule,
    DropdownModule,
    CommonModule,
    ChipsModule,
    FormsModule,
    TooltipModule
  ],  
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  providers: [DynamicDialogRef, DynamicDialogConfig, ConfirmationService, Location],
  exports: [COMPONENTS]
})
export class ArtifactTemplateModule { }
