import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './app.not-found.component.html',
})
export class AppNotfoundComponent {
  constructor(private location: Location) {
  }

  goBack() {
    this.location.back();
  }
}
