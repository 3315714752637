import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { ApplicationTypeEnum } from 'src/app/enums/application-type-enum';
import { IntegrationFileTypeEnum } from 'src/app/enums/integration-file-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { IntegrationFilesService } from 'src/app/services/integration-files/integration-service';
import { IntegrationFileViewModel } from 'src/app/services/integration-files/models/view-models/integration-file-view-model';
import { ContextService } from './../../../../providers/context-service';

@Component({
    templateUrl: './integration-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IntegrationListComponent implements OnInit {

    integrationFiles: IntegrationFileViewModel[] = [];
    displayErrorDetails: boolean = false;
    selectedIntegration: IntegrationFileViewModel = null;
    notes: any = null;

    constructor(
        private router: Router,
        private loadingService: LoadingService,
        private messageService: MessageService,
        private integrationFileService: IntegrationFilesService,
        private contextService: ContextService) {
    }

    async ngOnInit() {
        await this.loadData();
    }

    async loadData() {
        this.loadingService.show();
        let selectedApplication = this.contextService.getUserApplicationSelected();
        this.integrationFiles = await this.integrationFileService.getAll(null);
        if (selectedApplication.application.value == ApplicationTypeEnum.FoF) {
            this.integrationFiles = this.integrationFiles.filter(fill => fill.type.value !== IntegrationFileTypeEnum.BrokerOperation);
            this.integrationFiles = this.integrationFiles.sort((a, b) => {
                if (a.createDate > b.createDate) {
                    return -1
                } else {
                    return 1
                }
            })
        } else if (selectedApplication.application.value == ApplicationTypeEnum.Broker) {
            this.integrationFiles = this.integrationFiles.filter(fill => fill.type.value !== IntegrationFileTypeEnum.ManagerEntry &&
                fill.type.value !== IntegrationFileTypeEnum.FoFOperation);
            this.integrationFiles = this.integrationFiles.sort((a, b) => {
                if (a.createDate > b.createDate) {
                    return -1
                } else {
                    return 1
                }
            })
        }

        this.loadingService.hide();
    }

    addIntegration() {
        return this.router.navigateByUrl(`app/customer/integration/create`);
    }

    viewIntegration(integrationFile: IntegrationFileViewModel) {
        return this.router.navigateByUrl(`app/customer/integration/view/${integrationFile.id}`);
    }

    async retryIntegration(integrationFile: IntegrationFileViewModel) {
        try {
            this.loadingService.show();
            await this.integrationFileService.retryIntegration(integrationFile.id);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.loadData();
        }
    }

    viewDetails(integrationFile: IntegrationFileViewModel) {
        this.displayErrorDetails = true;
        this.selectedIntegration = integrationFile;
        this.notes = `${this.selectedIntegration.notes} \n`;
    }

    openDownloadFile(operation: IntegrationFileViewModel) {
        window.open(operation.fileUrl, "_blank");
    }

    getMenuItems(integrationFile: IntegrationFileViewModel): MenuItem[] {

        const items: MenuItem[] = [];
        switch (integrationFile.status.displayName) {
            case 'Processed':
                items.push({ label: 'Download do Arquivo', command: e => this.openDownloadFile(integrationFile) });
                break;
            case 'Failed':
                items.push({ label: 'Tentar novamente', command: e => this.retryIntegration(integrationFile) });
                items.push({ label: 'Ver Detalhes', command: e => this.viewDetails(integrationFile) });
                items.push({ label: 'Download do Arquivo', command: e => this.openDownloadFile(integrationFile) });
                break;
            case 'Processing':
                items.push({ label: 'Download do Arquivo', command: e => this.openDownloadFile(integrationFile) });
                break;

            default:
                break;
        }
        return items;
    }

}