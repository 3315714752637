import { NgModule } from "@angular/core";
import { ValidateCorporateEmailDirective } from "./validations/validate-corporate-email-directive";
import { ValidateEmailDirective } from "./validations/validate-email-directive";
import { ValidateFullNameDirective } from "./validations/validate-full-name-directive";
import { ValidatePasswordDirective } from "./validations/validate-password-directive";

const DIRECTIVES = [
    ValidatePasswordDirective,
    ValidateCorporateEmailDirective,
    ValidateEmailDirective,
    ValidateFullNameDirective
];

@NgModule({
    declarations: DIRECTIVES,
    exports: DIRECTIVES
  })
  export class DirectivesModule { }
  