import { Component, HostListener, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { ArtifactStatusEnum } from 'src/app/enums/artitact-status-enum';
import { OperationStatusEnum } from 'src/app/enums/operation-status-enum';
import { OperationTypeEnum } from 'src/app/enums/operation-type-enum';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { BrokerOperationAttachmentViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-attachment-view-model';
import { FileUploadService } from 'src/app/services/file-uploads/file-upload-service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';
import { FundManagerService } from 'src/app/services/fund-manager/fund-manager-service';
import { CreateContactRequest } from 'src/app/services/fund-manager/models/requests/create-contact-request';
import { ContactProperties } from 'src/app/services/fund-manager/models/view-models/contact-properties';
import { ContactViewModel } from 'src/app/services/fund-manager/models/view-models/contact-view-model';
import { AddAdministratorSignersRequest } from 'src/app/services/operations/models/request/add-administrator-signers-request';
import { AddAttachmentRequest } from 'src/app/services/operations/models/request/add-attachment-request';
import { AddFundRegulationAsAttachmentRequest } from 'src/app/services/operations/models/request/add-fund-regulation-as-attachment-request';
import { AddManagerSignersRequest } from 'src/app/services/operations/models/request/add-manager-signers-request';
import { AddRecipientRequest } from 'src/app/services/operations/models/request/add-recipient-request';
import { AddSignersRequest } from 'src/app/services/operations/models/request/add-signers-request';
import { DismissOperationAssessmentRequest } from 'src/app/services/operations/models/request/dismiss-operation-assessment-request';
import { FillOperationArtifactRequest } from 'src/app/services/operations/models/request/fill-operation-artifact-request';
import { ReplyOperationAssessmentRequest } from 'src/app/services/operations/models/request/reply-operation-assessment-request';
import { ResendContactManagerNotificationRequest } from 'src/app/services/operations/models/request/resend-contact-manager-notification-request';
import { SetSetupAsDoneRequest } from 'src/app/services/operations/models/request/set-setup-as-done-request';
import { AssessmentSummaryViewModel } from 'src/app/services/operations/models/view-models/assessment-summary-view-model';
import { AttachmentViewModel } from 'src/app/services/operations/models/view-models/attachment-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';
import { ArtifactViewModel } from 'src/app/services/operations/models/view-models/operation-view-model-operation-view-model';
import { OperationService } from 'src/app/services/operations/operation-service';
import { RepresentationViewModel } from 'src/app/services/representations/models/view-models/representation-view-model';
import { RepresentationService } from 'src/app/services/representations/representation-service';


@Component({
    templateUrl: './operation-setup.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss', './operation-setup.component.scss']
})
export class OperationSetupComponent implements OnInit {

    steps: MenuItem[] = [{ label: 'Setup' }, { label: 'Documentos' }, { label: 'Assinatura' }, { label: 'Análise do Gestor' }, { label: 'Concluído' }];
    currentStep: number = 0;
    managerContacts: ContactProperties[] = [];
    allRepresentativesA: RepresentationViewModel[] = [];
    allRepresentativesB: RepresentationViewModel[] = [];
    displayCreateManagerContacts: boolean = false;
    displayPendingOperation: boolean = false;
    displayOperationFiles: boolean = false;
    displayOperationFundRegulationFile: boolean = false;
    displayApprovedAssesment: boolean = false;
    requestFiles = null;
    requestFundRegulationFile = null;
    requestAssesmentReply = null;
    requestDissmisAssesment = null;
    selectedOperation: OperationViewModel = null;
    displayFillInfoTemplate: boolean = false;
    artefactObservable = new Subject<ArtifactViewModel>();
    requestArtefacts: FillOperationArtifactRequest = null;
    displayButtonArtefacts: boolean = false;
    displyDismissAssesment: boolean = false;
    displayAlertDismissAssesment: boolean = false;
    stepDescription: string = null;
    selectedContacts: [] = null;
    recipients: AssessmentSummaryViewModel[] = [];

    allRepresentativesAdminInvestedFund: RepresentativeViewModel[] = [];
    switchRepresentativeAdminInvestedFund: boolean = false;
    representativesAdminInvestedFund: string[] = [];

    allRepresentativesManagerInvestedFund: ContactViewModel[] = [];
    switchRepresentativeManagerInvestedFund: boolean = false;
    representativesManagerInvestedFund: string[] = [];

    switchContact: string = null;
    allContacts: SelectItem[] = [];
    selectedContatIncludeOperation: ContactViewModel = null;
    displayMessage: boolean = false;
    displayAlertDocuments: boolean = false;
    displayAttachment: boolean = false;
    hasFundRegulationFile: boolean = false;
    disabledButtonSignature: boolean = false;

    public artifactStatusEnum = ArtifactStatusEnum;
    public operationStatusEnum = OperationStatusEnum;
    public operationTypeEnum = OperationTypeEnum;

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        if (this.selectedOperation.currentStatus.status.value !== OperationStatusEnum.Concluída
            && this.selectedOperation.currentStatus.status.value !== OperationStatusEnum.Cancelada) {
            this.router.navigate([`app/customer/operation/list`], { queryParams: { tab: 0 } });
        } else if (this.selectedOperation.currentStatus.status.value == OperationStatusEnum.Concluída) {
            this.router.navigate([`app/customer/operation/list`], { queryParams: { tab: 1 } });
        } else if (this.selectedOperation.currentStatus.status.value == OperationStatusEnum.Cancelada) {
            this.router.navigate([`app/customer/operation/list`], { queryParams: { tab: 2 } });
        }
    }


    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
        private formValidationService: FormValidationService,
        private representationService: RepresentationService,
        private operationService: OperationService,
        private fundManagerService: FundManagerService,
        private fileUploadService: FileUploadService,
        private confirmationService: ConfirmationService,
        private fundAdministratorService: FundAdministratorService,
        private messageService: MessageService) {
    }

    async ngOnInit() {
        this.loadingService.show();
        this.setFormUpload();
        this.setFundRegulationFormUpload();
        await this.loadData();
        await this.loadAllRepresentatives();
        await this.loadManagerContacts();
        await this.getAllContactsFundManager();
        this.verifyHasFundRegulationFile();
        this.loadingService.hide();
    }

    setFundRegulationFormUpload() {
        this.requestFundRegulationFile = {
            description: "Regulamento do Fundo",
            files: []
        }
    }

    setFormUpload() {
        this.requestFiles = {
            description: null,
            files: []
        }
        this.requestAssesmentReply = {
            reply: null,
        }
        this.requestDissmisAssesment = {
            notes: null,
        }
    }

    verifyHasFundRegulationFile() {
        this.hasFundRegulationFile = this.selectedOperation?.attachments.some(c => c.description.includes("Regulamento do Fundo"));
    }

    defineStep() {
        switch (this.selectedOperation.currentStatus.status.value) {
            case OperationStatusEnum.EmSetup:
                this.currentStep = 0;
                this.stepDescription = 'Em Setup';
                this.verifyHasFundRegulationFile();
                break;
            case OperationStatusEnum.PreenchimentoManualNecessario:
            case OperationStatusEnum.PreenchimentoConcluido:
                this.currentStep = 1;
                this.stepDescription = 'Documentos';
                break;
            case OperationStatusEnum.AguardandoAssinatura:
                this.currentStep = 2;
                this.stepDescription = 'Assinatura'
                break;
            case OperationStatusEnum.EmAnalisePeloGestor:
            case OperationStatusEnum.PendenciadaPeloGestor:
                this.currentStep = 3;
                this.stepDescription = 'Análise do Gestor'
                this.getOpertionAssessments();
                this.verifyHasFundRegulationFile();
                break;
            case OperationStatusEnum.Concluída:
                this.currentStep = 4;
                this.stepDescription = 'Concluído'
                this.getOpertionAssessments();
                break;
            case OperationStatusEnum.Cancelada:
                this.currentStep = 5;
                this.stepDescription = 'Cancelado'
                this.getOpertionAssessments();
                break;
            default:
                this.messageService.add({ severity: 'warn', summary: 'Status não permitido', detail: 'O status atual da operação não permite operação', life: 5000 });
                this.router.navigateByUrl(`app/customer/operation/list`)
                break;
        }
    }

    async getOpertionAssessments() {
        const operationId = this.selectedOperation.id;
        this.recipients = await this.operationService.getOperationAssessments(operationId);
        this.recipients = this.recipients.sort((a, b) => {
            if (new Date(a.createDate) < new Date(b.createDate)) {
                return 1;
            } else {
                return -1;
            }
        });
    }

    async loadData() {
        const operationId = this.route.snapshot.params.id;
        this.selectedOperation = await this.operationService.getOperationById(operationId);
        this.defineStep();
        if (this.selectedOperation.managerSigners.length > 0) {
            this.switchRepresentativeManagerInvestedFund = true;
        }
        if (this.selectedOperation.administratorSigners.length > 0) {
            this.switchRepresentativeAdminInvestedFund = true;
        }
    }

    async uploadFiles(event: File, formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) {
            this.requestFiles.files = [];
            return;
        };

        try {
            this.displayOperationFiles = false;
            this.loadingService.show();
            const response = await this.fileUploadService.upload(event[0]);
            const request: AddAttachmentRequest = {
                operationId: this.selectedOperation.id,
                description: this.requestFiles.description,
                fileUrl: response.fileUrl
            }
            this.selectedOperation = await this.operationService.operationAttachments(request);
            this.updateUrl();
            this.selectedOperation.attachments = this.selectedOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
                return {
                    description: it.description,
                    fileUrl: it.fileUrl,
                    user: it.user,
                    contact: it.contact,
                    id: it.id,
                    isActive: it.isActive,
                    createDate: it.createDate
                }
            })
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
            this.displayOperationFiles = false;
        } finally {
            // this.updateUrl();
            this.setFormUpload();
            this.setFundRegulationFormUpload();
            this.activeAttachments();
            this.loadingService.hide();
        }
    }

    async removeFile(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        try {
            this.loadingService.show();
            // const fileIndex = this.selectedOperation.recipients.findIndex(c => c.id == file.id);
            // const attachment = this.selectedOperation.recipients[fileIndex];
            await this.operationService.deleteAttachment(file.id);
            this.updateUrl();
            // this.selectedOperation.recipients.splice(fileIndex, 1);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
            await this.loadData();
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
            this.formartDateAttachments();
            this.verifyHasFundRegulationFile();
        }
    }

    formartDateAttachments() {
        this.selectedOperation.attachments = this.selectedOperation.attachments.map(it => {
            const format = new Date(it.createDate);
            format.setHours(format.getHours() - 3);
            it.createDate = format.toISOString();
            return {
                description: it.description,
                fileUrl: it.fileUrl,
                user: it.user,
                contact: it.contact,
                id: it.id,
                isActive: it.isActive,
                createDate: it.createDate
            }
        });
    }

    confirm(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        this.confirmationService.confirm({
            header: `Anexo ${file.description}`,
            message: `Tem certeza que deseja excluir o anexo ${file.description}?`,
            acceptIcon: '',
            rejectIcon: '',
            acceptLabel: 'Confirmar',
            rejectLabel: 'Cancelar',
            accept: () => {
                this.removeFile(file);
            },
            reject: () => {
                this.confirmationService.close();
            }
        });
    }

    openAddFile() {
        if (this.selectedOperation.currentStatus.status.value == OperationStatusEnum.EmSetup) {
            this.displayAlertDocuments = true;
        } else {
            this.displayOperationFiles = true;
        }
    }

    openAddFundRegulationFile() {
        this.displayOperationFundRegulationFile = true;
    }

    async onOperationUploadFundRegulation() {
        this.loadingService.show();
        try {
            const request: AddFundRegulationAsAttachmentRequest = {
                operationId: this.selectedOperation.id,
            }
            this.selectedOperation = await this.operationService.operationAttachmentsOnFundRegulation(request);
            this.updateUrl();
            this.selectedOperation.attachments = this.selectedOperation.attachments.map(it => {
                const format = new Date(it.createDate);
                format.setHours(format.getHours() - 3);
                it.createDate = format.toISOString();
                return {
                    description: it.description,
                    fileUrl: it.fileUrl,
                    user: it.user,
                    contact: it.contact,
                    id: it.id,
                    isActive: it.isActive,
                    createDate: it.createDate
                }
            })
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.verifyHasFundRegulationFile();
            this.loadingService.hide();
        }
    }

    dismissAlert() {
        this.displayAlertDocuments = false;
        this.displayOperationFiles = true;
    }

    activeAttachments() {
        this.displayAttachment = true;
    }

    desactiveAttachments() {
        this.displayAttachment = true;
    }

    async loadAllRepresentatives() {
        const active = true;
        this.allRepresentativesA = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesB = await this.representationService.getAllRepresentations(active);
        this.allRepresentativesA = this.allRepresentativesA.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });
        this.allRepresentativesB = this.allRepresentativesB.sort((a, b) => {
            if (a.name > b.name) {
                return 1
            } else {
                return -1
            }
        });

        this.allRepresentativesAdminInvestedFund = await this.fundAdministratorService.getRepresentativesInFundAdministrator(this.selectedOperation.investedFund.administrator.id);
        this.allRepresentativesManagerInvestedFund = await this.fundManagerService.getContactsByFundManagerId(this.selectedOperation.investedFund.manager.id);
        this.allRepresentativesManagerInvestedFund = this.allRepresentativesManagerInvestedFund.filter(fill => fill.maskedPersonalDocumentNumber);

    }

    async changedRepresentativeA(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddSignersRequest = {
                operationId: this.selectedOperation.id,
                representationSignerOneId: event.id,
                representationSignerTwoId: this.selectedOperation?.shareholderFundSignerTwo?.id ? this.selectedOperation?.shareholderFundSignerTwo?.id : null,
            }
            this.selectedOperation = await this.operationService.operationSigners(request);
            this.allRepresentativesB = this.allRepresentativesB.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async changedRepresentativeB(event: RepresentationViewModel) {
        try {
            this.loadingService.show();
            const request: AddSignersRequest = {
                operationId: this.selectedOperation.id,
                representationSignerOneId: this.selectedOperation?.shareholderFundSignerOne?.id ? this.selectedOperation?.shareholderFundSignerOne?.id : null,
                representationSignerTwoId: event.id,
            }
            this.selectedOperation = await this.operationService.operationSigners(request);
            this.allRepresentativesA = this.allRepresentativesA.filter(fill => fill.name !== event.name);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async loadManagerContacts() {
        this.managerContacts = await this.fundManagerService.getContactsByFundManagerId(this.selectedOperation?.investedFund?.manager?.id);
    }

    async selectedCheckbox(event: ContactProperties) {
        try {
            this.loadingService.show()
            const request: AddRecipientRequest = {
                operationId: this.selectedOperation.id,
                contactsIds: [event.id]
            };
            await this.operationService.operationRecipients(request);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async unSelectCheckbox(event: ContactProperties) {
        try {
            this.loadingService.show()
            const recipientIndex = this.selectedOperation.recipients.findIndex(recipient => recipient.contact.id == event.id);
            const recipient = this.selectedOperation.recipients[recipientIndex];
            await this.operationService.deleteRecipients(recipient.id);
            this.selectedOperation.recipients.splice(recipientIndex, 1);
            this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async toggleOnHeader(checked: boolean) {
        if (checked) {
            for (let contact of this.managerContacts) {
                await this.selectedCheckbox(contact);
            }
        } else {
            for (let contact of this.managerContacts) {
                await this.unSelectCheckbox(contact);
            }
        }
    }


    async selectedManagerCheckbox(event: ContactViewModel) {
        let uniqrepresentativesManagerInvestedFund: string[] = [];
        this.representativesManagerInvestedFund.push(event.id);
        uniqrepresentativesManagerInvestedFund = [...new Set(this.representativesManagerInvestedFund)];
        const request: AddManagerSignersRequest = {
            operationId: this.selectedOperation.id,
            contactsIds: uniqrepresentativesManagerInvestedFund
        }
        try {
            this.loadingService.show();
            this.selectedOperation = await this.operationService.updateManagerSignersOperation(request)
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async unSelectManagerCheckbox(event: ContactViewModel) {
        const operatioId = this.selectedOperation.id;
        const contactId = event.id;

        try {
            this.loadingService.show();
            // const recipientIndex = this.selectedOperation.recipients.findIndex(recipient => recipient.contact.id == event.id);
            // const recipient = this.selectedOperation.recipients[recipientIndex];
            // await this.operationService.deleteRecipients(recipient.id);
            // this.selectedOperation.recipients.splice(recipientIndex, 1);
            await this.operationService.removeContactManagerSignersOperation(operatioId, contactId);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async toggleManagerOnHeader(checked: boolean) {
        if (checked) {
            for (let contact of this.allRepresentativesManagerInvestedFund) {
                await this.selectedManagerCheckbox(contact);
            }
        } else {
            for (let contact of this.selectedOperation.managerSigners) {
                await this.unSelectManagerCheckbox(contact.contactSigner);
            }
        }
    }

    async changeSwitchManager(event: boolean) {
        if (event == false) {
            for (let contact of this.selectedOperation.managerSigners) {
                await this.unSelectManagerCheckbox(contact.contactSigner);
            }
            this.switchRepresentativeManagerInvestedFund = false;
        }
    }



    async selectedAdminCheckbox(event: RepresentativeViewModel) {
        let uniqRepresentativesAdminInvestedFund: string[] = [];
        this.representativesAdminInvestedFund.push(event.id);
        uniqRepresentativesAdminInvestedFund = [...new Set(this.representativesAdminInvestedFund)];
        const request: AddAdministratorSignersRequest = {
            operationId: this.selectedOperation.id,
            representativesIds: uniqRepresentativesAdminInvestedFund
        }
        try {
            this.loadingService.show();
            this.selectedOperation = await this.operationService.updateAdminSignersOperation(request);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async unSelectAdminCheckbox(event) {
        const operatioId = this.selectedOperation.id;
        const representativeId = event.id;
        try {
            this.loadingService.show()
            // const recipientIndex = this.selectedOperation.recipients.findIndex(recipient => recipient.contact.id == event.id);
            // const recipient = this.selectedOperation.recipients[recipientIndex];
            // await this.operationService.deleteRecipients(recipient.id);
            // this.selectedOperation.recipients.splice(recipientIndex, 1);
            await this.operationService.removeContactAdminSignersOperation(operatioId, representativeId)
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async toggleAdminOnHeader(checked: boolean) {
        if (checked) {
            for (let contact of this.allRepresentativesAdminInvestedFund) {
                await this.selectedAdminCheckbox(contact);
            }
        } else {
            for (let contact of this.selectedOperation.administratorSigners) {
                await this.unSelectAdminCheckbox(contact.representativeSigner);
            }
        }
    }

    async changeSwitchAdmin(event: boolean) {
        if (event == false) {
            for (let contact of this.selectedOperation.administratorSigners) {
                await this.unSelectAdminCheckbox(contact.representativeSigner);
            }
            this.switchRepresentativeAdminInvestedFund = false;
        }
    }

    addManagerContact() {
        this.displayCreateManagerContacts = true;
    }

    async saveManagerContact(event, requestFormManagerContact) {

        if (!this.formValidationService.validateForm(requestFormManagerContact.form.form)) return;

        try {
            this.loadingService.show();
            const request: CreateContactRequest = {
                fundManagerId: this.selectedOperation.investedFund.manager.id,
                name: event.request.name,
                email: event.request.email,
                expireDate: event.request.expireDate
            }
            await this.fundManagerService.createContactsFundManager(request);
            this.displayCreateManagerContacts = false;
            let response = await this.fundManagerService.getContactsByFundManagerId(this.selectedOperation.investedFund.manager.id);
            let filterContact = response.filter(fill => fill.email == event.request.email)[0];
            const requestTwo: AddRecipientRequest = {
                operationId: this.selectedOperation.id,
                contactsIds: [filterContact.id]
            };
            await this.operationService.operationRecipients(requestTwo);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            await this.loadManagerContacts();
            this.displayCreateManagerContacts = false;
            this.loadingService.hide();
        }
    }

    onCancel() {
        this.displayCreateManagerContacts = false;
    }


    fillInfoTemplate(artefact: ArtifactViewModel) {
        this.displayFillInfoTemplate = true;
        this.artefactObservable.next(artefact);
        return this.artefactObservable.asObservable();
    }

    async updateArtifactById(artifact: ArtifactViewModel) {
        try {
            this.loadingService.show();
            const artifactId = artifact.id;
            await this.operationService.changeSingatures(artifactId);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Status atualizado com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async saveFillTemplate(event) {
        try {
            this.displayFillInfoTemplate = false;
            this.loadingService.show();
            this.selectedOperation = await this.operationService.updateArtifacts(event);
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async setAsDone() {
        if (this.switchRepresentativeAdminInvestedFund && this.selectedOperation.administratorSigners.length == 0 ||
            this.switchRepresentativeManagerInvestedFund && this.selectedOperation.managerSigners.length == 0) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'Outras Assinaturas está ativado. Selecione os Rep. Legais/Procuradores ou desative para prosseguir', life: 5000 });
            return;
        }
        try {
            this.loadingService.show();
            const request: SetSetupAsDoneRequest = {
                operationId: this.selectedOperation.id,
            }
            this.selectedOperation = await this.operationService.setupDone(request);
            this.defineStep();
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    async sendToSign() {
        this.disabledButtonSignature = true;
        this.loadingService.show();
        try {
            const request: SetSetupAsDoneRequest = {
                operationId: this.selectedOperation.id,
            }
            this.selectedOperation = await this.operationService.signatureReady(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    openArtefactUrl(file: AttachmentViewModel | BrokerOperationAttachmentViewModel) {
        window.open(file.fileUrl, '_blank');
    }

    openAlertDismissAssesment() {
        this.displayAlertDismissAssesment = true;
    }

    openDismissAssesment() {
        this.displayAlertDismissAssesment = false;
        this.displyDismissAssesment = true;
    }

    async sendReplyAssesment() {
        const contactId = this.selectedOperation.recipients.find(find => find.isOperationApproved == false).contact.id;
        try {
            this.loadingService.show();
            const request: ReplyOperationAssessmentRequest = {
                operationId: this.selectedOperation.id,
                contactId: contactId,
                notes: this.requestAssesmentReply.reply,
            }
            this.selectedOperation = await this.operationService.replyAssesment(request);
            this.defineStep();
            this.displayMessage = true;
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.setFormUpload();
            this.loadingService.hide();
        }
    }

    async dismissAssesments(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        try {
            const request: DismissOperationAssessmentRequest = {
                operationId: this.selectedOperation.id,
                notes: this.requestDissmisAssesment.notes,
            }
            this.loadingService.show();
            this.displyDismissAssesment = false;
            this.selectedOperation = await this.operationService.dismissAssessment(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }


    openShareholderFund() {
        // this.router.navigateByUrl(`app/customer/fund/view/${this.selectedOperation.shareholderFund.id}`)
    }

    async resendEmail(recipient) {
        try {
            this.loadingService.show();
            const request: ResendContactManagerNotificationRequest = {
                operationId: this.selectedOperation.id,
                contactId: recipient.contact.id,
            }
            this.selectedOperation = await this.operationService.resendMailAssesment(request);
            this.defineStep();
            this.messageService.add({ severity: 'success', summary: 'E-mail enviado.', detail: `E-mail enviado com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    createManagerContact() {
        this.displayCreateManagerContacts = true;
    }

    changeSwitchContact(event) {
        console.log(event)
    }

    async getAllContactsFundManager() {
        let response = await this.fundManagerService.getContactsByFundManagerId(this.selectedOperation.investedFund.manager.id);
        response.map(item => {
            this.allContacts.push({ label: `${item.name} - ${item.email}`, value: item })
        })
    }

    async onSaveExistContactInOperation(contact: ContactViewModel) {
        try {
            this.loadingService.show()
            const request: AddRecipientRequest = {
                operationId: this.selectedOperation.id,
                contactsIds: [contact.id]
            };
            this.displayCreateManagerContacts = false;
            await this.operationService.operationRecipients(request);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.displayCreateManagerContacts = false;
            this.loadingService.hide();
        }
    }

    async removeContact(contact: ContactProperties) {
        if (this.selectedOperation.recipients.length == 1) {
            this.messageService.add({ severity: 'error', summary: 'Houve um erro.', detail: 'A Operação precisa ter pelo menos um contato da gestora.', life: 5000 });
            return;
        }
        try {
            this.loadingService.show();
            await this.operationService.deleteRecipients(contact.id);
            await this.loadData();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    updateUrl() {
        const currentRoute = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentRoute]);
        });
    }

}