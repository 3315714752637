import { Injectable } from "@angular/core";
import { HttpClientService } from "src/app/providers/http-client-service";
import { DefaultEnumViewModel } from "src/app/providers/models/default-enum-view-model";
import { IntegrationFileViewModel } from "./models/view-models/integration-file-view-model";

@Injectable({
    providedIn: 'root'
})

export class IntegrationFilesService {
    constructor(private httpClient: HttpClientService) { }

    async getIntegrationFileTypes(applicationName: string): Promise<DefaultEnumViewModel[]> {
        return this.httpClient.get(`/integration-files`, `/types`, true, true, {applicationName: applicationName});
    }

    async getAll(isActive: boolean): Promise<IntegrationFileViewModel[]> {
        return this.httpClient.get(`/integration-files`, `/all/${isActive}`);
    }

    async uploadAdministratorFile(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, '/administrators-files', file);
    }

    async uploadFundFile(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, '/funds-files', file);
    }

    async uploadManagerFile(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, '/managers-files', file);
    }

    async uploadOperationFile(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, '/operations-files', file);
    }

    async uploadRepresentationFile(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, '/representations-files', file);
    }
    
    async retryIntegration(integrationFileId: string): Promise<IntegrationFileViewModel> {
        return this.httpClient.path(`/integration-files/`, `${integrationFileId}/retries`);
    }

    async sftp(): Promise<void> {
        return this.httpClient.get(`/integration-files`, `/sftp`);
    }

    async uploadFundClousure(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, `/closed-funds-files`, file);
    }

    async uploadBrokerOperation(file: File): Promise<IntegrationFileViewModel> {
        return this.httpClient.postFile(`/integration-files`, `/broker-operations-files`, file);
    }
}
