<div class="p-grid">
    <div class="p-col-12">
        <div class="titleBody" *ngIf="isRequestTemplateSent">
            Template configurado.
        </div>
        <form class="customCardOutlined" #entryForm="ngForm" novalidate [hidden]="isRequestTemplateSent">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-mt-3 p-col-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-mt-3">
                            <div class="p-col-12 customItem alignContentArtifactTemplate">
                                <app-fileuploader [isMultipleFiles]="false" [label]="''"
                                    [currentFiles]="currentDocument"
                                    [chooseLabel]="filledRequest.fileUrl == null ? 'Escolher Arquivo' : 'Trocar arquivo'"
                                    [isImage]="false" (onUploadResponse)="getArtifactTemplateVariables($event)">
                                </app-fileuploader>
                                <div class="spaceTop alignContentArtifactTemplateText">
                                    <span>Obs: Somente arquivo <b>(.docx), (.xlsx) ou (.pdf)</b> são permitidos.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="titleBody" *ngIf="filledRequest.variableFields.length > 0">
                Campos
            </div>
            <div class="p-fluid p-formgrid p-grid" *ngIf="filledRequest.variableFields.length > 0">
                <div class="p-mt-3 p-col-12" *ngFor="let variableField of filledRequest.variableFields; let i = index">
                    <div class="p-grid">
                        <div class="p-col-4 p-mt-1">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="replacer" type="text" [(ngModel)]="variableField.replacer"
                                        [name]="'replacer'+i" class="inputLiza" pInputText readonly>
                                    <label for="replacer">Variável</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-2 p-mt-1">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="fieldTypeOptions" placeholder="Selecione" [resetFilterOnHide]="true" [filter]="true"
                                        [(ngModel)]="variableField.fieldTypeId" optionLabel="friendlyName"
                                        optionValue="value" [name]="'fieldTypeId'+i"
                                        (onChange)="onFieldTypeSelected(variableField)">
                                    </p-dropdown>
                                    <!-- <label for="">Tipo</label> -->
                                </span>
                            </div>
                        </div>
                        <div class="p-col-5 p-mt-1"
                            [hidden]="variableField.fieldType == null || variableField.fieldType.hasAnswerOptions == false">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-chips [(ngModel)]="variableField.answerOptions" separator="," class="inputLiza"
                                        [disabled]="variableField.fieldType == null || variableField.fieldType.hasAnswerOptions == false"
                                        [name]="'answerOptions'+i">
                                    </p-chips>
                                    <label for="customerId">Opções de Resposta</label>
                                </span>
                            </div>
                        </div>
                        <div class="p-col-2 p-mt-1"
                            [hidden]="variableField.fieldType == null || variableField.fieldType.isEntityMapping == false">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="mappedEntityOptions" placeholder="Entidade" [resetFilterOnHide]="true" [filter]="true"
                                        [(ngModel)]="variableField.mappedEntityName" optionLabel="friendlyName"
                                        optionValue="displayName" [name]="'mappedEntityName'+i"
                                        (onChange)="onMappedEntitySelected(variableField)">
                                    </p-dropdown>
                                    <!-- <label for="">Entidade</label> -->
                                </span>
                            </div>
                        </div>
                        <div class="p-col-3 p-mt-1"
                            [hidden]="variableField.entityPropertiesOptions.length == 0 || variableField.fieldType == null || variableField.fieldType.isEntityMapping == false">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <p-dropdown [options]="variableField.entityPropertiesOptions" [filter]="true"
                                        placeholder="Propriedade" [(ngModel)]="variableField.mappedEntityProperty"
                                        optionLabel="label" optionValue="value" [name]="'mappedEntityProperty'+i">
                                    </p-dropdown>
                                    <!-- <label for="">Propriedade</label> -->
                                </span>
                            </div>
                        </div>
                        <div class="p-col-2 p-mt-1"  [hidden]="variableField.fieldType == null || variableField.fieldType.isEntityMapping == false">
                            <div class="p-inputgroup">
                                <span class="p-float-label">
                                    <input id="defaultReplacerValue" type="text" [(ngModel)]="variableField.defaultReplacerValue" appendTo="body"
                                        pTooltip="O valor será inserido caso a informação não seja encontrada" tooltipPosition="top"
                                        [name]="'defaultReplacerValue'+i" class="inputLiza" pInputText>
                                    <label for="defaultReplacerValue">Informação Opcional</label>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <!-- <div class="contentCenterColumn"> -->
                    <br>
                    <button pButton pRipple type="button" label="Editar" iconPos="right" class="buttonPlain"
                        *ngIf="isRequestTemplateSent" (click)="onCancel()"></button>
                    <br>
                    <button pButton pRipple type="button" label="Confirmar" iconPos="right"
                        *ngIf="!isRequestTemplateSent && currentDocument" (click)="onSave(entryForm)"></button>
                    <!-- </div> -->
                </div>
            </div>
        </form>
    </div>
</div>