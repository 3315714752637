import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BrokerOperationViewModel } from 'src/app/services/brokers/models/view-models/broker-operation-view-model';
import { OperationViewModel } from 'src/app/services/operations/models/view-models/operation-view-model';

@Component({
    templateUrl: './legal-representative-fund-list.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss'],
    encapsulation: ViewEncapsulation.None,
    selector: 'app-legal-representative-fund-list'
})
export class LegalRepresentativeFundListComponent implements OnInit {

    @Input() selectedOperation: OperationViewModel = null;
    @Input() selectedBrokerOperation: BrokerOperationViewModel = null;

    constructor() {}

    async ngOnInit() {
    }

    openDocumentFile(url: string) {
        window.open(url, "_blank");
    }


}