import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private scrollX: number = 0;
    private scrollY: number = 0;
    private showLoading = new Subject<boolean>();
    public showLoading$ = this.showLoading.asObservable();

    show() {
        this.scrollX = document.documentElement.scrollLeft || document.body.scrollLeft;
        this.scrollY = document.documentElement.scrollTop || document.body.scrollTop;
        this.showLoading.next(true);
        window.scrollTo(0, 0);
    }
    
    hide(delay: number = null) {
        
        if (delay == null) {
            this.showLoading.next(false);
            window.scrollTo(this.scrollX, this.scrollY);
        }else {
            setTimeout(() => {
                this.showLoading.next(false);
            },delay);
        }
    }
}
