<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            Auditoria
        </div>
        <div class="customCard">
            <div class="cardHeader text-bold">Tipo de relatório</div>
            <div class="p-grid">
                <div class="p-col-12 p-md-4 p-lg-4 inputItem">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="audits" placeholder="Selecione o tipo de relatório"
                                (onChange)="changeAudit($event.value)" [required]="true" [(ngModel)]="selectedAudit"
                                optionLabel="label" value="value" #report="ngModel">
                            </p-dropdown>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8 "></div>
                <div class="p-col-12 p-md-4 p-lg-4 inputItem" *ngIf="selectedAudit?.value == 'month'">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="auditMonths" placeholder="Selecione o mês"
                                [required]="true" [(ngModel)]="selectedMonth"
                                optionLabel="label" value="value" #report="ngModel">
                            </p-dropdown>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4 inputItem" *ngIf="selectedAudit?.value == 'month'">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <p-dropdown [options]="auditYears" placeholder="Selecione o ano"
                                [required]="true" [(ngModel)]="selectedYear"
                                optionLabel="label" value="value" #report="ngModel">
                            </p-dropdown>
                        </span>
                    </div>
                </div>
                <div class="p-col-2 p-md-3 p-lg-3" *ngIf="selectedAudit?.value == 'month'">
                    <button pButton pRipple type="button" label="Exportar" class="p-button-primary"
                        (click)="exportByMonth()"></button>
                </div>
            </div>
        </div>
    </div>
</div>