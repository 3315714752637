import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';
import { RepresentativeViewModel } from 'src/app/services/fund-administrators/models/view-models/representative-view-model';


@Component({
    templateUrl: './fund-administrator-procurator.component.html',
    styleUrls: ['../../../../../assets/layout/customized/custom-card.scss']
})
export class FundAdministratorProcuratorComponent implements OnInit {

    request = null;
    requestRepresentations: any[] = [];
    selectedFundAdministrator: FundAdministratorViewModel = null;
    requestValidateDate: Date = null;
    allRepresentatives: RepresentativeViewModel[] = [];

    constructor(
        private fundAdministratorService: FundAdministratorService,
        private route: ActivatedRoute,
        private router: Router,
        private loadingService: LoadingService,
    ) {
    }

    async ngOnInit() {

        this.loadingService.show();
        await this.getFundAdministrator();
        await this.getRepresentativesInFundAdministrator();
        this.loadingService.hide();
    }

    async getFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundAdministratorId).then(() => {
            this.selectedFundAdministrator.documentNumber = this.parseCNPJ(this.selectedFundAdministrator.documentNumber)
        });
    }

    async getFundAdministratorById(fundAdministratorId: string) {
        this.selectedFundAdministrator = await this.fundAdministratorService.getById(fundAdministratorId);
    }

    async getRepresentativesInFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.allRepresentatives = await this.fundAdministratorService.getRepresentativesInFundAdministrator(fundAdministratorId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }

    editFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-administrator/edit/${fundAdministratorId}`);
    }

    onCancel() {
        this.router.navigateByUrl(`/app/customer/fund-administrator/list`);
    }

    updateRegistrationFormTemplate() {
        const fundAdministratorId = this.route.snapshot.params.id;
        this.router.navigateByUrl(`/app/customer/fund-administrator/edit-form-template/${fundAdministratorId}`)
    }

}