import { LazyLoadEvent } from "primeng/api";

export interface PagedSearchRequest {
    globalFilter: string;
    pageSize: number;
    pageIndex: number;
    sortField: string;
    sortOrder: SortOrder;
}

export enum SortOrder {
    Ascending = 1,
    Descending = -1
}

export function CreatePagedSearchRequest(event: LazyLoadEvent) : PagedSearchRequest {
    console.log('paged-search-request', 'CreatePagedSearchRequest())', event);

    let pagedSearchRequest: PagedSearchRequest = {
        globalFilter: event?.globalFilter,
        pageSize: event?.rows ? event.rows : 10,
        pageIndex: event?.first ? event.first / (event.rows ? event.rows : 10) : 0,
        sortField: event?.sortField,
        sortOrder: event?.sortOrder
    };

    console.log('paged-search-request', 'CreatePagedSearchRequest())', 'pagedSearchRequest', pagedSearchRequest);

    return pagedSearchRequest;
}