<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Convidar Usuário
            </div>
            <div class="subTitle">
                Informe os dados do usuário a ser convidado
            </div>
        </div>

        <form #entryForm="ngForm" novalidate>
            <div class="customCard">
                <div class="rowForm flexRow">
                    <div class="cardHeader text-bold">Dados Pessoais</div>
                </div>
                <div class="p-fluid p-formgrid p-grid p-mt-3">
                    <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="fullName" type="text" [(ngModel)]="inviteUserRequest.fullName"
                                    appValidateFullName name="fullName" #fullName="ngModel" class="inputLiza" required
                                    pInputText>
                                <label for="fullName">Nome Completo</label>
                            </span>
                        </div>
                        <mark *ngIf="fullName.invalid && fullName.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                    <br>
                    <div class="p-col-12 p-md-12 p-lg-12 inputItem">
                        <div class="p-inputgroup">
                            <span class="p-float-label">
                                <input id="email" type="text" [(ngModel)]="inviteUserRequest.email" appValidateEmail
                                    name="email" #email="ngModel" class="inputLiza" required pInputText>
                                <label for="email">Digite o E-mail</label>
                            </span>
                        </div>
                        <mark *ngIf="email.invalid && email.dirty"
                            class="ui-message ui-messages-error ui-corner-all">Campo
                            obrigatório</mark>
                    </div>
                </div>
            </div>
            <div class="customCard">
                <div class="rowForm flexRow">
                    <div class="cardHeader text-bold">Funções no sistema</div>
                </div>
                <div *ngFor="let optionFoF of roleOptionsFoF" class="field-checkbox">
                    <p-radioButton [inputId]="optionFoF.value" [name]="optionFoF.label" [value]="optionFoF"
                        [(ngModel)]="selectedRolesFoF" (ngModelChange)="handleRoleFoF($event)"></p-radioButton>
                    <label class="labelCheckbox" [for]="optionFoF.label">{{optionFoF.label}}</label>
                </div>
                <br>
                <div *ngFor="let optionBroker of roleOptionsBroker" class="field-checkbox">
                    <p-radioButton [inputId]="optionBroker.value" [name]="optionBroker.label" [value]="optionBroker"
                        [(ngModel)]="selectedRolesBroker" (ngModelChange)="handleRoleBroker($event)"></p-radioButton>
                    <label class="labelCheckbox" [for]="optionBroker.label">{{optionBroker.label}}</label>
                </div>
                <!-- <div class="rowForm flexRow p-mt-3">
                    <p-pickList [source]="roleOptions" [target]="selectedRoles" sourceHeader="Funções Disponíveis"
                        targetHeader="Funções Selecionados" [dragdrop]="true" [responsive]="true"
                        [sourceStyle]="{'height':'30rem'}" [targetStyle]="{'height':'30rem'}" filterBy="label"
                        sourceFilterPlaceholder="Filtrar" [showSourceControls]="false" [showTargetControls]="false"
                        targetFilterPlaceholder="Buscar">
                        <ng-template let-infoType pTemplate="item">
                            <div class="infoType-item">
                                <div class="infoType-list-detail">
                                    <h5 class="p-mb-2">{{infoType.label}}</h5>
                                </div>
                            </div>
                        </ng-template>
                    </p-pickList>
                </div> -->
            </div>

            <div class="customCard">
                <div class="p-d-flex p-jc-end">
                    <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                        (click)="onSave(entryForm)"></button>
                </div>
            </div>
        </form>
    </div>
</div>