import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { UpdateRegistrationFormTemplateRequest } from 'src/app/services/fund-administrators/models/requests/update-registration-form-template-request';
import { FundAdministratorViewModel } from 'src/app/services/fund-administrators/models/view-models/fund-administrator-view-model';

@Component({
    templateUrl: './fund-administrator-edit-form-template.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundAdministratorEditFormTemplateComponent implements OnInit {

    requestModel: UpdateRegistrationFormTemplateRequest = null;
    selectedFundAdministrator: FundAdministratorViewModel = null;


    constructor(
        private fundAdministratorService: FundAdministratorService,
        private router: Router,
        private route: ActivatedRoute,
        private formValidationService: FormValidationService,
        private loadingService: LoadingService,
        private messageService: MessageService,
    ) {
    }

    async ngOnInit() {
        this.setforms();
        await this.getFundAdministrator();
    }

    setforms() {
        this.requestModel = {
            fundAdministratorId: null,
            registrationFormTemplateRequest: null,
        }
    }

    async getFundAdministrator() {
        const fundAdministratorId = this.route.snapshot.params.id;
        await this.getFundAdministratorById(fundAdministratorId).then(() => {
            this.selectedFundAdministrator.documentNumber = this.parseCNPJ(this.selectedFundAdministrator.documentNumber)
        });
    }

    async getFundAdministratorById(fundAdministratorId: string) {
        this.selectedFundAdministrator = await this.fundAdministratorService.getById(fundAdministratorId);
    }

    parseCNPJ(value: string): string {
        var x = value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        return !x[2] ? x[1] : x[1] + '.' + x[2] + '.' + x[3] + '/' + x[4] + (x[5] ? '-' + x[5] : '');
    }


    registrationFormTemplateFilled(createArtifactTemplateRequest: CreateArtifactTemplateRequest) {
        this.requestModel.registrationFormTemplateRequest = createArtifactTemplateRequest;
    }


    onCancel() {
        this.router.navigateByUrl(`app/customer/fund-administrator/list`)
    }


    async onSave(formComponent: NgForm) {

        
        if (!this.formValidationService.validateForm(formComponent.form)) return;
        
        if (this.requestModel.registrationFormTemplateRequest == null) {
            this.messageService.add({ severity: 'error', summary: 'Formulário Inválido', detail: `O campo template de ficha cadastral é obrigatório.`, life: 5000 });
            return;
        }
        
        const request: UpdateRegistrationFormTemplateRequest = {
            fundAdministratorId: this.selectedFundAdministrator.id,
            registrationFormTemplateRequest: this.requestModel.registrationFormTemplateRequest,
        }

        try {
            this.loadingService.show();
            await this.fundAdministratorService.updateRegistrationFormTemplate(request);
            await this.getFundAdministrator();
            this.messageService.add({ severity: 'success', summary: 'Alteração efetuada com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.loadingService.hide();
        }
    }

    // updateRegistrationFormTemplate() {
    //     const fundAdministratorId = this.route.snapshot.params.id;
    //     this.router.navigateByUrl(`/app/customer/fund-administrator/edit-form-template/${fundAdministratorId}`)
    // }
    
}