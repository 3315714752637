<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Corretoras
            </div>
        </div>
        <div class="customCard">
            <div class="p-col-12 p-md-12 p-lg-12">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="contentButtonActions">
                        <button pButton pRipple type="button" class="p-button-outlined" label="Cancelar operações em lote"
                            (click)="openCancelBrokerOperations()"></button>
                    </div>
                </div>
                <p-tabView (onChange)="changeTab($event.index)" [(activeIndex)]="tabView">
                    <p-tabPanel header="Em Andamento">
                        <p-table 
                            [responsive]="true" 
                            #dt 
                            [value]="brokerOperationsOnGoing" 
                            dataKey="id"
                            styleClass="p-datatable-customized"
                            selectionMode="single"
                            [rowHover]="true"
                            [autoLayout]="true" 
                            [paginator]="true" 
                            [lazy]="true"
                            (onLazyLoad)="getBrokerOperationsOnGoingData($event)"
                            [rows]="brokerOperationsOnGoingResult?.pageSize"
                            [totalRecords]="brokerOperationsOnGoingResult?.totalRecords"
                            [rowsPerPageOptions]="[10,25,50,100]"
                            [filterDelay]="0"
                            [globalFilterFields]="['sequentialId','type.friendlyName', 'fund', 'fund.sac', 'cblc', 'addtionNumber', 'broker', 'currentStatus.status.friendlyName']">
                            <ng-template pTemplate="caption">
                                <div class="contentButtonActions p-fluid">
                                    
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" let-operation>
                                <tr>
                                    <th pSortableColumn="sequentialId">
                                        <div>
                                            ID LIZA
                                            <p-sortIcon field="sequentialId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="legacySystemId">
                                        <div>
                                            ID Legado
                                            <p-sortIcon field="legacySystemId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="createDate">
                                        <div>
                                            Data da Entrada
                                            <p-sortIcon field="createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="type.friendlyName">
                                        <div>
                                            Operação
                                            <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund">
                                        <div>
                                            Fundo Cotista
                                            <p-sortIcon field="fund"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="frozenFundSAC">
                                        <div>
                                            SIGLA SAC
                                            <p-sortIcon field="frozenFundSAC"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="cblc">
                                        <div>
                                            CBLC
                                            <p-sortIcon field="cblc"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="addtionNumber">
                                        <div>
                                            ADITAMENTO
                                            <p-sortIcon field="addtionNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="broker">
                                        <div>
                                            Corretora
                                            <p-sortIcon field="broker"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.status.friendlyName">
                                        <div>
                                            Status
                                            <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-operation let-index>
                                <tr style="cursor: pointer" [pSelectableRow]="operation">
                                    <td>
                                        {{operation?.sequentialId}}
                                    </td>
                                    <td>
                                        {{operation?.legacySystemId}}
                                    </td>
                                    <td>
                                        {{operation?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{operation?.type?.friendlyName}}
                                    </td>
                                    <td>
                                        {{operation?.fund?.name}}
                                    </td>
                                    <td>
                                        {{operation?.frozenFundSAC}}
                                    </td>
                                    <td>
                                        {{operation?.cblc}}
                                    </td>
                                    <td>
                                        {{operation?.addtionNumber}}
                                    </td>
                                    <td>
                                        {{operation?.broker?.name}}
                                    </td>
                                    <td>
                                        {{operation?.currentStatus?.status?.friendlyName}}
                                    </td>
                                    <td>
                                        <p-menu #menu [popup]="true" [model]="getMenuItems(operation)"></p-menu>
                                        <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                            class="p-button-text" (click)="menu.toggle($event)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Nenhuma operação encontrada.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Concluídos">
                        <p-table 
                            [responsive]="true" 
                            #dtFinish
                            [value]="brokerOperationsFinished" 
                            dataKey="id"
                            styleClass="p-datatable-customized" 
                            selectionMode="single" 
                            [rowHover]="true" 
                            [autoLayout]="true" 
                            [paginator]="true" 
                            [lazy]="true"
                            (onLazyLoad)="getOperationsFinishedData($event)"
                            [rows]="brokerOperationsFinishedResult?.pageSize"
                            [totalRecords]="brokerOperationsFinishedResult?.totalRecords"
                            [rowsPerPageOptions]="[10,25,50,100]"
                            [filterDelay]="0"
                            [globalFilterFields]="['sequentialId','type.friendlyName', 'fund.name', 'fund.sac', 'cblc', 'addtionNumber', 'broker.name', 'currentStatus.status.friendlyName']">
                            <ng-template pTemplate="caption">
                                <div class="contentButtonActions p-fluid">
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="sequentialId">
                                        <div>
                                            ID LIZA
                                            <p-sortIcon field="sequentialId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="legacySystemId">
                                        <div>
                                            ID Legado
                                            <p-sortIcon field="legacySystemId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="createDate">
                                        <div>
                                            Data da Entrada
                                            <p-sortIcon field="createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.createDate">
                                        <div>
                                            Data Conclusão
                                            <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="type.friendlyName">
                                        <div>
                                            Operação
                                            <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund">
                                        <div>
                                            Fundo Cotista
                                            <p-sortIcon field="fund"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.sac">
                                        <div>
                                            SIGLA SAC
                                            <p-sortIcon field="fund.sac"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="cblc">
                                        <div>
                                            CBLC
                                            <p-sortIcon field="cblc"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="addtionNumber">
                                        <div>
                                            ADITAMENTO
                                            <p-sortIcon field="addtionNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="broker">
                                        <div>
                                            Corretora
                                            <p-sortIcon field="broker"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.status.friendlyName">
                                        <div>
                                            Status
                                            <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-admin let-index>
                                <tr style="cursor: pointer" [pSelectableRow]="admin">
                                    <td>
                                        {{admin?.sequentialId}}
                                    </td>
                                    <td>
                                        {{admin?.legacySystemId}}
                                    </td>
                                    <td>
                                        {{admin?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{admin?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{admin?.type?.friendlyName}}
                                    </td>
                                    <td>
                                        {{admin?.fund?.name}}
                                    </td>
                                    <td>
                                        {{admin?.frozenFundSAC}}
                                    </td>
                                    <td>
                                        {{admin?.cblc}}
                                    </td>
                                    <td>
                                        {{admin?.addtionNumber}}
                                    </td>
                                    <td>
                                        {{admin?.broker?.name}}
                                    </td>
                                    <td>
                                        {{admin?.currentStatus?.status?.friendlyName}}
                                    </td>
                                    <td>
                                        <p-menu #menu [popup]="true" [model]="getMenuItems(admin)"></p-menu>
                                        <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                            class="p-button-text" (click)="menu.toggle($event)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Nenhuma operação encontrada.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                    <p-tabPanel header="Cancelados">
                        <p-table 
                            [responsive]="true" 
                            #dtCanceled 
                            [value]="brokerOperationsCanceled" 
                            dataKey="id"
                            styleClass="p-datatable-customized" 
                            selectionMode="single" 
                            [rowHover]="true" 
                            [autoLayout]="true" 
                            [paginator]="true" 
                            [lazy]="true"
                            (onLazyLoad)="getOperationsCanceledData($event)"
                            [rows]="brokerOperationsCanceledResult?.pageSize"
                            [totalRecords]="brokerOperationsCanceledResult?.totalRecords"
                            [rowsPerPageOptions]="[10,25,50,100]"
                            [filterDelay]="0"
                            [globalFilterFields]="['sequentialId','type.friendlyName', 'fund.name', 'fund.sac', 'cblc', 'addtionNumber', 'broker.name', 'currentStatus.status.friendlyName']">
                            <ng-template pTemplate="caption">
                                <div class="contentButtonActions p-fluid">

                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="sequentialId">
                                        <div>
                                            ID LIZA
                                            <p-sortIcon field="sequentialId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="legacySystemId">
                                        <div>
                                            ID Legado
                                            <p-sortIcon field="legacySystemId"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="createDate">
                                        <div>
                                            Data da Entrada
                                            <p-sortIcon field="createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.createDate">
                                        <div>
                                            Data Cancelamento
                                            <p-sortIcon field="currentStatus.createDate"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="type.friendlyName">
                                        <div>
                                            Operação
                                            <p-sortIcon field="type.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund">
                                        <div>
                                            Fundo Cotista
                                            <p-sortIcon field="fund"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="fund.sac">
                                        <div>
                                            SIGLA SAC
                                            <p-sortIcon field="fund.sac"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="cblc">
                                        <div>
                                            CBLC
                                            <p-sortIcon field="cblc"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="addtionNumber">
                                        <div>
                                            ADITAMENTO
                                            <p-sortIcon field="addtionNumber"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="broker">
                                        <div>
                                            Corretora
                                            <p-sortIcon field="broker"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="currentStatus.status.friendlyName">
                                        <div>
                                            Status
                                            <p-sortIcon field="currentStatus.status.friendlyName"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th>Ações</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-admin let-index>
                                <tr style="cursor: pointer" [pSelectableRow]="admin">
                                    <td>
                                        {{admin?.sequentialId}}
                                    </td>
                                    <td>
                                        {{admin?.legacySystemId}}
                                    </td>
                                    <td>
                                        {{admin?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{admin?.currentStatus?.createDate | date: 'dd/MM/yyyy'}}
                                    </td>
                                    <td>
                                        {{admin?.type?.friendlyName}}
                                    </td>
                                    <td>
                                        {{admin?.fund?.name}}
                                    </td>
                                    <td>
                                        {{admin?.frozenFundSAC}}
                                    </td>
                                    <td>
                                        {{admin?.cblc}}
                                    </td>
                                    <td>
                                        {{admin?.addtionNumber}}
                                    </td>
                                    <td>
                                        {{admin?.broker?.name}}
                                    </td>
                                    <td>
                                        {{admin?.currentStatus?.status?.friendlyName}}
                                    </td>
                                    <td>
                                        <p-menu #menu [popup]="true" [model]="getMenuItems(admin)"></p-menu>
                                        <button type="button" pButton pRipple icon="pi pi-ellipsis-h"
                                            class="p-button-text" (click)="menu.toggle($event)"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">Nenhuma operação encontrada.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>


<p-dialog header="Detalhes" [(visible)]="displayBrokerOperationDetail" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 textPrimary contentCenterColumn">
            Operação: {{selectedBrokerOperation?.type?.friendlyName}}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Fundo:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.fund?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.fund?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Corretora:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.broker?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.broker?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Administrador do Fundo:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.fund?.administrator?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.fund?.administrator?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>

        <div class="p-col-12 p-md-3 p-lg-3 text-bold">
            Gestora do Fundo:
        </div>
        <div class="p-col-12 p-md-4 p-lg-4">
            {{selectedBrokerOperation?.fund?.manager?.name}}
        </div>
        <div class="p-col-12 p-md-2 p-lg-2 text-bold">
            CNPJ:
        </div>
        <div class="p-col-12 p-md-3 p-lg-3">
            {{selectedBrokerOperation?.fund?.manager?.documentNumber | documentNumber: 'cnpj' }}
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <h5 class="text-bold">Histórico:</h5>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12">
            <p-scrollPanel [style]="{width: '100%', height: '500px'}">
                <p-accordion [activeIndex]="0">
                    <p-accordionTab *ngFor="let item of selectedBrokerOperation?.history; let i = index" [attr.data-index]="i"
                        expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up"
                        header="{{item?.createDate | date : 'dd/MM/yyyy HH:mm'}} - {{item?.status?.friendlyName}}" class="p-mt-3">
                        <div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <p><span [innerHTML]="breakTextLine(item?.notes)"></span></p>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </p-scrollPanel>
        </div>
        <div class="p-col-12">
            <p-divider></p-divider>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Erro ao iniciar operação" [(visible)]="displayBrokerOperationInit" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12">
            <div class="cardHeader text-bold">{{selectedBrokerOperation?.currentStatus?.notes}}</div>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Cancelar Operação" [(visible)]="displayBrokerOperationCancel" [modal]="true" [responsive]="true"
    [style]="{width: '40vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12">
            <form #cancelForm="ngForm" novalidate>
                <div class="p-grid">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <h4>Deseja cancelar a operação com o id {{selectedBrokerOperationCancel?.sequentialId}}?</h4>
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                        <textarea id="float-input" pInputTextarea name="notes" [style]="{'width': '100%'}"
                            placeholder="Motivo" [(ngModel)]="requestBrokerOperationCancel.notes" required
                            #notes="ngModel"></textarea>
                    </div>
                </div>
                <div class="contentButtonActions">
                    <button pButton pRipple type="button" label="Salvar"
                        (click)="confirmCancelOperation(cancelForm)"></button>
                </div>
            </form>
        </div>

    </div>

    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Motivo" [(visible)]="displayReasonBrokerOperation" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">

    <div class="p-grid">
        <div class="p-col-12 p-md-12 2 p-lg-12">
            <p class="text-bold">Motivo:</p> <span>{{selectedBrokerOperationDisplay?.currentStatus?.notes}}</span>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" label="Fechar" (click)="close()"></button>
    </div>

</p-dialog>

<p-dialog header="Cancelar operações em lote" [(visible)]="displayCancelBrokerOperations" [modal]="true" [responsive]="true"
    [style]="{width: '80vw'}">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
            <textarea id="float-input" pInputTextarea name="notes" [style]="{'width': '100%'}" placeholder="Motivo *"
                [(ngModel)]="selectedNoteCancelBrokerOperations" required #notes="ngModel"></textarea>
        </div>
        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
            <div class="p-grid">
                <div class="p-col-10 p-md-10 p-lg-10 p-fluid">
                    <div class="p-inputgroup">
                        <span class="p-float-label">
                            <input id="selectedCancelOperation" type="text" [(ngModel)]="selectedCancelBrokerOperation" (keyup)="handleEnterKeyPress($event)"
                                name="selectedCancelOperation" class="inputLiza" pInputText>
                            <label for="selectedCancelOperation">Digite o ID da operação</label>
                        </span>
                    </div>
                </div>
                <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                    <button pButton pRipple type="button" class="p-button-outlined" label="Adicionar a Lista"
                        (click)="handleSearchBrokerOperation()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12 p-fluid" *ngFor="let op of selectedCancelBrokerOperations">
                    <div class="p-grid">
                        <div class="p-col-10 p-md-10 p-lg-10 p-fluid" >
                            <p style="border: 1px solid #ccc;padding: 0.5rem;">{{op.label}}</p>
                        </div>
                        <div class="p-col-2 p-md-2 p-lg-2 p-fluid">
                            <button pButton pRipple type="button" icon="pi pi-trash" 
                            class="p-button-rounded p-button-outlined p-button-text" (click)="removeSelectedCancelBrokerOperation(op)"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" class="p-button-outlined" label="Cancelar" (click)="displayCancelBrokerOperations = false"></button>
        <button pButton pRipple type="button" label="Salvar" (click)="handleSaveCancelBrokerOperations()"></button>
    </div>
</p-dialog>

<p-dialog header="Editar ID Legado" [(visible)]="displayLegacyId" [modal]="true" [responsive]="true"
    [style]="{width: '30vw'}">
    <div class="p-grid">
        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
            Editar ID Legado {{selectedBrokerOperationLegacyId?.legacySystemId}}
        </div>
        <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
            <div class="p-inputgroup">
                <span class="p-float-label">
                    <input id="legacySystemId" type="text" [(ngModel)]="brokerOperationlegacyId" name="legacySystemId" class="inputLiza"
                        pInputText>
                    <label for="legacySystemId">ID Legado</label>
                </span>
            </div>
        </div>
    </div>
    <div class="contentButtonActions">
        <button pButton pRipple type="button" class="p-button-outlined" label="Cancelar"
            (click)="displayLegacyId = false"></button>
        <button pButton pRipple type="button" label="Salvar" (click)="onSaveLegacyId()"></button>
    </div>
    
</p-dialog>