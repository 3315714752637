<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Visualizar Administrador
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Administrador de Fundo</div>
                </div>
                <div class="p-col-12 p-md-8 p-lg-8 ">
                    <div class="borderOutlined heightFix250">
                        <p><b class="text-bold">Nome:</b> <span> {{fundAdministrator?.name}}</span></p>
                        <p><b class="text-bold">CNPJ:</b> <span> {{fundAdministrator?.documentNumber |
                                documentNumber:'CNPJ'}}</span></p>
                        <p><b class="text-bold">Email:</b> <span> {{fundAdministrator?.email}}</span></p>
                        <p><b class="text-bold">Telefone:</b> <span> {{fundAdministrator?.phoneNumber | phoneNumber }}</span></p>
                        <p><b class="text-bold">Número GIIN:</b> <span> {{fundAdministrator?.giinNumber }}</span></p>
                        <p><b class="text-bold">Data de Constituição:</b> <span> {{fundAdministrator?.establishmentDate | date: 'dd/MM/yyyy' }}</span></p>
                        <a (click)="openEditFundAdministrator()" class="linkPrimary">
                            Editar
                        </a>
                    </div>
                </div>
                <div class="p-col-12 p-md-4 p-lg-4">
                    <div class="borderOutlined contentCenterColumn heightFix250"
                        *ngIf="fundAdministrator?.registrationFormTemplate">
                        <div class="text-bold">
                            Modelo de Ficha Cadastral
                        </div>
                        <a [href]="fundAdministrator.registrationFormTemplate.fileUrl" target="_blank"
                            class="linkPrimary">
                            Ver Template
                        </a>
                        <p><b class="text-bold">Data de inclusão:</b> {{fundAdministrator?.registrationFormTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                    <div class="cardHeader text-bold">Setups Cadastrados</div>
                    <div class="borderOutlined">
                        <div *ngIf="fundAdministrator?.operationSetups.length == 0">
                            <span>Não há setups cadastrados</span>
                        </div>
                        <div class="p-grid" *ngIf="fundAdministrator?.operationSetups.length > 0"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold ">
                                Tipo
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Ficha Cadastral
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Termo de Adesão
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Boletim de Subscrição
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-bold text-center">
                                Carta
                            </div>
                            <div class="p-col-12 p-md-1 p-lg-1 text-bold text-center">
                                <!-- Planilha ICVM -->
                                Template Extra
                            </div>
                            <!-- <div class="p-col-1 p-md-1 p-lg-1 text-bold">
                                Excluir
                            </div> -->
                        </div>
                        <div *ngFor="let operation of fundAdministrator?.operationSetups" class="p-grid"
                            style="display: flex;flex-direction: row;align-items: center;">
                            <div class="p-col-12 p-md-2 p-lg-2">
                                {{ operation.operationType.friendlyName}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                {{ operation.hasFundAdministratorRegistrationFormTemplate ? 'Sim' : 'Não'}}
                                {{(operation.hasFundAdministratorRegistrationFormTemplate &&
                                operation.isFundAdministratorRegistrationFormTemplateSignable ? '(Assinável)' : '')}}
                                {{(operation.hasFundAdministratorRegistrationFormTemplate &&
                                !operation.isFundAdministratorRegistrationFormTemplateSignable ? '(Não Assinável)' :
                                '')}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                {{ operation.hasFundAdhesionTermTemplate ? 'Sim' : 'Não'}}
                                {{(operation.hasFundAdhesionTermTemplate && operation.isFundAdhesionTermTemplateSignable
                                ?
                                '(Assinável)' : '')}}
                                {{(operation.hasFundAdhesionTermTemplate &&
                                !operation.isFundAdhesionTermTemplateSignable ?
                                '(Não Assinável)' : '')}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                {{ operation.hasFundSubscriptionFormTemplate ? 'Sim' : 'Não'}}
                                {{(operation.hasFundSubscriptionFormTemplate &&
                                operation.isFundSubscriptionFormTemplateSignable ? '(Assinável)' : '')}}
                                {{(operation.hasFundSubscriptionFormTemplate &&
                                !operation.isFundSubscriptionFormTemplateSignable ? '(Não Assinável)' : '')}}
                            </div>
                            <div class="p-col-12 p-md-2 p-lg-2 text-center">
                                <p>{{ operation.letterTemplate != null ? 'Sim' : 'Não'}}
                                    {{(operation.letterTemplate && operation.isLetterTemplateSignable ? '(Assinável)' :
                                    '')}}
                                    {{(operation.letterTemplate && !operation.isLetterTemplateSignable ? '(Não Assinável)' :
                                    '')}}
                                </p>
                                <div style="margin-top: 1rem;">
                                    <a class="textPrimary" [href]="operation.letterTemplate?.fileUrl">{{
                                        operation.letterTemplate?.fileUrl != null ? 'Ver template' : ''}}</a>
                                </div>
                                <div *ngIf="operation.letterTemplate != null" style="margin-top: 1rem;">
                                    <p class="text-bold">Data de inclusão:</p>
                                    <p>{{operation?.letterTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-1 p-lg-1 text-center">
                                <p>{{ operation.icvmTemplate != null ? 'Sim' : 'Não'}}
                                    {{(operation.icvmTemplate && operation.isICVMTemplateSignable ? '(Assinável)' :
                                    '')}}
                                    {{(operation.icvmTemplate && !operation.isICVMTemplateSignable ? '(Não Assinável)' :
                                    '')}}
                                </p>
                                <div style="margin-top: 1rem;">
                                    <a class="textPrimary"
                                        [href]="operation.icvmTemplate?.fileUrl">{{operation.icvmTemplate?.fileUrl != null ?
                                        'Ver template' : ''}}</a>
                                </div>
                                <div *ngIf="operation.icvmTemplate != null" style="margin-top: 1rem;">
                                    <p class="text-bold">Data de inclusão:</p>
                                    <p>{{operation?.icvmTemplate?.createDate | date : 'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-12">
                                <div class="line"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="p-grid">
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Procuradores / Rep Legal</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-12 p-lg-12">
                            <div class="p-grid">
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Nome</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">CPF</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">E-mail</p>
                                </div>
                                <div class="p-col-12 p-md-3 p-lg-3">
                                    <p class="text-bold">Data de Validade</p>
                                </div>
                            </div>
                            <form #managerForm="ngForm" novalidate>
                                <div class="p-grid" *ngFor="let representative of allRepresentatives">
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.name}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.maskedPersonalDocumentNumber}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.email}}
                                    </div>
                                    <div class="p-col-12 p-md-3 p-lg-3">
                                        {{ representative?.expireDate | date: 'dd/MM/yyyy' }}
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12">
                                        <div class="line"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Editar Informações" [(visible)]="displayEditFundAdministrator" [modal]="true" [draggable]="false"
    [resizable]="false">
    <form #editForm="ngForm" novalidate>
        <div class="p-grid p-mt-2">
            <div class="p-col-12 p-md-12 p-lg-12 p-sm-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="email" type="text"
                            [(ngModel)]="request.email" name="email"
                            #email="ngModel" class="inputLiza" pInputText>
                        <label for="email">Email</label>
                    </span>
                </div>
                <mark *ngIf="email.invalid && email.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-22 p-lg-22 p-sm-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-inputMask inputId="phoneNumber" class="inputLizaMask" mask="(99) 9 9999-999?9"
                            [(ngModel)]="request.phoneNumber" name="phoneNumber" #phoneNumber="ngModel"
                            [unmask]="true">
                        </p-inputMask>
                        <label for="phoneNumber" class="labelLiza">Telefone</label>
                    </span>
                </div>
                <mark *ngIf="phoneNumber.invalid && phoneNumber.dirty"
                    class="ui-message ui-messages-error ui-corner-all">Campo
                    obrigatório</mark>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12 p-fluid">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <input id="giinNumber" type="text" [(ngModel)]="request.giinNumber"
                            name="giinNumber" #giinNumber="ngModel" class="inputLiza" pInputText>
                        <label for="giinNumber">Número GIIN</label>
                    </span>
                </div>
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
                <div class="p-inputgroup">
                    <span class="p-float-label">
                        <p-calendar [(ngModel)]="establishmentDate" appendTo="body"
                            name="establishmentDatee" yearRange="2022:2099" [showIcon]="true"
                            [monthNavigator]="true" dateFormat="dd/mm/yy" [yearNavigator]="true"
                            [required]="true" #establishmentDatee="ngModel" [required]="true">
                        </p-calendar>
                        <label for="establishmentDatee" class="labelOcta">Data de Constituição</label>
                    </span>
                </div>
            </div>
            
        </div>
        <div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12 contentButtonActions">
                <button pButton pRipple type="button" label="Cancelar" class="p-button-outlined"
                    (click)="close()"></button>
                <button pButton pRipple type="button" label="Salvar" class="action-button p-ml-2"
                    (click)="onSaveEditFundAdministrator(editForm)"></button>
            </div>
        </div>
    </form>

</p-dialog>