import { Injectable } from '@angular/core';
import { HttpClientService } from 'src/app/providers/http-client-service';
import { AddressInformationViewModel } from './models/view-models/address-information-view-model';
import { CityViewModel } from './models/view-models/city-view-model';
import { ProvinceViewModel } from './models/view-models/province-view-model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(private httpClient: HttpClientService) { }

  async getProvices(): Promise<ProvinceViewModel[]> {
    return this.httpClient.get('/locations', `/provinces`, true);
  }

  async getCitiesByProvince(provinceAbbreviation: string): Promise<CityViewModel[]> {
    return this.httpClient.get('/locations', `/provinces/${provinceAbbreviation}/cities`, false);
  }

  async getAddressByPostalCode(postalCode: string): Promise<AddressInformationViewModel> {
    return this.httpClient.get(`/locations`, `/postal-codes/${postalCode}`, true);
  }

}
