<div class="containerLogin">
    <div class="boxLogin">
        <div class="leftBox">
            <div class="whiteLogo"></div>
            <div class="textLogin">
                Economize tempo e recursos na gestão dos informações empresariais
            </div>
        </div>
        <div class="rightBox">
            <div class="ocLogo"></div>
            <div class="title">
                Olá {{user?.fullName}}
            </div>
            <div class="boxForm">
                <div *ngIf="user != null" class="rowActivation">
                    <div class="pages-detail p-mb-6">
                        O código foi enviado para <strong>{{user.emailActivationCodeExpirationDate != null ?
                            (user.email) :
                            (user.cellPhone | phoneNumber )}}</strong>
                        <br>
                        e está válido até <strong>{{user.emailActivationCodeExpirationDate != null ?
                            (user.emailActivationCodeExpirationDate | date:'dd/MM/yyyy HH:mm') :
                            (user.cellPhoneActivationCodeExpirationDate | date:'dd/MM/yyyy HH:mm')}}</strong>
                    </div>

                    <div class="input-panel p-d-flex p-flex-column">
                        <form #activationForm="ngForm" novalidate>

                            <div class="p-inputgroup">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-exclamation-triangle"></i>
                                </span>
                                <span class="p-float-label">
                                    <input class="inputOcta" id="activationCode" type="text"
                                        [(ngModel)]="activateFormFields.activationCode" name="activationCode"
                                        #activationCode="ngModel" required pInputText />
                                    <label class="labelOcta" for="activationCode">Código Recebido</label>
                                </span>
                            </div>
                            <mark *ngIf="activationCode.invalid && activationCode.dirty"
                                class="ui-message ui-messages-error ui-corner-all markError">Campo
                                obrigatório</mark>

                            <div *ngIf="user.maskedDocumentNumber == null">
                                <div class="p-inputgroup p-mt-4">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-user"></i>
                                    </span>
                                    <span class="p-float-label">
                                        <p-inputMask class="inputOctaMask" inputId="documentNumber" type="tel"
                                            mask="999.999.999-99" [(ngModel)]="activateFormFields.documentNumber"
                                            name="documentNumber" #documentNumber="ngModel" [unmask]="true" [required]="true">
                                        </p-inputMask>
                                        <label class="labelOcta" for="documentNumber">CPF</label>
                                    </span>
                                </div>
                                <mark *ngIf="documentNumber.invalid && documentNumber.dirty"
                                    class="ui-message ui-messages-error ui-corner-all markError">Campo
                                    obrigatório</mark>

                            </div>

                            <div class="p-inputgroup p-mt-4">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-calendar"></i>
                                </span>
                                <span class="p-float-label">
                                    <p-calendar class="inputOctaMask" inputId="birthdate" [monthNavigator]="true"
                                        yearRange="1950:2099" [yearNavigator]="true"
                                        [(ngModel)]="activateFormFields.birthdate" name="birthdate" #birthdate="ngModel"
                                        dateFormat="dd/mm/yy" [maxDate]="maxDate" [defaultDate]="maxDate" [required]="true">
                                    </p-calendar>
                                    <label class="labelOcta" for="birthdate">Data de Nascimento</label>
                                </span>
                            </div>
                            <mark *ngIf="birthdate.invalid && birthdate.dirty"
                                class="ui-message ui-messages-error ui-corner-all markError">Campo
                                obrigatório</mark>

                            <div class="p-inputgroup p-mt-4">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-lock"></i>
                                </span>
                                <span class="p-float-label">
                                    <input class="inputOcta" id="saltPassword" type="password"
                                        [(ngModel)]="activateFormFields.saltPassword" name="saltPassword" required
                                        #saltPassword="ngModel" appValidatePassword pPassword />
                                    <label class="labelOcta" for="saltPassword">Senha</label>
                                </span>
                            </div>
                            <mark *ngIf="saltPassword.invalid && saltPassword.dirty"
                                class="ui-message ui-messages-error ui-corner-all markError">A senha deve ser
                                maior que 6 caracteres e conter número</mark>

                            <div class="p-inputgroup p-mt-4">
                                <span class="p-inputgroup-addon">
                                    <i class="pi pi-lock"></i>
                                </span>
                                <span class="p-float-label">
                                    <input class="inputOcta" id="confirmPasswordInput" type="password"
                                        [(ngModel)]="confirmPassword" name="confirmPasswordInput" required
                                        #confirmPasswordInput="ngModel" pInputText />
                                    <label class="labelOcta" for="confirmPasswordInput">Confirmar Senha</label>
                                </span>
                            </div>
                            <mark
                                *ngIf="confirmPasswordInput.invalid && confirmPasswordInput.dirty || (confirmPasswordInput.dirty && confirmPassword != activateFormFields.saltPassword)"
                                class="ui-message ui-messages-error ui-corner-all markError">Senha e confirmar
                                senha devem ser iguais</mark>
                        </form>

                        <div class="p-mt-3">
                            <button class="buttonLogin" (click)="activateUser(activationForm)" type="submit">
                                Ativar Conta
                            </button>
                        </div>
                        <div class="containerFooter">
                            <div class="pages-detail p-mb-2 p-mt-3">
                                Você possui {{user.resendCodesAllowed}} reenvios restantes.
                            </div>

                            <div>
                                <p-button label="Reenviar Código" styleClass="p-button-link" (click)="resendCode()">
                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>