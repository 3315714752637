<div class="p-grid">
    <form #entryForm="ngForm" novalidate class="p-col-12 p-md-12">
        <div class="titleCustomCard">
            Meus Dados
        </div>
        <div class="customCard">
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-3 p-lg-3">
                    <p>Nome</p>
                    <div class="text-bold">
                        <p>{{userInfo.user.firstName}} {{userInfo.user.lastName}}</p>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <p>CPF</p>
                    <div class="text-bold">
                        <p>{{userInfo.user.maskedDocumentNumber}}</p>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <p>Função</p>
                    <div class="text-bold" *ngFor="let user of userInfo?.user?.userRoles">
                        <p>{{user?.role?.friendlyName}}</p>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <p>Data de Nascimento</p>
                    <div class="text-bold">
                        <p>{{userInfo.user.birthdate | date: 'dd/MM/yyyy'}}</p>
                    </div>
                </div>

                <div class="p-col-12 p-md-3 p-lg-3">
                    <p>E-mail</p>
                    <div class="text-bold">
                        <p>{{userInfo.user.email}}</p>
                    </div>
                </div>

                <!-- <div class="p-field">
                    <label for="firstName">Nome*</label>
                    <input id="firstName" type="text" [(ngModel)]="userInfo.user.firstName" name="firstName"
                        #firstName="ngModel" readonly pInputText>
                </div>
                <div class="p-field">
                    <label for="lastName">Sobrenome*</label>
                    <input id="lastName" type="text" [(ngModel)]="userInfo.user.lastName" name="lastName"
                        #lastName="ngModel" readonly pInputText>
                </div>
                <div class="p-field">
                    <label for="documentNumber">CPF*</label>
                    <input id="documentNumber" type="tel" [(ngModel)]="userInfo.user.maskedDocumentNumber"
                        name="documentNumber" min="11" max="11" #documentNumber="ngModel" readonly pInputText>
                </div>
                <div class="p-field">
                    <label for="function">Função</label>
                    <input id="function" type="text" [(ngModel)]="userInfo.user.userRoles[0].role.friendlyName" name="function"
                        #function="ngModel" readonly pInputText>
                </div>
                <div class="p-field">
                    <label for="birthdate">Data de Nascimento*</label>
                    <p-calendar id="birthdate" [(ngModel)]="userInfo.user.birthdate" name="birthdate"
                        yearRange="1950:2099" [monthNavigator]="true" [yearNavigator]="true" #birthdate="ngModel"
                        dateFormat="dd/mm/yy" [minDate]="userInfo.user.birthdate" [maxDate]="userInfo.user.birthdate"
                        [readonlyInput]="true">
                    </p-calendar>
                </div>
                <div class="p-field">
                    <label for="email">Email*</label>
                    <input id="email" type="email" [(ngModel)]="userInfo.user.email" name="email" #email="ngModel"
                        readonly pInputText>
                </div> -->
                <!-- <div class="p-field">
                    <label for="cellPhone">Celular*</label>
                    <input id="cellPhone" type="tel" [(ngModel)]="userInfo.user.cellPhone" name="cellPhone" min="11"
                        max="11" #cellPhone="ngModel" readonly pInputText>
                </div> -->
            </div>
        </div>
    </form>
</div>