import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from "primeng/chip";
import { FileUploadModule } from "primeng/fileupload";
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PipesModule } from "src/app/pipes/pipes-module";
import { FillTemplateCreateComponent } from "./fill-template-create.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FileUploadModule,
        ChipModule,
        InputTextModule,
        SelectButtonModule,
        CheckboxModule,
        RadioButtonModule,
        CalendarModule,
        PipesModule,
        AccordionModule,
        PanelModule,
        MenuModule,
    ],
    declarations: [FillTemplateCreateComponent],
    exports: [ FillTemplateCreateComponent ]    
})
export class FillTemplatesModule { }
