export enum BrokerOperationStatusEnum {
    EmSetup = 1,
    PreenchimentoManualNecessario = 2,
    PreenchimentoConcluido = 3,
    AguardandoAssinatura = 4,
    EmAnalisePelaCorretora = 5,
    PendenciadaPelaCorretora = 6,
    Cancelada = 7,
    Concluída = 8,
    ErroCadastral = 9,
    ErroDePreenchimento = 10,
}