import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { LoadingService } from 'src/app/modules/loading/app.loading.service';
import { FormValidationService } from 'src/app/providers/form-validation-service';
import { CreateArtifactTemplateRequest } from 'src/app/services/artifact-templates/models/requests/create-artifact-template-request';
import { FundBrokerViewModel } from 'src/app/services/brokers/models/view-models/fund-broker-view-model';
import { FundAdministratorService } from 'src/app/services/fund-administrators/fund-administrator-service';
import { CreateFundAdministratorRequest, RepresentativeProperties } from 'src/app/services/fund-administrators/models/requests/create-fund-administrator-request';
import { FundBrokerService } from 'src/app/services/fund-brokers/fund-broker-service';
import { UpdateFundBrokerRequest } from 'src/app/services/fund-brokers/models/requests/update-fund-broker-request';
import { BrokerRepresentativeProperties } from 'src/app/services/fund-brokers/models/view-models/broker-representative-properties';
import { CreateFundBrokerRequest } from '../../../../services/fund-brokers/models/requests/create-fund-broker-request';

@Component({
    templateUrl: './fund-broker-edit-validation.component.html',
    styleUrls: [
        '../../../../../assets/layout/customized/custom-card.scss'
    ]
})
export class FundBrokerEditValidationComponent implements OnInit {

    requestModel: UpdateFundBrokerRequest = null;
    fundBrokerId: string = null;
    selectedFund: FundBrokerViewModel = null;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loadingService: LoadingService,
        private fundBrokerService: FundBrokerService,
        private messageService: MessageService,
        private formValidationService: FormValidationService) {
    }

    async ngOnInit() {
        this.fundBrokerId = this.route.snapshot.params.id;
        this.setForms();
        await this.getFundBroker();
    }

    async getFundBroker() {
        this.selectedFund = await this.fundBrokerService.getById(this.fundBrokerId);
    }

    setForms() {
        this.requestModel = {
            fundBrokerId: null,
            name: null,
            expirationDate: null,
        };
    }


    onCancel() {
        this.router.navigateByUrl('app/customer/fund-broker/list');
    }

    async onSave(formComponent: NgForm) {

        if (!this.formValidationService.validateForm(formComponent.form)) return;

        const request: UpdateFundBrokerRequest = {
            fundBrokerId: this.fundBrokerId,
            name: this.selectedFund.name,
            expirationDate: this.requestModel.expirationDate,
        }

        try {
            await this.fundBrokerService.update(request);
            this.messageService.add({ severity: 'success', summary: 'Atualização efetuado com sucesso', detail: `Os dados foram atualizados com sucesso!`, life: 5000 });
        } catch (error) {
            this.messageService.add({
                severity: 'error',
                summary: 'Não foi possível efetuar a alteração',
                detail: `${error?.messageError}!`,
                life: 5000
            });
        } finally {
            this.router.navigateByUrl('app/customer/fund-broker/list');
        }
    }
}