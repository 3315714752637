<div class="p-grid">
    <div class="p-col-12">
        <div class="titleCustomCard">
            <div class="titlePage">
                Email de finalização
            </div>
        </div>
        <div class="customCard">
            <div class="p-grid">
                <div class="-col-12 p-md-12 p-lg-12 contentButtonActions">
                    <button pButton pRipple type="button" label="Voltar" class="p-button-outlined"
                        (click)="onCancel()"></button>
                    <button pButton pRipple type="button" label="Editar" class="p-button-primary"
                        (click)="editConfigEmail()"></button>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="cardHeader text-bold">Informações</div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-12">
                    <div class="borderOutlined">
                        <p><b class="text-bold">Título do e-mail:</b> <span>
                                {{selectedFundBroker?.operationDoneNotificationSubject}}</span></p>
                        <p><b class="text-bold">Texto corpo do e-mail:</b> <span>
                                {{selectedFundBroker?.operationDoneNotificationTitle}}</span></p>
                        <div *ngFor="let email of selectedFundBroker?.operationDoneNotificationEmails">
                            <p><b class="text-bold">E-mail:</b> {{email}}</p>
                        </div>
                    </div>
                </div>



            </div>
            <br>
        </div>
    </div>
</div>